import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
// in bytes, compress images larger than 1MB
const fileSizeMax = 1 * 1000 * 1000;
// in pixels, compress images have the width or height larger than 1024px
const widthHeightMax = 1024;
const defaultWidthHeightRatio = 1;
const defaultQualityRatio = 0.8;
export class CompressImageService {
    compress(file) {
        const imageType = file.type || 'image/jpeg';
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return Observable.create(observer => {
            // This event is triggered each time the reading operation is successfully completed.
            reader.onload = ev => {
                // Create an html image element
                const img = this.createImage(ev);
                // Choose the side (width or height) that longer than the other
                const imgWH = img.width > img.height ? img.width : img.height;
                // Determines the ratios to compress the image
                let withHeightRatio = (imgWH > widthHeightMax) ? widthHeightMax / imgWH : defaultWidthHeightRatio;
                let qualityRatio = (file.size > fileSizeMax) ? fileSizeMax / file.size : defaultQualityRatio;
                // Fires immediately after the browser loads the object
                img.onload = () => {
                    const elem = document.createElement('canvas');
                    // resize width, height
                    elem.width = img.width * withHeightRatio;
                    elem.height = img.height * withHeightRatio;
                    const ctx = elem.getContext('2d');
                    ctx.drawImage(img, 0, 0, elem.width, elem.height);
                    ctx.canvas.toBlob(
                    // callback, called when blob created
                    blob => {
                        observer.next(new File([blob], file.name, {
                            type: imageType,
                            lastModified: Date.now(),
                        }));
                    }, imageType, qualityRatio);
                };
            };
            // Catch errors when reading file
            reader.onerror = error => observer.error(error);
        });
    }
    createImage(ev) {
        let imageContent = ev.target.result;
        const img = new Image();
        img.src = imageContent;
        return img;
    }
}
CompressImageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompressImageService_Factory() { return new CompressImageService(); }, token: CompressImageService, providedIn: "root" });
