<div class="y3-main-menu">
  <div class="y3-flex-row y3-flex-align-center" style="height: 100%; width: 100%">
    <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between" style="height: 100%; width: 100%">
      <img *ngIf="corporate?.logoUrl" style="height: 60px; padding-right: 24px;" [src]="corporate.logoUrl">
    </div>
    <div *ngIf="displayChat">
      <mat-icon *ngIf="!isDesktopMode()" class="y3-color-green-vogue">chat_outline</mat-icon>
      <app-y3-explained-button *ngIf="isDesktopMode() && booking && !property?.noShowChatContact" routerLink="{{'/guest/' + booking?.id +'/chat'}}" [title]="'menu-desktop.messaging' | translate" [description]="'Discuter en direct avec votre agence'"></app-y3-explained-button>
    </div>
  </div>
</div>
