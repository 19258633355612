<app-y3-header [corporate]="corporate" [booking]="booking" [property]="property"></app-y3-header>

<div class="y3-container {{isDesktopMode()?'y3-padding-top-32':'y3-padding-top-0'}}">


  <div class="y3-roboto">
    <div class="y3-flex-row y3-margin-bottom-24 y3-Large y3-McQueen y3-flex-justify-content-center ">
      <div class="">
        <app-tag (closeEvent)="back()" status="{{nonBlocking?'close':''}}" sizeText2="'16px'" minWidth="{{isDesktopMode()? '1024px': '100%'}}" text2="{{'guest-setup-page.pre-checkin' | translate}}" [type]="'banner'"></app-tag>
      </div>
    </div>


    <!-- ARRIVEE DEPART -->
    <div class="y3-yaago-title-page" >{{'guest-setup-page.about-arrival' | translate}}</div>

    <div *ngIf="booking" class="y3-flex-responsive y3-margin-bottom-16">
      <div class="y3-flex-column field3" >
        <mat-form-field appearance="fill" class="field3">
          <mat-label class="y3-yaago-sub-title-page">{{'guest-setup-page.check-in' | translate}}</mat-label>
          <input class="y3-roboto y3-Small time-selected" autocomplete="none" matInput [ngxTimepicker]="checkInTimePicker" [format]="24"
                 [(ngModel)]="booking.expectedCheckinLocalTime"  >
          <ngx-material-timepicker #checkInTimePicker></ngx-material-timepicker>

        </mat-form-field>
        <div class="y3-X-small y3-color-scorpion" *ngIf="property?.defaultCheckInTime">{{'guest-setup-page.explain-check-in' | translate}} <span >({{property.defaultCheckInTime}})</span></div>
      </div>

      <div *ngIf="booking" class="y3-flex-column field3" >
        <mat-form-field appearance="fill" class="field3">
          <mat-label class="y3-yaago-sub-title-page">{{'guest-setup-page.check-out' | translate}}</mat-label>
          <input class="y3-roboto y3-Small time-selected" autocomplete="none" matInput [ngxTimepicker]="checkOutTimePicker" [format]="24"
                 [(ngModel)]="booking.expectedCheckoutLocalTime"  >
          <ngx-material-timepicker #checkOutTimePicker></ngx-material-timepicker>
        </mat-form-field>
        <div class="y3-X-small y3-color-scorpion" *ngIf="property?.defaultCheckOutTime">{{'guest-setup-page.explain-check-out' | translate}} <span >({{property.defaultCheckOutTime}})</span></div>
      </div>

    </div>



    <!-- INFORMATION PERSONNELLES-->

    <div class="y3-yaago-title-page" >Informations Personnelles</div>

    <div class="y3-flex-responsive">
        <mat-form-field appearance="fill" class="field3">
          <mat-label class="y3-yaago-sub-title-page">{{'guest-setup-page.first-name' | translate}}</mat-label>
          <input class="y3-roboto y3-Small" autocomplete="none" matInput   [(ngModel)]="booking?.guests[0].firstName" maxlength="30" >
        </mat-form-field>
        <mat-form-field appearance="fill" class="field3">
          <mat-label class="y3-yaago-sub-title-page">{{'guest-setup-page.last-name' | translate}}</mat-label>
          <input class="y3-roboto y3-Small" autocomplete="none" matInput  [(ngModel)]="booking?.guests[0].lastName"  maxlength="30" >
        </mat-form-field>
        <mat-form-field appearance="fill" class="field3" *ngIf="!displayDisableEmail">
          <mat-label class="y3-yaago-sub-title-page">{{'guest-setup-page.email' | translate}}</mat-label>
          <input class="y3-roboto y3-Small" autocomplete="none" matInput  [(ngModel)]="booking?.guests[0].email"  maxlength="100" >
        </mat-form-field>
    </div>

    <div class="y3-flex-responsive">
      <mat-form-field appearance="fill" class="field3">
        <mat-label class="y3-yaago-sub-title-page">{{'guest-setup-page.second-email' | translate}}</mat-label>
        <input class="y3-roboto y3-Small" autocomplete="none" matInput   [(ngModel)]="booking?.guests[0].secondaryEmail" maxlength="30" >
      </mat-form-field>
      <mat-form-field appearance="fill" class="field3">
        <mat-label class="y3-yaago-sub-title-page">{{'guest-setup-page.phone-number' | translate}}</mat-label>
        <input class="y3-roboto y3-Small" autocomplete="none" matInput  [(ngModel)]="booking?.guests[0].phone"  maxlength="30" >
      </mat-form-field>
      <mat-form-field appearance="fill" class="field3">
        <mat-label class="y3-yaago-sub-title-page">{{'guest-setup-page.second-phone-number' | translate}}</mat-label>
        <input class="y3-roboto y3-Small" autocomplete="none" matInput  [(ngModel)]="booking?.guests[0].secondaryPhone"  maxlength="100" >
      </mat-form-field>
    </div>

    <!-- CONFIRMATION PERSONNES -->

    <div class="y3-yaago-title-page" >{{'guest-setup-page.how-is-coming' | translate}}
      <span *ngIf="property?.personCapacity > 0" class="y3-roboto y3-XX-small y3-color-scorpion">max : {{property?.personCapacity}}</span>
    </div>


    <div class="y3-flex-row y3-margin-bottom-16">

      <div class="y3-flex-column y3-margin-right-32">
        <div class="y3-roboto y3-Small">{{'guest-setup-page.adults' | translate}}</div>
        <div class="bulle-container">
          <div (click)="removeAdults()" class="clickable" [ngClass]="checkDisableBtnDecre(booking?.adults)">
            <img class="icon-btn-add-below" src="assets/icon/check/below.svg" alt="below-disabled"/>
          </div>
          <div class="valueNumber">
            <span>{{booking?.adults}}</span>
          </div>
          <div (click)="addAdults()" class="clickable">
            <img class="icon-btn-add-below" src="assets/icon/check/plus.svg" alt="add-time"/>
          </div>
        </div>
      </div>

      <div class="y3-flex-column y3-margin-right-32">
        <div class="y3-roboto y3-Small">{{'guest-setup-page.children' | translate}}</div>
        <div class="bulle-container">
          <div (click)="removeChild()" class="clickable" [ngClass]="checkDisableBtnDecre(booking?.children)">
            <img class="icon-btn-add-below" src="assets/icon/check/below.svg" alt="below-disabled"/>
          </div>
          <div class="valueNumber">
            <span>{{booking?.children}}</span>
          </div>
          <div (click)="addChild()" class="clickable">
            <img class="icon-btn-add-below" src="assets/icon/check/plus.svg" alt="add-time"/>
          </div>
        </div>
      </div>


      <div class="y3-flex-column " *ngIf="property?.allowPets">
        <div class="y3-roboto y3-Small">{{'guest-setup-page.pets' | translate}}</div>
        <div class="bulle-container">
          <div (click)="remove('pets')" class="clickable" [ngClass]="checkDisableBtnDecre(booking?.pets)">
            <img class="icon-btn-add-below" src="assets/icon/check/below.svg" alt="below-disabled"/>
          </div>
          <div class="valueNumber">
            <span>{{booking?.pets}}</span>
          </div>
          <div (click)="add('pets')" class="clickable">
            <img class="icon-btn-add-below" src="assets/icon/check/plus.svg" alt="add-time"/>
          </div>
        </div>
      </div>



    </div>

    <!-- BEDS CONFIGURATION -->
    <div *ngIf="!property?.noshowBedsDetail" class="y3-yaago-title-page" >{{'guest-setup-page.beds-title' | translate}}</div>

    <div *ngIf="!property?.noshowBedsDetail" class="y3-flex-row y3-margin-bottom-16">
      <div class="y3-flex-column y3-margin-right-32" *ngIf="property?.doubleBeds > 0">
        <div class="y3-roboto y3-Small">{{'guest-setup-page.double-beds' | translate}}</div>
        <span class="y3-roboto y3-XX-small y3-color-scorpion">max : {{property?.doubleBeds}}</span>
        <div class="bulle-container">
          <div (click)="remove('double')" class="clickable" [ngClass]="checkDisableBtnDecre(booking?.doubleBeds)">
            <img class="icon-btn-add-below" src="assets/icon/check/below.svg" alt="below-disabled"/>
          </div>
          <div class="valueNumber">
            <span>{{booking?.doubleBeds}}</span>
          </div>
          <div (click)="add('double')" class="clickable">
            <img class="icon-btn-add-below" src="assets/icon/check/plus.svg" alt="add-time"/>
          </div>
        </div>
      </div>

      <div class="y3-flex-column y3-margin-right-32" *ngIf="property?.singleBeds > 0">
        <div class="y3-roboto y3-Small">{{'guest-setup-page.single-beds' | translate}}</div>
        <span class="y3-roboto y3-XX-small y3-color-scorpion">max : {{property?.singleBeds}}</span>
        <div class="bulle-container">
          <div (click)="remove('single')" class="clickable" [ngClass]="checkDisableBtnDecre(booking?.singleBeds)">
            <img class="icon-btn-add-below" src="assets/icon/check/below.svg" alt="below-disabled"/>
          </div>
          <div class="valueNumber">
            <span>{{booking?.singleBeds}}</span>
          </div>
          <div (click)="add('single')" class="clickable">
            <img class="icon-btn-add-below" src="assets/icon/check/plus.svg" alt="add-time"/>
          </div>
        </div>
      </div>

      <div class="y3-flex-column y3-margin-right-32" *ngIf="property?.sofaBeds > 0">
        <div class="y3-roboto y3-Small">{{'guest-setup-page.sofa-beds' | translate}}</div>
        <span class="y3-roboto y3-XX-small y3-color-scorpion">max : {{property?.sofaBeds}}</span>
        <div class="bulle-container">
          <div (click)="remove('sofa')" class="clickable" [ngClass]="checkDisableBtnDecre(booking?.sofaBeds)">
            <img class="icon-btn-add-below" src="assets/icon/check/below.svg" alt="below-disabled"/>
          </div>
          <div class="valueNumber">
            <span>{{booking?.sofaBeds}}</span>
          </div>
          <div (click)="add('sofa')" class="clickable">
            <img class="icon-btn-add-below" src="assets/icon/check/plus.svg" alt="add-time"/>
          </div>
        </div>
      </div>

      <div class="y3-flex-column y3-margin-right-32" *ngIf="property?.babyBeds > 0">
        <div class="y3-roboto y3-Small">{{'guest-setup-page.baby-beds' | translate}}</div>
        <span class="y3-roboto y3-XX-small y3-color-scorpion">max : {{property?.babyBeds}}</span>
        <div class="bulle-container">
          <div (click)="remove('crib')" class="clickable" [ngClass]="checkDisableBtnDecre(booking?.babyBeds)">
            <img class="icon-btn-add-below" src="assets/icon/check/below.svg" alt="below-disabled"/>
          </div>
          <div class="valueNumber">
            <span>{{booking?.babyBeds}}</span>
          </div>
          <div (click)="add('crib')" class="clickable">
            <img class="icon-btn-add-below" src="assets/icon/check/plus.svg" alt="add-time"/>
          </div>
        </div>
      </div>



    </div>

    <!-- Demande Particulière -->
    <div *ngIf="booking && !property.noShowParticularRequest" class="y3-margin-bottom-16">
      <div class="y3-yaago-title-page" >{{'guest-setup-page.leave-note' | translate}}</div>
      <div class="y3-margin-bottom-16">
        <div class="y3-yaago-sub-title-page" *ngIf="corporate?.lightCheckInOnLineWording != null"
             [innerHTML]="corporate.lightCheckInOnLineWording">
        </div>
        <div *ngIf="!corporate.lightCheckInOnLineWording" class="y3-yaago-sub-title-page ">
          * {{'guest-setup-page.request-text' | translate}}
        </div>
      </div>
      <div class="textarea-for-review">
        <textarea style="width: 100%" rows="5" [(ngModel)]="booking.guestNote"></textarea>
      </div>
    </div>

    <app-button [type]="'primary'" [text]="'guest-setup-page.confirm-btn' | translate" (debounceClick)="saveBookingInfo()"></app-button>
    <div *ngIf="!isDesktopMode()" class="empty-space"></div>

  </div>
  <div class="y3-empty-space" *ngIf="!isDesktopMode()"></div>

</div>
