import {Component, Input, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-tag2',
  templateUrl: './tag2.component.html',
  styleUrls: ['./tag2.component.scss']
})
export class Tag2Component implements OnInit {


  @Input() type: TagType = TagType.TRANSPARENT;
  @Input() status: TagStatus;
  @Input() text: string;

  tagStatusImage: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status && changes.status.currentValue) {
      switch (this.status) {
        case TagStatus.ERROR: this.tagStatusImage = 'assets/icons/not-valid.svg'; break;
        case TagStatus.WARNING: this.tagStatusImage = 'assets/icons/not-valid.svg'; break;
        case TagStatus.SUCCESS: this.tagStatusImage = 'assets/icons/valid.svg'; break;
      }
    }
  }

}

export enum TagType {
  BOOKED = 'booked',
  GREEN = 'green',
  ORANGE = 'orange',
  BLUE = 'blue',
  RED = 'red',
  OVERRED = 'overred',
  TRANSPARENT = 'transparent',
  CONFIRMED = 'confirmed',
  PAUSED = 'paused',
  STARTED = 'started',
  PAID = 'paid',
  PRERESERVATION = 'prereservation',
  DEPROPRIETAIRE = 'deproprietaire',
  UNDISPO = 'undispo'
}

export enum TagStatus {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}
