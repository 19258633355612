import {Component, HostListener, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/services/utils.service';
import {GuestService} from '../../../../core/services/guest.service';
import {PropertyForGuest} from '../../../../models/guestview/PropertyForGuest';
import {BookingForGuest} from '../../../../models/guestview/BookingForGuest';
import {InternalPropertyService} from '../../../../core/services/internal.property.service';
import {forkJoin} from "rxjs";
import {Corporate} from "../../../../models/guestview/Corporate";

@Component({
  selector: 'app-guest-reco-widget',
  templateUrl: './guest-reco-widget.component.html',
  styleUrls: ['./guest-reco-widget.component.scss']
})
export class GuestRecoWidgetComponent implements OnInit {

  constructor(public utilsService: UtilsService,
              public propertyService: InternalPropertyService,
              public guestService: GuestService) { }

  showMap = false;
  property: PropertyForGuest;
  corporate: Corporate;
  booking: BookingForGuest;

  ngOnInit() {

    forkJoin(this.propertyService.getCurrentProperty(), this.guestService.getCorporate())
    .subscribe(results => {
      this.property = results[0];
      this.corporate = results[1];
      this.guestService.showMap(true, this.property, this.corporate);
    });

    this.guestService.isMap$.subscribe(
      res => {
        console.log(res);
        this.showMap = res.value;
        /*this.property = res.property;
        if (this.property && this.property.host && this.property.host.includeCommunityRecommendation) {
          this.isCommunity = true;
          this.isYaago = true;
        }*/
      },
      err => {}
    );
  }

  @HostListener('window:resize', ['$event'])
  isDesktopMode(event?) {
    return this.utilsService.isDesktop(window.innerWidth);
  }

}
