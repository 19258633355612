import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class SecurityService {

  removeSecurtityPassword() {
    localStorage.removeItem("CheckPassword");
  }

  setSecurityPassword(bookingId: string | null) {
    if (bookingId != null) {
      localStorage.setItem("CheckPassword", bookingId);
    }
  }

  getSecurityPassword(): string | null {
    return localStorage.getItem("CheckPassword");
  }

}
