/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./custom-header.component";
import * as i3 from "@angular/material/datepicker";
import * as i4 from "@angular/material/core";
import * as i5 from "@ngx-translate/core";
var styles_CustomHeaderComponent = [i0.styles];
var RenderType_CustomHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomHeaderComponent, data: {} });
export { RenderType_CustomHeaderComponent as RenderType_CustomHeaderComponent };
export function View_CustomHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "datepicker-head-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "datepicker-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "date-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "d-flex align-items-center datepicker-sub-header mt-1 mb-4 position"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "previous month"], ["class", "datepicker-arrow-left datepicker-arrow clickable"], ["src", "assets/icon/arrows/arrow-up.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previousClicked("month") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "datepicker-header-label clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previousClickedyear("year") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "previous month"], ["class", "datepicker-arrow-right datepicker-arrow clickable"], ["src", "assets/icon/arrows/arrow-up.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextClicked("month") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.periodLabel; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.periodLabel; _ck(_v, 7, 0, currVal_1); }); }
export function View_CustomHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-header", [], null, null, null, View_CustomHeaderComponent_0, RenderType_CustomHeaderComponent)), i1.ɵdid(1, 245760, null, 0, i2.CustomHeaderComponent, [i3.MatDatepicker, i3.MatCalendar, i4.DateAdapter, i5.TranslateService, i4.MAT_DATE_FORMATS, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomHeaderComponentNgFactory = i1.ɵccf("app-custom-header", i2.CustomHeaderComponent, View_CustomHeaderComponent_Host_0, {}, {}, []);
export { CustomHeaderComponentNgFactory as CustomHeaderComponentNgFactory };
