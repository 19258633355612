import { Component, OnInit } from '@angular/core';
import {Corporate} from '../../../models/guestview/Corporate';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {GuestService} from '../../../core/services/guest.service';
import {LoaderService} from '../../../core/services/LoaderService';
import {ApiService} from '../../../core/http/api.service';
import {Location} from '@angular/common';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {BookingForGuest, CheckNote} from '../../../models/guestview/BookingForGuest';

@Component({
  selector: 'app-m10-inventory',
  templateUrl: './m10-inventory.component.html',
  styleUrls: ['./m10-inventory.component.scss']
})
export class M10InventoryComponent extends AbstractWindow implements OnInit {

  corporate: Corporate;
  property: PropertyForGuest;
  booking: BookingForGuest;
  arrival: boolean;
  departure: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              public utilService: UtilsService,
              public translateService: TranslateService,
              private guestService: GuestService,
              private loaderService: LoaderService,
              private apiService: ApiService,
              private location: Location) {
    super(utilService);
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      this.loaderService.dismissLoader();
      if (res && res.propertyAndBooking) {
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        //this.utilService.manageCorporateColors(this.corporate);
        this.booking = res.propertyAndBooking.booking;
      }
    });
  }

  goDeparture() {
    this.departure = true;
    this.arrival = false;
    if (this.booking != null && this.booking.guestInventoryCheckOut == null) {
      this.booking.guestInventoryCheckOut = new CheckNote();
    }
  }

  goArrival() {
    this.departure = false;
    this.arrival = true;
    if (this.booking != null && this.booking.guestInventoryCheckIn == null) {
      this.booking.guestInventoryCheckIn = new CheckNote();
    }
  }
  goBack() {
    if (this.arrival || this.departure) {
      this.arrival = false;
      this.departure = false;
    } else {
      this.location.back();
    }
  }

}
