import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ApiService} from '../../../core/http/api.service';
import {BookletReviews} from '../../../models/BookletReviews';
import {BookletReviewsLight} from '../../../models/BookletReviewsLight';
import {SnackbarMessage} from "../../../shared/components/shared-snackbar/models/snackbar-message";
import {SnackbarService} from "../../../core/services/SnackbarService";
import {TranslateService} from "@ngx-translate/core";
import { SharedSnackbarService } from 'src/app/shared/components/shared-snackbar/services/shared-snackbar.service';

@Component({
  selector: 'app-footer-booklet-reviews',
  templateUrl: './footer-booklet-reviews.component.html',
  styleUrls: ['./footer-booklet-reviews.component.scss']
})
export class FooterBookletReviewsComponent implements OnInit, OnChanges {

  @Input() bookletReviews: BookletReviews;
  @Input() bookingId: string;
  textReview: string = "";
  rating: number = 0;

  constructor(private apiService: ApiService,
              private translateService: TranslateService,
              private snackbar: SnackbarService,
              public sharedSnackbarService: SharedSnackbarService
              ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bookletReviews && this.bookletReviews) {
      this.textReview = this.bookletReviews.guestReviewComment;
      this.rating = this.bookletReviews.guestReviewRate;
    }
  }
 
  saveReview() {
    if (this.textReview.length > 0 && this.rating > 0) {
      const bookletReviewsLight = new BookletReviewsLight();
      bookletReviewsLight.guestReviewComment = this.textReview;
      bookletReviewsLight.guestReviewRate = this.rating;
      this.apiService.leaveBookletReviews(this.bookingId, bookletReviewsLight).subscribe(res => {
        this.sharedSnackbarService.push(new SnackbarMessage(this.translateService.instant('booklet-review.success'), 'info'));
      });
    } else {
      this.sharedSnackbarService.push(new SnackbarMessage(this.translateService.instant('booklet-review.need-info'), 'warning'));
    }
  }

}
