<div *ngIf="guides && guides?.length > 0" class="m3-guide-top-section">


  <div class="">

    <div class="y3-High y3-margin-bottom-16 y3-bold y3-title-colors">{{'guest-guid-page.title' | translate}}</div>

    <div class="width-100 flex flex-column">
      <span class="roboto txt-color-scorpion Small margin-top-8 y3-sub-title-colors"
            *ngIf="!property?.host?.btobInfo">{{'guest-guid-page.title-explanation' | translate}}</span>

      <div class="width-100 flex flex-column margin-top-32 ">

        <div *ngFor="let cat of categoryGuides; let i = index" class="card-small flex flex-column y3-margin-bottom-8">
          <div class="width-100 flex flex-row flex-align-center flex-space-between clickable"
               (click)="openMobileGuideList(cat, i)">
            <div class="flex flex-row flex-align-center">
              <div style="position: relative">
                <img class="category-cover margin-right-12" src="{{getPictureFromCategory(cat.category)}}" alt="">
                <div class="m3-guide-badge">{{cat?.guides?.length}}</div>
              </div>

              <div style="position: relative" class="y3-flex-row">

                <div *ngIf="!cat.custom" class="y3-Small y3-bold y3-title-colors" >{{(('guides-list.' + cat.category) | translate) }}</div>
                <div *ngIf="cat.custom" class="y3-Small y3-bold y3-title-colors" >{{getTranslatedCategory(cat)}}</div>

              </div>
            </div>

            <mat-icon class="m0-button-quick-2">chevron_right</mat-icon>

            <!--<img src="assets/icon/arrow-down.svg"
                 class="{{i === mobileCurrentOpenCategory ? 'rotate-90' : 'rotate-270'}}" alt="">-->
          </div>

          <div class="non-drag flex flex-column padding-top-16" *ngIf="mobileCurrentOpenCategory === i">
            <div>
              <div *ngFor="let guide of currentGuidesList; let j = index">
                <div *ngIf="needToShowGuide(guide)" class=" width-100 flex flex-row flex-align-center padding-8 clickable" (click)="showMobileGuideDetails(guide)">
                  <img class="mobile-guide-cover mar" src="{{getGuideCover(guide)}}" alt="Cover">

                  <span *ngIf="guide.translations && guide.translations.length > 0"
                        class="y3-Small margin-left-16 y3-title-colors">
                      {{guide.translations[0].title}}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--<div class="details-dialog-container" *ngIf="currentGuide && showGuideDetails">

  <div class="details-container">
    <div class="flex flex-row flex-align-center clickable margin-top-24 padding-left-32" (click)="closeGuide()">
      <img src="assets/icon/arrow-down.svg" alt="Icon" class="margin-right-8">
      <span class="roboto bold Small txt-color-grvogue">{{'guest-guid-page.return' | translate}}</span>
    </div>

    <app-guide-details [property]="property" [guide]="currentGuide"></app-guide-details>
  </div>

</div>-->
