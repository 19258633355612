<link href='https://fonts.googleapis.com/css?family=Muli' rel='stylesheet'>
<footer class="g2f-footer-container g2f-container">
  <div class="g2f-footer wrapper first-container ">
    <div class="container-display">

      <section class="g2f-footer-info col-xs-12 col-sm-5">
        <div class="g2f-footer-info-wrapper">

          <!--<div id="block-g2f-footer-other"
            class="block block-system block-system-menu-blockg2f-footer-other menu-footer-wrapper">
            <div class="g2f-footer-other">
              <ul class="g2f-footer-other-list g2f-footer-other-list-display">
                <li class="g2f-footer-other-item u-mg-r--l">
                  <a href="https://www.boutique.gites-de-france.com/" title="La boutique" class="strong g2f-link"
                    target="_blank" rel="noopener">La boutique</a>
                </li>
                <li class="g2f-footer-other-item u-mg-r--l">
                  <a href="https://www.gites-de-france.com/fr/ce/connexion" title="Le site Comité d'Entreprise"
                    class="strong g2f-link" data-drupal-link-system-path="ce/login" target="_blank">Le site Comité
                    d'Entreprise</a>
                </li>
              </ul>
            </div>

          </div>-->

          <header class="u-mg-t--l">
            <h2 class="g2f--tt-3">À propos de Gîtes de France®</h2>
            <div id="block-g2f-footer-about"
              class="block block-system block-system-menu-blockg2f-footer-about menu-footer-wrapper">

              <div class="g2f-footer-about">
                <ul class="g2f-footer-about-list">
                  <li class="g2f-footer-about-item">
                    <a href="https://www.gites-de-france-loire-atlantique.com/fr" target="_self" title="Le blog"
                      class="g2f-footer-about-link" data-drupal-link-system-path="node/718147" target="_blank">Notre
                      portail</a>
                  </li>
                  <!--<li class="g2f-footer-about-item">
                    <a href="https://www.gites-de-france.com/fr/initiatives" title="Gîtes de France Initiatives"
                      class="g2f-footer-about-link" data-drupal-link-system-path="node/493413" target="_blank">Gîtes de
                      France Initiatives®</a>
                  </li>
                  <li class="g2f-footer-about-item">
                    <a href="https://www.gites-de-france.com/fr/le-reseau-gites-de-france"
                      title="Le réseau Gîtes de France" class="g2f-footer-about-link"
                      data-drupal-link-system-path="node/2044" target="_blank">Le réseau Gîtes de France®</a>
                  </li>
                  <li class="g2f-footer-about-item">
                    <a href="https://www.gites-de-france.com/fr/gites-de-france-avis-clients"
                      class="g2f-footer-about-link" title="Gîtes de France® Avis Clients"
                      data-drupal-link-system-path="node/877427" target="_blank">Gîtes de France® Avis Clients</a>
                  </li>
                  <li class="g2f-footer-about-item">
                    <a href="https://www.gites-de-france.com/fr/partenaires" title="Partenaires"
                      class="g2f-footer-about-link" data-drupal-link-system-path="node/2042"
                      target="_blank">Partenaires</a>
                  </li>
                  <li class="g2f-footer-about-item">
                    <a href="https://www.gites-de-france.com/fr/espace-presse" title="Espace presse"
                      class="g2f-footer-about-link" data-drupal-link-system-path="node/2043" target="_blank">Espace
                      presse</a>
                  </li>-->
                </ul>
              </div>

            </div>

          </header>
        </div>
      </section>
      <section class="g2f-footer-action  g2f-extended-bg  g2f-extended-bg-right col-xs-12 col-sm-3">
        <div class="g2f-footer-action-wrapper ">
          <div class="g2f-footer-social col-sm-12 col-xs-9">
            <div id="block-g2f-footer-contact"
              class="block block-system block-system-menu-blockg2f-footer-contact menu-footer-wrapper">
              <div class="g2f-footer-contact">
                <ul class="g2f-footer-contact-list">
                  <li class="g2f-footer-contact-item">
                    <a class="g2f-btn g2f-btn-simple" href="https://www.gites-de-france-loire-atlantique.com/fr/page/infos-pratiques"
                      target="_blank">FAQ</a>
                  </li>
                  <li class="g2f-footer-contact-item">
                    <a class="g2f-btn g2f-btn-simple" href="https://www.gites-de-france-loire-atlantique.com/fr/contact"
                      target="_blank">Nous contacter</a>
                  </li>
                </ul>
              </div>

            </div>

            <div id="block-g2f-footer-social"
              class="block block-system block-system-menu-blockg2f-footer-social menu-footer-wrapper">
              <div class="g2f-footer-social">
                <ul class="g2f-footer-social-list">
                  <li class="g2f-foooter-social-item">
                    <a href="https://www.facebook.com/gitesdefrance44"
                      title="Rejoignez la Communauté Gîtes de France " class="g2f-footer-social-btn" target="_blank"
                      rel="noopener"> <span class="visually-hidden">Facebook</span>

                      <img class="social-icon" aria-hidden="true" src="assets/icon/facebook-white.svg"
                        style="width: 15px;">
                    </a>
                  </li>
                  <li class="g2f-foooter-social-item">
                    <a href="https://www.instagram.com/gitesdefranceloireatlantique/"
                      title="Partagez vos expériences de #vacances avec #gitesdefrance" class="g2f-footer-social-btn"
                      target="_blank" rel="noopener"> <span class="visually-hidden">Instagram</span>

                      <img class="social-icon" aria-hidden="true" src="assets/icon/insta-white.svg"
                        style="width: 15px;">
                    </a>
                  </li>
                  <li class="g2f-foooter-social-item">
                    <a href="https://twitter.com/gites_de_france/?ref=br_rs" title="Suivez-nous sur twitter !"
                      class="g2f-footer-social-btn" target="_blank" rel="noopener"> <span
                        class="visually-hidden">Twitter</span>

                      <img class="social-icon" aria-hidden="true" src="assets/icon/twitter-white.svg"
                        style="width: 15px;">
                    </a>
                  </li>
                </ul>
              </div>


            </div>

          </div>
        </div>
      </section>

    </div>
  </div>
  <section class="g2f-footer-legal">
    <div id="block-g2f-footer-items" class="block block-system block-system-menu-blockfooter menu-footer-wrapper">
      <div class="g2f-footer-legals">
        <ul class="g2f-footer-legals-list">
          <li class="g2f-footer-legals-item">
            <a href="https://www.gites-de-france.com/fr/mentions-legales" class="g2f-footer-legals-link"
              data-drupal-link-system-path="node/122">Mentions Légales</a>
          </li>
          <li class="g2f-footer-legals-item">
            <a href="https://www.gites-de-france.com/fr/protection-des-donnees" class="g2f-footer-legals-link"
              data-drupal-link-system-path="node/682882">Protection des données</a>
          </li>
          <li class="g2f-footer-legals-item">
            <a href="https://www.gites-de-france.com/fr/conditions-generales-de-ventes" class="g2f-footer-legals-link"
              data-drupal-link-system-path="node/2030">CGV</a>
          </li>


        </ul>
      </div>

    </div>


  </section>
</footer>
