import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfService} from '../conf.service';
import {Observable} from 'rxjs';
import {RequestForGuide} from '../../../models/GuestGuide/RequestForGuide';
import {Guide} from '../../../models/Guide';

@Injectable({ providedIn: 'root' })
export class GuestGuideEndpoint {
  constructor(
    private http: HttpClient,
    private confService: ConfService) {
  }

  getGuideFullTranslation(requestForGuide: RequestForGuide): Observable<Guide> {
    return this.http.post<Guide>(this.confService.getBackendApiEndPoint() + 'api-v2/guest/guest/guide', requestForGuide);
  }
}
