import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-y3-explained-button',
  templateUrl: './y3-explained-button.component.html',
  styleUrls: ['./y3-explained-button.component.scss']
})
export class Y3ExplainedButtonComponent implements OnInit {

  @Input() title :string;
  @Input() description: string;
  constructor() { }

  ngOnInit() {
  }

}
