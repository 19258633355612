export class GuestBookingInformationConfirmationDto {

  expectedCheckinLocalTime: string | null;
  requestedCheckinLocalTime: string | null;
  expectedCheckoutLocalTime: string | null;
  requestedCheckoutLocalTime: string | null;


  adults: number;
  children: number;
  babies: number;
  doubleBeds: number;
  singleBeds: number;
  sofaBeds: number;
  babyBeds: number;
  pets: number;
  parkings: number;

  guestNote: string;
  firstName: string;
  companyName: string;
  lastName: string;
  phone: string;
  secondaryPhone: string;
  email: string;
  secondaryEmail: string;

  travelForBusiness: boolean;

}
