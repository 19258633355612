
<div class="map-desktop" id="map-container">

  <app-leaflet-map
                  [centerOfTheMap]="centerOfTheMap"
                  [corporate]="corporate"
                   [propertyPoi]="propertyPoi"
                   [whereIamPoi]="whereIamPoi"
                   [properties]="propertiesForMap"
                   [language]="language"
                   [showAllProperties]="true"
                   [showRecoCorporate]="true"
                   [showProperty]="true"
                   [showRecoYaago]="showRecoYaago"
                   [showCommunityReco]="showCommunityReco"
                   [showRecoInfluencer]="showRecoInfluencer"
                   [showRecoTourism]="false"
                   [showOwner]="showOwner"
                   [showFilterReco]="true"
                   [property]="property"
                   [recapVersion]="recapVersion"
                   [showZoomControl]="showZoomControl"
                   [showChecks]="false"
                   [relatedPoiForDistanceCalculation]="centerOfTheMap"
  ></app-leaflet-map>

</div>
<gusl-shared-snackbar></gusl-shared-snackbar>
