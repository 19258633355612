/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-toppage.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./desktop/guest-toppage-desktop.component.ngfactory";
import * as i3 from "./desktop/guest-toppage-desktop.component";
import * as i4 from "../../../core/services/internal.property.service";
import * as i5 from "@angular/router";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../core/services/utils.service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@angular/common";
import * as i11 from "./guest-toppage.component";
var styles_GuestToppageComponent = [i0.styles];
var RenderType_GuestToppageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestToppageComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { "opacity": "1" }, offset: null }, options: undefined }, { type: 0, name: "out", styles: { type: 6, styles: { "opacity": "0.5" }, offset: null }, options: undefined }, { type: 1, expr: "* <=> *", animation: [{ type: 4, styles: null, timings: 1000 }], options: null }], options: {} }] } });
export { RenderType_GuestToppageComponent as RenderType_GuestToppageComponent };
function View_GuestToppageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-toppage-desktop", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GuestToppageDesktopComponent_0, i2.RenderType_GuestToppageDesktopComponent)), i1.ɵdid(1, 4833280, null, 0, i3.GuestToppageDesktopComponent, [i4.InternalPropertyService, i5.ActivatedRoute, i6.TranslateService, i7.UtilsService, i5.Router, i8.MatDialog, i9.DomSanitizer], { property: [0, "property"], booking: [1, "booking"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.property; var currVal_1 = _co.booking; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_GuestToppageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { gMapElement: 0 }), i1.ɵqud(402653184, 2, { gContainerElement: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestToppageComponent_1)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDesktopMode(); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_GuestToppageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-toppage", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestToppageComponent_0, RenderType_GuestToppageComponent)), i1.ɵdid(1, 4833280, null, 0, i11.GuestToppageComponent, [i4.InternalPropertyService, i5.ActivatedRoute, i6.TranslateService, i7.UtilsService, i5.Router, i8.MatDialog, i9.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestToppageComponentNgFactory = i1.ɵccf("app-guest-toppage", i11.GuestToppageComponent, View_GuestToppageComponent_Host_0, { property: "property", booking: "booking" }, { bookingChange: "bookingChange" }, []);
export { GuestToppageComponentNgFactory as GuestToppageComponentNgFactory };
