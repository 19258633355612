<div class="page-container">
  <app-online-check-header
    [corporate]="corporate"
    *ngIf="
      booking?.onLineCheck?.checkStatus == 'EMPTY' ||
      booking?.onLineCheck?.checkStatus == 'WAITING' ||
      booking?.onLineCheck?.checkStatus == 'NOUVEAU' ||
      booking?.onLineCheck?.checkStatus == 'REFUSED'
    "
  ></app-online-check-header>
  <app-g0-guest-menu-mobile
    [corporate]="corporate"
    [booking]="booking"
    *ngIf="booking?.onLineCheck?.checkStatus == 'VALIDATED'"
  ></app-g0-guest-menu-mobile>

  <div class="landing-page-container">
    <img
      class="mainPhotoLandingPage"
      *ngIf="mainPropertyPhoto != null"
      [src]="mainPropertyPhoto"
      alt="mainPhoto"
    />

    <div class="title title-position">
      <p class="margin-bottom-16">{{ property?.title }}</p>
      <app-online-check-welcome-bar
        [property]="property"
        [booking]="booking"
      ></app-online-check-welcome-bar>
    </div>

    <div *ngIf="booking != null" class="journey-summary">
      <div class="row justify-content-sb align-items-center">
        <div class="welcome-container">
          <p class="margin-bottom-16">
            {{ "guest-top-page.welcome" | translate }}
          </p>
          <p>
            {{ booking?.guests[0]?.firstName | titlecase }}
            <span *ngIf="property?.host?.btobInfo">{{
              booking?.guests[0]?.lastName | titlecase
            }}</span>
          </p>
          <p *ngIf="property?.host?.btobInfo" class="company-name">
            {{ booking?.guests[0]?.companyName | titlecase }}
          </p>
        </div>
        <div *ngIf="!property?.host?.btobInfo" class="nights-container">
          <p class="Small">{{ "guest-top-page.nights-desktop" | translate }}</p>
          <p class="number">{{ booking?.nights }}</p>
        </div>
      </div>
      <div *ngIf="booking?.startDate !== booking?.endDate" class="date-cards">
        <div class="date-card margin-right-16">
          <img src="assets/images/illustrations/men-fly.svg" alt="start" />
          <div class="date-container">
            <p class="title">{{ "guest-top-page.start" | translate }}</p>
            <p class="date">
              {{ booking?.startDate | moment: currentLang | titlecase }}
            </p>
          </div>
        </div>
        <div class="date-card">
          <img
            src="assets/images/illustrations/men-luaggage.svg"
            alt="departure"
          />
          <div class="date-container">
            <p class="title">{{ "guest-top-page.end" | translate }}</p>
            <p class="date">
              {{ booking?.endDate | moment: currentLang | titlecase }}
            </p>
          </div>
        </div>
      </div>

      <!-- SAME DATE : RESERVATION -->
      <div *ngIf="booking?.startDate === booking?.endDate" class="date-cards">
        <div class="date-card">
          <img src="assets/images/illustrations/men-fly.svg" alt="start" />
          <div class="date-container">
            <p class="title">{{ "guest-top-page.your-resa" | translate }}</p>
            <p class="date">
              {{ booking?.startDate | moment: currentLang | titlecase }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="card-informations">
      <div
        *ngIf="booking?.onLineCheck?.checkStatus === 'WAITING' || booking?.onLineCheck?.checkStatus == 'NOUVEAU'"
        class="online-check-status-button"
      >
        {{ "online-check.online-check" | translate }}
      </div>
      <div
        *ngIf="booking?.onLineCheck?.checkStatus === 'REFUSED'"
        class="online-check-status-refused-button"
      >
        {{ "online-check.online-check-refused" | translate }}
      </div>

      <div
        *ngIf="booking?.onLineCheck?.checkStatus === 'REFUSED'"
        style="padding-bottom: 18px"
      >
        <div class="reason">{{ "online-check.reason" | translate }}</div>
        <div
          *ngIf="this.booking?.guests[0].languages[0] === 'fr'"
          style="width: 40% ;"
        >
          {{ booking?.onLineCheck?.reasonRefused }}
        </div>
        <div
          *ngIf="this.booking?.guests[0].languages[0] != 'fr'"
          style="width: 40% ;"
        >
          {{ booking?.onLineCheck?.reasonRefusedTranslated }}
        </div>
      </div>

      <div class="containers-block">
        <div class="information-position">
          <div class="informations">
            <p>{{ "online-check.info" | translate }}</p>
            <div class="display-informations information-content">
              <div>{{ "online-check.lastname" | translate }}</div>
              <div>{{ localOnLineCheck?.lastName }}</div>
            </div>
            <div class="display-informations information-content">
              <div>{{ "online-check.firstname" | translate }}</div>
              <div>{{ localOnLineCheck?.firstName }}</div>
            </div>
            <div class="display-informations information-content">
              <div>{{ "online-check.birthdate" | translate }}</div>
              <div>
                {{ localOnLineCheck?.guestBirthDate | date: "dd-MM-yyyy" }}
              </div>
            </div>
            <div class="display-informations information-content">
              <div>{{ "online-check.gender" | translate }}</div>
              <div *ngIf="this.localOnLineCheck?.guestGender === 'homme'">
                {{ "online-check.male" | translate }}
              </div>
              <div *ngIf="this.localOnLineCheck?.guestGender === 'femme'">
                {{ "online-check.female" | translate }}
              </div>
              <div *ngIf="this.localOnLineCheck?.guestGender === 'autre'">
                {{ "online-check.other" | translate }}
              </div>
            </div>

            <div class="display-informations information-content">
              <div>{{ "online-check.email" | translate }}</div>
              <div>{{ localOnLineCheck?.email }}</div>
            </div>

            <div class="display-informations information-content">
              <div>{{ "online-check.contactNumber" | translate }}</div>
              <div>{{ localOnLineCheck?.phoneNumber }}</div>
            </div>

            <div class="display-informations information-content">
              <div>{{ "online-check.country" | translate }}</div>
              <!-- <div> {{booking?.onLineCheck?.guestCountry}}</div> -->
              <div>{{ onLineCheckCountry }}</div>
            </div>
            <div
              *ngIf="property?.checkinOnlineWithTransport === true"
              class="display-informations information-content"
            >
              <div>
                {{ "online-check.onlineCheckWithTransport" | translate }}
              </div>
              <!-- <div> {{booking?.onLineCheck?.guestCountry}}</div> -->
              <div *ngIf="localOnLineCheck?.onLineCheckWithTransport === true">
                {{ "online-check.true" | translate }}
              </div>
              <div *ngIf="localOnLineCheck?.onLineCheckWithTransport === false">
                {{ "online-check.false" | translate }}
              </div>
            </div>
          </div>
          <div class="images">
            <div class="identity">{{ "online-check.id" | translate }}</div>
            <div class="images" *ngFor="let img of localOnLineCheck?.identityProofUrls">
              <iframe class="online-check-img" *ngIf="img.link().includes('.pdf')"
              [src]="img| safe"></iframe>
            <img *ngIf="img.link().includes('.jpg')"  class="online-check-img"  [src]="img" />
            <img *ngIf="img.link().includes('.jpeg')"  class="online-check-img"  [src]="img" />
          </div>
          </div>

          <div
            *ngIf="property?.checkinOnlineWithContract"
            class="contrat contrat-position"
            style="height: 66vh"
          >
            <p>{{ "online-check.contract" | translate }}</p>

            <div class="contratpdf">
              <pdf-viewer
                class="ng2-pdf-viewer-container contratpdf"
                [src]="localOnLineCheck?.contractDocumentUrlSigned"
                [render-text]="true"
                [original-size]="false"
                [show-borders]="true"
                [show-all]="true"
                [fit-to-page]="true"
                [page]="1"
                [zoom]="custom"
              ></pdf-viewer>
              <div class="rect" style="width: 105px">
                <div class="rect-inside">
                  <img
                    (click)="customminus()"
                    src="/assets/icon/remove_white.svg"
                  />
                  <img
                    (click)="customzoom()"
                    src="/assets/icon/add_white.svg"
                  />
                  <a href="{{ localOnLineCheck?.contractDocumentUrlSigned }}">
                    <img src="/assets/icon/open_in_new_white.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="property?.checkinOnlineWithCgv"
            class="contrat contrat-position"
            style="height: 66vh"
          >
            <p>{{ "online-check.cgv" | translate }}</p>

            <div class="contratcgv">
              <pdf-viewer
                class="ng2-pdf-viewer-container contratcgv"
                [src]="localOnLineCheck?.cgvDocumentUrl"
                [render-text]="true"
                [original-size]="false"
                [show-borders]="true"
                [show-all]="true"
                [fit-to-page]="true"
                [page]="1"
                [zoom]="custom"
              ></pdf-viewer>
              <div class="rect" style="width: 105px">
                <div class="rect-inside">
                  <img
                    (click)="customminus()"
                    src="/assets/icon/remove_white.svg"
                  />
                  <img
                    (click)="customzoom()"
                    src="/assets/icon/add_white.svg"
                  />
                  <a
                    target="_blank"
                    href="{{ localOnLineCheck?.cgvDocumentUrl }}"
                  >
                    <img src="/assets/icon/open_in_new_white.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="booking?.onLineCheck?.checkStatus != 'VALIDATED'"
          class="edit-button"
        >
          <app-button
            [text]="'online-check.edit' | translate"
            [type]="'primary'"
            (debounceClick)="editInfo()"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
