import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {GuestLostComponent} from './guest-lost/guest-lost.component';
import {RecoWidgetComponent} from './reco-widget/reco-widget.component';
import {PropertyGenericComponent} from './property-generic/property-generic.component';
import {PropertyGenericConfirmationComponent} from './property-generic-confirmation/property-generic-confirmation.component';


const routes: Routes = [
  {
    path: 'p/:propertyId',
    component: PropertyGenericComponent
  },
  {
    path: 'p/:propertyId/confirm',
    component: PropertyGenericConfirmationComponent
  },
  {
    path: 'lost',
    component: GuestLostComponent
  },
  {
    path: '',
    component: GuestLostComponent,
  },
  {
    path: 'reco-widget',
    component: RecoWidgetComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MiscRoutingModule { }
