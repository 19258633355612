<button mat-flat-button appDebounceClick [ngClass]="type"
        [disabled]="disabled"
        (debounceClick)="onClick()">
  <mat-icon *ngIf="leftIcon" class="margin-right-8" svgIcon="{{leftIcon}}"></mat-icon>
  {{text | translate}}
  <mat-icon *ngIf="rightIcon" class="margin-right-8" svgIcon="{{rightIcon}}"></mat-icon>
</button>

<button *ngIf="routerLink" mat-flat-button appDebounceClick [ngClass]="type"
        [routerLink]="routerLink"
        [queryParams]="queryParams"
        [disabled]="disabled"
        >
  <mat-icon *ngIf="leftIcon" class="margin-right-8" svgIcon="{{leftIcon}}"></mat-icon>
  {{text | translate}}
  <mat-icon *ngIf="rightIcon" class="margin-right-8" svgIcon="{{rightIcon}}"></mat-icon>
</button>
