import { Component, OnInit } from '@angular/core';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {GuestService} from '../../../core/services/guest.service';
import {LoaderService} from '../../../core/services/LoaderService';
import {ApiService} from '../../../core/http/api.service';
import {Location} from '@angular/common';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {Photo} from '../../../models/Photo';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DocType} from "../../../models/Guide";

@Component({
  selector: 'app-m1-property-details',
  templateUrl: './m1-property-details.component.html',
  styleUrls: ['./m1-property-details.component.scss']
})
export class M1PropertyDetailsComponent extends AbstractWindow implements OnInit {

  property: PropertyForGuest;
  corporate: Corporate;
  booking: BookingForGuest;
  currentStep = 'HOME';
  _hasPrevious: boolean = false;
  _hasNext: boolean = false;
  mainPropertyPhoto: string;
  mainPropertyPhotoCaption: string;
  index: number = 0;
  urlSanitized: SafeResourceUrl;
  DocType = DocType;

  constructor(private activatedRoute: ActivatedRoute,
              public utilService: UtilsService,
              public translateService: TranslateService,
              private guestService: GuestService,
              private loaderService: LoaderService,
              private apiService: ApiService,
              public sanitizer: DomSanitizer,
              private location: Location) {
    super(utilService);
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      console.log("Property Details data changed");
      this.loaderService.dismissLoader();
      if (res && res.propertyAndBooking) {
        console.log("Property Details data changed 2");
        console.log("Description", res.propertyAndBooking.property.description);
        this.booking = res.propertyAndBooking.booking;
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        //this.utilService.manageCorporateColors(this.corporate);

        this.mainPropertyPhoto = this.utilService.getMainPhoto(this.property);
        this._hasNext = this.hasNext();
        this._hasPrevious = this.hasPrevious();
        this.index = this.getPhotoIndex(this.property.photos, this.mainPropertyPhoto);
      }
    });
  }

  transform(url): any {
    if (!this.urlSanitized) {
      this.urlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    return this.urlSanitized;
  }

  counter(type: 'travelers' | 'beds' | 'baths' | 'doublebeds' | 'singlebeds' | 'sofabeds' | 'shower') {
    switch (type) {
      case 'travelers':
        const travelersCount = this.booking.adults + this.booking.children + this.booking.babies;
        return travelersCount > 0 ? travelersCount : (this.booking.guests ? this.booking.guests.length : 0);

      case 'beds':
        return this.property.doubleBeds + this.property.singleBeds + this.property.sofaBeds + this.property.babyBeds;
      case 'doublebeds':
        return this.property.doubleBeds;
      case 'singlebeds':
        return this.property.singleBeds;
      case 'sofabeds':
        return this.property.sofaBeds;
      case 'baths':
        return this.property.bathrooms;
      case 'shower':
        return this.property.showerRooms;
    }
  }

  hasPrevious(): boolean {
    let ret = false;
    if (this.property && this.property.photos
      && this.property.photos.length > 0 && this.mainPropertyPhoto) {
      const index = this.getPhotoIndex(this.property.photos, this.mainPropertyPhoto);
      ret = index > 0;
    }
    return ret;
  }

  private getPhotoIndex(photos: Photo[], photoUrl: string) {
    if (photos && photoUrl) {
      const fPhoto = photoUrl.replace('?aki_policy=medium', '');
      return photos.findIndex(p => p.url.startsWith(fPhoto));
    } else {
      return -1;
    }
  }

  hasNext(): boolean {
    let ret = false;
    if (this.property && this.property.photos
      && this.property.photos.length > 0 && this.mainPropertyPhoto) {
      const index = this.getPhotoIndex(this.property.photos, this.mainPropertyPhoto);
      ret = index !== this.property.photos.length - 1;
    }
    return ret;
  }

  next() {
    if (this._hasNext) {
      this.index++;
      if (this.property.photos[this.index].url) {
        this.mainPropertyPhoto = this.property.photos[this.index].url.replace('?aki_policy=medium', '');
        this.mainPropertyPhotoCaption = this.property.photos[this.index].caption;
      }
      this._hasNext = this.hasNext();
      this._hasPrevious = this.hasPrevious();
    }
  }

  previous() {
    if (this._hasPrevious) {
      this.index--;
      this.mainPropertyPhoto = this.property.photos[this.index].url.replace('?aki_policy=medium', '');
      this.mainPropertyPhotoCaption = this.property.photos[this.index].caption;
      this._hasNext = this.hasNext();
      this._hasPrevious = this.hasPrevious();
    }
  }

  goBack() {
    if (this.currentStep === 'HOME') {
      this.location.back();
    } else {
      this.currentStep = 'HOME';
    }
  }

}
