import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../conf.service";
export class GuestGuideEndpoint {
    constructor(http, confService) {
        this.http = http;
        this.confService = confService;
    }
    getGuideFullTranslation(requestForGuide) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/guest/guest/guide', requestForGuide);
    }
}
GuestGuideEndpoint.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GuestGuideEndpoint_Factory() { return new GuestGuideEndpoint(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfService)); }, token: GuestGuideEndpoint, providedIn: "root" });
