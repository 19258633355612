import { Component, OnInit } from '@angular/core';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {GuestService} from '../../../core/services/guest.service';
import {LoaderService} from '../../../core/services/LoaderService';
import {Corporate} from '../../../models/guestview/Corporate';
import {CategoryGuide, CategoryGuides, DocType, Guide} from '../../../models/Guide';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {MatDialog} from '@angular/material';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {M22GuideDisplayComponent} from '../m22-guide-display/m22-guide-display.component';

@Component({
  selector: 'app-m3-guides-top',
  templateUrl: './m3-guides-top.component.html',
  styleUrls: ['./m3-guides-top.component.scss']
})
export class M3GuidesTopComponent extends AbstractWindow implements OnInit {

  property: PropertyForGuest;
  booking: BookingForGuest;
  corporate: Corporate;
  guides: Guide[] = [];
  currentGuidesList: Guide[] = [];
  currentGuide: Guide;
  categoryGuides: CategoryGuides[] = [];
  availableCategories = CategoryGuide.list();
  mobileCurrentOpenCategory: number = null;
  currentSelectedCategory: string;
  constructor(private activatedRoute: ActivatedRoute,
              public utilService: UtilsService,
              public translateService: TranslateService,
              private guestService: GuestService,
              protected dialog: MatDialog,
              private loaderService: LoaderService) {
    super(utilService);
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      this.loaderService.dismissLoader();
      if (res && res.propertyAndBooking) {
        this.booking = res.propertyAndBooking.booking;
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        //this.utilService.manageCorporateColors(this.corporate);
        this.guides = this.property.guides.filter(item => item.upSellSection == null || item.upSellSection.showAsUpSell === false);
        this.initCategories();
      }
    });
  }

  initCategories() {

    if (!this.property.guides) {
      return;
    }
    let grouped = this.utilService.groupByKey(this.guides, 'category');


    this.categoryGuides = [];
    Object.keys(grouped).forEach(categoryKey => {
      const catGuides = new CategoryGuides();
      catGuides.category = categoryKey; catGuides.guides = grouped[categoryKey];
      if (grouped[categoryKey].length > 0) {
        if (!this.availableCategories.includes(categoryKey)) {
          catGuides.custom = true;
        }
      }
      this.categoryGuides.push(catGuides);
    });



  }

  openMobileGuideList(cat: CategoryGuides, i) {
    console.log('openMobileGuideList', cat);
    this.mobileCurrentOpenCategory = this.mobileCurrentOpenCategory === i ? null : i;
    console.log('mobileCurrentOpenCategory', this.mobileCurrentOpenCategory);
    if (this.mobileCurrentOpenCategory === i) {
      this.currentGuidesList = cat.guides;
    }
    this.currentSelectedCategory = cat.category;

    console.log('currentGuidesList', this.currentGuidesList);
  }

  getPictureFromCategory(category: string) {
    let image;

    if (!category) {
      return image;
    }
    if (this.corporate && this.corporate.customGuideCategoriesPictures) {
      const found = this.corporate.customGuideCategoriesPictures.find(
        (item) => category === item.categoryName
      );
      if (found && found.url) {
        return found.url;
      }
    }

    switch (category) {
      case CategoryGuide.APPLIANCES:
        image = '../../../../assets/images/list-guides/Appliance/Appliances.png';
        break;
      case CategoryGuide.CHECKIN:
        image = '../../../../assets/images/list-guides/Checkin/checkin.png';
        break;
      case CategoryGuide.CHECKOUT:
        image = '../../../../assets/images/list-guides/Check-out/checkout.png';
        break;
      case CategoryGuide.ORIENTATION:
        image = '../../../../assets/images/list-guides/Direction/dir.png';
        break;
      case CategoryGuide.RULES:
        image = '../../../../assets/images/list-guides/Rules/img@3x.png';
        break;
      case CategoryGuide.SERVICES:
        image = '../../../../assets/images/list-guides/service.png';
        break;
      case CategoryGuide.WIFI:
        image = '../../../../assets/images/list-guides/Wifi/wifi.png';
        break;
      case CategoryGuide.PARKING:
        image = '../../../../assets/images/list-guides/Parking/parking.png';
        break;
      case CategoryGuide.TRASH:
        image = '../../../../assets/images/list-guides/Trash/trash.png';
        break;
      case CategoryGuide.HEATING:
        image = '../../../../assets/images/list-guides/Heating/heater.png';
        break;
      case CategoryGuide.OTHER:
        image = '../../../../assets/images/list-guides/Other/questions.png';
        break;
      default: {
        image = '../../../../assets/images/list-guides/Other/questions.png';
      }
    }
    return image;

  }

  isSwiklyConfigured() {
    return this.booking && this.booking.swiklyInfo && this.booking.swiklyInfo.swiklyAcceptUrl;
  }
  isIgloohomeConfigured(): boolean {
    return this.property.iglooHomeDisplayWelcomeBooklet && this.booking && !!(this.booking.iglooHomeAccess ? this.booking.iglooHomeAccess.code : this.booking.iglooHomeLockCode);
  }

  isTheKeysConfigured(): boolean {
    return this.property.smartLockDisplayWelcomeBooklet && this.booking && this.booking.theKeysAccesses && this.booking.theKeysAccesses.length > 0;
  }

  needToShowGuide(guide: Guide) {
    if (guide.partnerName != null && guide.partnerName !== '' && guide.partnerType != null && guide.partnerType !== '') {
      if (guide.partnerName === 'swikly') {
        return this.isSwiklyConfigured() && !this.booking.swiklyInfo.swiklyDepositAcceptedDate;
      } else if (guide.partnerName === 'the-keys') {
        return this.isTheKeysConfigured();
      } else if (guide.partnerName === 'igloohome') {
        return this.isIgloohomeConfigured();
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  getGuideCover(guide: Guide) {

    if (guide && guide.guidePictureUrl) {
      return guide.guidePictureUrl;
    }

    if (!guide || !guide.documents || guide.documents.length === 0) {
      return this.getPictureFromCategory(guide.category);
    }
    const firstPhoto = guide.documents.find(item => item.type === DocType.IMAGE);
    if (!firstPhoto) {
      return this.getPictureFromCategory(guide.category);
    }

    return firstPhoto.url;
  }

  showMobileGuideDetails(guide: Guide) {


    let bookingId = null;
    if (this.booking != null) {
      bookingId = this.booking.id;
    }
    const taskCreationDialog = this.dialog.open(M22GuideDisplayComponent, {
      height: this.isDesktopMode() ? '90%' : '100vh',
      width: this.isDesktopMode() ? '75%': '100vw',
      autoFocus: false,
      data: {
        property: this.property,
        bookingId: bookingId,

        guide: guide
      }
    });

    /*const taskCreationDialog = this.dialog.open(GuestGuideDetailsComponent, {
      height: this.isDesktopMode() ? '90%' : '100vh',
      width: this.isDesktopMode() ? '75%' : '100vw',
      autoFocus: false,
      data: {
        property: this.property,
        guide: guide
      }
    });*/

  }

  getTranslatedCategory(item: CategoryGuides) {
    if (!item.guides || item.guides.length === 0 || !item.guides[0] || !item.guides[0].translations) {
      return item.category;
    }
    return  item.guides[0].translations[0].category ? item.guides[0].translations[0].category : item.category;
  }

}
