import {Component, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DOCUMENT, ViewportScroller} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../../../core/services/utils.service';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {takeUntil, windowWhen} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {TranslateService} from "@ngx-translate/core";
import {LanguageManagementService} from "../../../core/services/language.service";
import {ApiService} from '../../../core/http/api.service';
import {Corporate} from "../../../models/guestview/Corporate";
import {LoaderService} from "../../../core/services/LoaderService";
import {Icons} from "../../../models/icons";
import * as Util from "util";
import { Console } from 'console';
import { GuestService } from 'src/app/core/services/guest.service';
import {OnlineCheckin} from '../../../models/guestview/OnlineCheckin';
import {GuestUpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/GuestUpSellOnBoarding';
import {GuestUpSellOnBoardingEndpoint} from '../../../core/services/V3_UpSellOnboarding/GuestUpSellOnBoardingEndpoint';
import {UpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/UpSellOnBoarding';



@Component({
  selector: 'app-g0-guest-menu-desktop',
  templateUrl: './guest-menu-desktop.component.html',
  styleUrls: ['./guest-menu-desktop.component.scss']
})
export class GuestMenuDesktopComponent implements OnInit, OnChanges, OnDestroy {

  onlineCheck: OnlineCheckin;

  Icons: typeof Icons = Icons;
  private onDestroy = new Subject();
  languageChooserOpened = false;
  currentLanguage;
  screenWidth: number;
  @Input() fragment: string;
  @Input('target') targetsElement;
  @Input() property: PropertyForGuest;
  @Input() theme;
  @Input() noreview = false;
  @Input() booking: BookingForGuest;
  @Input() corporate: Corporate;
  asClicked = false;
  currentActiveMenu = "details";
  bookingId: string;
  displayAlerts = false;
  private _vps: ViewportScroller;
  hasUnreadMessages= false;
  currentMode=""
  CheckinValidated = false
  withOnlinecheck = false;
  currentUpSellOnBoarding: UpSellOnBoarding;
  constructor(@Inject(DOCUMENT) document,
              private utilsService: UtilsService,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private languageSrv: LanguageManagementService,
              public router: Router,
              private apiService: ApiService,
              private loaderService: LoaderService,
              public guestService: GuestService,
              private activatedRoute: ActivatedRoute,
              public translateService: TranslateService,
              public guestUpSellOnBoardingEndpoint: GuestUpSellOnBoardingEndpoint
  ) {
  }

  getLanguages() {
    return UtilsService.languageArray;
  }

  ngOnInit() {
    this.currentMode = this.router.url.split('/')[1]
    this.currentLanguage = this.languageSrv.getLanguageInUrl();
    this.screenWidth = window.innerWidth;

    this.activatedRoute.data.subscribe((res) => {
      console.log("currentUpSellOnBoarding", res.propertyAndBooking.upSellOnBoarding);
      this.currentUpSellOnBoarding = res.propertyAndBooking.upSellOnBoarding;
    });

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(ParamMap => {
      if (!this.booking) {
        this.bookingId = ParamMap.get('bookingId');
        if (this.bookingId) {
          const lang: string = this.translateService.currentLang;
          this.guestService.getPropertyAndBookingForGuest(this.bookingId, lang).subscribe( res => {
            this.withOnlinecheck = res.property.checkInOnline;
            if (res.booking) {
              this.onlineCheck = res.booking.onLineCheck;
              if (res.booking.onLineCheck) {
                this.CheckinValidated = res.booking.onLineCheck.checkStatus === 'VALIDATED';  }
            }
          });
          this.apiService.getMessages(this.bookingId).subscribe(msgs => {
            if (msgs != null) {

              this.hasUnreadMessages = msgs.filter(msg => msg.side === 'OUT' && msg.status !== 'READ').length > 0;
            }
          });
        }
      } else {
        if (this.booking.id) {
          this.apiService.getMessages(this.booking.id).subscribe(msgs => {
            if (msgs != null) {

              this.hasUnreadMessages = msgs.filter(msg => msg.side === 'OUT' && msg.status !== 'READ').length > 0;
            }
          });
        }
      }
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.booking && changes.booking.currentValue) {
      this.bookingId = this.booking.id;
    }
    if (changes.fragment) {
      this.currentActiveMenu = changes.fragment.currentValue;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  goToFragment(fragment: string) {
    //this.router.navigate([this.router.url, { fragment: fragment }]);
    //console.log("this.route", this.route);
    //console.log("this.router", this.router.url);
    /*    const element = document.querySelector("#" + fragment);
        if (element) {
          console.log('element found');
          element.scrollIntoView(true);
        }*/
  }

  scrollToElement($element, manuel): void {
    // TODO: Find a way to scroll with an offset of the size of the top menu.
    if ($element) {

      $element.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'end'});
      /*setTimeout(() => {
        console.log('SCROLL INELEMENT2');
        // workaround for top menu hiding the titles
        // document.querySelector('body').scrollTo(0, -200);
        document.body.scrollTo({
          top: document.body.scrollTop + 100, // could be negative value
          left: 0,
          behavior: 'smooth'
        });
      }, 5000);*/
    } else {
      let element = null;
      document.location.href = '/#/guest/' + this.bookingId;
      const elements: any = document.getElementsByTagName(manuel);
      setTimeout(() => {
        element = elements.item(0);
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      }, 20);
    }

  }

  isDesktopForMenu(): boolean {
    return this.utilsService.isDesktopForMenu(this.screenWidth);
  }

  @HostListener('window:resize', ['$event'])
  getScreenWidth(event?) {
    this.screenWidth = window.innerWidth;
  }

  /*
    @HostListener('document:scroll')
    onScrollHost(e: Event): void {
      console.log(this.getYPosition(e));
      console.log('scrolled');
    }

    getYPosition(e: Event): number {
      return (e.target as Element).scrollTop;
    }
  */

  changeLanguage(language: string) {
    let link = window.location.href;
    if (link.endsWith('/')) {
      link = link.substr(0, link.lastIndexOf('/'));
    }
    if (link.includes('?lang=')) {
      link = link.substr(0, link.lastIndexOf('?lang='));
    }

    window.location.href = link + '?lang=' + language;
    window.location.reload();
  }

  getLanguage(languageCode: string) {
    return UtilsService.getLanguageFlag(languageCode);
  }

  getNonTranslatedLanguageName(languageCode: string) {
    return UtilsService.getNonTranslatedLanguageName(languageCode);
  }
  loader() {
    // this.loaderService.showFullLoader('');
  }
  goOnlinecheck(){
    this.currentActiveMenu='details';
    this.router.navigate([ this.bookingId + '/online-check-in'],{state: {data: {booking:this.booking,keep:true}}})
  }

  checkIfUpSell() {
    return localStorage.getItem("guestUpSellOnBoarding") != null;
  }
  getNumbers() {
    let amount: number = 0;
    let currency = '';
     let guestUpSellOnboarding: GuestUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
     if (guestUpSellOnboarding != null) {
        if (guestUpSellOnboarding.guides != null) {
          guestUpSellOnboarding.guides.forEach(guide => {
            if (guide.upSellSection != null && guide.upSellSection.upSellAmount != null) {
              if (guide.upSellSection.upSellAmount.amount != null) {
                currency = guide.upSellSection.upSellAmount.currency;
                if (guide.upSellSection.relatedQty != null) {
                  amount += (guide.upSellSection.upSellAmount.amount * guide.upSellSection.relatedQty);
                } else {
                  amount += guide.upSellSection.upSellAmount.amount;
                }
              }
            }
          });
        }
     }
     return amount + ' ' + currency.toUpperCase();
  }
  validateUpsell() {
    this.loaderService.showFullLoader('');
    let guestUpSellOnboarding: GuestUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
    this.guestUpSellOnBoardingEndpoint.validateBasket(guestUpSellOnboarding).subscribe( res => {
      this.loaderService.dismissLoader();
      this.currentUpSellOnBoarding = res;
      // window.location.reload();
      localStorage.removeItem("guestUpSellOnBoarding");
      this.putPaymentMethod();
    }, err => {
      this.loaderService.dismissLoader();
    });
  }

  cancelUpsell() {
    this.loaderService.showFullLoader('');
    let guestUpSellOnboarding: GuestUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
    this.guestUpSellOnBoardingEndpoint.cancelBasket(this.currentUpSellOnBoarding.id).subscribe( res => {
      this.loaderService.dismissLoader();
      localStorage.removeItem("guestUpSellOnBoarding");
    });
  }
  resetBasket() {
    localStorage.removeItem("guestUpSellOnBoarding");
  }
  putPaymentMethod() {
    window.open(this.currentUpSellOnBoarding.intentConfirmationUrl, '_blank');
  }
}
