/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./property-generic-confirmation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./property-generic-confirmation.component";
import * as i5 from "../../../core/services/guest.service";
import * as i6 from "../../../core/services/V3_GenericLinkEndpoint/GenericLinkEndpoint";
import * as i7 from "../../../shared/components/shared-snackbar/services/shared-snackbar.service";
import * as i8 from "@angular/router";
var styles_PropertyGenericConfirmationComponent = [i0.styles];
var RenderType_PropertyGenericConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PropertyGenericConfirmationComponent, data: {} });
export { RenderType_PropertyGenericConfirmationComponent as RenderType_PropertyGenericConfirmationComponent };
function View_PropertyGenericConfirmationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "y3-flex-row y3-flex-justify-content-center y3-padding-bottom-16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["style", "max-width: 50%; max-height: 30vh"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.corporate.logoUrl; _ck(_v, 1, 0, currVal_0); }); }
export function View_PropertyGenericConfirmationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "generic-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "y3-flex-column generic-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PropertyGenericConfirmationComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "y3-XLarge"], ["style", "text-align: center; font-weight: bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "y3-Small y3-margin-top-16"], ["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "y3-Small y3-margin-bottom-16"], ["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.corporate == null) ? null : _co.corporate.logoUrl); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("generic-link-confirm.title")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("generic-link-confirm.description")); _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("generic-link-confirm.description2")); _ck(_v, 11, 0, currVal_3); }); }
export function View_PropertyGenericConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-property-generic-confirmation", [], null, null, null, View_PropertyGenericConfirmationComponent_0, RenderType_PropertyGenericConfirmationComponent)), i1.ɵdid(1, 245760, null, 0, i4.PropertyGenericConfirmationComponent, [i5.GuestService, i6.GenericLinkEndpoint, i3.TranslateService, i7.SharedSnackbarService, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PropertyGenericConfirmationComponentNgFactory = i1.ɵccf("app-property-generic-confirmation", i4.PropertyGenericConfirmationComponent, View_PropertyGenericConfirmationComponent_Host_0, {}, {}, []);
export { PropertyGenericConfirmationComponentNgFactory as PropertyGenericConfirmationComponentNgFactory };
