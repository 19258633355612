import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {GuestLandingPageComponent} from './g1-guest-landing-page/guest-landing-page.component';
import {GuestHostServicesComponent} from './guest-host-services/guest-host-services.component';
import {GuestGuidesDetailDesktopComponent} from "./guest-guides-detail-desktop/guest-guides-detail-desktop.component";
import {GuestGuidePhotoDetailComponent} from "./guest-guide-photo-detail/guest-guide-photo-detail.component";
import {GuestUsefulnumberFullListDesktopComponent} from "./guest-usefulnumber-full-list-desktop/guest-usefulnumber-full-list-desktop.component";
import { RecommendationsCreateComponent } from './guest-recommendations/recommendations-create/recommendations-create.component';
import {GuestReviewComponent} from "./guest-review/guest-review.component";
import {GuestPropertyGalleryComponent} from "./guest-property-gallery/guest-property-gallery.component";
import {GuestReviewTeamamteComponent} from "./guest-review/guest-review-teamamte/guest-review-teamamte.component";
import { GuestResolverService } from "./guest-resolver.service";
import {GuestRecommendationsViewComponent} from './guest-recommendations/guest-recommendations-view/guest-recommendations-view.component';
import {GuestBookingSetupComponent} from './guest-booking-setup/guest-booking-setup.component';
import {GuestInventoryComponent} from './guest-inventory/guest-inventory.component';
import {UpsaleSelectorComponent} from "./upsale/upsale-selector/upsale-selector.component";
import {UpsaleBasketComponent} from "./upsale/upsale-basket/upsale-basket.component";
import {GuestRecoWidgetComponent} from './guest-recommendations/guest-reco-widget/guest-reco-widget.component';
import { LivretWelcomeComponent } from './guest-welcome-livret/guest-welcome-livret.component';
import {Y3ChatMessagingComponent} from './y3-chat-messaging/y3-chat-messaging.component';
import {G10PreCheckInComponent} from './g10-pre-check-in/g10-pre-check-in.component';
import {G11OnlineCheckInComponent} from './g11-online-check-in/g11-online-check-in.component';
import {M0LandingPageComponent} from './m0-landing-page/m0-landing-page.component';
import {M4ContactDetailsComponent} from './m4-contact-details/m4-contact-details.component';
import {M7ChatComponent} from './m7-chat/m7-chat.component';
import {M1PropertyDetailsComponent} from './m1-property-details/m1-property-details.component';
import {M6UpsellsComponent} from './m6-upsells/m6-upsells.component';
import {M8ImportantGuidesComponent} from './m8-important-guides/m8-important-guides.component';
import {M9NumbersComponent} from './m9-numbers/m9-numbers.component';
import {M5HygienComponent} from './m5-hygien/m5-hygien.component';
import {M10InventoryComponent} from './m10-inventory/m10-inventory.component';
import {M11PlatformServicesComponent} from './m11-platform-services/m11-platform-services.component';
import {M12PasswordScreenComponent} from './m12-password-screen/m12-password-screen.component';
import {M13WelcomeScreenComponent} from './m13-welcome-screen/m13-welcome-screen.component';
import {M14BookletReviewComponent} from './m14-booklet-review/m14-booklet-review.component';
import {M26ReviewComponent} from './m26-review/m26-review.component';


const routes: Routes = [
  {
  path: '',
  //component: GuestLandingPageComponent,
  resolve: { propertyAndBooking: GuestResolverService },
  runGuardsAndResolvers: 'always',
  children: [
    {
      path: ':bookingId',
      component: GuestLandingPageComponent,

    },
    {
      path: ':bookingId/details',
      component: GuestLandingPageComponent,
    },
    {
      path: ':bookingId/recommendations',
      component: GuestLandingPageComponent,
    },
    {
      path: ':bookingId/guides',
      component: GuestLandingPageComponent,
    },
    {
      path: ':bookingId/guides/:guideId',
      component: GuestLandingPageComponent,
    },
    {
      path: ':bookingId/services',
      component: GuestLandingPageComponent,
    },
    {
      path: ':bookingId/hygienes',
      component: GuestLandingPageComponent,
    },
    {
      path: ':bookingId/upsells',
      component: GuestLandingPageComponent,
    },
    {
      path: ':bookingId/4guest',
      component: GuestLandingPageComponent,
    },
    {
      path: ':bookingId/usefulnumber',
      component: GuestLandingPageComponent,
    },
/*    {
      path: ':bookingId/gallery-photo-mobile',
      component: GuestPropertyGalleryPhotoComponent,
    },*/
    {
      path: ':bookingId/property-gallery',
      component: GuestPropertyGalleryComponent,
    },
    {
      path: ':bookingId/host-services',
      component: GuestHostServicesComponent,
    },
    {
      path: ':bookingId/recommendations',
      component: GuestRecommendationsViewComponent,
    },
    {
      path: ':bookingId/recommendations/create',
      component: RecommendationsCreateComponent,
    },
    {
      path: ':bookingId/useful-number',
      component: GuestUsefulnumberFullListDesktopComponent,
      /*component: GuestUsefulnumberComponent,*/
    },
    {
      path: ':bookingId/useful-number-full-list',
      component: GuestUsefulnumberFullListDesktopComponent,
    },
    {
     path: ':bookingId/guide-full-detail',
     component: GuestGuidesDetailDesktopComponent,
    },
    {
      path: ':bookingId/guide-detail-photo',
      component: GuestGuidePhotoDetailComponent,
    },
    {
      path: ':bookingId/review',
      component: GuestReviewComponent,
    },
    {
      path: ':bookingId/review-teammate',
      component: GuestReviewTeamamteComponent,
    },
    {
      path: ':bookingId/booking-setup',
      component: GuestBookingSetupComponent,
    },
    {
      path: ':bookingId/inventory',
      component: GuestInventoryComponent,
    },
    {
      path: ':bookingId/chat',
      component: Y3ChatMessagingComponent,
    },
    {
      path: ':bookingId/messaging',
      component: Y3ChatMessagingComponent,
    },
    {
      path: ':bookingId/upsaleselector',
      component: UpsaleSelectorComponent
    },
    {
      path: ':bookingId/basket',
      component: UpsaleBasketComponent
    },
    {
      path: ':bookingId/misc/reco-widget',
      component: GuestRecoWidgetComponent
    },
    {
      path: ':bookingId/livret-welcome',
      component: LivretWelcomeComponent
    },
    {
      path: ':bookingId/pre-check-in',
      component: G10PreCheckInComponent
    },
    {
      path: ':bookingId/online-check-in',
      component: G11OnlineCheckInComponent
    },
    {
      path: ':bookingId/mobile',
      component: M0LandingPageComponent
    },
    {
      path: ':bookingId/mobile/recommendations',
      component: M0LandingPageComponent
    },
    {
      path: ':bookingId/mobile/guides',
      component: M0LandingPageComponent
    },
    {
      path: ':bookingId/mobile/upsells',
      component: M0LandingPageComponent
    },
    {
      path: ':bookingId/mobile-details',
      component: M4ContactDetailsComponent
    },
    {
      path: ':bookingId/mobile-chat',
      component: M7ChatComponent
    },
    {
      path: ':bookingId/mobile/chat',
      component: M7ChatComponent
    },
    {
      path: ':bookingId/mobile/messaging',
      component: M7ChatComponent
    },
    {
      path: ':bookingId/mobile-property',
      component: M1PropertyDetailsComponent
    },
    {
      path: ':bookingId/mobile-upsells',
      component: M6UpsellsComponent
    },
    {
      path: ':bookingId/mobile-important',
      component: M8ImportantGuidesComponent
    },
    {
      path: ':bookingId/mobile-numbers',
      component: M9NumbersComponent
    },
    {
      path: ':bookingId/mobile/usefulnumber',
      component: M9NumbersComponent
    },
    {
      path: ':bookingId/mobile-hygien',
      component: M5HygienComponent
    },
    {
      path: ':bookingId/mobile-inventory',
      component: M10InventoryComponent
    },
    {
      path: ':bookingId/mobile-services',
      component: M11PlatformServicesComponent
    },
    {
      path: ':bookingId/mobile-password',
      component: M12PasswordScreenComponent
    },
    {
      path: ':bookingId/mobile-welcome',
      component: M13WelcomeScreenComponent
    },
    {
      path: ':bookingId/mobile-online-check-in',
      component: G11OnlineCheckInComponent
    },
    {
      path: ':bookingId/mobile/booking-setup',
      component: G11OnlineCheckInComponent
    },
    {
      path: ':bookingId/mobile/guides/:guideId',
      component: M0LandingPageComponent
    },
    {
      path: ':bookingId/mobile/guides',
      component: M0LandingPageComponent
    },
    {
      path: ':bookingId/mobile/booklet-review',
      component: M14BookletReviewComponent
    },
    {
      path: ':bookingId/mobile/review',
      component: M26ReviewComponent
    }
  ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GuestRoutingModule { }
