import { Icons } from '../../models/icons';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/icon";
import * as i2 from "@angular/platform-browser";
export class IconService {
    constructor(matIconRegistry, domSanitizer) {
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
    }
    registerIcons() {
        this.loadIcons(Object.values(Icons), '../../assets/icon/buttons');
    }
    loadIcons(iconKeys, iconUrl) {
        iconKeys.forEach(key => {
            this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
        });
    }
}
IconService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IconService_Factory() { return new IconService(i0.ɵɵinject(i1.MatIconRegistry), i0.ɵɵinject(i2.DomSanitizer)); }, token: IconService, providedIn: "root" });
