<div class="page-container">
  <app-online-check-header [corporate]="corporate"></app-online-check-header>

  <div class="check-container margin-top-16">
    <div class="title">
      <p class="margin-bottom-16">{{ property?.title }}</p>
    </div>

    <app-online-check-welcome-bar [booking]="booking" [property]="property"></app-online-check-welcome-bar>

    <div class="stepper-padding">
      <p class="label-registration">
        {{ "online-check.registration" | translate }}
      </p>
      <mat-horizontal-stepper #stepper>
        <mat-step>
          <form [formGroup]="firstFormGroup">
            <ng-template class="step-label" matStepLabel>{{
              "online-check.info" | translate
              }}</ng-template>

            <div class="col-2">
              <mat-form-field class="padding-right">
                <mat-label>{{ "online-check.firstname" | translate }}
                </mat-label>
                <input [ngModel]="localOnLineCheck?.firstName" formControlName="prenom" matInput placeholder="Field" />
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{ "online-check.lastname" | translate }}
                </mat-label>
                <input [ngModel]="localOnLineCheck?.lastName" formControlName="nom" matInput placeholder="Field" />
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field class="check padding-right">
                <mat-label>{{ "online-check.check-in" | translate }}
                </mat-label>
                <input [ngModel]="
                    localOnLineCheck?.onLineCheckInTime ||
                    booking?.expectedCheckinLocalTime || booking?.requestedCheckinLocalTime
                  " class="time-selected" formControlName="onLineCheckInTime" matInput name="defaultCheckInTime"
                  type="time" />
              </mat-form-field>

              <mat-form-field class="check">
                <mat-label>{{ "online-check.check-out" | translate }}
                </mat-label>
                <input [ngModel]="
                    localOnLineCheck?.onLineCheckOutTime ||
                    booking?.expectedCheckoutLocalTime || booking?.requestedCheckoutLocalTime
                  " class="time-selected" formControlName="onLineCheckOutTime" matInput name="defaultCheckInTime"
                  type="time" />
              </mat-form-field>
            </div>

            <div class="col-2">
              <mat-form-field class="padding-right">
                <mat-label class="mat-label">{{ "online-check.email" | translate }}
                </mat-label>
                <input [ngModel]="localOnLineCheck?.email" formControlName="email" matInput placeholder="Field" />
              </mat-form-field>
              <mat-form-field appearance="legacy" class="">
                <mat-select [ngModel]="localOnLineCheck?.guestCountry" formControlName="pays"
                  placeholder="{{ 'online-check.country' | translate }}">
                  <mat-option *ngFor="let country of countries" [value]="country.cca2">
                    {{
                    language == "en"
                    ? country?.name?.common
                    : country?.translations[language]
                    ? country?.translations[language].common
                    : country?.name?.common
                    }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-2">
              <mat-form-field class="padding-right">
                <mat-label class="mat-label">{{
                  "online-check.gender" | translate
                  }}</mat-label>
                <mat-select [ngModel]="localOnLineCheck?.guestGender" class="select" formControlName="genre" required>
                  <mat-option value="homme">{{
                    "online-check.male" | translate
                    }}</mat-option>
                  <mat-option value="femme">{{
                    "online-check.female" | translate
                    }}</mat-option>
                  <mat-option value="autre">{{
                    "online-check.other" | translate
                    }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="check" appearance="fill" class="">
                <mat-label class="mat-label">{{
                  "online-check.birthdate" | translate
                  }}</mat-label>
                <input class="clickable" onfocus="blur()" [matDatepicker]="picker" formControlName="dateNaissance"
                  (focus)="picker.open()" [ngModel]="localOnLineCheck?.guestBirthDate" matInput />
                <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
              </mat-form-field>
              <mat-datepicker #picker></mat-datepicker>
            </div>

            <div></div>

            <div class="grid-2">
              <div [formGroup]="firstFormGroup" class="form-group">
                <label class="label-profile">{{
                  "online-check.contactNumber" | translate
                  }}</label>

                <ngx-intl-tel-input (countryChange)="countryChange()" [cssClass]="'custom-tel-input'"
                  [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [maxLength]="15"
                  [numberFormat]="'NATIONAL'" [phoneValidation]="true" [preferredCountries]="preferredCountries"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]" [searchCountryFlag]="true" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.France"
                  [separateDialCode]="separateDialCode" formControlName="phone"
                  name="phone">
                </ngx-intl-tel-input>
              </div>

              <mat-checkbox *ngIf="property?.checkinOnlineWithTransport === true" class="checkbox-transp"
                [ngModel]="localOnLineCheck?.onLineCheckWithTransport" formControlName="onLineCheckWithTransport"
                name="WithTransport">
                <span class="tr-span">{{
                  "online-check.onLineCheckWithTransport" | translate
                  }}</span>
              </mat-checkbox>
            </div>

            <app-button (debounceClick)="goForward(stepper)" *ngIf="!firstFormGroup.valid"
              [text]="'online-check.next' | translate" [type]="'primary'" class="next-button" disabled></app-button>
            <app-button (debounceClick)="goForwardValid(stepper); valid()" *ngIf="firstFormGroup.valid"
              [text]="'online-check.next' | translate" [type]="'primary'" class="next-button">
            </app-button>
          </form>
        </mat-step>

        <mat-step [stepControl]="">
          <form>
            <ng-template matStepLabel>{{
              "online-check.id" | translate
              }}</ng-template>
            <div class="all-width margin-top-12">
              <div class="btn-upload">
                <app-shared-upload (docUploaded)="onPictureOutUploaded($event)" [allowedTypes]="allowedImagesDocs"
                  [btnLabel]="'guest-inventory.upload'" [uploadType]="'multiple'"></app-shared-upload>
              </div>

              <div class="row m-0">
                <perfect-scrollbar [config]="config" [ngClass]="{
                    'scroll-container-height': proofOfIdentity?.length > 3
                  }" class="scroll-container">
                  <div class="container-imgs row mx-0">
                    <div *ngFor="let doc of proofOfIdentity; let i = index">
                      <div *ngIf="doc" class="container-img">
                        <div (click)="showDialog(i, 'in')" class="container-delete-icon clickable">
                          <img alt="icon-delete" class="icon-delete" src="assets/icon/inventory/delete.svg" />
                        </div>
                        <img *ngIf="doc.type === 'IMAGE'" [src]="doc.url" class="Photo picture-width" />
                        <iframe *ngIf="doc.type === 'PDF'" class="Photo picture-width"
                        [src]="doc.url | safe"></iframe>

                      </div>
                      <div *ngIf="!doc" class="container-img">
                        <mat-spinner [diameter]="24" mode="indeterminate"></mat-spinner>
                      </div>
                    </div>
                    <div *ngIf="showSpinner" class="container-img">
                      <mat-spinner [diameter]="24" mode="indeterminate"></mat-spinner>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>

              <div class="document-info">
                <p class="label-document-info">
                  {{ "online-check.info-documents" | translate }}
                </p>
              </div>
              <div *ngIf="message" class="mt-1 size-msg-err"></div>
            </div>
          </form>

          <div *ngIf="property?.checkinOnlineWithContract" class="buttons">
            <app-button (debounceClick)="goPrevious(stepper)" [text]="'online-check.previous' | translate"
              [type]="'secondary'"></app-button>
            <app-button (debounceClick)="goForward(stepper)" *ngIf="proofOfIdentity?.length == 0"
              [text]="'online-check.next' | translate" [type]="'primary'" disabled></app-button>
            <app-button (debounceClick)="goForward(stepper); loaded(); createContract()"
              *ngIf="proofOfIdentity?.length > 0" [text]="'online-check.next' | translate" [type]="'primary'">
            </app-button>
          </div>
          <div *ngIf="!property?.checkinOnlineWithContract" class="buttons">
            <app-button (debounceClick)="goPrevious(stepper)" [text]="'online-check.previous' | translate"
              [type]="'secondary'"></app-button>
            <app-button  *ngIf="
            proofOfIdentity?.length == 0 && property?.checkinOnlineWithCgv
          " (debounceClick)="goForward(stepper)" [text]="'online-check.next' | translate" [type]="'primary'" disabled></app-button>
            <app-button (debounceClick)="goForward(stepper)" *ngIf="
                proofOfIdentity?.length == 0 && !property?.checkinOnlineWithCgv
              " [text]="'online-check.validate' | translate" [type]="'primary'" disabled></app-button>
            <app-button *ngIf="
                proofOfIdentity?.length > 0 && !property?.checkinOnlineWithCgv
              " (debounceClick)="goForward(stepper); withoutContrat()" [text]="'online-check.validate' | translate"
              [type]="'primary'"></app-button>
            <app-button *ngIf="
                proofOfIdentity?.length > 0 && property?.checkinOnlineWithCgv
              " (debounceClick)="goForward(stepper)" [text]="'online-check.next' | translate" [type]="'primary'">
            </app-button>
          </div>
        </mat-step>

        <div *ngIf="property?.checkinOnlineWithContract" class="">
          <mat-step [stepControl]="" label="Contrat">
            <ng-template matStepLabel>{{
              "online-check.contract" | translate
              }}</ng-template>

            <div class="contrat contrat-position contratpdf canvas-wrapper">
              <pdf-viewer [autoresize]="true" [fit-to-page]="true" [original-size]="true" [render-text]="0"
                [rotation]="0" [show-all]="true" [show-borders]="false" [src]="localOnLineCheck?.contractDocumentUrl"
                [stick-to-page]="false" [zoom-scale]="'page-width'" [zoom]="1" style="max-width: 50px"></pdf-viewer>
            </div>

            <div class="signature-pad" id="signature-pad">
              <p class="msg-info">
                {{ "online-check.message-info" | translate }}
              </p>
              <div class="signature-pad--body">
                <canvas id="canvasIDForSignature" style="width: 100%"></canvas>
              </div>
            </div>
            <div class="buttons">
              <app-button (debounceClick)="goPrevious(stepper)" [text]="'online-check.previous' | translate"
                type="secondary"></app-button>
              <app-button *ngIf="property?.checkinOnlineWithCgv" (debounceClick)="runboth(stepper)"
                [text]="'online-check.sign' | translate" type="primary"></app-button>
              <app-button *ngIf="!property?.checkinOnlineWithCgv" (debounceClick)="savePad()"
                [text]="'online-check.validate' | translate" type="primary"></app-button>
            </div>
          </mat-step>
        </div>

        <div class="" *ngIf="property?.checkinOnlineWithCgv">
          <mat-step [stepControl]="" label="Contrat">
            <ng-template matStepLabel>CGV</ng-template>

            <div class="contrat contrat-position contratpdf canvas-wrapper" style="padding-top: 6px">
              <pdf-viewer [autoresize]="true" [fit-to-page]="true" [original-size]="true" [render-text]="0"
                [rotation]="0" [show-all]="true" [show-borders]="false" [src]="localOnLineCheck?.cgvDocumentUrl"
                [stick-to-page]="false" [zoom-scale]="'page-width'" [zoom]="1" style="max-width: 50px"></pdf-viewer>
            </div>
            <div class="checkbox-position">
              <mat-checkbox [(ngModel)]="agree">
                <span class="roboto Small txt-color-grvogue">
                  {{ "online-check.confirm-cgv" | translate }}</span>
              </mat-checkbox>
            </div>
            <div class="buttons">
              <app-button (debounceClick)="goPrevious(stepper)" [text]="'online-check.previous' | translate"
                type="secondary"></app-button>
              <app-button [disabled]="!agree" (debounceClick)="withoutContratCGV(); validate()"
                [text]="'online-check.validate' | translate" type="primary"></app-button>
            </div>
          </mat-step>
        </div>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>
