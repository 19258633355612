<div class="page-container">


    <app-online-check-header [corporate]="corporate"></app-online-check-header>

    <img class="mainPhotoLandingPage" *ngIf="mainPropertyPhoto != null" [src]="mainPropertyPhoto" alt="mainPhoto">

    <div class="title title-position">

      <h4 class="margin-bottom-16">{{property?.title}}</h4>

    </div>
    <app-online-check-welcome-bar [booking]="booking" [property]="property"></app-online-check-welcome-bar>
    <div *ngIf="booking != null" class="journey-summary">
      <div class="row justify-content-sb align-items-center">
        <div class="welcome-container">
          <p class="margin-bottom-16">{{'guest-top-page.welcome' | translate}}</p>
          <p>{{booking?.guests[0]?.firstName | titlecase}} <span
              *ngIf="property?.host?.btobInfo">{{booking?.guests[0]?.lastName | titlecase}}</span></p>
          <p *ngIf="property?.host?.btobInfo" class="company-name">{{booking?.guests[0]?.companyName | titlecase}}</p>
        </div>
        <div *ngIf="!property?.host?.btobInfo" class="nights-container">
          <p class="Small">{{'guest-top-page.nights-desktop' | translate}}</p>
          <p class="number">{{booking?.nights}}</p>
        </div>
      </div>
      <div *ngIf="booking?.startDate !== booking?.endDate" class="date-cards">
        <div class="date-card margin-right-16">
          <img src="assets/images/illustrations/men-fly.svg" alt="start">
          <div class="date-container">
            <p class="title">{{'guest-top-page.start' | translate}}</p>
            <p class="date">{{booking?.startDate | moment: translateService.currentLang | titlecase}}</p>
          </div>
        </div>
        <div class="date-card">
          <img src="assets/images/illustrations/men-luaggage.svg" alt="departure">
          <div class="date-container">
            <p class="title">{{'guest-top-page.end' | translate}}</p>
            <p class="date">{{booking?.endDate | moment: translateService.currentLang | titlecase}}</p>
          </div>
        </div>
      </div>


      <div *ngIf="booking?.startDate === booking?.endDate" class="date-cards">
        <div class="date-card ">
          <img src="assets/images/illustrations/men-fly.svg" alt="start">
          <div class="date-container">
            <p class="title">{{'guest-top-page.your-resa' | translate}}</p>
            <p class="date">{{booking?.startDate  | moment: translateService.currentLang | titlecase}}</p>
          </div>
        </div>
      </div>
    </div>

  <div class="">
    <p class="label-registration"> {{'online-check.registration' |translate }}</p>
  </div>
    <mat-vertical-stepper #stepper class="stepper-padding " *ngIf="booking?.guests != null">

      <mat-step>
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel class="step-label">{{'online-check.info' | translate}}</ng-template>

          <div class="col-2 ">

            <mat-form-field class="margin-right-12">
              <mat-label>{{'online-check.firstname' | translate}} </mat-label>
              <input matInput placeholder="Field" formControlName="prenom" [ngModel]="localOnLineCheck?.firstName" />

            </mat-form-field>

            <mat-form-field class="margin-right-12">
              <mat-label>{{'online-check.lastname' | translate}} </mat-label>
              <input matInput placeholder="Field" formControlName="nom" [ngModel]="localOnLineCheck?.lastName" />
            </mat-form-field>


          </div>
          <div class="col-2 ">

            <mat-form-field class="check" style="margin-right:16px">
              <mat-label>{{'online-check.check-in' | translate}} </mat-label>
              <input type="time" class="time-selected" name="defaultCheckInTime" formControlName="onLineCheckInTime" matInput
              [ngModel]="localOnLineCheck?.onLineCheckInTime || booking?.expectedCheckinLocalTime || booking?.requestedCheckinLocalTime" />
            </mat-form-field>

            <mat-form-field class="check " style="margin-right:16px">
              <mat-label>{{'online-check.check-out' | translate}} </mat-label>
              <input [ngModel]="localOnLineCheck?.onLineCheckOutTime || booking?.expectedCheckoutLocalTime || booking?.requestedCheckoutLocalTime" class="time-selected" formControlName="onLineCheckOutTime" matInput name="defaultCheckInTime"
              type="time"/>
            </mat-form-field>
          </div>
          <div class="col-2 ">

            <mat-form-field class="margin-right-12">
              <mat-label>{{'online-check.email' | translate}} </mat-label>
              <input formControlName="email" matInput placeholder="Field"
              [ngModel]="localOnLineCheck?.email" />
            </mat-form-field>


            <mat-form-field appearance="legacy" class="">
              <mat-select [ngModel]="localOnLineCheck?.guestCountry" formControlName="pays"
                          placeholder="{{ 'online-check.country' | translate}}">
                <mat-option *ngFor="let country of countries " [value]="country.cca2 ">
                  {{ language == "en" ? country?.name?.common : country?.translations[language]? country?.translations[language].common:country?.name?.common }}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div class="col-2 ">

            <mat-form-field class="margin-right-12">
              <mat-label>{{'online-check.gender' | translate}}</mat-label>
              <mat-select class="select" formControlName="genre" required
              [ngModel]="localOnLineCheck?.guestGender">
              <mat-option value="homme">{{'online-check.male' | translate}}</mat-option>
              <mat-option value="femme">{{'online-check.female' | translate}}</mat-option>
              <mat-option value="autre">{{'online-check.other' | translate}}</mat-option>
            </mat-select>
            </mat-form-field>


            <mat-form-field class="check"  appearance="fill" class="">
              <mat-label class="mat-label">{{'online-check.birthdate' | translate}}</mat-label>
              <input class="clickable"  onfocus="blur()"
                   [matDatepicker]="picker" formControlName="dateNaissance"
                  (focus)="picker.open()" [ngModel]="localOnLineCheck?.guestBirthDate" matInput
                >
                <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker #picker  ></mat-datepicker>
          </div>
          <div>

          </div>
          <div class="grid-2">
            <div class="form-group form-group-mobile" [formGroup]="firstFormGroup">
              <label class="label-profile">{{ 'online-check.contactNumber' | translate}}</label>

              <ngx-intl-tel-input [cssClass]="'custom-tel-input'" [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.France" [maxLength]="15" [numberFormat]="'NATIONAL'"
               [phoneValidation]="true" [separateDialCode]="separateDialCode"
               (countryChange)="countryChange()" name="phone"
              formControlName="phone" >
            </ngx-intl-tel-input>
            </div>
            <div class="checkbox-transp">
              <mat-checkbox  *ngIf="property?.checkinOnlineWithTransport === true" class="checkbox-transp" [ngModel]="localOnLineCheck?.onLineCheckWithTransport"

              formControlName="onLineCheckWithTransport" name="WithTransport" >
                <span class="tr-span" >{{'online-check.onLineCheckWithTransport' | translate}}</span>

                  </mat-checkbox>
            </div>
          </div>

          <app-button *ngIf="!firstFormGroup.valid" class="" [text]="'online-check.next' | translate" [type]="'primary'"
            (debounceClick)="goForward(stepper) " disabled>
          </app-button>
          <app-button *ngIf="firstFormGroup.valid" class="" [text]="'online-check.next' | translate" [type]="'primary'"
          (debounceClick)="goForwardValid(stepper) ; valid()">
          </app-button>
        </form>
      </mat-step>

      <mat-step [stepControl]="secondFormGroup">
        <form>
          <ng-template matStepLabel>{{'online-check.id' | translate}}</ng-template>

          <div class="header header-padding">
            <a class="back-link" (click)="goPrevious(stepper)"><img class="icon-btn"
                src="assets/icon/back-icon-arrow.svg" /> {{'online-check.previous' |
              translate}}</a>
          </div>
          <div class="all-width margin-top-12">

            <div class="btn-upload">
              <app-shared-upload [btnLabel]="'guest-inventory.upload'" [uploadType]="'multiple'"
                [allowedTypes]="allowedImagesDocs" (docUploaded)="onPictureOutUploaded($event)"></app-shared-upload>
            </div>


            <div class="row m-0">
              <perfect-scrollbar [config]="config"
                                 [ngClass]="{'scroll-container-height': proofOfIdentity?.length>3}" class="scroll-container">
                <div class="container-imgs row mx-0">

                  <div *ngFor="let doc of proofOfIdentity; let i = index">

                    <div *ngIf="doc" class="container-img">
                      <div (click)="showDialog(i, 'in')" class="container-delete-icon clickable">
                        <img alt="icon-delete" class="icon-delete" src="assets/icon/inventory/delete.svg" />
                      </div>
                      <img *ngIf="doc.type === 'IMAGE'" [src]="doc.url" class="Photo picture-width" />
                      <iframe *ngIf="doc.type === 'PDF'" class="Photo picture-width"
                      [src]="doc.url | safe"></iframe>

                    </div>
                    <div *ngIf="!doc" class="container-img">
                      <mat-spinner [diameter]="24" mode="indeterminate"></mat-spinner>
                    </div>
                  </div>
                  <div *ngIf="showSpinner" class="container-img">
                    <mat-spinner [diameter]="24" mode="indeterminate"></mat-spinner>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
            <div class="document-info">
              <p class="label-document-info">
              {{"online-check.info-documents" | translate}}
              </p>
            </div>
            <div *ngIf="message" class="mt-1 size-msg-err">{{message}}</div>

          </div>

        </form>

        <div class="" *ngIf="property?.checkinOnlineWithContract">

            <app-button (debounceClick)="goForward(stepper)" *ngIf="proofOfIdentity?.length == 0"
            [text]="'online-check.next' | translate" [type]="'primary'" disabled>
          </app-button>
          <app-button (debounceClick)="goForward(stepper); loaded(); createContract()"
            *ngIf="proofOfIdentity?.length > 0" [text]="'online-check.next' | translate" [type]="'primary'">
          </app-button>
        </div>

        <div class="" *ngIf="!property?.checkinOnlineWithContract">

          <app-button (debounceClick)="goForward(stepper) " *ngIf="proofOfIdentity?.length==0 && property?.checkinOnlineWithCgv" [text]="'online-check.next' | translate"
          [type]="'primary'" disabled></app-button>
          <app-button (debounceClick)="goForward(stepper) " *ngIf="proofOfIdentity?.length==0 && !property?.checkinOnlineWithCgv" [text]="'online-check.validate' | translate"
          [type]="'primary'" disabled></app-button>



<app-button *ngIf="proofOfIdentity?.length>0 && !property?.checkinOnlineWithCgv" (debounceClick)=" goForward(stepper) ; withoutContrat()"  [text]="'online-check.validate' | translate"
          [type]="'primary'"></app-button>
          <app-button  *ngIf=" proofOfIdentity?.length>0  && property?.checkinOnlineWithCgv " (debounceClick)=" goForward(stepper)  " [text]="'online-check.next' | translate"
            [type]="'primary'"></app-button>

        </div>
      </mat-step>

      <div class="test" *ngIf="property?.checkinOnlineWithContract">

        <mat-step [stepControl]="" label="Contrat">
          <form [formGroup]="">
            <ng-template matStepLabel>{{'online-check.contract' | translate}}</ng-template>
            <div class="header header-padding">
              <a class="back-link" (click)="goPrevious(stepper)"><img class="icon-btn"
                  src="assets/icon/back-icon-arrow.svg" /> {{'online-check.previous' |
                translate}}</a>
            </div>

            <div class="contrat contrat-position contratpdf">



              <div class="col-2 ">

                <pdf-viewer class="ng2-pdf-viewer-container contratpdf" [src]="localOnLineCheck?.contractDocumentUrl"
                  [render-text]="true"
                  [original-size]="false"
                  [show-borders]="true"
                  [show-all]="true"
                  [fit-to-page]="true"
                  [page]="1"
                  [zoom]="custom"
                style="width: 100%;"
            ></pdf-viewer>

                <div class="rect">
                  <div class="rect-inside">
                    <img (click)="customminus()"  src="/assets/icon/remove_white.svg">
                           <img (click)="customzoom()" src="/assets/icon/add_white.svg">
                           <a  href="{{localOnLineCheck?.contractDocumentUrl}}" >
                            <img  src="/assets/icon/open_in_new_white.svg">
                           </a>

                  </div>
                </div>

              </div>
            </div>
            <div id="signature-pad" class="signature-pad">
              <p class="msg-info">{{'online-check.message-info' | translate}}</p>
              <div  class="signature-pad--body">
                <canvas id="canvasIDForSignature" style="width: 100% "></canvas>
              </div>

            </div>
          </form>

              <app-button *ngIf="property?.checkinOnlineWithCgv" (debounceClick)="runboth(stepper) " [text]="'online-check.sign' | translate"
              type="primary"></app-button>
              <app-button *ngIf="!property?.checkinOnlineWithCgv" (debounceClick)="savePad() " [text]="'online-check.validate' | translate"
              type="primary"></app-button>
          </mat-step>

      </div>
      <div class="" *ngIf="property?.checkinOnlineWithCgv">
        <mat-step [stepControl]="" label="Contrat">

          <div class="header header-padding">
            <a class="back-link" (click)="goPrevious(stepper)"><img class="icon-btn"
                src="assets/icon/back-icon-arrow.svg" /> {{'online-check.previous' |
              translate}}</a>
          </div>
          <ng-template matStepLabel>CGV</ng-template>

          <div class="contrat contrat-position contratpdf canvas-wrapper">
            <pdf-viewer [autoresize]="true"
                        [fit-to-page]="true"
                        [original-size]="true"
                        [render-text]="0"
                        [rotation]="0"
                        [show-all]="true"
                        [show-borders]="false"
                        [src]="localOnLineCheck?.cgvDocumentUrl"
                        [stick-to-page]="false"
                        [zoom-scale]="'page-width'"
                        [zoom]="1"
                        style=" max-width: 50px;"></pdf-viewer>
          </div>
          <div class="checkbox-position">

          <mat-checkbox  [(ngModel)]="agree" >

          </mat-checkbox>
          <p class="roboto Small txt-color-grvogue confirm "> {{'online-check.confirm-cgv' | translate}}</p>
        </div>

            <app-button [disabled]="!agree" (debounceClick)="withoutContratCGV() ; validate() " [text]="'online-check.validate' | translate"
            type="primary"></app-button>
        </mat-step>
      </div>

    </mat-vertical-stepper>

  </div>

  <script src="js/signature_pad.js"></script>
