/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shared-upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./shared-upload.component";
import * as i5 from "../shared-snackbar/services/shared-snackbar.service";
import * as i6 from "../../../core/services/DocumentService";
import * as i7 from "../../../core/services/compress-image.service";
var styles_SharedUploadComponent = [i0.styles];
var RenderType_SharedUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharedUploadComponent, data: {} });
export { RenderType_SharedUploadComponent as RenderType_SharedUploadComponent };
function View_SharedUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "picto"], ["class", "margin-right-8"], ["src", "assets/icon/upload.svg"]], null, null, null, null, null))], null, null); }
function View_SharedUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "picto"], ["class", "margin-right-8 rotate-loader"], ["src", "assets/icon/loading.svg"]], null, null, null, null, null))], null, null); }
function View_SharedUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "inline-blue-upload clickable"], ["matRipple", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedUploadComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedUploadComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "roboto bold X-small txt-color-grvogue"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["fileUploader", 1]], null, 0, "input", [["class", "upload-input clickable"], ["matRipple", ""], ["title", ""], ["type", "file"]], [[8, "disabled", 0], [8, "id", 0], [8, "accept", 0], [8, "multiple", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChoosed($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isUploading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isUploading; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.btnLabel)); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.isUploading; var currVal_4 = i1.ɵinlineInterpolate(1, "", _co.id, ""); var currVal_5 = _co.allowedTypes; var currVal_6 = (_co.uploadType === "multiple"); _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_SharedUploadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { fileUploader: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedUploadComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.btnType === "inline-blue"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SharedUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shared-upload", [], null, null, null, View_SharedUploadComponent_0, RenderType_SharedUploadComponent)), i1.ɵdid(1, 245760, null, 0, i4.SharedUploadComponent, [i5.SharedSnackbarService, i3.TranslateService, i6.DocumentService, i7.CompressImageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharedUploadComponentNgFactory = i1.ɵccf("app-shared-upload", i4.SharedUploadComponent, View_SharedUploadComponent_Host_0, { btnType: "btnType", uploadType: "uploadType", id: "id", btnLabel: "btnLabel", allowedTypes: "allowedTypes", hideButton: "hideButton" }, { uploading: "uploading", docUploaded: "docUploaded" }, []);
export { SharedUploadComponentNgFactory as SharedUploadComponentNgFactory };
