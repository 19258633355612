/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-booking-setup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../g0-guest-menu-desktop/guest-menu-desktop.component.ngfactory";
import * as i3 from "../g0-guest-menu-desktop/guest-menu-desktop.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../core/services/utils.service";
import * as i6 from "@angular/router";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../core/services/language.service";
import * as i9 from "../../../core/http/api.service";
import * as i10 from "../../../core/services/LoaderService";
import * as i11 from "../../../core/services/guest.service";
import * as i12 from "../../../core/services/V3_UpSellOnboarding/GuestUpSellOnBoardingEndpoint";
import * as i13 from "../g0-guest-menu-mobile/guest-menu-mobile.component.ngfactory";
import * as i14 from "../g0-guest-menu-mobile/guest-menu-mobile.component";
import * as i15 from "../../../core/services/internal.property.service";
import * as i16 from "../y3-pre-checkin/y3-pre-checkin.component.ngfactory";
import * as i17 from "../y3-pre-checkin/y3-pre-checkin.component";
import * as i18 from "../../../shared/components/shared-snackbar/services/shared-snackbar.service";
import * as i19 from "@angular/material/dialog";
import * as i20 from "./guest-booking-setup.component";
var styles_GuestBookingSetupComponent = [i0.styles];
var RenderType_GuestBookingSetupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestBookingSetupComponent, data: {} });
export { RenderType_GuestBookingSetupComponent as RenderType_GuestBookingSetupComponent };
function View_GuestBookingSetupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-g0-guest-menu-desktop", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GuestMenuDesktopComponent_0, i2.RenderType_GuestMenuDesktopComponent)), i1.ɵdid(1, 770048, null, 0, i3.GuestMenuDesktopComponent, [i4.DOCUMENT, i5.UtilsService, i6.ActivatedRoute, i7.TranslateService, i8.LanguageManagementService, i6.Router, i9.ApiService, i10.LoaderService, i11.GuestService, i6.ActivatedRoute, i7.TranslateService, i12.GuestUpSellOnBoardingEndpoint], { targetsElement: [0, "targetsElement"], property: [1, "property"], corporate: [2, "corporate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵEMPTY_ARRAY; var currVal_1 = _co.property; var currVal_2 = _co.corporate; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_GuestBookingSetupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-g0-guest-menu-mobile", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_GuestMenuMobileComponent_0, i13.RenderType_GuestMenuMobileComponent)), i1.ɵdid(1, 770048, null, 0, i14.GuestMenuMobileComponent, [i6.ActivatedRoute, i11.GuestService, i8.LanguageManagementService, i7.TranslateService, i15.InternalPropertyService, i5.UtilsService, i9.ApiService, i6.Router], { corporate: [0, "corporate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.corporate; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GuestBookingSetupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestBookingSetupComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestBookingSetupComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-y3-pre-checkin", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_Y3PreCheckinComponent_0, i16.RenderType_Y3PreCheckinComponent)), i1.ɵdid(5, 114688, null, 0, i17.Y3PreCheckinComponent, [i5.UtilsService, i6.ActivatedRoute, i8.LanguageManagementService, i11.GuestService, i7.TranslateService, i18.SharedSnackbarService, i10.LoaderService, i4.Location, i19.MatDialog], { nonBlocking: [0, "nonBlocking"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDesktopMode(); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isDesktopMode(); _ck(_v, 3, 0, currVal_1); var currVal_2 = true; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_GuestBookingSetupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-booking-setup", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestBookingSetupComponent_0, RenderType_GuestBookingSetupComponent)), i1.ɵdid(1, 114688, null, 0, i20.GuestBookingSetupComponent, [i10.LoaderService, i11.GuestService, i6.ActivatedRoute, i18.SharedSnackbarService, i7.TranslateService, i5.UtilsService, i8.LanguageManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestBookingSetupComponentNgFactory = i1.ɵccf("app-guest-booking-setup", i20.GuestBookingSetupComponent, View_GuestBookingSetupComponent_Host_0, {}, {}, []);
export { GuestBookingSetupComponentNgFactory as GuestBookingSetupComponentNgFactory };
