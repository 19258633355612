/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./online-check-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/router";
import * as i5 from "@angular/material/icon";
import * as i6 from "../../../shared/components/button/button.component.ngfactory";
import * as i7 from "../../../shared/components/button/button.component";
import * as i8 from "@angular/common";
import * as i9 from "../../../directives/click-outside.directive";
import * as i10 from "./online-check-header.component";
import * as i11 from "../../../core/services/utils.service";
import * as i12 from "../../../core/services/language.service";
import * as i13 from "../../../core/services/LoaderService";
import * as i14 from "@angular/material/dialog";
import * as i15 from "../../../core/services/guest.service";
import * as i16 from "@angular/forms";
import * as i17 from "../../../core/services/pays.service";
import * as i18 from "../../../core/services/DocumentService";
import * as i19 from "../../../core/services/checkin.service";
import * as i20 from "../../../core/services/SnackbarService";
import * as i21 from "../../../core/services/internal.property.service";
import * as i22 from "../../../shared/components/shared-snackbar/services/shared-snackbar.service";
import * as i23 from "@angular/platform-browser";
var styles_OnlineCheckHeaderComponent = [i0.styles];
var RenderType_OnlineCheckHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OnlineCheckHeaderComponent, data: {} });
export { RenderType_OnlineCheckHeaderComponent as RenderType_OnlineCheckHeaderComponent };
function View_OnlineCheckHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["style", "height: 50px; padding-right: 24px;"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.corporate == null) ? null : _co.corporate.logoUrl); _ck(_v, 0, 0, currVal_0); }); }
function View_OnlineCheckHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "online-check-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("online-check.check-in-online")); _ck(_v, 1, 0, currVal_0); }); }
function View_OnlineCheckHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-icon", [["class", "colorCatSkill mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(1, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["chat"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "", (("/guest/" + _co.bookindnewId) + "/messaging"), ""); _ck(_v, 1, 0, currVal_2); _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_OnlineCheckHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "padding-top: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-button", [], null, [[null, "debounceClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("debounceClick" === en)) {
        var pd_1 = ((_co.currentActiveMenu = "messaging") !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i1.ɵdid(2, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 114688, null, 0, i7.ButtonComponent, [], { text: [0, "text"], type: [1, "type"], routerLink: [2, "routerLink"] }, { debounceClick: "debounceClick" }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (("/guest/" + _co.bookindnewId) + "/messaging"), ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("menu-desktop.messaging")); var currVal_2 = "primary"; var currVal_3 = i1.ɵinlineInterpolate(1, "", (("/guest/" + _co.bookindnewId) + "/messaging"), ""); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, null); }
function View_OnlineCheckHeaderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "flex flex-row flex-align-center clickable margin-top-12 margin-bottom-12"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLanguage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Flag"], ["class", "flag margin-right-12"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "roboto Small txt-color-black txt-nowrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.getLanguage(_v.context.$implicit), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getNonTranslatedLanguageName(_v.context.$implicit); _ck(_v, 3, 0, currVal_1); }); }
function View_OnlineCheckHeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "chooser-container grey-scroll"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OnlineCheckHeaderComponent_7)), i1.ɵdid(2, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLanguages(); _ck(_v, 2, 0, currVal_0); }, null); }
function View_OnlineCheckHeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["appClickOutside", ""], ["class", "language-chooser flex flex-row flex-align-center clickable margin-left-16"]], null, [[null, "click"], [null, "clickOutside"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = ((_co.languageChooserOpened = !_co.languageChooserOpened) !== false);
        ad = (pd_1 && ad);
    } if (("clickOutside" === en)) {
        var pd_2 = ((_co.languageChooserOpened = false) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i9.ClickOutsideDirective, [i1.ElementRef], null, { clickOutside: "clickOutside" }), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Flag"], ["class", "flag margin-left-8"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OnlineCheckHeaderComponent_6)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.languageChooserOpened; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.getLanguage(_co.currentLanguage), ""); _ck(_v, 2, 0, currVal_0); }); }
export function View_OnlineCheckHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "online-check-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "online-check-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OnlineCheckHeaderComponent_1)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OnlineCheckHeaderComponent_2)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OnlineCheckHeaderComponent_3)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OnlineCheckHeaderComponent_4)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OnlineCheckHeaderComponent_5)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.corporate == null) ? null : _co.corporate.logoUrl) != null); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.isDesktopMode(); _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.isDesktopMode(); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.isDesktopMode(); _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.mode.edit; _ck(_v, 12, 0, currVal_4); }, null); }
export function View_OnlineCheckHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-online-check-header", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_OnlineCheckHeaderComponent_0, RenderType_OnlineCheckHeaderComponent)), i1.ɵdid(1, 4308992, null, 0, i10.OnlineCheckHeaderComponent, [i11.UtilsService, i12.LanguageManagementService, i13.LoaderService, i12.LanguageManagementService, i4.ActivatedRoute, i14.MatDialog, i15.GuestService, i2.TranslateService, i16.FormBuilder, i17.PaysService, i18.DocumentService, i11.UtilsService, i19.CheckinService, i20.SnackbarService, i21.InternalPropertyService, i22.SharedSnackbarService, i23.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OnlineCheckHeaderComponentNgFactory = i1.ɵccf("app-online-check-header", i10.OnlineCheckHeaderComponent, View_OnlineCheckHeaderComponent_Host_0, { corporate: "corporate", booking: "booking", bookindnewId: "bookindnewId" }, {}, []);
export { OnlineCheckHeaderComponentNgFactory as OnlineCheckHeaderComponentNgFactory };
