<div class="m12-main-container">

  <div class="y3-flex-row y3-flex-justify-content-center y3-margin-bottom-32 ">
    <img *ngIf="corporate.logoUrl" class="m12-logo" [src]="corporate.logoUrl" alt="image" />
  </div>

  <div class="y3-margin-top-24 y3-margin-bottom-24 y3-flex-row y3-flex-align-center y3-flex-justify-content-center">
    <div class="y3-High y3-bold ">{{'m12-password-screen.title' | translate}}</div>
  </div>

  <div style="padding-top: 32px; padding-bottom: 16px; text-align: center" >
    <div class="y3-Medium">{{'m12-password-screen.askforpassword-label' | translate}}</div>
    <input class="y3-margin-top-16 y3-input-text-small y3-full-width" [(ngModel)]="password" type="text" >
  </div>

  <div class="m12-error-msg y3-Medium" *ngIf="errorMsg !== ''">{{errorMsg}}</div>

  <app-button style="margin-top: 32px;" (debounceClick)="setPassword()" [text]="'m12-password-screen.validate'" [type]="'primary'"></app-button>

</div>
