import { EventEmitter } from '@angular/core';
// @ts-ignore
import imageCompression from 'browser-image-compression';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
export class ImageService {
    handleImageUpload(event) {
        const emitter = new EventEmitter();
        const imageFile = event.target.files[0];
        console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };
        imageCompression(imageFile, options)
            .then(compressedFile => {
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            emitter.emit(compressedFile);
            // return this.uploadToServer(compressedFile); // write your own logic
        })
            .catch(error => {
            console.log(error.message);
            emitter.error(error);
        });
        return emitter.asObservable();
    }
    uploadToServer(file) {
        return true;
    }
}
ImageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImageService_Factory() { return new ImageService(); }, token: ImageService, providedIn: "root" });
