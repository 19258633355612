import {Component, HostListener, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../core/http/api.service';
import {Property} from '../../../models/Property';
import {Poi} from '../../../models/Poi';
import {GuestService} from '../../../core/services/guest.service';
import {RecommendationCategory} from '../../../models/guestview/RecommendationCategory';
import {UtilsService} from '../../../core/services/utils.service';
import {Corporate} from '../../../models/guestview/Corporate';
import {SnackbarMessage} from '../../../shared/components/shared-snackbar/models/snackbar-message';
import {SnackbarService} from '../../../core/services/SnackbarService';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-reco-widget',
  templateUrl: './reco-widget.component.html',
  styleUrls: ['./reco-widget.component.scss']
})
export class RecoWidgetComponent implements OnInit {

  constructor(private router: Router,
              public renderer: Renderer2,
              private utilsService: UtilsService,
              private apiService: ApiService,
              private snackbar: SnackbarService,
              private translateService: TranslateService,
              private guestService: GuestService,
              private route: ActivatedRoute) {
  }

  centerOfTheMap: Poi;
  propertiesForMap: Property[] = [];
  property: Property;
  propertyPoi: Poi;
  whereIamPoi: Poi;
  platformName: string = "";
  propertyReference: string = "";
  showZoomControl = false;
  showFilterReco = true;
  showOwner = true;
  showRecoYaago = false;
  showCommunityReco = false;
  showRecoInfluencer = false;
  recapVersion = 'v2';

  // recommendation Search
  language = 'fr';
  categories: any[] = new RecommendationCategory().list;
  selectedCategory: any;
  corporate: Corporate;

  // All map relatives
  @ViewChild('yaagoMap', null) gMapElement: any;
  public map: any;
  public markers = [];
  public lat: number;
  public lng: number;
  currentRecommendation: any = null;

  ngOnInit() {
    console.log(this.router.url);
    this.language = navigator.language;

    this.guestService.getStatusOfCard().subscribe( res => {
      console.log("this.currentRecommendation", res.recommendation);
      this.currentRecommendation = res.recommendation;

    });

    this.route.queryParamMap.subscribe(params => {
      if (params.has('lang')) {
        console.log("langue:", params.get('lang'));
        this.language = params.get('lang');
      }
      if (params.has('corporateId')) {
        this.apiService.getCorporate(params.get('corporateId')).subscribe( res => {
          this.corporate = res;
          console.log("Corporate:", this.corporate);
        });
      }
      if (params.has("autoloc")) {
        if (params.get('autoloc') === 'true') {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
              this.whereIamPoi = new Poi();
              this.whereIamPoi.y = position.coords.latitude;
              this.whereIamPoi.x = position.coords.longitude;
              const centerOfMapPoi = new Poi();
              centerOfMapPoi.y = position.coords.latitude;
              centerOfMapPoi.x = position.coords.longitude;
              this.centerOfTheMap = centerOfMapPoi;

            }, err => {
              if (params.has('lat') && params.has('lng')) {
                this.propertyPoi = new Poi();
                this.propertyPoi.x = Number(params.get('lng'));
                this.propertyPoi.y = Number(params.get('lat'));
                this.centerOfTheMap = this.propertyPoi;
              }
              this.snackbar.push(new SnackbarMessage(this.translateService.instant('guest-view-booking-details.geolocation-error'), 'error'));
            });
          } else {
            if (params.has('lat') && params.has('lng')) {
              this.propertyPoi = new Poi();
              this.propertyPoi.x = Number(params.get('lng'));
              this.propertyPoi.y = Number(params.get('lat'));
              this.centerOfTheMap = this.propertyPoi;
            }
            this.snackbar.push(new SnackbarMessage(this.translateService.instant('guest-view-booking-details.geolocation-error'), 'error'));
          }
        } else {
          if (params.has('lat') && params.has('lng')) {
            this.propertyPoi = new Poi();
            this.propertyPoi.x = Number(params.get('lng'));
            this.propertyPoi.y = Number(params.get('lat'));
            this.centerOfTheMap = this.propertyPoi;
          }
        }
      } else {
        if (params.has('lat') && params.has('lng')) {
          this.propertyPoi = new Poi();
          this.propertyPoi.x = Number(params.get('lng'));
          this.propertyPoi.y = Number(params.get('lat'));
          this.centerOfTheMap = this.propertyPoi;
        }
      }


      if (params.has('reference') && params.has('platformname')) {
        this.platformName = params.get('platformname');
        console.log("platformName:", params.get('platformname'));

        this.propertyReference = params.get('reference');
        console.log("reference:", params.get('reference'));
        this.getTheProperty();
      }
      if (params.has('showZoomControl')) {
        this.showZoomControl = (params.get('showZoomControl') === 'true');
      }
      if (params.has('recap')) {
        this.recapVersion = params.get('recap');
      }
      if (params.has('showFilterReco')) {
        this.showFilterReco = (params.get('showFilterReco') === 'true');
      }
      if (params.has('showOwner')) {
        this.showOwner = (params.get('showOwner') === 'true');
      }
      if (params.has('showRecoYaago')) {
        this.showRecoYaago = (params.get('showRecoYaago') === 'true');
      }
      if (params.has('showCommunityReco')) {
        this.showCommunityReco = (params.get('showCommunityReco') === 'true');
      }
      if (params.has('showRecoInfluencer')) {
        this.showRecoInfluencer = (params.get('showRecoInfluencer') === 'true');
      }
    });

  }

  getTheProperty() {
    console.log('get the property');
    this.apiService.getPropertyByPlatformAndReference(this.platformName, this.propertyReference).subscribe( res => {
      this.property = res;
      this.centerOfTheMap = this.property.poi;
      this.propertiesForMap.push(this.property);
      console.log(this.centerOfTheMap);
      console.log("found property for the platform", this.property);
    });
  }

  @HostListener('window:resize', ['$event'])
  isDesktopMode(event?) {
    return this.utilsService.isDesktop(window.innerWidth);
  }

}
