import {Component, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BookingForGuest, CheckNote} from '../../../models/guestview/BookingForGuest';
import {MatDialog} from '@angular/material';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../core/http/api.service';
import {takeUntil} from 'rxjs/operators';
import {GuestService} from '../../../core/services/guest.service';
import {LanguageManagementService} from '../../../core/services/language.service';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {SharedSnackbarService} from '../../../shared/components/shared-snackbar/services/shared-snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../../../core/services/utils.service';
import {DocumentUploadTypes, YaagoDocument} from '../../../models/YaagoDocument';
import {DeletePhotoComponent} from './delete-photo/delete-photo.component';
import {SnackbarMessage} from '../../../shared/components/shared-snackbar/models/snackbar-message';
import {LoaderService} from '../../../core/services/LoaderService';
import * as moment from 'moment';


@Component({
  selector: 'app-guest-inventory',
  templateUrl: './guest-inventory.component.html',
  styleUrls: ['./guest-inventory.component.scss']
})
export class GuestInventoryComponent extends AbstractWindow implements OnInit {

  @Output()
  formInventoryCheckIn: FormGroup;
  formInventoryCheckOut: FormGroup;

  bookingId: string;
  inventoryCheckIn: CheckNote = new CheckNote();
  inventoryCheckOut: CheckNote = new CheckNote();

  photosIn = [];
  photosOut = [];
  videosIn = [];
  videosOut = [];
  urlsPhotos = [];
  booking: BookingForGuest;
  showSpinner: boolean = false;
  public config: PerfectScrollbarConfigInterface = {};
  allowedImages = DocumentUploadTypes.images();
  allowedVideos = DocumentUploadTypes.videos();
  message: string;

  constructor(private route: ActivatedRoute,
              private loaderService: LoaderService,
              private snackbar: SharedSnackbarService,
              private translate: TranslateService,
              public utilService: UtilsService,
              private activatedRoute: ActivatedRoute,
              private api: ApiService,
              private guestService: GuestService,
              private fb: FormBuilder,
              private languageService: LanguageManagementService,
              public dialog: MatDialog) {
    super(utilService);
  }

  ngOnInit() {


    this.activatedRoute.data.subscribe((res) => {
      if (res && res.propertyAndBooking) {
        this.booking = res.propertyAndBooking.booking;
        console.log('booking', this.booking);
      }
    });
    this.formInventoryCheckIn = this.fb.group({
      comment: ['', [Validators.required]]
    });
    this.formInventoryCheckOut = this.fb.group({
      comment: ['', [Validators.required]]
    });


    this.route.paramMap.subscribe(ParamMap => {
      this.bookingId = ParamMap.get('bookingId');
      const lang: string = this.languageService.getLanguageInUrl();
      this.guestService.getPropertyAndBookingForGuest(this.bookingId, lang).pipe(takeUntil(this.onDestroy)).subscribe(res => {
        if (res.booking.guestInventoryCheckOut != null) {
          this.inventoryCheckOut = res.booking.guestInventoryCheckOut;
          if (this.inventoryCheckOut.pictures != null) {
            this.photosOut = this.inventoryCheckOut.pictures;
          } else {
            this.photosOut = [];
          }
          this.videosOut = this.inventoryCheckOut.videos;
        }
        if (res.booking.guestInventoryCheckIn != null) {
          this.inventoryCheckIn = res.booking.guestInventoryCheckIn;
          if (this.inventoryCheckIn.pictures != null) {
            this.photosIn = this.inventoryCheckIn.pictures;
          } else {
            this.photosIn = [];
          }

          this.videosIn = this.inventoryCheckIn.videos;
        }
        if (res.booking) {
          this.booking = res.booking;
        }
        console.log('photoIn', this.photosIn);
      });

    });

  }

  onPictureOutUploaded(doc: YaagoDocument) {
    if (!this.photosOut) { this.photosOut = []; }
    this.photosOut.push(doc.url);
  }

  onPictureInUploaded(doc: YaagoDocument) {
    if (!this.photosIn) { this.photosIn = []; }
    this.photosIn.push(doc.url);
  }

  onVideoInUploaded(doc: YaagoDocument) {
    if (!this.videosIn) { this.videosIn = []; }
    this.videosIn.push(doc.url);
  }

  onVideoOutUploaded(doc: YaagoDocument) {
    if (!this.videosOut) { this.videosOut = []; }
    this.videosOut.push(doc.url);
  }

  validate(type: string) {
    this.loaderService.showFullLoader('');
    if (type === 'checkin') {
      this.inventoryCheckIn.pictures = this.photosIn;
      this.inventoryCheckIn.videos = this.videosIn;
      this.api.guestInventoryCheck(type, this.inventoryCheckIn, this.bookingId).subscribe(p => {
        this.loaderService.dismissLoader();
        this.snackbar.push(new SnackbarMessage(this.translate.instant('guest-inventory.success'), 'info'));
      }, err => {
        this.loaderService.dismissLoader();
        this.snackbar.push(new SnackbarMessage(this.translate.instant('guest-inventory.error'), 'error'));
      });
    } else {
      this.inventoryCheckOut.pictures = this.photosOut;
      this.inventoryCheckOut.videos = this.videosOut;
      this.api.guestInventoryCheck(type, this.inventoryCheckOut, this.bookingId).subscribe(p => {
        this.loaderService.dismissLoader();
        this.snackbar.push(new SnackbarMessage(this.translate.instant('guest-inventory.success'), 'info'));
      }, err => {
        this.loaderService.dismissLoader();
        this.snackbar.push(new SnackbarMessage(this.translate.instant('guest-inventory.error'), 'error'));
      });
    }
  }

  showDialog(index, type: string) {
    const dialogRef = this.dialog.open(DeletePhotoComponent, {
      data: {index: index, photos: (type === 'in') ? this.photosIn : this.photosOut, urlsPhotos: this.urlsPhotos},
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        this.urlsPhotos = result.urlsPhotos;
        console.log('urlsPhotos', this.urlsPhotos);

      }
    });
  }


  deleteVideoIn(i: number) {
    this.videosIn.splice(i, 1);
  }

  deleteVideoOut(i: number) {
    this.videosOut.splice(i, 1);
  }


}
