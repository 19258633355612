/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./m101-inventory-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "../../../../shared/components/shared-upload/shared-upload.component.ngfactory";
import * as i6 from "../../../../shared/components/shared-upload/shared-upload.component";
import * as i7 from "../../../../shared/components/shared-snackbar/services/shared-snackbar.service";
import * as i8 from "../../../../core/services/DocumentService";
import * as i9 from "../../../../core/services/compress-image.service";
import * as i10 from "../../../../shared/components/button/button.component.ngfactory";
import * as i11 from "../../../../shared/components/button/button.component";
import * as i12 from "./m101-inventory-item.component";
import * as i13 from "@angular/router";
import * as i14 from "../../../../core/services/utils.service";
import * as i15 from "../../../../core/services/guest.service";
import * as i16 from "../../../../core/services/LoaderService";
import * as i17 from "../../../../core/http/api.service";
var styles_M101InventoryItemComponent = [i0.styles];
var RenderType_M101InventoryItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_M101InventoryItemComponent, data: {} });
export { RenderType_M101InventoryItemComponent as RenderType_M101InventoryItemComponent };
function View_M101InventoryItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "y3-X-small "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "y3-margin-bottom-8 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "y3-cursor-pointer y3-margin-left-8"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deletePicture(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.utilService.getDocumentName(_v.context.$implicit); _ck(_v, 3, 0, currVal_1); var currVal_2 = "assets/icon/inventory/delete.svg"; _ck(_v, 4, 0, currVal_2); }); }
function View_M101InventoryItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "y3-X-small "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "y3-margin-bottom-8 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "y3-cursor-pointer y3-margin-left-8"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteVideo(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.utilService.getDocumentName(_v.context.$implicit); _ck(_v, 3, 0, currVal_1); var currVal_2 = "assets/icon/inventory/delete.svg"; _ck(_v, 4, 0, currVal_2); }); }
function View_M101InventoryItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 31, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "y3-Small y3-margin-top-16 y3-margin-bottom-8"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 5, "textarea", [["class", "y3-input-text-small"], ["style", "width: 100%"]], [[8, "rows", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.inventory.comment = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(7, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(9, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "y3-Medium y3-margin-top-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "y3-flex-column y3-margin-top-16 y3-X-small"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_M101InventoryItemComponent_2)), i1.ɵdid(15, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "g11-upload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "app-shared-upload", [], null, [[null, "docUploaded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("docUploaded" === en)) {
        var pd_0 = (_co.onPicturesUpload($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_SharedUploadComponent_0, i5.RenderType_SharedUploadComponent)), i1.ɵdid(18, 245760, null, 0, i6.SharedUploadComponent, [i7.SharedSnackbarService, i2.TranslateService, i8.DocumentService, i9.CompressImageService], { uploadType: [0, "uploadType"], btnLabel: [1, "btnLabel"], allowedTypes: [2, "allowedTypes"] }, { docUploaded: "docUploaded" }), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "y3-Medium y3-margin-top-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(20, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(22, 0, null, null, 2, "div", [["class", "y3-flex-column y3-margin-top-16 y3-X-small"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_M101InventoryItemComponent_3)), i1.ɵdid(24, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 2, "div", [["class", "g11-upload "]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "app-shared-upload", [], null, [[null, "docUploaded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("docUploaded" === en)) {
        var pd_0 = (_co.onVideosUpload($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_SharedUploadComponent_0, i5.RenderType_SharedUploadComponent)), i1.ɵdid(27, 245760, null, 0, i6.SharedUploadComponent, [i7.SharedSnackbarService, i2.TranslateService, i8.DocumentService, i9.CompressImageService], { uploadType: [0, "uploadType"], btnLabel: [1, "btnLabel"], allowedTypes: [2, "allowedTypes"] }, { docUploaded: "docUploaded" }), (_l()(), i1.ɵeld(28, 0, null, null, 3, "div", [["class", " y3-margin-top-16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 2, "app-button", [], null, [[null, "debounceClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("debounceClick" === en)) {
        var pd_0 = (_co.validate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ButtonComponent_0, i10.RenderType_ButtonComponent)), i1.ɵdid(30, 114688, null, 0, i11.ButtonComponent, [], { text: [0, "text"], type: [1, "type"] }, { debounceClick: "debounceClick" }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.inventory.comment; _ck(_v, 7, 0, currVal_9); var currVal_11 = _co.inventory.pictures; _ck(_v, 15, 0, currVal_11); var currVal_12 = "multiple"; var currVal_13 = "guest-inventory.upload"; var currVal_14 = _co.utilService.allowedImagesDocs; _ck(_v, 18, 0, currVal_12, currVal_13, currVal_14); var currVal_16 = _co.inventory.videos; _ck(_v, 24, 0, currVal_16); var currVal_17 = "multiple"; var currVal_18 = "guest-inventory.upload"; var currVal_19 = _co.utilService.allowVideosDocs; _ck(_v, 27, 0, currVal_17, currVal_18, currVal_19); var currVal_20 = i1.ɵunv(_v, 30, 0, i1.ɵnov(_v, 31).transform("guest-inventory.validate")); var currVal_21 = "primary"; _ck(_v, 30, 0, currVal_20, currVal_21); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("guest-inventory.add-comment-description")); _ck(_v, 2, 0, currVal_0); var currVal_1 = 10; var currVal_2 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 9).ngClassValid; var currVal_7 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("guest-inventory.share-pictures")); _ck(_v, 11, 0, currVal_10); var currVal_15 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("guest-inventory.share-videos")); _ck(_v, 20, 0, currVal_15); }); }
export function View_M101InventoryItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_M101InventoryItemComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inventory != null); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_M101InventoryItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-m101-inventory-item", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_M101InventoryItemComponent_0, RenderType_M101InventoryItemComponent)), i1.ɵdid(1, 114688, null, 0, i12.M101InventoryItemComponent, [i13.ActivatedRoute, i14.UtilsService, i2.TranslateService, i15.GuestService, i16.LoaderService, i17.ApiService, i8.DocumentService, i7.SharedSnackbarService, i4.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var M101InventoryItemComponentNgFactory = i1.ɵccf("app-m101-inventory-item", i12.M101InventoryItemComponent, View_M101InventoryItemComponent_Host_0, { inventory: "inventory", type: "type" }, {}, []);
export { M101InventoryItemComponentNgFactory as M101InventoryItemComponentNgFactory };
