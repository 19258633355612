import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class SharedSnackbarService {
    constructor() {
        this._message = new Subject();
        this.newMessageEvent$ = this._message.asObservable();
        this.dismissTimeout = 3000;
    }
    setDismissTimeout(timeout) {
        this.dismissTimeout = timeout;
    }
    push(message) {
        this._message.next(message);
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if (message && (message.type === 'info' || message.type === 'warning')) {
            this.timeout = setTimeout(() => {
                this.dismiss();
            }, this.dismissTimeout);
        }
    }
    dismiss() {
        this.push(undefined);
    }
}
SharedSnackbarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedSnackbarService_Factory() { return new SharedSnackbarService(); }, token: SharedSnackbarService, providedIn: "root" });
