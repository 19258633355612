<!-- DESKTOP VERSION OF THE WELCOME BAR -->
<div *ngIf="booking && isDesktopMode()" class="desktop-journey-summary">
  <div class="welcome-container">
    <p> {{'online-check.welcome' | translate}}</p>
    <p class="margin-bottom-16">{{booking?.guests[0].firstName}}</p>

  </div>
  <div *ngIf="!property?.host?.btobInfo" class="nights-container">
    <p class="Small">{{'guest-top-page.nights-desktop' | translate}}</p>
    <p class="number">{{booking?.nights}}</p>
  </div>
  <div *ngIf="booking?.startDate !== booking?.endDate" class="date-cards">
    <div class="date-card margin-right-16">
      <img src="assets/images/illustrations/men-fly.svg" alt="start">
      <div class="date-container">
        <p class="title">{{'guest-top-page.start' | translate}}</p>
        <p class="date">{{booking?.startDate | moment: translateService.currentLang | titlecase}}</p>
      </div>
    </div>
    <div class="date-card">
      <img src="assets/images/illustrations/men-luaggage.svg" alt="departure">
      <div class="date-container">
        <p class="title">{{'guest-top-page.end' | translate}}</p>
        <p class="date">{{booking?.endDate | moment: translateService.currentLang | titlecase}}</p>

      </div>
    </div>
  </div>

  <div *ngIf="booking?.startDate === booking?.endDate" class="date-cards">
    <div class="date-card margin-right-16">
      <img src="assets/images/illustrations/men-fly.svg" alt="start">
      <div class="date-container">
        <p class="title">{{'guest-top-page.your-resa' | translate}}</p>
        <p class="date">{{booking?.startDate | titlecase}}</p>
      </div>
    </div>

  </div>
</div>

