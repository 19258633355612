/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./images-carousel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-owl-carousel-o";
import * as i3 from "../../../../../../node_modules/ngx-owl-carousel-o/ngx-owl-carousel-o.ngfactory";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./images-carousel.component";
import * as i7 from "@angular/material/dialog";
var styles_ImagesCarouselComponent = [i0.styles];
var RenderType_ImagesCarouselComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImagesCarouselComponent, data: {} });
export { RenderType_ImagesCarouselComponent as RenderType_ImagesCarouselComponent };
function View_ImagesCarouselComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "img-header-page"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showImgDialog(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
function View_ImagesCarouselComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 1, null, View_ImagesCarouselComponent_2)), i1.ɵdid(1, 16384, [[1, 4]], 0, i2.CarouselSlideDirective, [i1.TemplateRef], null, null)], null, null); }
export function View_ImagesCarouselComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "owl-carousel-o", [], null, [["document", "visibilitychange"]], function (_v, en, $event) { var ad = true; if (("document:visibilitychange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onVisibilityChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CarouselComponent_0, i3.RenderType_CarouselComponent)), i1.ɵprd(512, null, i2.ɵb, i2.ɵb, [i2.ɵc]), i1.ɵprd(131584, null, i2.ɵa, i2.ɵa, [i2.ɵb]), i1.ɵprd(131584, null, i2.ɵd, i2.ɵd, [i2.ɵb, i2.ɵe, i2.ɵl]), i1.ɵprd(131584, null, i2.ɵs, i2.ɵs, [i2.ɵb]), i1.ɵprd(131584, null, i2.ɵt, i2.ɵt, [i2.ɵb]), i1.ɵprd(131584, null, i2.ɵu, i2.ɵu, [i2.ɵb]), i1.ɵprd(131584, null, i2.ɵv, i2.ɵv, [i2.ɵb, [2, i4.ActivatedRoute], [2, i4.Router]]), i1.ɵdid(8, 3391488, null, 1, i2.CarouselComponent, [i1.ElementRef, i2.ɵw, i2.ɵb, i2.ɵa, i2.ɵd, i2.ɵs, i2.ɵt, i2.ɵu, i2.ɵv, i2.ɵc, i1.ChangeDetectorRef, i2.ɵl], { options: [0, "options"] }, null), i1.ɵqud(603979776, 1, { slides: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImagesCarouselComponent_1)), i1.ɵdid(11, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customOptions; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.pictureUrls; _ck(_v, 11, 0, currVal_1); }, null); }
export function View_ImagesCarouselComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-images-carousel", [], null, null, null, View_ImagesCarouselComponent_0, RenderType_ImagesCarouselComponent)), i1.ɵdid(1, 114688, null, 0, i6.ImagesCarouselComponent, [i7.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImagesCarouselComponentNgFactory = i1.ɵccf("app-images-carousel", i6.ImagesCarouselComponent, View_ImagesCarouselComponent_Host_0, { pictureUrls: "pictureUrls", photos: "photos" }, {}, []);
export { ImagesCarouselComponentNgFactory as ImagesCarouselComponentNgFactory };
