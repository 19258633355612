import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class InternalPropertyService {
    constructor() {
        this.localProperty = null;
        this.currentCorporate = null;
        this._currentProperty = new BehaviorSubject(undefined);
        this.currentProperty$ = this._currentProperty.asObservable();
        this._currentPhotoIndex = 0;
    }
    setCurrentCorporate(corporate) {
        this.currentCorporate = corporate;
    }
    getCurrentCorporate() {
        return this.currentCorporate;
    }
    getCurrentProperty() {
        return this.currentProperty$;
    }
    setCurrentProperty(property) {
        this._currentProperty.next(property);
        this.localProperty = property;
    }
    getMainPhotoUrl() {
        let ret = null;
        let index = -1;
        this.currentProperty$.subscribe(p => {
            if (p.photos) {
                p.photos.forEach(photo => {
                    index++;
                    if (photo.main) {
                        ret = photo;
                        this._currentPhotoIndex = index;
                        photo.url = photo.url.replace('aki_policy=medium', '');
                        return ret;
                    }
                });
                if (ret == null && p.photos.length > 0) {
                    ret = p.photos[0];
                    this._currentPhotoIndex = 0;
                    ret.url = ret.url.replace('aki_policy=medium', '');
                    return ret;
                }
            }
        });
        return ret;
    }
    getNextPhoto() {
        let photo = null;
        this.currentProperty$.subscribe(p => {
            if (this._currentPhotoIndex === p.photos.length - 1) {
                this._currentPhotoIndex = -1;
            }
            photo = p.photos[this._currentPhotoIndex + 1];
            this._currentPhotoIndex = this._currentPhotoIndex + 1;
            photo.url = photo.url.replace('aki_policy=medium', '');
        });
        return photo;
    }
    getPreviousPhoto() {
        let photo = null;
        this.currentProperty$.subscribe(p => {
            if (this._currentPhotoIndex === 0) {
                this._currentPhotoIndex = p.photos.length;
            }
            photo = p.photos[this._currentPhotoIndex - 1];
            this._currentPhotoIndex = this._currentPhotoIndex - 1;
            photo.url = photo.url.replace('aki_policy=medium', '');
        });
        return photo;
    }
}
InternalPropertyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InternalPropertyService_Factory() { return new InternalPropertyService(); }, token: InternalPropertyService, providedIn: "root" });
