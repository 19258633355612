<div class="m10-main-container">

  <div class="m10-close-button">
    <img src="assets/icon/close-round.svg" alt="close" (click)="goBack()" />
  </div>

  <div class="y3-flex-row y3-flex-justify-content-center y3-margin-bottom-32 ">
    <img *ngIf="corporate.logoUrl" class="m10-logo" [src]="corporate.logoUrl" alt="image" />
  </div>

  <div *ngIf="!departure && !arrival">
    <div *ngIf="utilsService.isCheckIndDate(booking)" class="y3-margin-top-8"  (click)="goArrival()">
      <div class="m0-new-button">
        <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">
          <div class="y3-flex-row">
            <mat-icon class="m0-icon-1">photo_camera</mat-icon>
            <div class="y3-flex-column y3-padding-left-24">
              <div class="y3-X-small y3-color-scorpion">Etat des lieux d'entrée</div>
              <div *ngIf="booking.guestInventoryCheckIn == null " class="y3-Medium y3-bold y3-margin-top-3 ">Commencer</div>
              <div *ngIf="booking.guestInventoryCheckIn != null" class="y3-Medium y3-bold y3-margin-top-3 ">Editer</div>
            </div>
          </div>
          <mat-icon *ngIf="booking.guestInventoryCheckIn == null " class="m0-button-quick-2">chevron_right</mat-icon>
          <mat-icon *ngIf="booking.guestInventoryCheckIn != null" class="m0-button-quick-done">done</mat-icon>

        </div>
      </div>
    </div>

    <div class="y3-margin-top-8" *ngIf="utilsService.isCheckOutDate(booking)" (click)="goDeparture()">
      <div class="m0-new-button">
        <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">
          <div class="y3-flex-row">
            <mat-icon class="m0-icon-1">photo_camera</mat-icon>
            <div class="y3-flex-column y3-padding-left-24">
              <div class="y3-X-small y3-color-scorpion">Etat des lieux de sortie</div>
              <div *ngIf="booking.guestInventoryCheckOut == null" class="y3-Medium y3-bold y3-margin-top-3 ">Commencer</div>
              <div *ngIf="booking.guestInventoryCheckOut != null" class="y3-Medium y3-bold y3-margin-top-3 ">Editer</div>
            </div>
          </div>
          <mat-icon *ngIf="booking.guestInventoryCheckOut == null " class="m0-button-quick-2">chevron_right</mat-icon>
          <mat-icon *ngIf="booking.guestInventoryCheckOut?.checkAt != null" class="m0-button-quick-done">done</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="arrival">
    <div class="y3-margin-top-24 y3-margin-bottom-24">
      <div class="y3-High y3-bold y3-title-colors">{{'guest-inventory.title-in' | translate}}</div>
      <app-m101-inventory-item [inventory]="booking?.guestInventoryCheckIn" [type]="'checkin'"></app-m101-inventory-item>
    </div>
  </div>

  <div *ngIf="departure">
    <div class="y3-margin-top-24 y3-margin-bottom-24">
      <div class="y3-High y3-bold y3-title-colors">{{'guest-inventory.title-out' | translate}}</div>
      <app-m101-inventory-item [inventory]="booking?.guestInventoryCheckOut" [type]="'checkout'"></app-m101-inventory-item>
    </div>
    <div>

    </div>
  </div>




</div>
