import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../conf.service";
export class GenericLinkEndpoint {
    constructor(http, confService) {
        this.http = http;
        this.confService = confService;
    }
    createPendingBooking(genericLinkBookingCreation) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/guest/generic-link/create-booking', genericLinkBookingCreation);
    }
}
GenericLinkEndpoint.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GenericLinkEndpoint_Factory() { return new GenericLinkEndpoint(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfService)); }, token: GenericLinkEndpoint, providedIn: "root" });
