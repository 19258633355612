<div *ngIf="showFull" class="loader-container" (click)="closeLoader()">
  <div class="loader-content">
    <div style="position: absolute; top: 6px; right: 8px;">
  <a><img (click)="closeLoader() "src="assets/icon/close-round.svg" /></a>
  </div>
    <h4 *ngIf="!mainText" class="loader-title">{{'loader.loading' | translate }}</h4>
    <h4 *ngIf="mainText" class="loader-title">{{mainText | translate}}</h4>
    <div class="text-large margin-top-16">{{'loader.please_wait' | translate}}</div>
    <!--<ng-lottie [options]="options" style="margin-left: 40px"></ng-lottie>-->
    <div class="animation-container">
      <img *ngIf="corporate?.logoUrl" [src]="corporate.logoUrl" alt="logo">
      <img style="max-width: 50%" *ngIf="!corporate?.logoUrl" src="/assets/img/favicon_updated.png" alt="logo">
      <div class="dot1"></div>
      <div class="dot2"></div>
      <div class="dot3"></div>
    </div>
  </div>
</div>
