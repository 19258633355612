<div class="generic-container">
  <div class="y3-flex-column generic-content">

    <div class="y3-flex-row y3-flex-justify-content-center y3-padding-bottom-16" *ngIf="corporate?.logoUrl">
      <img  style="max-width: 50%; max-height: 30vh" [src]="corporate.logoUrl" >
    </div>

    <div class="y3-XLarge" style="text-align: center; font-weight: bold">{{'generic-link-confirm.title' | translate}}</div>
    <div class="y3-Small y3-margin-top-16" style="text-align: center;">{{'generic-link-confirm.description' | translate}}</div>
    <div class="y3-Small y3-margin-bottom-16" style="text-align: center;">{{'generic-link-confirm.description2' | translate}}</div>

  </div>
</div>
