
<div class="footer clearfix">
	<div class="images_footer">
		<div><img alt="" src="../../../../../assets/images/footers/halo-conciergerie/halo-crabe.png"></div>
		<div><img alt="Logo Halo Conciergerie" src="../../../../../assets/images/footers/halo-conciergerie/footer_logo_halo.png"></div>
		<div><img alt="" src="../../../../../assets/images/footers/halo-conciergerie/halo-poisson.png"></div>
	</div>
	<p class="colophon">
		 2020 - 2022  – Halo Conciergerie en Vendée
		<br>
		<a rel="nofollow" target="_blank" href="https://www.haloconcierge.fr/contact.html">Contact</a> – <a rel="nofollow" target="_blank" href="https://www.haloconcierge.fr/mentions-legales-credits.html">Mentions légales</a>
	</p>
</div>