<div class="m11-main-container">
  <div class="m11-close-button">
    <img src="assets/icon/close-round.svg" alt="close" (click)="goBack()" />
  </div>


  <div class="y3-margin-top-24 y3-margin-bottom-24">
    <div class="y3-High y3-bold y3-title-colors">{{'guest-platefromservices-page.title' | translate}}</div>
  </div>

  <div class="y3-flex-responsive">
    <div class="y3-service-card" *ngFor="let p of platformServices; let i = index">
      <div class="y3-service-image-container">
        <img [id]="'platformLogo' + i" class="y3-service-image" [src]="p.logoUrl" alt="partner-img"/>
      </div>
      <div class="y3-card-description">
        <div class="y3-service-title">
          {{p.name}}
        </div>
        <div class="y3-service-description">
          {{p.translations[0].description}}
        </div>

        <div class="partner-link y3-margin-top-8">
          <a [id]="'platformLink' + i" class="alink-no-decoration " target="_blank" [href]="p.translations[0].webSiteUrl">{{'guest-platefromservices-page.more-btn'  | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>



</div>
