/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-guide-photo-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../g0-guest-menu-desktop/guest-menu-desktop.component.ngfactory";
import * as i3 from "../g0-guest-menu-desktop/guest-menu-desktop.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../core/services/utils.service";
import * as i6 from "@angular/router";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../core/services/language.service";
import * as i9 from "../../../core/http/api.service";
import * as i10 from "../../../core/services/LoaderService";
import * as i11 from "../../../core/services/guest.service";
import * as i12 from "../../../core/services/V3_UpSellOnboarding/GuestUpSellOnBoardingEndpoint";
import * as i13 from "../../../shared/components/guest-gallery-detail/guest-gallery-detail.component.ngfactory";
import * as i14 from "../../../shared/components/guest-gallery-detail/guest-gallery-detail.component";
import * as i15 from "../guest.service";
import * as i16 from "@angular/material/dialog";
import * as i17 from "./guest-guide-photo-detail.component";
var styles_GuestGuidePhotoDetailComponent = [i0.styles];
var RenderType_GuestGuidePhotoDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestGuidePhotoDetailComponent, data: {} });
export { RenderType_GuestGuidePhotoDetailComponent as RenderType_GuestGuidePhotoDetailComponent };
export function View_GuestGuidePhotoDetailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-g0-guest-menu-desktop", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GuestMenuDesktopComponent_0, i2.RenderType_GuestMenuDesktopComponent)), i1.ɵdid(1, 770048, null, 0, i3.GuestMenuDesktopComponent, [i4.DOCUMENT, i5.UtilsService, i6.ActivatedRoute, i7.TranslateService, i8.LanguageManagementService, i6.Router, i9.ApiService, i10.LoaderService, i11.GuestService, i6.ActivatedRoute, i7.TranslateService, i12.GuestUpSellOnBoardingEndpoint], { targetsElement: [0, "targetsElement"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-guest-gallery-detail", [], null, null, null, i13.View_GuestGalleryDetailComponent_0, i13.RenderType_GuestGalleryDetailComponent)), i1.ɵdid(4, 114688, null, 0, i14.GuestGalleryDetailComponent, [i6.ActivatedRoute, i6.Router, i15.GuestServiceIntern, i16.MAT_DIALOG_DATA, i16.MatDialogRef], { photos: [0, "photos"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, null); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.photos; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_GuestGuidePhotoDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-guide-photo-detail", [], null, null, null, View_GuestGuidePhotoDetailComponent_0, RenderType_GuestGuidePhotoDetailComponent)), i1.ɵdid(1, 114688, null, 0, i17.GuestGuidePhotoDetailComponent, [i15.GuestServiceIntern], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestGuidePhotoDetailComponentNgFactory = i1.ɵccf("app-guest-guide-photo-detail", i17.GuestGuidePhotoDetailComponent, View_GuestGuidePhotoDetailComponent_Host_0, {}, {}, []);
export { GuestGuidePhotoDetailComponentNgFactory as GuestGuidePhotoDetailComponentNgFactory };
