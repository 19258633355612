<div class="generic-container">
  <div class="y3-flex-column generic-content">

    <div class="y3-flex-row y3-flex-justify-content-center y3-padding-bottom-16" *ngIf="corporate?.logoUrl">
      <img  style="max-width: 50%; max-height: 30vh" [src]="corporate.logoUrl" >
    </div>

    <div class="y3-XLarge" style="text-align: center; font-weight: bold">{{'generic-link.title' | translate}}</div>
    <div class="y3-XLarge y3-margin-top-16" style="text-align: center; font-weight: bold">{{propertyTitle}}</div>
    <div class="y3-Small y3-margin-top-16" style="text-align: center;">{{'generic-link.description' | translate}}</div>

    <div class="y3-flex-responsive y3-margin-top-16" >
      <div class="y3-flex-column y3-X-small g11-input-margin">
        <div>{{'generic-link.platform' | translate}}</div>

        <div class="y3-margin-top-4 input-size">
          <ng-multiselect-dropdown class="" [placeholder]="'generic-link.choose' | translate"
                                   [settings]="dropdownSettingsNoFilter"
                                   [data]="stayTypes"
                                   [(ngModel)]="platform"
                                   (onSelect)="selectType($event)"
                                   (onDeSelect)="unSelectType($event)"
          >
          </ng-multiselect-dropdown>
        </div>

      </div>
    </div>


    <div class="y3-flex-row y3-flex-justify-space-between">
      <div class="y3-X-small y3-margin-top-8 y3-flex-column y3-margin-right-8">
        {{'generic-link.startdate' | translate}}
        <div class="y3-margin-top-4">
          <input class="input-date" type="date" [(ngModel)]="genericLinkBookingCreation.startDate" >
        </div>
      </div>

      <div class="y3-X-small y3-margin-top-8 y3-flex-column">
        {{'generic-link.enddate' | translate}}
        <div class="y3-margin-top-4">
          <input type="date" class="input-date" [(ngModel)]="genericLinkBookingCreation.endDate">
        </div>
      </div>
    </div>


    <div class="y3-flex-responsive y3-flex-justify-space-between">
      <div class="y3-X-small y3-margin-top-8 y3-flex-column">
        {{'generic-link.firstname' | translate}}
        <div class="y3-margin-top-4">
          <input type="text" [(ngModel)]="genericLinkBookingCreation.guestFirstname">
        </div>
      </div>

      <div class="y3-X-small y3-margin-top-8 y3-flex-column">
        {{'generic-link.lastname' | translate}}
        <div class="y3-margin-top-4">
          <input type="text" [(ngModel)]="genericLinkBookingCreation.guestLastname">
        </div>
      </div>
    </div>

    <div class="y3-flex-responsive y3-margin-top-8 y3-flex-justify-space-between">
      <div class="y3-X-small  y3-flex-column">
        {{'generic-link.email' | translate}}
        <div class="y3-margin-top-4">
          <input type="email" [(ngModel)]="genericLinkBookingCreation.guestEmail">
        </div>
      </div>

      <div class="y3-X-small y3-flex-column">
        {{'generic-link.phone' | translate}}
        <div class="y3-margin-top-4">
          <input type="tel" id="phone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required [(ngModel)]="genericLinkBookingCreation.guestPhone">
        </div>
      </div>
    </div>



  <div class="y3-margin-top-24">
    <app-button style="width: 100%" [type]="'steph-button-blue'" [text]="'Confirmation'" (debounceClick)="submitIdentification()"></app-button>
  </div>

  </div>
</div>
