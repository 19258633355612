<div class="m26-main-container">
  <div class="m26-close-button">
    <img src="assets/icon/close-round.svg" alt="close" (click)="goBack()" />
  </div>

  <div class="form-container">
    <h4 class="y3-title-colors" >{{'review-page.title' | translate}}</h4>

    <div class="content-container">

      <div class="y3-flex-column y3-flex-justify-content-center y3-flex-align-center y3-text-ellipsis y3-margin-top-16 y3-margin-bottom-8 y3-full-width">
        <div class="y3-Large y3-bold y3-title-colors" style="text-align: center">{{property.bookletTitle? property.bookletTitle : property.title}}</div>
        <div class="y3-X-small y3-margin-top-4 y3-title-colors" style="text-align: center">{{property.fullAddress}}</div>
      </div>

      <ul >
        <li *ngFor="let rating of bookingRating.ratingItems" class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-margin-bottom-8">
          <h4 class="y3-Medium y3-bold" lang>{{'review-page.'+rating.type | translate}}</h4>
          <div class="range-box">
            <app-star-rating class="stars" [(rating)]="rating.rating"></app-star-rating>
            <!--<div class="value">{{rating.rating}}</div>-->
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="noteBlock">
      <h4 class="section-title">{{'review-page.prompt' | translate}}</h4>
      <textarea style="min-height: 150px; padding: 8px; width: 100%"
                placeholder="{{'review-page.prompt-placeholder' | translate}}"
                matInput matTextareaAutosize  [(ngModel)]="bookingRating.ratingComment" ></textarea>
  </div>


  <div class="y3-margin-top-16">
    <app-button [text]="'review-page.save-review-btn' | translate" [type]="'new-primary'" (debounceClick)="saveRating()"></app-button>
  </div>

</div>
