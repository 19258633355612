<div class="y3-container  g10-page-container">



  <div class="y3-main-card g10-card-height" >

    <div class="y3-flex-row y3-flex-justify-content-center y3-padding-bottom-16" *ngIf="corporate?.logoUrl">
      <img  style="height: 60px; padding-right: 24px;" [src]="corporate.logoUrl" >
    </div>

    <div class="y3-flex-row y3-flex-justify-content-center">
      <div class="y3-XLarge y3-roboto y3-margin-bottom-16 y3-bold">{{'g10-pre-checkin.title' | translate}}</div>
    </div>

    <div *ngIf="booking" class="g10-journey-summary ">


      <div class="y3-margin-1024 y3-Large y3-color-black y3-McQueen y3-margin-bottom-8">{{'guest-top-page.welcome' | translate}} {{booking?.guests[0]?.firstName | titlecase}} {{booking?.guests[0]?.lastName | titlecase}}</div>
      <div class="y3-margin-1024 y3-Small y3-bold y3-roboto y3-color-green-vogue">{{property.bookletTitle? property.bookletTitle : property.title}}</div>
      <div class="y3-margin-1024 y3-X-small y3-roboto y3-color-scorpion">{{property.fullAddress}}</div>

      <div class="y3-margin-1024 y3-flex-row y3-roboto y3-margin-top-16" >
        <div class="y3-flex-column y3-roboto g10-check-card" >
          <div class="y3-color-green-vogue y3-bold y3-margin-bottom-8 y3-Small">{{'guest-top-page.start' | translate}}</div>
          <div class="y3-color-scorpion y3-Small">{{booking?.startDate | moment: translateService.currentLang  | titlecase}} </div>
          <div class="y3-color-scorpion y3-Small">{{ utilsService.getTime(booking?.expectedCheckinLocalTime, property?.defaultCheckInTime)}}</div>
        </div>
        <div class="y3-flex-column y3-roboto g10-check-card" >
          <div class="y3-color-green-vogue y3-bold y3-margin-bottom-8 y3-Small">{{'guest-top-page.end' | translate}}</div>
          <div class="y3-color-scorpion y3-Small">{{booking?.endDate | moment: translateService.currentLang  | titlecase}} </div>
          <div class="y3-color-scorpion y3-Small">{{utilsService.getTime(booking?.expectedCheckoutLocalTime, property?.defaultCheckOutTime)}}</div>
        </div>
      </div>
    </div>

    <div class="y3-flex-responsive y3-margin-bottom-8 y3-margin-top-16" >
      <div class="y3-flex-column y3-X-small g10-input-margin" >
        <div class="y3-flex-column y3-X-small g10-input-time" >
          <div>{{'g10-pre-checkin.check-in-time' | translate}}</div>
          <input class="y3-margin-top-4 y3-input-text-small " type="time" autocomplete="none" [(ngModel)]="preCheckInBooking.expectedCheckinLocalTime">
        </div>
        <div class="y3-X-small y3-color-scorpion y3-margin-top-8" *ngIf="property?.defaultCheckInTime">{{'guest-setup-page.explain-check-in' | translate}} <span >({{property.defaultCheckInTime}})</span></div>
      </div>
      <div class="y3-flex-column y3-X-small g10-input-margin g10-second-input" >
        <div class="y3-flex-column y3-X-small g10-input-time" >
          <div>{{'g10-pre-checkin.check-out-time' | translate}}</div>
          <input class="y3-margin-top-4 y3-input-text-small " type="time" autocomplete="none" [(ngModel)]="preCheckInBooking.expectedCheckoutLocalTime">
        </div>
        <div class="y3-X-small y3-color-scorpion y3-margin-top-8" *ngIf="property?.defaultCheckOutTime">{{'guest-setup-page.explain-check-out' | translate}} <span >({{property.defaultCheckOutTime}})</span></div>
      </div>
    </div>

    <div *ngIf="!property?.neverAskTheGuestEmail" class="y3-yaago-title-page y3-margin-top-16">{{'g10-pre-checkin.personal-info' | translate}}</div>

    <div *ngIf="!property?.neverAskTheGuestEmail" class="y3-flex-responsive y3-margin-bottom-8" >
      <div class="y3-flex-column y3-X-small g10-input-margin">
        <div>{{'g10-pre-checkin.firstname' | translate}}</div>
        <input class="y3-input-text-small y3-margin-top-4" type="text" [(ngModel)]="preCheckInBooking.firstName" />
      </div>
      <div class="y3-flex-column y3-X-small g10-input-margin g10-second-input">
        <div>{{'g10-pre-checkin.lastname' | translate}}</div>
        <input class="y3-input-text-small y3-margin-top-4" type="text" [(ngModel)]="preCheckInBooking.lastName" />
      </div>
    </div>

    <div *ngIf="!property?.neverAskTheGuestEmail" class="y3-flex-responsive y3-margin-bottom-8 " >
      <div class="y3-flex-column y3-X-small g10-input-margin" >
        <div>{{'g10-pre-checkin.email' | translate}}</div>
        <input class="y3-input-text-small y3-margin-top-4" type="text" [(ngModel)]="preCheckInBooking.email" />
      </div>
      <div class="y3-flex-column y3-X-small g10-input-margin g10-second-input" >
        <div>{{'g10-pre-checkin.personal-email' | translate}}</div>
        <input class="y3-input-text-small y3-margin-top-4" type="text" [(ngModel)]="preCheckInBooking.secondaryEmail" />
      </div>
    </div>

    <div *ngIf="!property?.neverAskTheGuestEmail" class="y3-flex-responsive y3-margin-bottom-8" >
      <div class="y3-flex-column y3-X-small g10-input-margin" >
        <div>{{'g10-pre-checkin.phone' | translate}}</div>
        <input class="y3-input-text-small y3-margin-top-4" type="text" [(ngModel)]="preCheckInBooking.phone" />
      </div>
      <div class="y3-flex-column y3-X-small g10-input-margin g10-second-input" >
        <div>{{'g10-pre-checkin.secondary-phone' | translate}}</div>
        <input class="y3-input-text-small y3-margin-top-4" type="text" [(ngModel)]="preCheckInBooking.secondaryPhone" />
      </div>
    </div>


    <div *ngIf="!property?.neverAskTheGuestEmail" class="y3-yaago-title-page y3-margin-top-16" >{{'guest-setup-page.how-is-coming' | translate}}
      <span *ngIf="property?.personCapacity > 0" class="y3-roboto y3-XX-small y3-color-scorpion">max : {{property?.personCapacity}}</span>
    </div>


    <div class="y3-flex-row y3-margin-bottom-16">

      <div class="y3-flex-column y3-margin-right-32">
        <div class="y3-roboto y3-Small">{{'guest-setup-page.adults' | translate}}</div>
        <div class="g10-bulle-container">
          <div (click)="removeAdults()" class="y3-clickable" [ngClass]="checkDisableBtnDecre(preCheckInBooking?.adults)">
            <img class="icon-btn-add-below" src="assets/icon/check/below.svg" alt="below-disabled"/>
          </div>
          <div class="g10-valueNumber">
            <span>{{preCheckInBooking?.adults}}</span>
          </div>
          <div (click)="addAdults()" class="y3-clickable">
            <img class="icon-btn-add-below" src="assets/icon/check/plus.svg" alt="add-time"/>
          </div>
        </div>
      </div>

      <div class="y3-flex-column">
        <div class="y3-roboto y3-Small">{{'guest-setup-page.children' | translate}}</div>
        <div class="g10-bulle-container">
          <div (click)="removeChild()" class="y3-clickable" [ngClass]="checkDisableBtnDecre(preCheckInBooking?.children)">
            <img class="icon-btn-add-below" src="assets/icon/check/below.svg" alt="below-disabled"/>
          </div>
          <div class="g10-valueNumber">
            <span>{{preCheckInBooking?.children}}</span>
          </div>
          <div (click)="addChild()" class="y3-clickable">
            <img class="icon-btn-add-below" src="assets/icon/check/plus.svg" alt="add-time"/>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!property?.noshowBedsDetail" class="y3-yaago-title-page" >{{'guest-setup-page.beds-title' | translate}}</div>

    <div *ngIf="!property?.noshowBedsDetail" class="y3-flex-row y3-margin-bottom-16">
      <div class="y3-flex-column y3-margin-right-32" *ngIf="property?.doubleBeds > 0">
        <div class="y3-roboto y3-Small">{{'guest-setup-page.double-beds' | translate}}</div>
        <span class="y3-roboto y3-XX-small y3-color-scorpion">max : {{property?.doubleBeds}}</span>
        <div class="g10-bulle-container">
          <div (click)="remove('double')" class="y3-clickable" [ngClass]="checkDisableBtnDecre(preCheckInBooking?.doubleBeds)">
            <img class="icon-btn-add-below" src="assets/icon/check/below.svg" alt="below-disabled"/>
          </div>
          <div class="g10-valueNumber">
            <span>{{preCheckInBooking?.doubleBeds}}</span>
          </div>
          <div (click)="add('double')" class="y3-clickable">
            <img class="icon-btn-add-below" src="assets/icon/check/plus.svg" alt="add-time"/>
          </div>
        </div>
      </div>

      <div class="y3-flex-column y3-margin-right-32" *ngIf="property?.singleBeds > 0">
        <div class="y3-roboto y3-Small">{{'guest-setup-page.single-beds' | translate}}</div>
        <span class="y3-roboto y3-XX-small y3-color-scorpion">max : {{property?.singleBeds}}</span>
        <div class="g10-bulle-container">
          <div (click)="remove('single')" class="y3-clickable" [ngClass]="checkDisableBtnDecre(preCheckInBooking?.singleBeds)">
            <img class="icon-btn-add-below" src="assets/icon/check/below.svg" alt="below-disabled"/>
          </div>
          <div class="g10-valueNumber">
            <span>{{preCheckInBooking?.singleBeds}}</span>
          </div>
          <div (click)="add('single')" class="y3-clickable">
            <img class="icon-btn-add-below" src="assets/icon/check/plus.svg" alt="add-time"/>
          </div>
        </div>
      </div>

      <div class="y3-flex-column y3-margin-right-32" *ngIf="property?.sofaBeds > 0">
        <div class="y3-roboto y3-Small">{{'guest-setup-page.sofa-beds' | translate}}</div>
        <span class="y3-roboto y3-XX-small y3-color-scorpion">max : {{property?.sofaBeds}}</span>
        <div class="g10-bulle-container">
          <div (click)="remove('sofa')" class="y3-clickable" [ngClass]="checkDisableBtnDecre(preCheckInBooking?.sofaBeds)">
            <img class="icon-btn-add-below" src="assets/icon/check/below.svg" alt="below-disabled"/>
          </div>
          <div class="g10-valueNumber">
            <span>{{preCheckInBooking?.sofaBeds}}</span>
          </div>
          <div (click)="add('sofa')" class="clickable">
            <img class="icon-btn-add-below" src="assets/icon/check/plus.svg" alt="add-time"/>
          </div>
        </div>
      </div>

      <div class="y3-flex-column y3-margin-right-32" *ngIf="property?.babyBeds > 0">
        <div class="y3-roboto y3-Small">{{'guest-setup-page.baby-beds' | translate}}</div>
        <span class="y3-roboto y3-XX-small y3-color-scorpion">max : {{property?.babyBeds}}</span>
        <div class="g10-bulle-container">
          <div (click)="remove('crib')" class="y3-clickable" [ngClass]="checkDisableBtnDecre(preCheckInBooking?.babyBeds)">
            <img class="icon-btn-add-below" src="assets/icon/check/below.svg" alt="below-disabled"/>
          </div>
          <div class="g10-valueNumber">
            <span>{{preCheckInBooking?.babyBeds}}</span>
          </div>
          <div (click)="add('crib')" class="y3-clickable">
            <img class="icon-btn-add-below" src="assets/icon/check/plus.svg" alt="add-time"/>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="property?.parkings > 0" class="y3-yaago-title-page" >{{'guest-setup-page.parkings-title' | translate}}</div>

    <div *ngIf="property?.parkings > 0" class="y3-flex-row y3-margin-bottom-16">
      <div class="y3-flex-column y3-margin-right-32" >
        <div class="y3-roboto y3-Small">{{'guest-setup-page.double-beds' | translate}}</div>
        <span class="y3-roboto y3-XX-small y3-color-scorpion">max : {{property?.parkings}}</span>
        <div class="g10-bulle-container">
          <div (click)="remove('parkings')" class="y3-clickable" [ngClass]="checkDisableBtnDecre(preCheckInBooking?.parkings)">
            <img class="icon-btn-add-below" src="assets/icon/check/below.svg" alt="below-disabled"/>
          </div>
          <div class="g10-valueNumber">
            <span>{{preCheckInBooking?.parkings}}</span>
          </div>
          <div (click)="add('parkings')" class="y3-clickable">
            <img class="icon-btn-add-below" src="assets/icon/check/plus.svg" alt="add-time"/>
          </div>
        </div>
      </div>
    </div>

    <!-- Demande Particulière -->
    <div *ngIf="booking && !property.noShowParticularRequest" class="y3-margin-bottom-16">
      <div class="y3-yaago-title-page" >{{'guest-setup-page.leave-note' | translate}}</div>
      <div class="y3-margin-bottom-16">
        <div class="y3-X-small" *ngIf="corporate?.lightCheckInOnLineWording != null"
             [innerHTML]="corporate.lightCheckInOnLineWording">
        </div>
        <div *ngIf="!corporate.lightCheckInOnLineWording" class="y3-X-small ">
          * {{'guest-setup-page.request-text' | translate}}
        </div>
      </div>
      <div >
        <textarea class="y3-input-text-small" style="width: 100%" rows="5" [(ngModel)]="preCheckInBooking.guestNote"></textarea>
      </div>
    </div>

    <app-button [type]="'primary'" [text]="'guest-setup-page.confirm-btn' | translate" (debounceClick)="saveBookingInfo()"></app-button>



  </div>
</div>
