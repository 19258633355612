/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-lost.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./guest-lost.component";
var styles_GuestLostComponent = [i0.styles];
var RenderType_GuestLostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestLostComponent, data: {} });
export { RenderType_GuestLostComponent as RenderType_GuestLostComponent };
export function View_GuestLostComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container404"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["style", "position: absolute; bottom: 0; width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [["class", "desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["src", "assets/images/404.svg"], ["style", "width: 100%; object-fit: fill"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("guest-lost-page.title")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("guest-lost-page.description")); _ck(_v, 6, 0, currVal_1); }); }
export function View_GuestLostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-lost", [], null, null, null, View_GuestLostComponent_0, RenderType_GuestLostComponent)), i1.ɵdid(1, 114688, null, 0, i3.GuestLostComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestLostComponentNgFactory = i1.ɵccf("app-guest-lost", i3.GuestLostComponent, View_GuestLostComponent_Host_0, {}, {}, []);
export { GuestLostComponentNgFactory as GuestLostComponentNgFactory };
