<div class="container404">

  <div style="position: absolute; bottom: 0; width: 100%;">
    <p class="title">{{'guest-lost-page.title' | translate}}</p>
    <p class="desc">{{'guest-lost-page.description' | translate}}</p>
    <img style="width: 100%; object-fit: fill" src="assets/images/404.svg">
  </div>
</div>

<!--<div class="container">
  <div class="error-container">
    <div class="icon-logo-wrapper">
      <img class="icon-logo" src="assets/images/yaago.svg" />
    </div>
    <div class="error-information">
      <div class="error-illustration-wrapper">
        <img class="error-illustration" src="assets/icon/error-illustration.svg" />
      </div>
      <div class="error-info-wrapper">
        <p class="title">{{'guest-lost-page.title' | translate}}</p>
        <p class="desc">{{'guest-lost-page.description' | translate}}</p>
      </div>
    </div>
  </div>
</div>
-->
