/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./m11-platform-services.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./m11-platform-services.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../core/services/utils.service";
import * as i7 from "../../../core/services/guest.service";
import * as i8 from "../../../core/services/LoaderService";
import * as i9 from "../../../core/http/api.service";
var styles_M11PlatformServicesComponent = [i0.styles];
var RenderType_M11PlatformServicesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_M11PlatformServicesComponent, data: {} });
export { RenderType_M11PlatformServicesComponent as RenderType_M11PlatformServicesComponent };
function View_M11PlatformServicesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "y3-service-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "y3-service-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "partner-img"], ["class", "y3-service-image"]], [[8, "id", 0], [8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "y3-card-description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "y3-service-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "y3-service-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "partner-link y3-margin-top-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [["class", "alink-no-decoration "], ["target", "_blank"]], [[8, "id", 0], [8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = ("platformLogo" + _v.context.index); var currVal_1 = _v.context.$implicit.logoUrl; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.translations[0].description; _ck(_v, 7, 0, currVal_3); var currVal_4 = ("platformLink" + _v.context.index); var currVal_5 = _v.context.$implicit.translations[0].webSiteUrl; _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("guest-platefromservices-page.more-btn")); _ck(_v, 10, 0, currVal_6); }); }
export function View_M11PlatformServicesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "m11-main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "m11-close-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "close"], ["src", "assets/icon/close-round.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "y3-margin-top-24 y3-margin-bottom-24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "y3-High y3-bold y3-title-colors"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "y3-flex-responsive"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_M11PlatformServicesComponent_1)), i1.ɵdid(9, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.platformServices; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("guest-platefromservices-page.title")); _ck(_v, 5, 0, currVal_0); }); }
export function View_M11PlatformServicesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-m11-platform-services", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_M11PlatformServicesComponent_0, RenderType_M11PlatformServicesComponent)), i1.ɵdid(1, 114688, null, 0, i4.M11PlatformServicesComponent, [i5.ActivatedRoute, i6.UtilsService, i2.TranslateService, i7.GuestService, i8.LoaderService, i9.ApiService, i3.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var M11PlatformServicesComponentNgFactory = i1.ɵccf("app-m11-platform-services", i4.M11PlatformServicesComponent, View_M11PlatformServicesComponent_Host_0, {}, {}, []);
export { M11PlatformServicesComponentNgFactory as M11PlatformServicesComponentNgFactory };
