import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Corporate} from '../../../models/guestview/Corporate';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../../../core/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {GuestService} from '../../../core/services/guest.service';
import {LoaderService} from '../../../core/services/LoaderService';
import {ApiService} from '../../../core/http/api.service';
import {SecurityService} from '../../../core/services/SecurityService';
import {Location} from '@angular/common';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';

@Component({
  selector: 'app-m13-welcome-screen',
  templateUrl: './m13-welcome-screen.component.html',
  styleUrls: ['./m13-welcome-screen.component.scss']
})
export class M13WelcomeScreenComponent extends AbstractWindow implements OnInit, AfterViewInit {

  corporate: Corporate;
  property: PropertyForGuest;
  booking: BookingForGuest;
  idForRouting: string;
  urlLang: string;
  password: string = '';
  errorMsg: string = '';
  constructor(private activatedRoute: ActivatedRoute,
              public utilService: UtilsService,
              public translateService: TranslateService,
              private guestService: GuestService,
              private loaderService: LoaderService,
              private apiService: ApiService,
              private router: Router,
              public route: ActivatedRoute,
              private securityService: SecurityService,
              private location: Location) {
    super(utilService);
  }

  ngAfterViewInit() {
    this.route.queryParams
      .subscribe(params => {
          console.log("params lang in welcome screen", params.lang);
          this.urlLang = params.lang;
        }
      );
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      this.loaderService.dismissLoader();
      if (res && res.propertyAndBooking) {
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        //this.utilService.manageCorporateColors(this.corporate);
        this.booking = res.propertyAndBooking.booking;

        this.idForRouting = this.property.id;
        if (this.booking) {
          this.idForRouting = this.booking.id;
        }
      }
    });
  }

  getLogo() {
    if (this.corporate.homePageSettings != null && this.corporate.homePageSettings.agencyLogoUrl != null) {
      return this.corporate.homePageSettings.agencyLogoUrl;
    } else {
      return this.corporate.logoUrl;
    }
  }

  goToBooklet() {
    this.loaderService.showFullLoader('');
    GuestService.welcomeWasClosed = true;
    if (this.urlLang) {
      // console.log("go with url lang", this.urlLang);
      this.router.navigate(['/guest/' + this.idForRouting ], {queryParams: {lang: this.urlLang}}).then( () => {
        this.loaderService.dismissLoader();
      });
    } else {
      this.router.navigate(['/guest/' + this.idForRouting ]).then( () => {
        this.loaderService.dismissLoader();
      });
    }
    /*this.router.navigate(['/guest/' + this.idForRouting ], {queryParams: {lang: this.urlLang}}).then( () => {
      this.loaderService.dismissLoader();
    });*/
  }

}
