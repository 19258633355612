<section id="SECTION_____MENU___LANDING_PAGE">
  <app-g0-guest-menu-desktop class="fixed-header" *ngIf="isDesktopMode()"
                          [property]="property"
                          [corporate]="corporate"
                          [booking]="booking"
                          [fragment]="landingPageFragment"
                          [target]="[details['mytmpl'],
                          guides['mytmpl'],
                          usefullnumber['mytmpl'],
                          services['mytmpl'],
                          hygienes['mytmpl'],

                          recommendations['mytmpl']]" ></app-g0-guest-menu-desktop>
  <app-g0-guest-menu-mobile *ngIf="!isDesktopMode()" [(booking)]="booking" [corporate]="corporate"></app-g0-guest-menu-mobile>
</section>


<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />

<div class="landing-page-container" style="position: relative; top: 100px">


    <app-guest-toppage  [property]="property" [(booking)]="booking"></app-guest-toppage>

    <section style=" width: 1024px;margin: auto; margin-bottom: 32px; margin-top: 32px" >

      <div class="y3-margin-bottom-16" *ngIf="currentUpSellOnBoarding" >
        <app-m24-upsells-recap ></app-m24-upsells-recap>
      </div>

      <app-m23-upsell-recap *ngIf="checkIfUpSell()" [currentUpSellOnBoarding]="currentGuestUpsellOnBoarding"></app-m23-upsell-recap>
    </section>

    <app-guest-booking-details  id="details" #details [booking]="booking" [property]="property" [corporate]="corporate"></app-guest-booking-details>



    <app-y3-upsells-guide (getClosed)="reloadBasket()" id="upsells" #upsells [booking]="booking" [property]="property" [corporate]="corporate" [currentUpSellOnBoarding]="currentUpSellOnBoarding"></app-y3-upsells-guide>
    <app-guest-property-guides id="guides" #guides [booking]="booking" [property]="property" ></app-guest-property-guides>

    <app-guest-recommendations-view  id="recommendations" #recommendations></app-guest-recommendations-view>
    <app-guest-usefulnumber id="usefullnumber" #usefullnumber></app-guest-usefulnumber>

    <app-upsale-category-list *ngIf="hasUpsales" id="upsales" #upsales [booking]="booking" ></app-upsale-category-list>

    <app-y3-plaform-services  id="services2"  #services [property]="property" [platformServices]="platformAffiliateServices"></app-y3-plaform-services>
<!--    <app-guest-platform-services  id="services"  #services [property]="property" [platformServices]="platformAffiliateServices"></app-guest-platform-services>-->

    <!--<app-guest-hygiene  id="hygienes"  #hygienes></app-guest-hygiene>-->
    <app-yaagov2-hygiene  id="hygienes"  #hygienes></app-yaagov2-hygiene>




    <app-footer-booklet-reviews *ngIf="booking && !property?.noshowBookletReview" [bookingId]="booking?.id" [bookletReviews]="bookletReviews"></app-footer-booklet-reviews>

    <section style=" width: 1024px;margin: auto; margin-bottom: 32px; margin-top: 32px" >
      <div class="y3-margin-bottom-32" *ngIf="previousUpSellOnBoardings?.length>0" >
        <app-m25-upsells-validated ></app-m25-upsells-validated>
      </div>
    </section>

    <app-guest-footer-page *ngIf="property?.host?.corporate" [corporate]="property?.host?.corporate"></app-guest-footer-page>


    <div *ngIf="!property?.neverDisplayArrivalDepartureButton">
      <app-desktop-guest-arrived-left *ngIf="isDesktopMode() && booking" [booking]="booking" [property]="property"></app-desktop-guest-arrived-left>
      <app-mobile-guest-arrived-left *ngIf="!isDesktopMode() && booking" [booking]="booking" [property]="property"></app-mobile-guest-arrived-left>
    </div>

</div>

<div *ngIf="corporate?.corporateTag">
  <app-cotoon-footer *ngIf="corporate?.corporateTag === 'COTOON'" ></app-cotoon-footer>
  <!--<app-rentatranquille-footer *ngIf="corporate?.corporateTag === 'RENTATRANQUILLE'" ></app-rentatranquille-footer>-->
<!--  <app-guest-adom-footer *ngIf="corporate?.corporateTag === 'GUESTADOM'" ></app-guest-adom-footer>-->
  <app-keylodge-footer  *ngIf="corporate?.corporateTag === 'KEYLODGE'"></app-keylodge-footer>

  <app-gites-de-france *ngIf="corporate?.corporateTag === 'GITESDEFRANCE'" ></app-gites-de-france>
  <app-haloconcierge *ngIf="corporate?.corporateTag === 'HALOCONCIERGE'" ></app-haloconcierge>
  <app-yaago2-powered-by-footer [corporate]="corporate"></app-yaago2-powered-by-footer>
</div>


