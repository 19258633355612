import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-keylodge-footer',
  templateUrl: './keylodge-footer.component.html',
  styleUrls: ['./keylodge-footer.component.scss']
})
export class KeylodgeFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
