/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tooltip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tooltip.component";
var styles_TooltipComponent = [i0.styles];
var RenderType_TooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipComponent, data: {} });
export { RenderType_TooltipComponent as RenderType_TooltipComponent };
export function View_TooltipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "tooltip"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵncd(null, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "tooltip-content ", _co.position, ""); _ck(_v, 2, 0, currVal_0); }); }
export function View_TooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tooltip", [], null, null, null, View_TooltipComponent_0, RenderType_TooltipComponent)), i1.ɵdid(1, 114688, null, 0, i2.TooltipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TooltipComponentNgFactory = i1.ɵccf("app-tooltip", i2.TooltipComponent, View_TooltipComponent_Host_0, { position: "position" }, {}, ["[placeholder]", "[content]"]);
export { TooltipComponentNgFactory as TooltipComponentNgFactory };
