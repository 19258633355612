import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../conf.service";
export class GuestUpSellOnBoardingEndpoint {
    constructor(http, confService) {
        this.http = http;
        this.confService = confService;
    }
    validateBasket(guestUpSellOnBoarding) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/guest/upsell-onboarding/create-or-update', guestUpSellOnBoarding);
    }
    cancelBasket(guestUpSellOnBoardingId) {
        return this.http.delete(this.confService.getBackendApiEndPoint() + 'api-v2/guest/upsell-onboarding/' + guestUpSellOnBoardingId);
    }
}
GuestUpSellOnBoardingEndpoint.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GuestUpSellOnBoardingEndpoint_Factory() { return new GuestUpSellOnBoardingEndpoint(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfService)); }, token: GuestUpSellOnBoardingEndpoint, providedIn: "root" });
