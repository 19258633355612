<div class="m8-main-container">
  <div class="m8-close-button">
    <img src="assets/icon/close-round.svg" alt="close" (click)="goBack()" />
  </div>

  <div class="y3-flex-row y3-flex-justify-content-center y3-margin-bottom-32 ">
    <img *ngIf="corporate.logoUrl" class="m8-logo" [src]="corporate.logoUrl" alt="image" />
  </div>

  <div class="y3-margin-top-24 y3-margin-bottom-24">
    <div class="y3-High y3-bold y3-margin-top-16 y3-title-colors">{{'guest-view-booking-details.important-info' | translate}}</div>
  </div>

  <div class="y3-partner-section">
    <!--<img *ngIf="isDesktopMode()" src="/assets/icon/icon-arrow-left.svg" alt="previous" class="y3-clickable y3-margin-right-16" (click)="partnerScroll(partnerContainer, 'left')"-->
    <div class="new-upsale-card-container " #partnerContainer>


      <div class="card-small flex flex-column y3-margin-bottom-8" *ngFor="let guide of guides" (click)="showMobileGuideDetails(guide)" >
        <div class="width-100 y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-clickable">
          <div class="y3-flex-row y3-flex-align-center">
            <div style="position: relative">
              <img class="category-cover y3-margin-right-12" src="{{getGuideCover(guide)}}" alt="">
            </div>

            <div style="position: relative" class="y3-flex-row">
              <div class="y3-Small y3-bold "  *ngIf="guide.category === 'wifi'">WIFI :</div>
              <div  class="y3-Small y3-bold" >{{guide.translations[0].title }}</div>

            </div>
          </div>

          <mat-icon class="m0-button-quick-2">chevron_right</mat-icon>

          <!--<img src="assets/icon/arrow-down.svg"
               class="{{i === mobileCurrentOpenCategory ? 'rotate-90' : 'rotate-270'}}" alt="">-->
        </div>

        <!--<div class="card-small" *ngFor="let guide of upsellsGuides" (click)="showMobileGuideDetails(guide)" >
          <div class="y3-flex-row y3-flex-align-center">
            <div class="y3-margin-right-12">
              <img class="category-cover " src="{{getGuideCover(guide)}}" alt="guide">
            </div>
          <div class="new-upsale-descr">
            <div class="header1 truncate-text">
              <div class="reco-title truncate-text">{{guide.translations[0].title |titlecase }}</div>
            </div>
          </div>
            <mat-icon class="m0-button-quick-2">chevron_right</mat-icon>
            </div>
        </div>-->
      </div>
      <!--<img *ngIf="isDesktopMode()" src="/assets/icon/icon-arrow-left.svg" alt="next" class="y3-clickable y3-rotate-180 y3-margin-left-16" (click)="partnerScroll(partnerContainer, 'right')">-->
    </div>

  </div>

</div>
