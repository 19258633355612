<div class="y3-divider y3-padding-bottom-24 local-main-container" *ngIf="(upsellsGuides && upsellsGuides.length > 0)" >
  <div class="y3-container">


    <div class="y3-padding-top-48 y3-margin-bottom-32">
      <mat-icon class="material-symbols-outlined y3-steph-title-dot">storefront</mat-icon>
      <div class="y3-steph-title" style="color: black">{{'guest-view-booking-details.upsells' | translate}}<span class="y3-steph-title-dot">.</span></div>
      <div class="y3-Small y3-margin-top-4 y3-steph-color-text " style="color: black">{{'guest-view-booking-details.upsells-text' | translate}}</div>
    </div>

    <!--<div class="y3-padding-top-48 y3-margin-bottom-8">
      <h3 class="y3-title-colors">{{'guest-view-booking-details.upsells' | translate}}</h3>
      <div class="y3-Small y3-sub-title-colors">{{'guest-view-booking-details.upsells-text' | translate}}</div>
    </div>-->


    <div id="SECTION__HORIZONTAL_VIEW_ONLY" class="y3-flex-row y3-flex-align-center">

      <button  appDebounceClick (debounceClick)="partnerScroll(upSellContainer, 'left')" class="fab-button previous" mat-mini-fab color="primary" aria-label="Previous">
        <mat-icon style="line-height: 24px;">arrow_back_ios_new</mat-icon>
      </button>


      <!--<perfect-scrollbar class="y3-flex-row" [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}" >-->
        <div class="y3-flex-row upSellContainer" #upSellContainer>
          <div class="new-upsale-card2 y3-clickable" *ngFor="let guide of upsellsGuides" (click)="showGuide(guide)" >
            <div class="ribbon-2" *ngIf="checkIfUpsellItemExists(guide.guideId)">{{ 'guest-guid-page.in-card' | translate}}</div>
            <div class="ribbon-3" *ngIf="checkUpSellState(guide.guideId)==='created'">{{ 'guest-guid-page.waiting' | translate}}</div>
            <div class="ribbon-4" *ngIf="checkUpSellState(guide.guideId)==='paid'">{{ 'guest-guid-page.paid' | translate}}</div>
            <div class="ribbon-4" *ngIf="checkUpSellState(guide.guideId)==='accepted'">{{ 'guest-guid-page.accepted' | translate}}</div>
            <div class="ribbon-5" *ngIf="checkUpSellState(guide.guideId)==='refused'">{{ 'guest-guid-page.refused' | translate}}</div>
            <div class="image-zoom">
              <img class="new-upsale-photo" src="{{getGuideCover(guide)}}">
            </div>

            <div class="new-upsale-descr y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-Small ">
              <div class="header1 truncate-text">
                <div class="reco-title truncate-text">{{guide.translations[0].title }}</div>
              </div>
              <div style="min-width: 75px" *ngIf="guide.upSellSection?.upSellAmount != null">
                <app-tag [padding]="'8px'"  text="{{guide.upSellSection.upSellAmount.amount}} {{guide.upSellSection.upSellAmount.currency}}" [type]="'blue'"></app-tag>
              </div>
            </div>

            <!--<div class="y3-Small y3-padding-8 y3-bold y3-text-ellipsis-nowrap">
              {{guide.translations[0].title |titlecase}}
            </div>-->
          </div>
        </div>
      <!--</perfect-scrollbar>-->

      <button  appDebounceClick (debounceClick)="partnerScroll(upSellContainer, 'right')" class="fab-button next" mat-mini-fab color="primary" aria-label="Next">
        <mat-icon style="line-height: 24px;">arrow_forward_ios</mat-icon>
      </button>

    </div>

    <!--<div class="y3-partner-section" >
      <perfect-scrollbar class="y3-flex-row" [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
        <img *ngIf="isDesktopMode()" src="/assets/icon/icon-arrow-left.svg" alt="previous" class="y3-clickable y3-margin-right-16" (click)="partnerScroll(partnerContainer, 'left')">
        <div class="new-upsale-card-container " #partnerContainer>

          <div class="new-upsale-card" *ngFor="let guide of upsellsGuides" (click)="showGuide(guide)" >
            <div class="ribbon-2" *ngIf="checkIfUpsellItemExists(guide.guideId)">{{ 'guest-guid-page.in-card' | translate}}</div>
            <div class="ribbon-3" *ngIf="checkUpSellState(guide.guideId)==='created'">{{ 'guest-guid-page.waiting' | translate}}</div>
            <div class="ribbon-4" *ngIf="checkUpSellState(guide.guideId)==='paid'">{{ 'guest-guid-page.paid' | translate}}</div>
            <div class="ribbon-4" *ngIf="checkUpSellState(guide.guideId)==='accepted'">{{ 'guest-guid-page.accepted' | translate}}</div>
            <div class="ribbon-5" *ngIf="checkUpSellState(guide.guideId)==='refused'">{{ 'guest-guid-page.refused' | translate}}</div>
            <img class="new-upsale-photo" src="{{getGuideCover(guide)}}" alt="guide">
            <div class="new-upsale-descr y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-Small y3-title-colors">
              <div class="header1 truncate-text">
                <div class="reco-title truncate-text">{{guide.translations[0].title |titlecase }}</div>
              </div>
              <div style="min-width: 75px" *ngIf="guide.upSellSection?.upSellAmount != null">
                <app-tag [padding]="'8px'"  text="{{guide.upSellSection.upSellAmount.amount}} {{guide.upSellSection.upSellAmount.currency}}" [type]="'blue'"></app-tag>
                </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
      <img *ngIf="isDesktopMode()" loading="lazy" src="/assets/icon/icon-arrow-left.svg" alt="next" class="y3-clickable y3-rotate-180 y3-margin-left-16" (click)="partnerScroll(partnerContainer, 'right')">
    </div>-->

  </div>
</div>

