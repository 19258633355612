<div class="m1-main-container">
  <div class="m1-close-button">
    <img src="assets/icon/close-round.svg" alt="close" (click)="goBack()" />
  </div>


  <section *ngIf="currentStep === 'HOME'">
  <div class="m1-property-title y3-margin-top-24 y3-flex-column y3-flex-justify-content-center y3-flex-align-center y3-text-ellipsis y3-full-width y3-color-desktop-bandeau">
    <div class="y3-Medium y3-bold y3-color-desktop-bandeau-font" style="text-align: center">{{property.bookletTitle != null ?property.bookletTitle:property.title}}</div>
    <div class="y3-X-small y3-margin-top-4 y3-color-desktop-bandeau-font">{{property.fullAddress}}</div>
  </div>

  <div class="y3-High y3-bold y3-margin-top-16 y3-title-colors">{{'m1-property-details.property-details' | translate}}</div>
  <div class="y3-Small y3-margin-top-8 y3-color-scorpion " [innerHtml]="property.description"></div>

  <div class="y3-margin-top-16">
    <div class="y3-margin-top-8" *ngIf="property?.matterportUrl != null" (click)="currentStep = 'VIDEO'">
      <div class="m1-new-button">
        <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">
          <div class="y3-flex-row">
            <mat-icon class="m1-icon-1">photo_camera</mat-icon>
            <div class="y3-flex-column y3-padding-left-24">
              <div class="y3-X-small y3-color-scorpion">{{'m1-property-details.property-video' | translate}} </div>
              <div class="y3-Medium y3-bold y3-margin-top-3 ">{{'m1-property-details.look' | translate}}</div>
            </div>
          </div>
          <mat-icon class="m1-button-quick-2">chevron_right</mat-icon>

        </div>
      </div>
    </div>

    <div class="y3-margin-top-8" *ngIf="property?.photos?.length > 0" (click)="currentStep = 'PHOTOS'">
      <div class="m1-new-button">
        <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">
          <div class="y3-flex-row">
            <mat-icon class="m1-icon-1">photo_camera</mat-icon>
            <div class="y3-flex-column y3-padding-left-24">
              <div class="y3-X-small y3-color-scorpion">{{'m1-property-details.property-photos' | translate}} </div>
              <div class="y3-Medium y3-bold y3-margin-top-3 ">{{'m1-property-details.look' | translate}}</div>
            </div>
          </div>
          <mat-icon class="m1-button-quick-2">chevron_right</mat-icon>

        </div>
      </div>
    </div>
    </div>


  <div *ngIf="(!property?.host?.btobInfo || !property?.noshowBedsDetail) && (property.doubleBeds + property.singleBeds + property.sofaBeds + property.bathrooms) >0  " class="y3-margin-top-24">
    <div class="y3-XLarge y3-bold y3-margin-top-16 y3-title-colors">{{'guest-view-booking-details.disposition' | translate}}</div>
  </div>

  <div *ngIf="(!property?.host?.btobInfo || !property?.noshowBedsDetail) && (property.doubleBeds + property.singleBeds + property.sofaBeds + property.bathrooms) >0  " class="card y3-flex-column y3-roboto y3-Small y3-margin-top-8">
    <div class="y3-margin-top-4" *ngIf="property.doubleBeds > 0">{{counter('doublebeds')}} {{counter('doublebeds') > 1 ? ('guest-detail-booking.doublebeds' | translate) : ('guest-detail-booking.doublebed' | translate) }}</div>
    <div class="y3-margin-top-4" *ngIf="property.singleBeds > 0">{{counter('singlebeds')}} {{counter('singlebeds') > 1 ? ('guest-detail-booking.singlebeds' | translate) : ('guest-detail-booking.singlebed' | translate)}}</div>
    <div class="y3-margin-top-4" *ngIf="property.sofaBeds > 0">{{counter('sofabeds')}} {{counter('sofabeds') > 1 ? ('guest-detail-booking.sofabeds' | translate) : ('guest-detail-booking.sofabed' | translate)}}</div>
    <div class="y3-margin-top-4" *ngIf="property.bathrooms > 0">{{counter('baths')}} {{counter('baths') > 1 ? ('guest-detail-booking.bathRooms' | translate) : ('guest-detail-booking.bathRoom' | translate)}}</div>
    <div class="y3-margin-top-4" *ngIf="property.showerRooms > 0">{{counter('shower')}} {{counter('shower') > 1 ? ('guest-detail-booking.showerRoom' | translate) : ('guest-detail-booking.showerRoom' | translate)}}</div>
  </div>

  <div class="y3-margin-top-24" *ngIf="property.poi">
    <div class="y3-XLarge y3-bold y3-margin-top-16 y3-title-colors">{{'guest-view-booking-details.around' | translate}}</div>
  </div>

  <div class="map-container" *ngIf="property.poi">
    <app-leaflet-map
      [mapId]="'y3-mapDetailDesktop'"
      [defaultZoom]="15"
      [centerOfTheMap]="property.poi"
      [corporate]="corporate"
      [propertyPoi]="property.poi"
      [whereIamPoi]=""
      [properties]=""
      [language]="translateService.currentLang"
      [showAllProperties]="true"
      [showRecoCorporate]="true"
      [showProperty]="true"
      [showRecoYaago]="true"
      [showRecoInfluencer]="true"
      [showRecoTourism]="true"
      [showOwner]="true"
      [showFilterReco]="false"
      [recapVersion]="'v0'"
      [showZoomControl]="false"
      [showChecks]="false"
      [relatedPoiForDistanceCalculation]="property.poi"
    ></app-leaflet-map>
  </div>

  </section>

  <section id="SECTIONVIDEO" *ngIf="currentStep === 'VIDEO'" class="y3-margin-top-24">
    <div style="height: 100%">
      <div *ngIf="property?.matterportUrl && utilService.videoUrlType(property?.matterportUrl) !== DocType.YOUTUBE" style="height: calc(100vh - 70px); width: 100%;">
        <iframe style="width: 100%; height: 100%" [src]="transform(property?.matterportUrl)"></iframe>
      </div>

      <div style="height: calc(100vh - 70px)" *ngIf="property?.matterportUrl && utilService.videoUrlType(property?.matterportUrl) === DocType.YOUTUBE">
        <app-external-video-box [videoUrl]="property?.matterportUrl"></app-external-video-box>
      </div>
    </div>
  </section>

  <section id="SECTIONPHOTOS" *ngIf="currentStep === 'PHOTOS'">

    <div class="top-image-container">
      <img class="mainPhotoLandingPage" [src]="mainPropertyPhoto" alt="mainPhoto">
      <div>{{mainPropertyPhotoCaption}}</div>
      <button *ngIf="_hasPrevious" appDebounceClick (debounceClick)="previous()" class="fab-button previous" mat-mini-fab color="primary" aria-label="Previous">
        <mat-icon>arrow_back_ios_new</mat-icon>
      </button>
      <button *ngIf="_hasNext" appDebounceClick (debounceClick)="next()" class="fab-button next" mat-mini-fab color="primary" aria-label="Next">
        <mat-icon>arrow_forward_ios</mat-icon>
      </button>

    </div>

  </section>

  <div class="y3-empty-space"></div>

</div>
