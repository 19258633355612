import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IconService } from './core/services/icon.service';
import { LanguageManagementService } from './core/services/language.service';
import {LoaderService} from "./core/services/LoaderService";
import {SecurityService} from "./core/services/SecurityService";
import {UtilsService} from "./core/services/utils.service";
import {Corporate} from "./models/guestview/Corporate";
import {GuestService} from "./core/services/guest.service";
import {Title} from '@angular/platform-browser';
import {AbstractWindow} from './core/abstract/AbstractWindow';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AbstractWindow implements OnInit {

  favIcon: HTMLLinkElement | null = document.querySelector('#favicon');
  showFooter = true;
  corporate: Corporate | null = null;
  screenWidth: number;
  public title: Title;
  constructor( private translate: TranslateService,
               private iconService: IconService,
               private activatedRoute: ActivatedRoute,
               private router: Router,
               private securityService: SecurityService,
               private languageService: LanguageManagementService,
               private guestService: GuestService,
               public utilsService: UtilsService,
               private loaderService: LoaderService) {
    super(utilsService);

    let browserLang = translate.getBrowserLang();
    browserLang = UtilsService.isLanguageSupported(browserLang);
    this.languageService.setLanguageInUrl(browserLang);
    this.translate.use(browserLang);

    this.iconService.registerIcons();
    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (event.url.indexOf("/misc") >= 0) {
            this.showFooter = false;
          } else if (event.urlAfterRedirects.indexOf("/misc") >= 0) {
            this.showFooter = false;
          } else {
            this.showFooter = true;
          }
          if(event.url.indexOf("/livret-welcome") >= 0){
            this.showFooter = false;
          }
        }
      }
    );

  }

  ngOnInit(): void {
    this.manageServiceWorker();
    this.loaderService.dismissLoader();
  }

  onActivate(e) {
    let element : HTMLElement | null = document.getElementById('scrollable-container');
    if (element) {
      element.scrollTo(0, 0);
    }
  }

}
