/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./m9-numbers.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./m9-numbers.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../core/services/utils.service";
import * as i7 from "../../../core/services/guest.service";
import * as i8 from "../../../core/services/LoaderService";
import * as i9 from "../../../core/http/api.service";
var styles_M9NumbersComponent = [i0.styles];
var RenderType_M9NumbersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_M9NumbersComponent, data: {} });
export { RenderType_M9NumbersComponent as RenderType_M9NumbersComponent };
function View_M9NumbersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "image"], ["class", "m9-logo"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.corporate.logoUrl; _ck(_v, 0, 0, currVal_0); }); }
function View_M9NumbersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "m9-card-usefulNumber y3-flex-column y3-flex-align-center y3-flex-justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", " y3-Small y3-color-scorpion"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "y3-margin-top-4 y3-bold y3-Small "]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "y3-margin-top-4 y3-X-small "]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "y3-margin-top-8 y3-Medium y3-color-scorpion"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.category; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.fullAddress; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.phoneNumber; _ck(_v, 9, 0, currVal_3); }); }
export function View_M9NumbersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "m9-main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "m9-close-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "close"], ["src", "assets/icon/close-round.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "y3-flex-row y3-flex-justify-content-center y3-margin-bottom-32 "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_M9NumbersComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "y3-margin-top-24 y3-margin-bottom-24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "y3-High y3-bold y3-title-colors"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "y3-padding-bottom-16"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_M9NumbersComponent_2)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.corporate.logoUrl; _ck(_v, 5, 0, currVal_0); var currVal_2 = _co.numbers; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("guest-view-booking-details.useful-number")); _ck(_v, 8, 0, currVal_1); }); }
export function View_M9NumbersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-m9-numbers", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_M9NumbersComponent_0, RenderType_M9NumbersComponent)), i1.ɵdid(1, 114688, null, 0, i4.M9NumbersComponent, [i5.ActivatedRoute, i6.UtilsService, i3.TranslateService, i7.GuestService, i8.LoaderService, i9.ApiService, i2.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var M9NumbersComponentNgFactory = i1.ɵccf("app-m9-numbers", i4.M9NumbersComponent, View_M9NumbersComponent_Host_0, {}, {}, []);
export { M9NumbersComponentNgFactory as M9NumbersComponentNgFactory };
