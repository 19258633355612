<div class="container-modal" >
    <h4 class="request-confirm text-center">{{!data.documentType?'':'guest-inventory.ask-for-delete-identity' | translate}}{{data.documentType?'':'guest-inventory.ask-for-delete' | translate}}</h4>
  

    <mat-dialog-actions class="row d-flex align-items-center justify-content-center text-center buttons-container">
        <div class="mt-3">
            <button class="cancel  col-sm-12"  mat-stroked-button [mat-dialog-close]="true">
                {{'guest-inventory.cancel' | translate}}
            </button>
        </div>
        <div  class="mt-3">
            <button mat-flat-button class="confirm col-sm-12 text-center" (click)="removeImg()">
                {{'guest-inventory.delete' | translate}}
            </button>
        </div>
      </mat-dialog-actions>
</div>
