import { Component, OnInit } from '@angular/core';
import {GuestBookingInformationConfirmationDto} from '../../../models/preCheckOnLine/GuestBookingInformationConfirmationDto';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {takeUntil} from 'rxjs/operators';
import {GuestService} from '../../../core/services/guest.service';
import {ActivatedRoute} from '@angular/router';
import {LanguageManagementService} from '../../../core/services/language.service';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {UtilsService} from '../../../core/services/utils.service';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {LoaderService} from '../../../core/services/LoaderService';
import {SnackbarService} from '../../../core/services/SnackbarService';
import {SnackbarMessage} from '../../../models/snackbar/SnackbarMessage';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-g10-pre-check-in',
  templateUrl: './g10-pre-check-in.component.html',
  styleUrls: ['./g10-pre-check-in.component.scss']
})
export class G10PreCheckInComponent extends AbstractWindow implements OnInit {

  preCheckInBooking = new GuestBookingInformationConfirmationDto();
  property: PropertyForGuest;
  booking: BookingForGuest;
  corporate: Corporate;
  urlLang: string;
  constructor(
              public utilsService: UtilsService,
              private guestService: GuestService,
              private loaderService: LoaderService,
              public translateService: TranslateService,
              private snackbarService: SnackbarService,
              private languageService: LanguageManagementService,
              private route: ActivatedRoute) {
    super(utilsService);
  }

  ngOnInit() {

    this.route.queryParamMap.subscribe(params => {
      if (params[`params`].lang) {
        this.urlLang = params[`params`].lang;
        console.log("---> language :", this.urlLang);
      }
    });

    this.route.paramMap.subscribe( ParamMap => {
      const bookingId = ParamMap.get('bookingId');
      console.log("bookingId", bookingId);
      this.guestService.getPropertyAndBookingForGuest(bookingId , this.languageService.getLanguageInUrl()).pipe(takeUntil(this.onDestroy)).subscribe( res => {
        this.booking = res.booking;

        this.preCheckInBooking.adults = this.booking.adults;
        this.preCheckInBooking.children = this.booking.children;
        this.preCheckInBooking.email = this.booking.guests[0].email;
        this.preCheckInBooking.firstName = this.booking.guests[0].firstName;
        this.preCheckInBooking.lastName = this.booking.guests[0].lastName;
        this.preCheckInBooking.secondaryEmail = this.booking.guests[0].secondaryEmail;
        this.preCheckInBooking.secondaryPhone = this.booking.guests[0].secondaryPhone;
        this.preCheckInBooking.singleBeds = this.booking.singleBeds;
        this.preCheckInBooking.doubleBeds = this.booking.doubleBeds;
        this.preCheckInBooking.sofaBeds = this.booking.sofaBeds;
        this.preCheckInBooking.babyBeds = this.booking.babyBeds;
        this.preCheckInBooking.pets = this.booking.pets;
        this.preCheckInBooking.guestNote = this.booking.guestNote;

        this.preCheckInBooking.expectedCheckinLocalTime = this.booking.expectedCheckinLocalTime;
        this.preCheckInBooking.expectedCheckoutLocalTime = this.booking.expectedCheckoutLocalTime;

        //this.preCheckInBooking.travelForBusiness = this.booking.businessTrip;


        this.corporate = res.corporate;
        this.property = res.property;


        let bookletLanguage = this.urlLang;
        if (bookletLanguage == null) {
          this.urlLang = this.translateService.getBrowserLang();
          if (this.booking && this.booking.guests != null && this.booking.guests.length > 0
            && this.booking.guests[0].languages != null && this.booking.guests[0].languages.length > 0) {
            bookletLanguage = this.booking.guests[0].languages[0];
          }
          if (this.booking == null) {
            bookletLanguage = this.translateService.getBrowserLang();
          }
        }

        bookletLanguage = UtilsService.isLanguageSupported(bookletLanguage);
        this.languageService.setLanguageInUrl(bookletLanguage);
        this.translateService.use(bookletLanguage);
      });
    });
  }


  addChild() {
    if (this.property.personCapacity > 0) {
      if (this.preCheckInBooking.adults + this.preCheckInBooking.children < this.property.personCapacity) {
        this.preCheckInBooking.children++;
      }
    } else {
      this.preCheckInBooking.children++;
    }

  }

  addAdults() {
    if (this.property.personCapacity > 0) {
      if (this.preCheckInBooking.adults + this.preCheckInBooking.children < this.property.personCapacity) {
        this.preCheckInBooking.adults++;
      }
    } else {
      this.preCheckInBooking.adults++;
    }

  }

  add(type: string) {
    if (type === 'double' && this.booking.doubleBeds<this.property.doubleBeds) {
      this.booking.doubleBeds++;
    }
    if (type === 'single' && this.booking.singleBeds<this.property.singleBeds) {
      this.booking.singleBeds++;
    }
    if (type === 'sofa' && this.booking.sofaBeds<this.property.sofaBeds) {
      this.booking.sofaBeds++;
    }
    if (type === 'parkings' && this.booking.parkings<this.property.parkings) {
      this.booking.parkings++;
    }
    if (type === 'crib' && this.booking.babyBeds<this.property.babyBeds) {
      this.booking.babyBeds++;
    }
  }

  remove(type: string) {
    if (type === 'double' && this.booking.doubleBeds > 0) {
      this.booking.doubleBeds--;
    }
    if (type === 'single' && this.booking.singleBeds > 0) {
      this.booking.singleBeds--;
    }
    if (type === 'parkings' && this.booking.parkings > 0) {
      this.booking.parkings--;
    }
    if (type === 'sofa' && this.booking.sofaBeds > 0) {
      this.booking.sofaBeds--;
    }
    if (type === 'crib' && this.booking.babyBeds > 0) {
      this.booking.babyBeds--;
    }

  }


  checkDisableBtnDecre(value : number) {
    if (value<1 ) {
      return 'primary-not-valid';
    }
  }

  removeAdults() {
    if (this.preCheckInBooking.adults > 0) {
      this.preCheckInBooking.adults--;
    }
  }
  removeChild() {
    if (this.preCheckInBooking.children > 0) {
      this.preCheckInBooking.children--;
    }
  }

  saveBookingInfo() {

    console.log("saveBookings");
    // Check the Time ...
    if (this.property.defaultCheckInTime && !this.property.canEarlyCheck) {
      if (moment(this.preCheckInBooking.expectedCheckinLocalTime, 'hh:mm').isBefore(moment(this.property.defaultCheckInTime, 'hh:mm'))) {
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('online-check.impossible-checkin-time') + ' '
          + this.property.defaultCheckInTime, 'error'));
        console.log('error');
        return;
      }
    }
    if (this.property.defaultCheckOutTime && !this.property.canEarlyCheck) {
      if (moment(this.preCheckInBooking.expectedCheckoutLocalTime, 'hh:mm').isAfter(moment(this.property.defaultCheckOutTime, 'hh:mm'))) {
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('online-check.impossible-checkout-time') + ' '
          + this.property.defaultCheckOutTime, 'error'));
        console.log('error');
        return;
      }
    }

    this.snackbarService.dismiss();
    this.loaderService.showFullLoader('')
    this.guestService.saveBooking(this.booking.id, this.preCheckInBooking).pipe(takeUntil(this.onDestroy)).subscribe( res => {
      this.loaderService.dismissLoader();
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('guest-setup-page.guest_success_message'), 'info'));
      console.log('pas derror');
    }, err => {
      this.loaderService.dismissLoader();
      console.log('ERROR', err);
    });
  }




}
