import { Subject } from 'rxjs';
import { LoaderType } from "../../models/LoaderType";
import * as i0 from "@angular/core";
export class Yaago2LoaderService {
    constructor() {
        this.showLoaderSubjectLoader2 = new Subject();
        this.showLoaderEvent$ = this.showLoaderSubjectLoader2.asObservable();
        this.dismissLoaderSubjectLoader2 = new Subject();
        this.dismissLoaderEvent$ = this.dismissLoaderSubjectLoader2.asObservable();
    }
    openLoader(message) {
        this.showLoaderSubjectLoader2.next(new LoaderType(Yaago2LoaderService.FULL, message));
    }
    closeLoader() {
        this.dismissLoaderSubjectLoader2.next();
    }
}
Yaago2LoaderService.FULL = 'full';
Yaago2LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Yaago2LoaderService_Factory() { return new Yaago2LoaderService(); }, token: Yaago2LoaderService, providedIn: "root" });
