import { Subject } from 'rxjs';
import { LoaderType } from "../../models/LoaderType";
import * as i0 from "@angular/core";
import * as i1 from "./Yaago2-LoaderService";
export class LoaderService {
    constructor(yaago2LoaderService) {
        this.yaago2LoaderService = yaago2LoaderService;
        this.showLoaderSubject = new Subject();
        this.showLoaderEvent$ = this.showLoaderSubject.asObservable();
        this.dismissLoaderSubject = new Subject();
        this.dismissLoaderEvent$ = this.dismissLoaderSubject.asObservable();
    }
    showFullLoader(message) {
        this.showLoaderSubject.next(new LoaderType(LoaderService.FULL, message));
    }
    dismissLoader() {
        this.dismissLoaderSubject.next();
    }
}
LoaderService.FULL = 'full';
LoaderService.BAR = 'bar';
LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(i0.ɵɵinject(i1.Yaago2LoaderService)); }, token: LoaderService, providedIn: "root" });
