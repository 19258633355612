import { NgModule } from '@angular/core';
import { MiscRoutingModule } from './misc-routing.module';
import { GuestLostComponent } from './guest-lost/guest-lost.component';
import {SharedModule} from '../../shared/shared.module';
import { RecoWidgetComponent } from './reco-widget/reco-widget.component';
import {MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSliderModule} from '@angular/material';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SharedPipesModule} from '../../pipes/shared-pipes.module';
import { PropertyGenericComponent } from './property-generic/property-generic.component';
import { PropertyGenericConfirmationComponent } from './property-generic-confirmation/property-generic-confirmation.component';


@NgModule({
    declarations: [GuestLostComponent, RecoWidgetComponent, PropertyGenericComponent, PropertyGenericConfirmationComponent],
    exports: [
    ],
    imports: [
        SharedModule,
        MiscRoutingModule,
        MatFormFieldModule,
        MatIconModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        SharedPipesModule
    ]
})
export class MiscModule { }
