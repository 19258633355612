import {Component, Input, OnInit} from '@angular/core';
import {BookingForGuest, CheckNote} from '../../../../models/guestview/BookingForGuest';
import {Corporate} from '../../../../models/guestview/Corporate';
import {PropertyForGuest} from '../../../../models/guestview/PropertyForGuest';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../../core/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {GuestService} from '../../../../core/services/guest.service';
import {LoaderService} from '../../../../core/services/LoaderService';
import {ApiService} from '../../../../core/http/api.service';
import {Location} from '@angular/common';
import {AbstractWindow} from '../../../../core/abstract/AbstractWindow';
import {YaagoDocument} from '../../../../models/YaagoDocument';
import {SnackbarMessage} from '../../../../shared/components/shared-snackbar/models/snackbar-message';
import {DocumentService} from '../../../../core/services/DocumentService';
import {SharedSnackbarService} from '../../../../shared/components/shared-snackbar/services/shared-snackbar.service';

@Component({
  selector: 'app-m101-inventory-item',
  templateUrl: './m101-inventory-item.component.html',
  styleUrls: ['./m101-inventory-item.component.scss']
})
export class M101InventoryItemComponent extends AbstractWindow implements OnInit {

  @Input() inventory: CheckNote;
  corporate: Corporate;
  property: PropertyForGuest;
  booking: BookingForGuest;
  @Input() type: string; // checkout or checkin
  constructor(private activatedRoute: ActivatedRoute,
              public utilService: UtilsService,
              public translateService: TranslateService,
              private guestService: GuestService,
              private loaderService: LoaderService,
              private apiService: ApiService,
              private documentService: DocumentService,
              private snackbarService: SharedSnackbarService,
              private location: Location) {
    super(utilService);
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      this.loaderService.dismissLoader();
      if (res && res.propertyAndBooking) {
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        //this.utilService.manageCorporateColors(this.corporate);
        this.booking = res.propertyAndBooking.booking;
      }
    });
  }

  onPicturesUpload(doc: YaagoDocument) {
    if (!this.inventory.pictures) { this.inventory.pictures = []; }
    this.inventory.pictures.push(doc.url);
  }

  onVideosUpload(doc: YaagoDocument) {
    if (!this.inventory.videos) { this.inventory.videos = []; }
    this.inventory.videos.push(doc.url);
  }
  deleteVideo(i) {
    this.documentService.deleteDocument(this.inventory.videos[i]).subscribe(p => {
      this.inventory.videos.splice(i, 1);
    });
  }

  deletePicture(i) {
    // console.log("Picture number", i);
    // console.log("Picture number", this.inventory.pictures[i]);
    // console.log("Pictures", this.inventory.pictures);

    this.documentService.deleteDocument(this.inventory.pictures[i]).subscribe(p => {
      this.inventory.pictures.splice(i, 1);
    });

  }

  validate() {
    this.loaderService.showFullLoader('');
    this.apiService.guestInventoryCheck(this.type, this.inventory, this.booking.id).subscribe(p => {
      this.loaderService.dismissLoader();
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('guest-inventory.success'), 'info'));
    }, err => {
      this.loaderService.dismissLoader();
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('guest-inventory.error'), 'error'));
    });
  }

}
