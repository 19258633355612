/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./audio-play.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./audio-play.component";
var styles_AudioPlayComponent = [i0.styles];
var RenderType_AudioPlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AudioPlayComponent, data: {} });
export { RenderType_AudioPlayComponent as RenderType_AudioPlayComponent };
function View_AudioPlayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "audio-button"], ["src", "./assets/icon/audio/play-button.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.playText() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_AudioPlayComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "audio-button margin-left-8"], ["src", "./assets/icon/audio/pause.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pauseText() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_AudioPlayComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "audio-button margin-left-8"], ["src", "./assets/icon/audio/continuous.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resumeText() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_AudioPlayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "secondary-button"], ["style", "width: fit-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "flex flex-row"], ["style", "line-height: 28px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["style", "padding-right: 8px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AudioPlayComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AudioPlayComponent_3)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AudioPlayComponent_4)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isPlay; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.isPlay; _ck(_v, 8, 0, currVal_2); var currVal_3 = (!_co.isPlay && _co.isPause); _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("audio-component.listen")); _ck(_v, 3, 0, currVal_0); }); }
export function View_AudioPlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AudioPlayComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.textToSpeak && (_co.textToSpeak !== "")); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AudioPlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-audio-play", [], null, null, null, View_AudioPlayComponent_0, RenderType_AudioPlayComponent)), i1.ɵdid(1, 114688, null, 0, i4.AudioPlayComponent, [i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AudioPlayComponentNgFactory = i1.ɵccf("app-audio-play", i4.AudioPlayComponent, View_AudioPlayComponent_Host_0, { textToSpeak: "textToSpeak" }, {}, []);
export { AudioPlayComponentNgFactory as AudioPlayComponentNgFactory };
