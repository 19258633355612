import {Guide} from '../Guide';

export class GuestUpSellOnBoarding {
  propertyBookingId: string;
  guides: Guide[];
  language: string;
  qty: number;
  time: string;
  returnUrl: string;
}
