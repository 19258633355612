<div style=" position: relative; overflow: auto; background: white; height: 100%" class="y3-full-width">
  <div class="m21-page-container">
    <img *ngIf="currentPictureUrl == null" class="m21-image-container" src="{{recommendation.mainPhoto}}">
    <img *ngIf="currentPictureUrl != null" class="m21-image-container" src="{{currentPictureUrl}}">
    <div class="m21-return-container">
      <div class="y3-flex-row y3-flex-align-center y3-flex-justify-content-center" >
        <div class="m21-mat" (click)="back()">arrow_back</div>
      </div>
    </div>

    <div class="m21-card-container">
      <div class="m21-card-content">

        <div class="y3-bold y3-Large">{{recommendation.title}}</div>
        <div class="y3-flex-row y3-flex-justify-space-between y3-flex-align-center y3-margin-top-4">
          <div class="y3-flex-column">
            <div class="y3-X-small">{{recommendation.fullAddress}}</div>
            <div class="y3-X-small">{{recommendation.phoneNumber}}</div>
            <div class="y3-flex-row y3-flex-align-center y3-margin-top-8 ">
              <div *ngIf="recommendation.webSite" class="y3-margin-right-8">
                <a [href]="recommendation.webSite"><img class="m21-icon-social" src="./assets/icon/icon_world.svg" alt="Description of the image"></a>
              </div>
              <div *ngIf="recommendation.facebook" class="y3-margin-right-8">
                <a [href]="recommendation.facebook"><img class="m21-icon-social" src="./assets/icon/facebook-dark-bleu.svg" alt="Description of the image"></a>
              </div>
              <div *ngIf="recommendation.instagram" class="y3-margin-right-8">
                <a [href]="recommendation.instagram"><img class="m21-icon-social" src="./assets/icon/instagram-dark-bleu.svg" alt="Description of the image"></a>
              </div>
            </div>
          </div>

          <div class="y3-X-small">({{recommendation.distance}} km)</div>
        </div>

        <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between">
          <section id="SECTION_____PROFIL_HOST">
            <div class="y3-flex-row y3-flex-align-center y3-margin-top-16">
              <img class="m21-profile-container" *ngIf="recommendation?.comment?.pictureUrl" src="{{recommendation?.comment?.pictureUrl}}" alt="picture">
              <span *ngIf="!recommendation?.comment?.pictureUrl" class="roboto Large picture-placeholder">
                  {{recommendation?.comment.name.charAt(0).toUpperCase()}}
              </span>

              <div class="y3-margin-left-8 y3-flex-column">
                <span class="y3-Small y3-bold txt-color-black ">{{recommendation?.comment?.name | titlecase}}</span>
                <span class="y3-X-small txt-color-grvogue">{{'guest-recommendation.card.' + recommendation.comment.type | translate}}</span>
              </div>
            </div>
          </section>

          <section id="SECTION_____HOST__RATINGS">
            <div class="y3-flex-row y3-flex-align-center">

              <div class="y3-Small txt-color-grvogue y3-margin-right-8" *ngIf="recommendation.priceRanking.rating !== 1 && recommendation.priceRanking.icons?.length > 0">
              {{'guest-recommendation.price-rate' | translate}}
                {{'guest-recommendation.' + recommendation.priceRanking.label | translate}}
              </div>
              <div class="flex flex-row align-items-center icon-coin" *ngIf="recommendation.priceRanking.icons?.length > 0">
                <img class="m21-rate-icon"  src="/{{recommendation.priceRanking.icons[0].url}}" alt="">
              </div>
            </div>

            <!-- Rate Bloc-->
            <div class="y3-flex-row y3-flex-align-center y3-flex-justify-content-end" *ngIf="recommendation.ranking.icons?.length>0 && recommendation.ranking.rating > 0">
              <div class="y3-Small txt-color-grvogue y3-bold y3-margin-right-8" >{{recommendation.ranking.rating.toFixed(1)}}</div>
              <div class="y3-flex-row y3-flex-align-center">
                <img class="m21-rate-icon" src="/{{recommendation.ranking.icons[0].url}}" alt="">
              </div>

            </div>
          </section>
        </div>

        <div style="height: 100px;">
          <div class="y3-Small y3-color-scorpion y3-margin-top-16 m21-guide-description" [innerHTML]="recommendation.comment.commentLong"></div>
        </div>

        <section id="SECTION____GALERIE____IMAGE" *ngIf="recommendation.photos != null && recommendation.photos?.length > 0" class="y3-margin-top-16 y3-margin-right-24">
          <div class="y3-flex-row y3-flex-align-center">
            <div class="y3-bold y3-Small y3-margin-right-4">More Picture(s) </div>
            <div>
              <app-tag [padding]="'8px'" [sizeText2]="'12px'" text="+{{recommendation.photos.length}}" [type]="'blue'"></app-tag>
            </div>
          </div>
          <perfect-scrollbar class="y3-flex-row y3-margin-bottom-4" [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
            <div class="y3-flex-row y3-flex-align-center y3-margin-top-16">
              <div *ngFor="let image of recommendation.photos" >
                <img (click)="choosePicture(image)" class="m21-image-size y3-clickable" src="{{image}}" >
              </div>
            </div>
          </perfect-scrollbar>
        </section>

        <div  class="y3-margin-top-16 y3-margin-bottom-16">
          <app-button [text]="'guest-recommendation.card.go-to-place' | translate"
                      [type]="'secondary'"
                      (debounceClick)="openDirection(property, recommendation)"
          ></app-button>
        </div>


      </div>
    </div>
  </div>

</div>
