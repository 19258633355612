<div class="map-mobile">

  <div class="map-wrapper" id="map-container">

    <div class="location-search-container">

      <div class="flex-row flex-align-center">

        <div class="flex-row flex-align-center margin-right-24 clickable" (click)="closeMap()">
          <mat-icon style="font-size: 30px; height: unset" class="y3-color-desktop-bandeau-font">chevron_left</mat-icon>
          <!--<img src="/assets/icon/icon-arrow-left.svg" alt="left" class="margin-right-8">-->
          <div class="roboto bold Small y3-color-desktop-bandeau-font">{{'guest-recommendation.return' | translate}}</div>
        </div>
        <div class="flex-row flex-align-center location-search">

          <img src="/assets/icon/search.svg" alt="search" class="margin-right-8 ">
          <input  id="fullAddress" type="text" placeholder="{{'guest-recommendation.indicate-place' | translate}}" class="width-75 padding-bottom-12 padding-top-12">
        </div>
      </div>

      <!--<div id="filterContainer" class="filter-placeholder clickable" (click)="showHideFilters()">
        <img id="filterPicture" src="/assets/icon/filter.svg" alt="filter-icon" class="margin-right-16">
        <span id="filterLabel" class="roboto bold Medium txt-color-grvogue" >{{'guest-recommendation.filters' | translate}}</span>
        <span class="filters-badge" *ngIf="selectedCategory && selectedCategory !== 'all-categories'"><span>•</span></span>
      </div>-->

    </div>

    <app-leaflet-map
      [mapId]="'mapRecoMobile'"
      [centerOfTheMap]="centerOfTheMap"
      [corporate]="corporate"
      [propertyPoi]="propertyPoi"
      [whereIamPoi]="whereIamPoi"
      [properties]="propertiesForMap"
      [language]="language"
      [showAllProperties]="true"
      [showRecoCorporate]="true"
      [showProperty]="true"
      [showCommunityReco]="showCommunityReco"
      [showRecoYaago]="showRecoYaago"
      [showRecoInfluencer]="showRecoInfluencer"
      [showRecoTourism]="true"
      [bookingId]="booking?.id"
      [bookingStart]="booking?.startDate"
      [bookingEnd]="booking?.endDate"
      [showOwner]="showOwner"
      [showFilterReco]="true"
      [property]="property"
      [recapVersion]="recapVersion"
      [showZoomControl]="showZoomControl"
      [showChecks]="showRecoYaago"
      [relatedPoiForDistanceCalculation]="centerOfTheMap"
    ></app-leaflet-map>


  </div>

  <app-guest-recommendation-v2-mini-card></app-guest-recommendation-v2-mini-card>
  <app-guest-recommendations-card [bookingId]="booking?.id"></app-guest-recommendations-card>
  <app-guest-recommendations-reviews></app-guest-recommendations-reviews>

</div>
