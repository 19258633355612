/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-host-services.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./guest-host-services.component";
var styles_GuestHostServicesComponent = [i0.styles];
var RenderType_GuestHostServicesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestHostServicesComponent, data: {} });
export { RenderType_GuestHostServicesComponent as RenderType_GuestHostServicesComponent };
export function View_GuestHostServicesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["guest-host-services works!"]))], null, null); }
export function View_GuestHostServicesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-host-services", [], null, null, null, View_GuestHostServicesComponent_0, RenderType_GuestHostServicesComponent)), i1.ɵdid(1, 114688, null, 0, i2.GuestHostServicesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestHostServicesComponentNgFactory = i1.ɵccf("app-guest-host-services", i2.GuestHostServicesComponent, View_GuestHostServicesComponent_Host_0, {}, {}, []);
export { GuestHostServicesComponentNgFactory as GuestHostServicesComponentNgFactory };
