import { element } from 'protractor';
import { EventClickCategory } from 'src/app/models/profiling/EventClickCategory';
import { GuestCategory } from 'src/app/models/profiling/GuestCategory';
import { GuestReviews } from 'src/app/models/profiling/GuestReviews';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { EventClickRecommendation } from 'src/app/models/profiling/EventClickRecommendation';
import { GuestTag } from 'src/app/models/profiling/GuestTag';
import { EventClickTag } from 'src/app/models/profiling/EventClickTag';
import { ConfService } from './conf.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CollectDataServiceService {

  private EndpointDataCollect =this.confService.getBackendApiEndPoint() + 'api-v2/guest/profiling'
  private RecommendationUrl: string=this.EndpointDataCollect+"/recommendation"
  private CategorieUrl : string =this.EndpointDataCollect+"/category"
  private TagUrl : string = this.EndpointDataCollect+"/tag"
  private  eventClickRecommendation: EventClickRecommendation;
  private  eventClickCategory: EventClickCategory;
  private  eventClickTag: EventClickTag;
  private  guestReviews: GuestReviews;
  private  guestCategory: GuestCategory;
  private  guestTag: GuestTag;
  constructor(
    private http: HttpClient,
    private confService: ConfService,
    ) { }


  createProfileReview(guestId : string , recommendationId : string ){
    this.eventClickRecommendation={
      guestId:guestId,
      recommendationId:recommendationId,
    }
    this.guestReviews={
      eventClick: this.eventClickRecommendation,
      nbClickRecoCard: 0,
      nbClickRecoMarker:0,
      nbClickRecoWebSite:0,
      nbClickRecoDirection:0,
      clickOnSliderPictures: false,
      isLike: false
    }
  }

  createProfileCategorie(guestId : string , category : string){
      this.eventClickCategory={
        guestId:guestId,
        category:category
      }
      this.guestCategory={
        eventClickCategory: this.eventClickCategory,
        nbClickCategory:0
      }
  }

  createProfileTag(guestId: string , tagId: string){
    this.eventClickTag={
      guestId: guestId,
      tagId: tagId
    }
    this.guestTag={
      eventClickTag: this.eventClickTag,
      nbClickTag:0
    }
  }




    // If profile click on Tag in Categorie
    setOnEventClickTag(guestId : string , tagId : string){
      this.createProfileTag(guestId , tagId)
      return this.http.post<any>(this.TagUrl , this.guestTag).toPromise().then((data:any) => {
        console.log(data);
      });
    }





  // If profile click on Categories :
  setOnEventClickCategory(guestId : string , categorie_id : string){
    this.createProfileCategorie(guestId , categorie_id)
    return this.http.post<any>(this.CategorieUrl , this.guestCategory ).toPromise().then((data:any) => {
      console.log(data);
    });
  }
  // IF profile click on Marker in Map
  setOnClickMarker(guestId: string , recommendation_id: string) {
    this.createProfileReview(guestId , recommendation_id);
    return this.http.post<any>(this.RecommendationUrl  + "/RecommendationMarker" , this.guestReviews).toPromise().then((data:any) => {
      console.log(data);
      this.guestReviews = data;
    });
  }

  setOnClickVisteWebSite(guestId : string , recommendation_id : string){
    this.createProfileReview(guestId , recommendation_id);
    return this.http.post<any>(this.RecommendationUrl  + "/RecommendationWebsite" , this.guestReviews).toPromise().then((data:any) => {
      console.log(data);

    });
  }



  setOnClickSyRendre(guestId : string , recommendation_id : string){
    this.createProfileReview(guestId , recommendation_id);
    return this.http.post<any>(this.RecommendationUrl  + "/RecommendationDirection" , this.guestReviews).toPromise().then((data:any) => {
      console.log(data);
    });
  }


  setOnClickNextInPhoto(guestId : string, reccommendation_id: string){
    this.createProfileReview(guestId , reccommendation_id );
    return this.http.post<any>(this.RecommendationUrl  + "/RecommendationSlider" , this.guestReviews).toPromise().then((data:any) => {
      console.log(data);
    });
  }

  setOnClickItemInRecomendation(guestId : string , recommendation_id : string ){
    this.createProfileReview(guestId , recommendation_id);
    return this.http.post<any>( this.RecommendationUrl  + "/RecommendationCard" , this.guestReviews).toPromise().then((data:any) => {
      console.log(data);
    });
  }





}
