<div>
  <perfect-scrollbar [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
    <div class="y3-flex-row ">
      <div *ngFor="let tab of tabs" (click)="onClick(tab)"  class="y3-clickable" style="margin-bottom: 16px">
        <div class="local-menu-tab" [ngClass]="{'selected' : tab === selectedTab}">
          <div class="local-menu-tab-text">{{tab.title |translate | titlecase}}</div>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>
