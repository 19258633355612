<section id="BOOKLET-REVIEWS" class="booklet-bk-color">
  <div class="y2021-guest-section " >

    <div class="y3-padding-top-24 y3-margin-bottom-16">
      <mat-icon class="material-symbols-outlined y3-steph-title-dot">storefront</mat-icon>
      <div class="y3-steph-title">{{'booklet-review.avis' | translate}}<span class="y3-steph-title-dot">.</span></div>
      <div class="y3-Small y3-margin-top-4 y3-steph-color-text y3-sub-title-colors">{{'booklet-review.avis-subtitle' | translate}}</div>
    </div>

    <!--<div class="y2021-h3 y3-title-colors">{{'booklet-review.avis' | translate}}</div>
    <div class="y2021-h3-subtitle y3-sub-title-colors">{{'booklet-review.avis-subtitle' | translate}} </div>-->

    <div class="range-box margin-top-16">
      <app-star-rating class="stars" [(rating)]="rating"></app-star-rating>
      <div class="value">{{rating}}</div>
    </div>

    <div class="y2021-flexrow margin-top-16 div-button">
      <div class="textarea-for-review">
        <textarea style="width: 100%" rows="10" [(ngModel)]="textReview"></textarea>
      </div>

      <app-button [type]="'steph-button-blue'" text="{{'booklet-review.avis-submit' | translate}}" (debounceClick)="saveReview()"></app-button>

    </div>

  </div>
</section>
