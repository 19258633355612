/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tag2.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tag2.component";
var styles_Tag2Component = [i0.styles];
var RenderType_Tag2Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Tag2Component, data: {} });
export { RenderType_Tag2Component as RenderType_Tag2Component };
function View_Tag2Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "margin-left-8"]], [[8, "alt", 0], [8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.status; var currVal_1 = _co.tagStatusImage; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_Tag2Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "tag"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Tag2Component_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tag"; var currVal_1 = _co.type; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.status; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.text; _ck(_v, 4, 0, currVal_2); }); }
export function View_Tag2Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tag2", [], null, null, null, View_Tag2Component_0, RenderType_Tag2Component)), i1.ɵdid(1, 638976, null, 0, i3.Tag2Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Tag2ComponentNgFactory = i1.ɵccf("app-tag2", i3.Tag2Component, View_Tag2Component_Host_0, { type: "type", status: "status", text: "text" }, {}, []);
export { Tag2ComponentNgFactory as Tag2ComponentNgFactory };
