<!--<div id="map" class="map" [ngStyle]="{height: doWeOpenRecommendationCard ? '0px': '100%'}">-->
<div id="{{mapId}}" class="map" >
  <div *ngIf="showFilterReco" class="filters-container clickable">
    <div id="filterContainer" class="filter-placeholder " (click)="showHideFilters()">
      <img id="filterPicture" src="/assets/icon/filter.svg" alt="filter-icon">
      <span class="filters-badge" *ngIf="selectedCategory && selectedCategory !== 'all-categories'"><span>•</span></span>
    </div>

    <div class="all-filters flex flex-row padding-16" *ngIf="showFilters">
      <!--CATEGORY & TAGS  -->

      <div class="flex flex-column  filters-left-container">
        <div style="max-height: 300px;">
          <mat-form-field class="margin-top-10" >
            <mat-select class="select-category" panelClass="yaago-myclass2" [placeholder]="'guest-recommendation.choose-category' |translate" [ngModelOptions]="{standalone: true}" [ngModel]="selectedCategory" (ngModelChange)="categoryChanged($event)"  >

              <mat-select-trigger>
                <div class="flex-row flex-align-center">
                  <img *ngIf="selectedCategory !== 'all-categories'" class="margin-right-12" src="{{'assets/icon/recommendation/icon-category-colored-' + selectedCategory + '.svg'}}" alt="picto">
                  <div class="select-category"> {{('recommendation.'+ selectedCategory ) | translate}}</div>
                </div>
              </mat-select-trigger>
              <mat-option  [value]="'all-categories'" style="display: flex; flex-flow: row;align-items: center">
                {{'guest-recommendation.all-categories' | translate}}
              </mat-option>
              <mat-option *ngFor="let cat of categories"
                          [value]="cat.key"
                          style="display: flex; flex-flow: row;align-items: center"
                          >
                <div class="flex-row flex-align-center">
                  <img *ngIf="cat.key != 'all'" class="img-small-category margin-right-12" src="{{'assets/icon/recommendation/icon-category-colored-' + cat.key + '.svg'}}">
                  <div class="select-category">{{('recommendation.'+ cat.key) | translate}}</div>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex-row flex-wrap overflow-x-auto grey-scroll">
          <div class="flex-row flex-align-center margin-bottom-8 margin-right-8 tag-element clickable {{isTagSelected(tag.id) ? 'selected-tag' : ''}}"
               *ngFor="let tag of categoryTags; let i = index" (click)="chooseTag(tag.id)" >
            <span class="mcqueen Small txt-color-black">#{{getTagName(tag.id)}}</span>
          </div>
        </div>
      </div>

      <!--CHECKBOXES-->
      <div class="flex flex-column padding-left-24" *ngIf="showChecks">
        <div class="choice-container" >
          <mat-checkbox  [(ngModel)]="showRecoCorporate"  (ngModelChange)="loadReco()"></mat-checkbox>
          <span class="roboto Small txt-color-grvogue margin-left-16">{{'guest-recommendation.my-host-addresses' | translate}}</span>
        </div>
        <div class="choice-container community-line" >
          <mat-checkbox  [(ngModel)]="showCommunityReco" (ngModelChange)="loadReco()"></mat-checkbox>
          <span class="roboto Small txt-color-grvogue margin-left-16">{{'guest-recommendation.community-addresses' | translate}}</span>
        </div>
        <div class="choice-container community-line" >
          <mat-checkbox  [(ngModel)]="showRecoYaago" (ngModelChange)="loadReco()"></mat-checkbox>
          <span class="roboto Small txt-color-grvogue margin-left-16">{{'guest-recommendation.yaago-addresses' | translate}}</span>
        </div>
        <div class="choice-container community-line" >
          <mat-checkbox  [(ngModel)]="showRecoInfluencer" (ngModelChange)="loadReco()"></mat-checkbox>
          <span class="roboto Small txt-color-grvogue margin-left-16">{{'guest-recommendation.influencer-addresses' | translate}}</span>
        </div>
      </div>
    </div>

  </div>



  <div class="footer-container">
    Powered by Yaago.com
  </div>

</div>



<div class="reco-card" *ngIf="doWeOpenRecommendationCard && recapVersion === 'v2'">

  <div class="flex flex-row nowrap"  style="height: 100%">
    <div  class="{{mustHide() ? 'width-0' : 'width-50'}}">
      <div class="top-image-container">
        <img class="mainPhotoLandingPage" *ngIf="currentRecommendation.mainPhoto" [src]="currentRecommendation.mainPhoto" alt="mainPhoto">
        <img class="mainPhotoLandingPage" *ngIf="!currentRecommendation.mainPhoto" src="/assets/images/recommendation/no-image.png" alt="mainPhoto">

        <button *ngIf="_hasPrevious" appDebounceClick (debounceClick)="previous()" class="fab-button previous" mat-mini-fab color="primary" aria-label="Previous">
          <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
        <button *ngIf="_hasNext" appDebounceClick
                (debounceClick)="next()"
                class="fab-button next" mat-mini-fab color="primary" aria-label="Next">
          <mat-icon>arrow_forward_ios</mat-icon>
        </button>
      </div>

      <section *ngIf="isDesktopMode()">
        <div class="card-title-detail header1 truncate-text" >

          <div class="category bold ">
            {{currentRecommendation.title}}
          </div>
          <div class="margin-bottom-8" style="font-size: 10px">
            {{currentRecommendation.fullAddress}}
          </div>

          <div class="card-sub-title">

            <div class="category-container">
              <img class="img-small-category" src="{{currentRecommendation.category.mapIcon}}">
              <div class="category truncate-text">{{('guest-recommendation.' + currentRecommendation.category.key) | translate | titlecase}}</div>
            </div>

            <div class="distance roboto Small txt-color-grvogue" style="width: 150px; text-align: right">{{('guest-recommendation.card.at-distance1'| translate) + currentRecommendation?.distance + ('guest-recommendation.card.at-distance2'| translate)}}</div>
          </div>

          <div class="card-sub-title margin-top-8">

            <div class="category-container">
              <img class="img-small-category" *ngIf="currentRecommendation?.comment?.pictureUrl" src="{{currentRecommendation?.comment?.pictureUrl}}" alt="picture">
              <span *ngIf="!currentRecommendation?.comment?.pictureUrl" class="roboto Large picture-placeholder">
                {{getFirstLetter(currentRecommendation?.comment)}}
              </span>
              <div class="category truncate-text">{{currentRecommendation?.comment?.name | titlecase}} </div>
            </div>

            <div class="flex flex-row align-items-center flex-justify-end" *ngIf="currentRecommendation.ranking.rating > 0">
              <div class="flex align-items-center">
                <img class="rate-icon" src="./assets/icon/icon-rate-rating-card.svg" alt="">
              </div>
              <span class="roboto Small txt-color-grvogue bold margin-left-8" >{{currentRecommendation.ranking.rating.toFixed(1)}}</span>
            </div>


          </div>
        </div>
      </section>
    </div>

    <div class="{{mustHide() ? 'width-100' : 'width-50'}}" style=" overflow-y: auto; border-left: 1px gainsboro solid">
      <div class="close">
        <div class="icon" (click)="close()">
          <div class="icon-wrapper">
            <img src="../../../../../../assets/icon/recommendation/icon-close.svg" alt="">
          </div>
        </div>
      </div>


      <div class="card-title-detail margin-top-8">

        <div *ngIf="!isDesktopMode()">
          <div class="card-title-detail header1 truncate-text" >

            <div class="category bold ">
              {{currentRecommendation.title}}
            </div>
            <div class="margin-bottom-8" style="font-size: 10px">
              {{currentRecommendation.fullAddress}}
            </div>

            <div class="card-sub-title-mobile">

              <div class="category-container">
                <img class="img-small-category" src="{{currentRecommendation.category.mapIcon}}">
                <div class="category truncate-text">{{('guest-recommendation.' + currentRecommendation.category.key) | translate | titlecase}}</div>
              </div>

              <div class="distance roboto Small txt-color-grvogue" >{{('guest-recommendation.card.at-distance1'| translate) + currentRecommendation?.distance + ('guest-recommendation.card.at-distance2'| translate)}}</div>
            </div>

            <div class="card-sub-title-mobile margin-top-8">

              <div class="category-container">
                <img class="img-small-category" *ngIf="currentRecommendation?.comment?.pictureUrl" src="{{currentRecommendation?.comment?.pictureUrl}}" alt="picture">
                <span *ngIf="!currentRecommendation?.comment?.pictureUrl" class="roboto Large picture-placeholder">
                {{getFirstLetter(currentRecommendation?.comment)}}
              </span>
                <div class="category truncate-text">{{currentRecommendation?.comment?.name | titlecase}} </div>
              </div>

              <div class="flex flex-row align-items-center flex-justify-end" *ngIf="currentRecommendation.ranking.rating > 0">
                <div class="flex align-items-center">
                  <img class="rate-icon" src="./assets/icon/icon-rate-rating-card.svg" alt="">
                </div>
                <span class="roboto Small txt-color-grvogue bold margin-left-8" >{{currentRecommendation.ranking.rating.toFixed(1)}}</span>
              </div>


            </div>
          </div>

          <div class="flex-row align-items-center" *ngIf="currentRecommendation?.webSite">
            <div class="category bold">{{'widget-reco.website' | translate}}</div>
            <div>
              <a href="{{currentRecommendation.webSite}}" target="_blank" class="category truncate-text margin-left-8">{{currentRecommendation?.webSite}}</a>
            </div>
          </div>
          <div class="flex-row align-items-center" *ngIf="currentRecommendation?.phoneNumber">
            <div class="category bold">{{'widget-reco.phone' | translate}}</div>
            <div class="category truncate-text margin-left-8">{{currentRecommendation?.phoneNumber}}</div>
          </div>
          <div *ngIf="currentComments != null && currentComments.length > 0">
            <div class="category bold margin-top-8">{{'widget-reco.comment' | translate}}</div>
            <div class="category truncate-text margin-top-8">{{currentComments[0].comment}}</div>
          </div>

          <div *ngIf="mustHide() && currentRecommendation?.photos && currentRecommendation.photos.length > 0" class="width-100 flex flex-row wrap margin-top-8">
            <div *ngFor="let p of currentRecommendation.photos" class="miniature">
              <img src="{{p}}" alt="pic">
            </div>
          </div>
        </div>

      </div>




    </div>
  </div>
</div>


<!-- /////////////////////////////////// -->
<!-- /////////////////////////////////// -->
<!--          OLD RECOMMENDATION         -->
<!-- /////////////////////////////////// -->
<!-- /////////////////////////////////// -->

<div class="card" *ngIf="doWeOpenRecommendationCard && recapVersion === 'v1'">
  <div class="close">
    <div class="icon" (click)="close()">
      <div class="icon-wrapper">
        <img src="../../../../../../assets/icon/recommendation/icon-close.svg" alt="">
      </div>
    </div>
  </div>
  <div class="top-image-container">
    <img class="mainPhotoLandingPage" *ngIf="currentRecommendation.mainPhoto" [src]="currentRecommendation.mainPhoto" alt="mainPhoto">
    <img class="mainPhotoLandingPage" *ngIf="!currentRecommendation.mainPhoto" src="/assets/images/recommendation/no-image.png" alt="mainPhoto">
    <div class="recommendation-name">
      <span>{{currentRecommendation?.title}}</span>
    </div>
    <button *ngIf="_hasPrevious" appDebounceClick (debounceClick)="previous()" class="fab-button previous" mat-mini-fab color="primary" aria-label="Previous">
      <mat-icon>arrow_back_ios_new</mat-icon>
    </button>
    <button *ngIf="_hasNext" appDebounceClick
            (debounceClick)="next()"
            class="fab-button next" mat-mini-fab color="primary" aria-label="Next">
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
  </div>
  <div class="card-wrapper">

    <div class="card-body grey-scroll">
      <div class="flex flex-row flex-align-center flex-space-between">
        <!--Name Bloc-->
        <div class="flex flex-row flex-align-center" *ngIf="showOwner">
          <img class="profile-container" *ngIf="currentRecommendation?.comment?.pictureUrl" src="{{currentRecommendation?.comment?.pictureUrl}}" alt="picture">
          <span *ngIf="!currentRecommendation?.comment?.pictureUrl" class="roboto Large picture-placeholder">
              {{getFirstLetter(currentRecommendation?.comment)}}
          </span>
          <div class="margin-left-16 flex flex-column">
            <span class="mcqueen Small txt-color-black margin-bottom-4">{{currentRecommendation?.comment?.name | titlecase}}</span>
            <span class="mcqueen Small txt-color-grvogue">{{'guest-recommendation.card.' + currentRecommendation.comment.type | translate}}</span>
          </div>
        </div>

        <!--Category Bloc-->
        <div class="flex flex-column flex-justify-end">
          <div class="flex flex-row align-items-center flex-justify-end">
            <img src="{{currentRecommendation.category.mapIcon}}" alt="picto" class="pin-icon">
            <span class="mcqueen Medium txt-color-black margin-left-8 width-min-content">{{('guest-recommendation.' + currentRecommendation.category.key) | translate | titlecase}}</span>
          </div>
          <span class="roboto XX-small txt-color-scorpion margin-top-8" *ngIf="currentRecommendation.durationSinceCreation?.typeTime">
            {{'guest-recommendation.added-since' |translate}} {{currentRecommendation.durationSinceCreation.time}} {{'guest-recommendation.card.' + currentRecommendation.durationSinceCreation.typeTime | translate}}
          </span>
        </div>
      </div>

      <div class="flex margin-top-16" style="flex-flow: column" *ngIf="currentRecommendation?.influencerDetails != null">
        <div *ngIf="currentRecommendation?.influencerDetails?.bio" class="mcqueen Small txt-color-grvogue">Bio: {{currentRecommendation?.influencerDetails?.bio}}</div>

        <div *ngIf="currentRecommendation?.influencerDetails?.socialMediaUrl && currentRecommendation?.influencerDetails?.instaAccount == null" class="mcqueen Small txt-color-grvogue">SocialMedia Url: <a [href]="currentRecommendation.influencerDetails.socialMediaUrl">{{currentRecommendation.influencerDetails.socialMediaUrl}}</a> </div>
        <div *ngIf="currentRecommendation?.influencerDetails?.socialMediaUrl && currentRecommendation?.influencerDetails?.instaAccount != null" class="mcqueen Small txt-color-grvogue">@<a [href]="currentRecommendation.influencerDetails.socialMediaUrl">{{currentRecommendation.influencerDetails.instaAccount}}</a> </div>


        <div *ngIf="currentRecommendation?.influencerDetails?.blogUrl" class="mcqueen Small txt-color-grvogue">Blog : <a [href]="currentRecommendation.influencerDetails.blogUrl">{{currentRecommendation.influencerDetails.blogUrl}}</a> </div>
      </div>

      <div *ngIf="currentRecommendation.category?.key === 'events'"  class="margin-top-24 flex-row flex-justify-center flex-align-center mcqueen Small txt-color-scorpion">
        <span class="bold">{{'recommendation.from' | translate}}</span>
        <span class="margin-left-8">{{currentRecommendation.startDate | moment : language:'DD/MM/YY - hh:mm'}}</span>
        <span class="margin-left-8 bold">{{'recommendation.to' | translate}}</span>
        <span class="margin-left-8">{{currentRecommendation.endDate | moment : language:'DD/MM/YY - hh:mm'}}</span>
      </div>
      <!--Ratings -->
      <div class="flex flex-row flex-space-between margin-top-32">
        <!-- Price Bloc-->
        <div class="flex flex-row align-items-center">
          <div class="flex flex-row align-items-center icon-coin" *ngFor="let rate of currentRecommendation.priceRanking.icons">
            <img class="rate-icon" [ngStyle]="{'width':currentRecommendation.priceRanking.rating === 1 ? '50px' : '24px', 'height':currentRecommendation.priceRanking?.rating === 1 ? '50px' : '24px'}"
                 src="/{{rate.url}}" alt="">
          </div>
          <span class="roboto bold Small txt-color-grvogue" *ngIf="currentRecommendation.priceRanking.rating !== 1">
              {{'guest-recommendation.price-rate' | translate}}
            {{'guest-recommendation.' + currentRecommendation.priceRanking.label | translate}}
            </span>
        </div>

        <!-- Rate Bloc-->
        <div class="flex flex-row align-items-center flex-justify-end">
          <div class="flex align-items-center">
            <img *ngFor="let rate of currentRecommendation.ranking.icons"
                 [ngStyle]="{'opacity': rate.value <= currentRecommendation.ranking.rating ? '1' : '0.4'}" class="rate-icon"
                 src="/{{rate.url}}" alt="">
          </div>
          <span class="roboto Small txt-color-grvogue bold margin-left-8" >{{currentRecommendation.ranking.rating.toFixed(1)}}</span>
        </div>
      </div>

      <!--Avis-->
      <!--<div class="flex flex-row flex-space-between margin-top-32">
        <H5 class="mcqueen" id="avisSection" *ngIf="!isLoadingReviews">{{'guest-recommendation.avis' | translate}}</H5>
        <span class="roboto Small bold underline txt-color-grvogue flex flex-justify-end clickable"
              *ngIf="!isLoadingReviews && currentRecommendation.reviews && currentRecommendation.reviews.length > 1"
              (click)="showReviews(currentRecommendation.reviews)">
          {{'guest-recommendation.card.see-reviews'| translate}} ({{currentRecommendation.reviews.length}})
        </span>
      </div>-->

      <div *ngIf="isLoadingReviews" class="width-100 flex flex-row flex-justify-center">

      </div>
      <div class="roboto Medium txt-color-scorpion width-100 margin-top-16" *ngIf="!isLoadingReviews">
        {{currentRecommendation?.comment?.comment }}
      </div>
      <span class="roboto Small bold underline txt-color-grvogue flex flex-justify-end clickable"
            *ngIf="currentRecommendation.comment?.commentShort && !isLoadingReviews"
            (click)="showMoreLessComment()">
          {{(readMore ? 'guest-recommendation.card.btn-readLess' :'guest-recommendation.card.btn-readMore') | translate }}
      </span>


      <div class="flex flex-row flex-space-between align-items-center margin-top-32" *ngIf="currentRecommendation?.fullAddress">
        <div class="flex flex-row align-items-center adress-container">
          <img src="/assets/icon/full-adress-pin.svg" alt="pin">
          <span class="roboto Small txt-color-scorpion margin-left-12">{{currentRecommendation?.fullAddress}}</span>
        </div>
        <span class="roboto bold X-small txt-color-grvogue">
          {{('guest-recommendation.card.at-distance1'| translate) +currentRecommendation?.distance + ('guest-recommendation.card.at-distance2'| translate)}}
        </span>
      </div>
      <div class="flex flex-row align-items-center margin-top-12" *ngIf="currentRecommendation?.phoneNumber">
        <img src="/assets/icon/full-phone.svg" alt="phone">
        <span class="roboto Small txt-color-scorpion margin-left-12">{{currentRecommendation?.phoneNumber}}</span>
      </div>

      <div class="flex flex-row flex-space-between align-items-center margin-top-24"
           *ngIf="currentRecommendation?.webSite || currentRecommendation?.facebook || currentRecommendation.instagram">
        <app-button [text]="'guest-recommendation.card.website' | translate" *ngIf="currentRecommendation.webSite"
                    [type]="ButtonType.SECONDARY_X_SMALL"
                    (debounceClick)="openWebsite(currentRecommendation.webSite);"
        ></app-button>
        <app-button [text]="'Facebook'" *ngIf="currentRecommendation.facebook"
                    [type]="ButtonType.SECONDARY_X_SMALL"
                    [leftIcon]="Icons.FACEBOOK"
                    (debounceClick)="openWebsite(currentRecommendation.facebook)"
        ></app-button>
        <app-button [text]="'Instagram'" *ngIf="currentRecommendation.instagram"
                    [type]="ButtonType.SECONDARY_X_SMALL"
                    [leftIcon]="Icons.INSTAGRAM"
                    (debounceClick)="openWebsite(currentRecommendation.instagram)"
        ></app-button>
      </div>


      <!--TAGS-->
      <h5 class="mcqueen margin-top-32" *ngIf="tags && tags.length > 0">
        {{'guest-recommendation.card.tags' | translate}}
      </h5>

      <div class="tags-wrapper margin-top-16" *ngIf="tags && tags.length > 0">
        <div class="tag" *ngFor="let tag of tags">
          <span>#{{tag.name}}</span>
        </div>
      </div>


      <div  class="margin-top-32 margin-bottom-32">
        <app-button [text]="'guest-recommendation.card.go-to-place' | translate"
                    [type]="ButtonType.SECONDARY"
                    (debounceClick)="openDirection(property, currentRecommendation)"
        ></app-button>
      </div>

      <div class="empty-space" *ngIf="!isDesktopMode()"></div>

    </div>
  </div>
</div>


<div class="mini-card-container" *ngIf="doWeOpenRecommendationCardMini" [ngStyle]="{bottom: isDesktopMode() ? '24px': '116px'}" >
  <div class="mini-card flex flex-row padding-16">

    <img class="main-photo" src="{{currentRecommendation?.mainPhoto}}" alt="picture">

    <div class="flex flex-column margin-left-12 infos-container">
      <div class="flex flex flex-row flex-align-center flex-space-between">
        <span class="mcqueen Medium txt-color-black width-75 txt-nowrap txt-ellipsis hidden-overflow">{{currentRecommendation?.title}}</span>
        <div class="flex flex-row flex-align-center">
          <img src="assets/icon/star-yellow.svg" alt="icon">
          <span class="roboto bold X-small margin-left-6 margin-right-12">{{currentRecommendation?.ranking?.rating}}</span>
          <img src="{{currentRecommendation?.category?.mapIcon}}" alt="picto" class="category-icon">
        </div>
      </div>
      <div class="flex flex flex-row flex-align-center margin-top-12">
        <img src="assets/icon/full-place.svg" alt="icon">
        <span class="margin-left-8 hidden-overflow roboto X-small txt-color-scorpion txt-nowrap txt-ellipsis">{{currentRecommendation?.fullAddress}}</span>
      </div>
      <div class="flex flex flex-row flex-align-center flex-space-between margin-top-12">
        <span class="mcqueen X-small txt-color-grvogue">{{'guest-recommendation.card.' + currentRecommendation?.comment?.type | translate}}</span>
        <span class="roboto bold X-small txt-color-grvogue underline clickable" (click)="seeMore()">{{'guest-recommendation.see-more' | translate}}</span>
      </div>
    </div>
  </div>
</div>
