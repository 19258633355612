<div class="upsell-recap-section" *ngIf="realUpSellOnBoarding">

  <div *ngIf="realUpSellOnBoarding.paymentIntentStatus" class="y3-Large y3-bold y3-margin-bottom-16 s23-title-color " style="text-align: center">{{'m23-upsell-recap.your-waiting-command' | translate}}</div>
  <div *ngIf="!realUpSellOnBoarding.paymentIntentStatus" class="y3-Large y3-bold y3-margin-bottom-16 s23-title-color " style="text-align: center">{{'m23-upsell-recap.your-waiting-command-not-paid' | translate}}</div>

  <div class="y3-full-width y3-Small">

    <div class="y3-X-small">{{'m23-upsell-recap.creation-date' | translate}} {{utilService.formatDateTime(realUpSellOnBoarding.creationTime)}}</div>

    <table class="y3-full-width">

      <tr *ngFor="let item of realUpSellOnBoarding.upSellOnBoardingItems">
        <td> <span *ngIf="item.relatedQty">{{item.relatedQty}} x </span>
          {{item.guideTitle}}
        </td>
        <td style="text-align: right">{{item.upsellCheckAmount.amount.toFixed(2) | currency: item.upsellCheckAmount.currency.toUpperCase()}}</td>
      </tr>
      <tr class="y3-full-width y3-bold ">
        <td class="y3-padding-top-16" style="width: 75%">Total</td>
        <td class="y3-padding-top-16"  style="text-align: right">{{realUpSellOnBoarding.upsellAmount.amount.toFixed(2) | currency: realUpSellOnBoarding.upsellAmount.currency.toUpperCase()}}</td>
      </tr>
      <tr class="y3-full-width">
        <td class="y3-padding-top-16">
          <app-button [text]="'menu-desktop.remove-basket' | translate" [type]="'secondary-x-small-white'" (debounceClick)="cancelUpsell()"></app-button>
        </td>
      </tr>
      <tr class="y3-full-width" *ngIf="!realUpSellOnBoarding.paymentIntentStatus">
        <td class="y3-padding-top-16" style="width: 75%">
        <a [href]="realUpSellOnBoarding.intentConfirmationUrl"> {{'m23-upsell-recap.paiment-link' | translate}} </a>
        </td>

      </tr>
    </table>

  </div>

</div>


