/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-reco-widget.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../guest-recommendations-map/guest-recommendations-map-desktop/guest-recommendations-map-desktop.component.ngfactory";
import * as i3 from "../guest-recommendations-map/guest-recommendations-map-desktop/guest-recommendations-map-desktop.component";
import * as i4 from "../../../../core/services/utils.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../../core/services/guest.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../../core/services/language.service";
import * as i9 from "../../../../core/services/collect-data-service.service";
import * as i10 from "../../../../core/http/api.service";
import * as i11 from "@angular/common";
import * as i12 from "./guest-reco-widget.component";
import * as i13 from "../../../../core/services/internal.property.service";
var styles_GuestRecoWidgetComponent = [i0.styles];
var RenderType_GuestRecoWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestRecoWidgetComponent, data: {} });
export { RenderType_GuestRecoWidgetComponent as RenderType_GuestRecoWidgetComponent };
function View_GuestRecoWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-guest-recommendations-map-desktop", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GuestRecommendationsMapDesktopComponent_0, i2.RenderType_GuestRecommendationsMapDesktopComponent)), i1.ɵdid(2, 114688, null, 0, i3.GuestRecommendationsMapDesktopComponent, [i4.UtilsService, i1.NgZone, i1.Renderer2, i5.Router, i6.GuestService, i7.TranslateService, i8.LanguageManagementService, i9.CollectDataServiceService, i10.ApiService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_GuestRecoWidgetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["guest-reco-widget works!"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestRecoWidgetComponent_1)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showMap; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_GuestRecoWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-reco-widget", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestRecoWidgetComponent_0, RenderType_GuestRecoWidgetComponent)), i1.ɵdid(1, 114688, null, 0, i12.GuestRecoWidgetComponent, [i4.UtilsService, i13.InternalPropertyService, i6.GuestService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestRecoWidgetComponentNgFactory = i1.ɵccf("app-guest-reco-widget", i12.GuestRecoWidgetComponent, View_GuestRecoWidgetComponent_Host_0, {}, {}, []);
export { GuestRecoWidgetComponentNgFactory as GuestRecoWidgetComponentNgFactory };
