import { EventEmitter, NgZone } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
// @ts-ignore
import imageCompression from 'browser-image-compression';
import { ApiService } from '../http/api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../http/api.service";
import * as i3 from "./conf.service";
export class DocumentService {
    constructor(http, zone, apiService, confService) {
        this.http = http;
        this.zone = zone;
        this.apiService = apiService;
        this.confService = confService;
    }
    uploadLinksGeneration(files) {
        const listToSend = [];
        files.forEach(file => {
            listToSend.push({ filename: file.name, contentType: file.type });
        });
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/guest/document/multiple-upload', listToSend, ApiService.getHeaders());
    }
    storageDirectUpload(url, file) {
        return this.http.put(url, file, this.apiService.getEmptyHeadersForUpload());
    }
    uploadDocument(file) {
        const formData = new FormData();
        formData.append('content', file, file.name);
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/guest/document', formData, ApiService.getHeadersForUpload()); // {headers: new HttpHeaders()});
    }
    uploadDocuments(files) {
        const obs = [...files].map(file => {
            return this.uploadDocument(file);
        });
        return forkJoin(obs);
    }
    deleteDocument(url) {
        let param = new HttpParams();
        param = param.set('url', url);
        return this.http.delete(this.confService.getBackendApiEndPoint() + 'api-v2/guest/document', ApiService.getOptionsWithParams(param));
    }
    uploadPhotos(files) {
        const obs = [...files].map(file => {
            return this.uploadPhoto(file);
        });
        return forkJoin(obs);
    }
    uploadPhoto(photo) {
        return this.handleImageUpload(photo).pipe(mergeMap((blob) => {
            const formData = new FormData();
            formData.append('content', blob, photo.name);
            return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/guest/document', formData, ApiService.getHeadersForUpload()); // {headers: new HttpHeaders()});
        }));
    }
    handleImageUpload(file) {
        const emitter = new EventEmitter();
        const imageFile = file;
        console.log('original file instanceof Bloc', imageFile instanceof Blob);
        console.log('compressFile size ${imageFile.size / 1024 / 1024} MB');
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };
        imageCompression(imageFile, options).then(compressedFile => {
            console.log('compressed file instanceof Bloc', compressedFile instanceof Blob);
            console.log(`compressFile size ${compressedFile.size / 1024 / 1024} MB`);
            this.zone.run(() => {
                emitter.emit(compressedFile);
                emitter.complete();
            });
        })
            .catch(error => {
            console.log(error.message);
            emitter.error(error);
        });
        return emitter.asObservable();
    }
}
DocumentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DocumentService_Factory() { return new DocumentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i2.ApiService), i0.ɵɵinject(i3.ConfService)); }, token: DocumentService, providedIn: "root" });
export class FileDto {
}
