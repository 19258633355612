/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-booking-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./desktop-guest-booking-details/desktop-guest-booking-details.component.ngfactory";
import * as i3 from "./desktop-guest-booking-details/desktop-guest-booking-details.component";
import * as i4 from "../../../core/services/utils.service";
import * as i5 from "../../../shared/components/shared-snackbar/services/shared-snackbar.service";
import * as i6 from "@angular/router";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../core/services/guest.service";
import * as i9 from "ngx-clipboard";
import * as i10 from "@angular/platform-browser";
import * as i11 from "@angular/material/dialog";
import * as i12 from "../../../core/services/internal.property.service";
import * as i13 from "../../../core/http/api.service";
import * as i14 from "@angular/common";
import * as i15 from "./guest-booking-details.component";
var styles_GuestBookingDetailsComponent = [i0.styles];
var RenderType_GuestBookingDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestBookingDetailsComponent, data: {} });
export { RenderType_GuestBookingDetailsComponent as RenderType_GuestBookingDetailsComponent };
function View_GuestBookingDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-desktop-guest-booking-details", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DesktopGuestBookingDetailsComponent_0, i2.RenderType_DesktopGuestBookingDetailsComponent)), i1.ɵdid(1, 638976, null, 0, i3.DesktopGuestBookingDetailsComponent, [i4.UtilsService, i5.SharedSnackbarService, i6.Router, i7.TranslateService, i8.GuestService, i9.ClipboardService, i10.DomSanitizer, i11.MatDialog, i12.InternalPropertyService, i13.ApiService], { property: [0, "property"], booking: [1, "booking"], corporate: [2, "corporate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.property; var currVal_1 = _co.booking; var currVal_2 = _co.corporate; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_GuestBookingDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { gMapElement: 0 }), i1.ɵqud(402653184, 2, { mytmpl: 0 }), (_l()(), i1.ɵeld(2, 0, [[2, 0], ["mytemplate", 1]], null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i14.ɵNgClassImpl, i14.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i14.NgClass, [i14.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { "desktop-detail-booking": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestBookingDetailsComponent_1)), i1.ɵdid(7, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, _co.isDesktopMode()); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.isDesktopMode(); _ck(_v, 7, 0, currVal_1); }, null); }
export function View_GuestBookingDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-booking-details", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestBookingDetailsComponent_0, RenderType_GuestBookingDetailsComponent)), i1.ɵdid(1, 638976, null, 0, i15.GuestBookingDetailsComponent, [i4.UtilsService, i7.TranslateService, i6.Router, i8.GuestService, i9.ClipboardService, i10.DomSanitizer, i12.InternalPropertyService, i13.ApiService, i11.MatDialog, i5.SharedSnackbarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestBookingDetailsComponentNgFactory = i1.ɵccf("app-guest-booking-details", i15.GuestBookingDetailsComponent, View_GuestBookingDetailsComponent_Host_0, { property: "property", booking: "booking", corporate: "corporate" }, {}, []);
export { GuestBookingDetailsComponentNgFactory as GuestBookingDetailsComponentNgFactory };
