import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {ActivatedRoute, Router} from '@angular/router';
import {Corporate} from '../../../models/guestview/Corporate';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {BookingRating, PropertyRating} from '../../../models/guestview/BookingRating';
import {SnackbarMessage} from '../../../shared/components/shared-snackbar/models/snackbar-message';
import {SharedSnackbarService} from '../../../shared/components/shared-snackbar/services/shared-snackbar.service';
import {ApiService} from '../../../core/http/api.service';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../../../core/services/utils.service';
import {GuestServiceIntern} from '../guest.service';

@Component({
  selector: 'app-m26-review',
  templateUrl: './m26-review.component.html',
  styleUrls: ['./m26-review.component.scss']
})
export class M26ReviewComponent implements OnInit {

  property: PropertyForGuest;
  corporate: Corporate;
  booking: BookingForGuest;
  bookingRating: BookingRating = new BookingRating();
  propertyRating: PropertyRating = new PropertyRating();

  constructor(private location: Location,
              public snackbar: SharedSnackbarService,
              public apiService: ApiService,
              private translate: TranslateService,
              public utilService: UtilsService,
              public router: Router,
              private internGuestService: GuestServiceIntern,
              private activatedRoute: ActivatedRoute,) { }

  ngOnInit() {

    this.bookingRating.ratingItems = [
      {type: 'cleanliness' , rating: 0},
      {type: 'communication' , rating: 0},
      {type: 'checkin' , rating: 0},
      {type: 'location' , rating: 0},
      {type: 'accuracy' , rating: 0},
      {type: 'value' , rating: 0}
    ];

    this.activatedRoute.data.subscribe((res) => {
      if (res && res.propertyAndBooking) {
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        //this.utilService.manageCorporateColors(this.corporate);
        this.booking = res.propertyAndBooking.booking;
      }

      this.internGuestService.getBookingForGuest().subscribe(booking => {
        console.log('BOOKING', booking);
        if (booking) {
          this.booking = booking;
          if (booking.propertyRating) {
            this.bookingRating = booking.propertyRating;
          }
        }
      });

    });

  }

  goBack() {
    this.location.back();
  }

  saveRating() {
    this.snackbar.dismiss();
    if (!this.bookingRating.ratingComment) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('guest-message.note-error'), 'error'));
    } else {
      this.apiService.savePropertyRating(this.booking.id , this.bookingRating).subscribe(resp => {
          this.snackbar.push(new SnackbarMessage(this.translate.instant('guest-message.success'), 'info'));
          this.router.navigate(['guest', this.booking.id]);
        },
        error => {
          this.utilService.handleYaagoErrorCode(error);
        });
    }
  }

}
