<div class="m25-container">
  <div class="upsell-recap-section" *ngIf="previousUpSellOnBoardings?.length > 0">
    <div class="y3-Large y3-bold y3-margin-bottom-16 s23-title-color-validated " style="text-align: center">{{'m23-upsell-recap.your-validated-command' | translate}}</div>


    <div class="y3-full-width y3-Small" *ngFor="let validated of previousUpSellOnBoardings">

      <div class="y3-X-small"><strong>{{'m23-upsell-recap.creation-date' | translate}} : </strong> {{utilService.formatDateTime(validated.creationTime)}}</div>
      <div class="y3-X-small"><strong>{{'m23-upsell-recap.validation-date' | translate}} : </strong> {{utilService.formatDateTime(validated.paymentIntentValidationDate)}}</div>

      <table class="y3-full-width y3-margin-top-8">

        <tr *ngFor="let item of validated.upSellOnBoardingItems" class="">
          <td> <span *ngIf="item.relatedQty">{{item.relatedQty}} x </span>
            {{item.guideTitle}}
          </td>
          <td *ngIf="item.paymentIntentStatus !== 'rejected'" style="text-align: right">{{item.upsellCheckAmount.amount.toFixed(2) | currency: item.upsellCheckAmount.currency.toUpperCase()}}</td>
          <td *ngIf="item.paymentIntentStatus === 'rejected'" style="text-align: right" class="y3-flex-row y3-flex-justify-content-end"><app-tag2 [type]="'red '" [text]="'m23-upsell-recap.refused' | translate"></app-tag2></td>
        </tr>
        <tr class="y3-full-width y3-bold ">
          <td class="y3-padding-top-16" style="width: 75%">Total</td>
          <td  class="y3-padding-top-16"  style="text-align: right">{{validated.upsellAmount.amount.toFixed(2) | currency: validated.upsellAmount.currency.toUpperCase()}}</td>

        </tr>
        <tr >
          <td style="border-top: 1px solid gainsboro">&nbsp;</td>
          <td style="border-top: 1px solid gainsboro">&nbsp;</td>
        </tr>
      </table>

    </div>

  </div>
</div>
