/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./online-check-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./desktop-online-check-details/desktop-online-check-details.component.ngfactory";
import * as i3 from "./desktop-online-check-details/desktop-online-check-details.component";
import * as i4 from "../../core/services/LoaderService";
import * as i5 from "../../core/services/language.service";
import * as i6 from "@angular/router";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../core/services/guest.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "@angular/forms";
import * as i11 from "../../core/services/pays.service";
import * as i12 from "../../core/services/DocumentService";
import * as i13 from "../../core/services/utils.service";
import * as i14 from "../../core/services/checkin.service";
import * as i15 from "../../core/services/SnackbarService";
import * as i16 from "../../core/services/internal.property.service";
import * as i17 from "../../shared/components/shared-snackbar/services/shared-snackbar.service";
import * as i18 from "@angular/platform-browser";
import * as i19 from "./mobile-online-check-details/mobile-online-check-details.component.ngfactory";
import * as i20 from "./mobile-online-check-details/mobile-online-check-details.component";
import * as i21 from "./online-check-waiting/online-check-waiting.component.ngfactory";
import * as i22 from "./online-check-waiting/online-check-waiting.component";
import * as i23 from "@angular/common";
import * as i24 from "./mobile-online-check-waiting/mobile-online-check-waiting.component.ngfactory";
import * as i25 from "./mobile-online-check-waiting/mobile-online-check-waiting.component";
import * as i26 from "./online-check-details.component";
var styles_OnlineCheckDetailsComponent = [i0.styles];
var RenderType_OnlineCheckDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OnlineCheckDetailsComponent, data: {} });
export { RenderType_OnlineCheckDetailsComponent as RenderType_OnlineCheckDetailsComponent };
function View_OnlineCheckDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-desktop-online-check-details", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DesktopOnlineCheckDetailsComponent_0, i2.RenderType_DesktopOnlineCheckDetailsComponent)), i1.ɵdid(1, 4833280, null, 0, i3.DesktopOnlineCheckDetailsComponent, [i4.LoaderService, i5.LanguageManagementService, i6.ActivatedRoute, i7.MatDialog, i8.GuestService, i9.TranslateService, i10.FormBuilder, i11.PaysService, i12.DocumentService, i13.UtilsService, i14.CheckinService, i15.SnackbarService, i16.InternalPropertyService, i17.SharedSnackbarService, i18.Title], { corporate: [0, "corporate"], booking: [1, "booking"], property: [2, "property"], countries: [3, "countries"], localOnLineCheck: [4, "localOnLineCheck"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.corporate; var currVal_1 = _co.booking; var currVal_2 = _co.property; var currVal_3 = _co.countries; var currVal_4 = _co.localOnLineCheck; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_OnlineCheckDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mobile-online-check-details", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_MobileOnlineCheckDetailsComponent_0, i19.RenderType_MobileOnlineCheckDetailsComponent)), i1.ɵdid(1, 4833280, null, 0, i20.MobileOnlineCheckDetailsComponent, [i4.LoaderService, i5.LanguageManagementService, i6.ActivatedRoute, i7.MatDialog, i8.GuestService, i9.TranslateService, i10.FormBuilder, i12.DocumentService, i13.UtilsService, i14.CheckinService, i11.PaysService, i15.SnackbarService, i16.InternalPropertyService, i17.SharedSnackbarService, i18.Title], { countries: [0, "countries"], corporate: [1, "corporate"], booking: [2, "booking"], property: [3, "property"], localOnLineCheck: [4, "localOnLineCheck"], mainPropertyPhoto: [5, "mainPropertyPhoto"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.countries; var currVal_1 = _co.corporate; var currVal_2 = _co.booking; var currVal_3 = _co.property; var currVal_4 = _co.localOnLineCheck; var currVal_5 = _co.mainPropertyPhoto; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_OnlineCheckDetailsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-online-check-waiting", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i21.View_OnlineCheckWaitingComponent_0, i21.RenderType_OnlineCheckWaitingComponent)), i1.ɵdid(1, 4308992, null, 0, i22.OnlineCheckWaitingComponent, [i4.LoaderService, i5.LanguageManagementService, i6.ActivatedRoute, i7.MatDialog, i8.GuestService, i9.TranslateService, i10.FormBuilder, i13.UtilsService, i14.CheckinService, i11.PaysService, i12.DocumentService, i15.SnackbarService, i16.InternalPropertyService, i17.SharedSnackbarService, i18.Title, i23.DOCUMENT, i6.Router], { booking: [0, "booking"], localOnLineCheck: [1, "localOnLineCheck"], property: [2, "property"], corporate: [3, "corporate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.booking; var currVal_1 = _co.localOnLineCheck; var currVal_2 = _co.property; var currVal_3 = _co.corporate; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_OnlineCheckDetailsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mobile-online-check-waiting", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i24.View_MobileOnlineCheckWaitingComponent_0, i24.RenderType_MobileOnlineCheckWaitingComponent)), i1.ɵdid(1, 4308992, null, 0, i25.MobileOnlineCheckWaitingComponent, [i4.LoaderService, i5.LanguageManagementService, i6.ActivatedRoute, i7.MatDialog, i8.GuestService, i9.TranslateService, i10.FormBuilder, i13.UtilsService, i14.CheckinService, i11.PaysService, i12.DocumentService, i15.SnackbarService, i16.InternalPropertyService, i17.SharedSnackbarService, i6.Router, i18.Title], { booking: [0, "booking"], property: [1, "property"], localOnLineCheck: [2, "localOnLineCheck"], mainPropertyPhoto: [3, "mainPropertyPhoto"], corporate: [4, "corporate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.booking; var currVal_1 = _co.property; var currVal_2 = _co.localOnLineCheck; var currVal_3 = _co.mainPropertyPhoto; var currVal_4 = _co.corporate; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_OnlineCheckDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OnlineCheckDetailsComponent_1)), i1.ɵdid(1, 16384, null, 0, i23.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OnlineCheckDetailsComponent_2)), i1.ɵdid(3, 16384, null, 0, i23.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OnlineCheckDetailsComponent_3)), i1.ɵdid(5, 16384, null, 0, i23.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OnlineCheckDetailsComponent_4)), i1.ɵdid(7, 16384, null, 0, i23.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isDesktopMode() && _co.mode.edit); _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.isDesktopMode() && _co.mode.edit); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.isDesktopMode() && _co.mode.show); _ck(_v, 5, 0, currVal_2); var currVal_3 = (!_co.isDesktopMode() && _co.mode.show); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_OnlineCheckDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-online-check-details", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_OnlineCheckDetailsComponent_0, RenderType_OnlineCheckDetailsComponent)), i1.ɵdid(1, 4308992, null, 0, i26.OnlineCheckDetailsComponent, [i4.LoaderService, i5.LanguageManagementService, i6.ActivatedRoute, i7.MatDialog, i8.GuestService, i9.TranslateService, i10.FormBuilder, i13.UtilsService, i14.CheckinService, i11.PaysService, i12.DocumentService, i15.SnackbarService, i16.InternalPropertyService, i17.SharedSnackbarService, i18.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OnlineCheckDetailsComponentNgFactory = i1.ɵccf("app-online-check-details", i26.OnlineCheckDetailsComponent, View_OnlineCheckDetailsComponent_Host_0, {}, {}, []);
export { OnlineCheckDetailsComponentNgFactory as OnlineCheckDetailsComponentNgFactory };
