<footer id="footer" class="its--wrapper-full footer-block-its-corporative">
  <div id="upper_footer">
    <div class="its--container">
      <div
        class="its--row datos_empresa-wrapper links-wrapper ayuda-wrapper newsletter-wrapper"
      >
        <div class="its--container">
          <div class="its--col-sm-4">
            <div class="widget datos_empresa">
              <div id="address_widget" class="widget">
                <p class="title fwk-color-cont">Keylodge</p>
                <p class="direccion_empresa">Sainte-Clotilde, La Réunion</p>
                <div class="widget_inner"></div>
                <div class="widget_inner">
                  <hr />
                  <p class="av-tel">
                    <!-- <i class="icon-telefono"></i> -->
                    <img src="assets/icon/telephone-yellow.svg" width="17px" style=" margin-bottom: -3px; margin-right: 15px;" >
                    <a  target="_blank" href="tel:0262 83 00 50">0262 83 00 50</a>
                  </p>

                  <p class="av-email">
                    <img src="assets/icon/mail-yellow.svg" width="17px" style=" margin-bottom: -3px; margin-right: 15px;" >
                    <a  target="_blank" href="mailto:reservations@keylodge.fr"
                      >reservations@keylodge.fr</a
                    >
                  </p>
                </div>
                <p class="empresa_name copy_desktop">© Keylodge 2023</p>
              </div>
            </div>
          </div>
          <div class="its--col-sm-2">
            <div class="widget links">
              <div id="links_widget" class="widget">
                <p class="title fwk-color-cont">Liens rapides</p>
                <div class="widget_inner">
                  <ul>
                    <li><a  target="_blank" href="https://www.keylodge.fr/">Accueil</a></li>
                    <li>
                      <a
                      target="_blank"  href="https://www.keylodge.fr/location/location-locations-d0/"
                        >Nos Locations</a
                      >
                    </li>
                    <li>
                      <a

                        target="_blank"   href="https://www.keylodge.fr/blog-la-conciergerie-a-la-reunion/"
                        >Blog</a
                      >
                    </li>
                    <li>
                      <a target="_blank" href="https://www.keylodge.fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.keylodge.fr/programme-partenaire/"
                        >Partenaires</a
                      >
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.proprietaire.keylodge.fr/"
                        >Je suis propriétaire</a
                      >
                    </li>
                    <li>
                      <a target="_blank" href="https://keylodge.welcomekit.co/"
                        >Nous Rejoindre</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="its--col-sm-2">
            <div class="widget ayuda">
              <div id="links_widget" class="widget">
                <p class="title fwk-color-cont">Aide</p>
                <div class="widget_inner">
                  <ul>
                    <li>
                      <a  target="_blank" href="https://www.keylodge.fr/conditions-generales/"
                        >Conditions générales</a
                      >
                    </li>
                    <li>
                      <a  target="_blank" href="https://www.keylodge.fr/politique-de-cookies/"
                        >Politique de Cookies</a
                      >
                    </li>
                    <li>
                      <a
                      target="_blank"   href="https://www.keylodge.fr/politique-de-confidentialite/"
                        >Politique de confidentialité</a
                      >
                    </li>
                    <li>
                      <a
                      target="_blank"   href="https://www.keylodge.fr/location/offre-remboursement-garanti-lie-au-covid-19-18006.html"
                        >Mesures COVID-19</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="its--col-sm-4">
            <div class="widget newsletter">
              <p class="title fwk-color-cont">Newsletter</p>

              <div class="widget_inner">
                <form
                  name="formNewsletterFooter"
                  method="post"
                  data-endpoint="https://fwk.avantio.com/services/sendMail.php"
                  id="formNewsletterFooter"
                  class="send-form form-default"
                >
                  <input type="hidden" name="Envia_contacto" value="Si" />
                  <input type="hidden" name="Idioma" value="FR" />
                  <input type="hidden" name="type" value="newsletter" />
                  <input
                    style="display: none"
                    type="text"
                    name="valida"
                    value="avantio"
                    size="10"
                    tabindex="-1"
                  />
                  <input style="display: none" type="checkbox" name="check" />

                  <label id="CapaEmail">
                    <span>E-mail:<span>*</span></span>
                    <input
                      class="form-control"
                      name="email"
                      value=""
                      type="email"
                      id="email2"
                      size="38"
                      placeholder="Your email..."
                      align="left"
                      required=""
                    />
                  </label>

                  <div id="botonContacto">
                    <label>
                      <div id="boton_contacto">
                        <input
                          id="boton_enviar"
                          type="submit"
                          name="Submit"
                          value="S'abonner"
                          class="btn btn-primary"
                        />
                      </div>
                    </label>
                  </div>

                  <div class="its--col-md-12 acepto">
                    <input
                      id="acepto-footer1"
                      type="checkbox"
                      value="acepto"
                      required=""
                    />
                    <label for="acepto-footer1"
                      >J'ai lu et j'accepte les
                      <a
                      target="_blank"
                        href="https://www.keylodge.fr/politique-de-confidentialite/"
                        >politiques de confidentialité</a
                      ><a
                      target="_blank"
                        href="https://www.keylodge.fr/conditions-generales/"
                      ></a
                    ></label>
                  </div>
                  <div class="its--col-md-12 acepto si-acepta">
                    <input
                      id="acepto-footer2"
                      type="checkbox"
                      value="Oui"
                      name="aceptaOfertas"
                    />
                    <label for="acepto-footer2"
                      >J'accepte de recevoir les offres et promotions</label
                    >
                  </div>
                  <div
                    style="display: none"
                    class="its--col-md-12 acepto no-acepta"
                  >
                    <input
                      id="no-acepto"
                      type="hidden"
                      value="Non"
                      name="aceptaOfertas"
                    />
                  </div>
                </form>

                <div class="clearfix"></div>
              </div>
              <p class="empresa_name copy_mobile">
                © Keylodge 2022 , Design by <a href="">Avantio</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="lower_footer">
    <div class="its--container">
      <div class="its--row">
        <div id="social_widget" class="its--col-sm-5">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/keylodge.locations/"
                target="_blank"
              >
                <img
                  src="/assets/images/footers/keylodge/facebook.png"
                  alt="facebook"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/keylodge/"
                target="_blank"
              >
                <img
                  src="/assets/images/footers/keylodge/linkedin.png"
                  alt="linkedin"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/keylodge_locations/"
                target="_blank"
              >
                <img
                  src="/assets/images/footers/keylodge/instagram.png"
                  alt="instagram"
                />
              </a>
            </li>
          </ul>
        </div>
        <div id="designed_by" class="its--col-sm-2">
          <a href="https://www.keylodge.fr//">
            <img
              src="/assets/images/footers/keylodge/logo_footer.png"
              alt="$GLOBALS['datosEmpresa']['Empresa'];"
            />
          </a>
        </div>
        <div id="tipo_pago" class="its--col-sm-5">
          <ul class="list-inline">
            <li>
              <img
                class="bank-transfer"
                src="/assets/images/footers/keylodge/keylodgeBank.svg"
              />
            </li>
            <li>
              <img
                class="mastercard"
                  src="/assets/images/footers/keylodge/keylodgeMastercard.svg"
              />
            </li>
            <li>
              <img
                class="visa"
                src="/assets/images/footers/keylodge/visa.svg"
              />
            </li>
            <li>
              <img
                class="american-express"
                src="/assets/images/footers/keylodge/keylodgeExpress.svg"
              />
            </li>
            <li>
              <img
                class="connect"
                src="/assets/images/footers/keylodge/connect.png"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--<div class="pd">
    <p>
      *Remboursement garanti lié au Covid-19 : Remboursement si votre séjour est
      rendu impossible par des dispositions prises par les Pouvoirs Publics :
      confinement, motif impérieux, de votre lieu de domicile ou de votre lieu
      destination pendant la période du séjour. Remboursement en cas de maladie
      Covid-19. Le locataire devra joindre à sa demande d’annulation les
      documents justifiant de ce confinement, motif impérieux (justificatif de
      domicile, arrêté des Pouvoirs Publics, Justificatif). Cette garantie ne
      s'applique pas aux autres motifs d'empêchement suivants : plages, bars et
      restaurants fermés, inquiétude personnelle, infrastructures partiellement
      fermées. Cette offre est valable pour tout séjour du 26/02/2021 au
      31/12/2021 et est non rétroactive. Les montants versés dans le cadre du
      règlement du séjour seront remboursés. Offre non valable pour un séjour
      réglé via un bon à-valoir. Un séjour réglé via un bon-à-valoir sera
      remboursé par un nouveau bon-à-valoir du même montant.
    </p>
  </div>-->
</footer>
