import { Component, OnInit } from '@angular/core';
import {UpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/UpSellOnBoarding';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/services/utils.service';

@Component({
  selector: 'app-m25-upsells-validated',
  templateUrl: './m25-upsells-validated.component.html',
  styleUrls: ['./m25-upsells-validated.component.scss']
})
export class M25UpsellsValidatedComponent implements OnInit {

  previousUpSellOnBoardings: UpSellOnBoarding[] = [];

  constructor(private activatedRoute: ActivatedRoute,
              public utilService: UtilsService) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      this.previousUpSellOnBoardings = res.propertyAndBooking.paidUpSells;
    });
  }

}
