import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Recommendation} from '../../../models/guestview/Recommendation';
import {UtilsService} from '../../../core/services/utils.service';
import {CollectDataServiceService} from '../../../core/services/collect-data-service.service';
import {Property} from '../../../models/Property';

@Component({
  selector: 'app-m21-recommendation-card',
  templateUrl: './m21-recommendation-card.component.html',
  styleUrls: ['./m21-recommendation-card.component.scss']
})
export class M21RecommendationCardComponent implements OnInit {

  recommendation: Recommendation;
  property: Property;
  currentPictureUrl: string = null;
  constructor(public utilsService: UtilsService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<M21RecommendationCardComponent>) { }

  ngOnInit() {
    if (this.data && this.data.recommendation) {
      this.recommendation = this.data.recommendation;
    }
    if (this.data && this.data.property) {
      this.property = this.data.property;
    }
  }

  openDirection(property: Property, recommendation: Recommendation) {
    const url = 'https://www.google.com/maps/dir/' +
      property.poi.y + ',' + property.poi.x + '/' + recommendation.poi.y + ',' + recommendation.poi.x;
    UtilsService.openLinkInNewTab(url);
  }

  choosePicture(pictureUrl : string) {
    if (this.currentPictureUrl === pictureUrl) {
      this.currentPictureUrl = null;
    } else {
      this.currentPictureUrl = pictureUrl;
    }
  }

  back() {
    this.dialogRef.close();
  }


}
