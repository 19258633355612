/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../guest-usefulnumber/guest-usefulnumber.component.scss.shim.ngstyle";
import * as i1 from "../guest-usefulnumber/desktop-guest-usefulnumber/desktop-guest-usefulnumber.component.scss.shim.ngstyle";
import * as i2 from "./guest-usefulnumber-full-list-desktop.component.scss.shim.ngstyle";
import * as i3 from "@angular/core";
import * as i4 from "../guest-usefulnumber/card-number-mobile/card-number-mobile.component.ngfactory";
import * as i5 from "../guest-usefulnumber/card-number-mobile/card-number-mobile.component";
import * as i6 from "../../../core/services/utils.service";
import * as i7 from "@angular/router";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@angular/common";
import * as i10 from "@ngx-translate/core";
import * as i11 from "./guest-usefulnumber-full-list-desktop.component";
import * as i12 from "../../../core/services/guest.service";
import * as i13 from "../../../core/services/internal.property.service";
import * as i14 from "../guest.service";
var styles_GuestUsefulnumberFullListDesktopComponent = [i0.styles, i1.styles, i2.styles];
var RenderType_GuestUsefulnumberFullListDesktopComponent = i3.ɵcrt({ encapsulation: 0, styles: styles_GuestUsefulnumberFullListDesktopComponent, data: {} });
export { RenderType_GuestUsefulnumberFullListDesktopComponent as RenderType_GuestUsefulnumberFullListDesktopComponent };
function View_GuestUsefulnumberFullListDesktopComponent_1(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 2, "div", [["style", " width: 100%"]], null, null, null, null, null)), (_l()(), i3.ɵeld(1, 0, null, null, 1, "app-card-number-mobile", [], null, null, null, i4.View_CardNumberMobileComponent_0, i4.RenderType_CardNumberMobileComponent)), i3.ɵdid(2, 114688, null, 0, i5.CardNumberMobileComponent, [i6.UtilsService, i7.Router, i8.MatDialog], { property: [0, "property"], number: [1, "number"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.property; var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_GuestUsefulnumberFullListDesktopComponent_0(_l) { return i3.ɵvid(0, [i3.ɵqud(402653184, 1, { mytmpl: 0 }), (_l()(), i3.ɵeld(1, 0, null, null, 14, "div", [["class", "main-container-useful"], ["id", "usefulNumbers"]], null, null, null, null, null)), (_l()(), i3.ɵeld(2, 0, null, null, 13, "div", [["class", "guest-view-desktop-section-container"], ["style", "padding-top: 24px;"]], null, null, null, null, null)), (_l()(), i3.ɵeld(3, 0, null, null, 9, "div", [["class", "header-seeMore"]], null, null, null, null, null)), (_l()(), i3.ɵeld(4, 0, null, null, 5, "a", [["class", "back-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i3.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i3.ɵdid(5, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i3.ɵpad(6, 2), (_l()(), i3.ɵeld(7, 0, null, null, 0, "img", [["class", "icon-btn"], ["src", "assets/icon/back-icon-arrow.svg"]], null, null, null, null, null)), (_l()(), i3.ɵted(8, null, [" ", " "])), i3.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i3.ChangeDetectorRef]), (_l()(), i3.ɵeld(10, 0, null, null, 2, "h4", [["style", "margin-top: 24px;"]], null, null, null, null, null)), (_l()(), i3.ɵted(11, null, ["", ""])), i3.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i3.ChangeDetectorRef]), (_l()(), i3.ɵeld(13, 0, null, null, 2, "div", [["class", "useful-number-seeMore"]], null, null, null, null, null)), (_l()(), i3.ɵand(16777216, null, null, 1, null, View_GuestUsefulnumberFullListDesktopComponent_1)), i3.ɵdid(15, 278528, null, 0, i9.NgForOf, [i3.ViewContainerRef, i3.TemplateRef, i3.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 6, 0, "/guest", _co.bookingId); _ck(_v, 5, 0, currVal_2); var currVal_5 = _co.landingNumber; _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i3.ɵnov(_v, 5).target; var currVal_1 = i3.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = i3.ɵunv(_v, 8, 0, i3.ɵnov(_v, 9).transform("guest-global-text.back-btn")); _ck(_v, 8, 0, currVal_3); var currVal_4 = i3.ɵunv(_v, 11, 0, i3.ɵnov(_v, 12).transform("guest-view-useful-number.useful-number")); _ck(_v, 11, 0, currVal_4); }); }
export function View_GuestUsefulnumberFullListDesktopComponent_Host_0(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 1, "app-guest-usefulnumber-full-list-desktop", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i3.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestUsefulnumberFullListDesktopComponent_0, RenderType_GuestUsefulnumberFullListDesktopComponent)), i3.ɵdid(1, 114688, null, 0, i11.GuestUsefulnumberFullListDesktopComponent, [i12.GuestService, i13.InternalPropertyService, i7.ActivatedRoute, i7.Router, i14.GuestServiceIntern, i10.TranslateService, i6.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestUsefulnumberFullListDesktopComponentNgFactory = i3.ɵccf("app-guest-usefulnumber-full-list-desktop", i11.GuestUsefulnumberFullListDesktopComponent, View_GuestUsefulnumberFullListDesktopComponent_Host_0, { property: "property" }, {}, []);
export { GuestUsefulnumberFullListDesktopComponentNgFactory as GuestUsefulnumberFullListDesktopComponentNgFactory };
