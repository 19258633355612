import {Component, OnDestroy, OnInit} from '@angular/core';
import {GuestService} from '../../../core/services/guest.service';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Corporate} from '../../../models/guestview/Corporate';
import {TypeOfStay} from '../../guest/g11-online-check-in/g11-online-check-in.component';
import {SnackbarService} from '../../../core/services/SnackbarService';
import {SnackbarMessage} from '../../../models/snackbar/SnackbarMessage';
import {SharedSnackbarService} from '../../../shared/components/shared-snackbar/services/shared-snackbar.service';
import {GenericLinkEndpoint} from '../../../core/services/V3_GenericLinkEndpoint/GenericLinkEndpoint';
import {GenericLinkBookingCreation} from '../../../models/V3_GenericLink/GenericLinkBookingCreation';

@Component({
  selector: 'app-property-generic',
  templateUrl: './property-generic.component.html',
  styleUrls: ['./property-generic.component.scss']
})
export class PropertyGenericComponent implements OnInit, OnDestroy {

  lang: string = 'fr';
  onDestroy = new Subject();
  corporate: Corporate;
  propertyTitle: string;
  platform: TypeOfStay[] = [];
  stayTypes: TypeOfStay[] = [];
  dropdownSettingsNoFilter = {};
  genericLinkBookingCreation: GenericLinkBookingCreation = new GenericLinkBookingCreation();
  constructor(private guestService: GuestService,
              private genericLinkEndpoint: GenericLinkEndpoint,
              private translateService: TranslateService,
              private snackbarService: SharedSnackbarService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {

    this.dropdownSettingsNoFilter = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: false
    };

    this.stayTypes = [
      { id: 'airbnb', name: 'Airbnb' },
      { id: 'booking', name: 'Booking' },
      { id: 'vrbo', name: 'Vrbo' },
      { id: 'others', name: 'Others' }
    ];

    this.lang = this.translateService.currentLang;

    this.route.params.pipe(takeUntil(this.onDestroy)).subscribe(params => {
      if (params.propertyId != null) {
        console.log("params", params);
        this.guestService.getPropertyAndBookingForGuest(params.propertyId, this.lang).subscribe( res => {
          this.corporate = res.corporate;
          if (res.property.bookletTitle != null) {
            this.propertyTitle = res.property.bookletTitle;
          } else {
            this.propertyTitle = res.property.title;
          }
          this.genericLinkBookingCreation.propertyId = params.propertyId;
          this.genericLinkBookingCreation.guestLanguage = this.lang;
          console.log ("res", res);
        });
      }
    });



  }

  selectType($event) {
  }
  unSelectType($event) {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  submitIdentification() {

    if (this.genericLinkBookingCreation.guestEmail == null || this.genericLinkBookingCreation.guestEmail.length == 0) {
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('generic-link.missing-email'), 'error'));
      return;
    }
    if (this.genericLinkBookingCreation.guestFirstname == null || this.genericLinkBookingCreation.guestFirstname.length == 0) {
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('generic-link.missing-firstname'), 'error'));
      return;
    }
    if (this.genericLinkBookingCreation.guestLastname == null || this.genericLinkBookingCreation.guestLastname.length == 0) {
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('generic-link.missing-lastname'), 'error'));
      return;
    }
    if (this.genericLinkBookingCreation.guestPhone == null || this.genericLinkBookingCreation.guestPhone.length == 0) {
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('generic-link.missing-phone'), 'error'));
      return;
    }
    if (this.genericLinkBookingCreation.startDate == null || this.genericLinkBookingCreation.startDate.length == 0) {
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('generic-link.missing-startdate'), 'error'));
      return;
    }
    if (this.genericLinkBookingCreation.endDate == null || this.genericLinkBookingCreation.endDate.length == 0) {
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('generic-link.missing-enddate'), 'error'));
      return;
    }


    if (this.platform.length > 0) {
      this.genericLinkBookingCreation.platformName = this.platform[0].name;
    } else {
      this.genericLinkBookingCreation.platformName = "OTHERS";
    }
    console.log("submitIdentification", this.genericLinkBookingCreation);
    this.genericLinkEndpoint.createPendingBooking(this.genericLinkBookingCreation).subscribe( res => {
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('generic-link.success'), 'info'));
      this.router.navigate(['/misc/p/' + this.genericLinkBookingCreation.propertyId + '/confirm']).then() ;
    }, err => {
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('generic-link.error'), 'error'));
    });

  }

}
