import {HttpClient} from '@angular/common/http';
import {ConfService} from '../conf.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GenericLinkBookingCreation} from '../../../models/V3_GenericLink/GenericLinkBookingCreation';

@Injectable({ providedIn: 'root' })
export class GenericLinkEndpoint {
  constructor(
    private http: HttpClient,
    private confService: ConfService) {
  }

  createPendingBooking(genericLinkBookingCreation: GenericLinkBookingCreation): Observable<void> {
    return this.http.post<void>(this.confService.getBackendApiEndPoint() + 'api-v2/guest/generic-link/create-booking', genericLinkBookingCreation);
  }
}
