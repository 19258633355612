/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-gallery-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./guest-gallery-detail.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../modules/guest/guest.service";
import * as i7 from "@angular/material/dialog";
var styles_GuestGalleryDetailComponent = [i0.styles];
var RenderType_GuestGalleryDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestGalleryDetailComponent, data: {} });
export { RenderType_GuestGalleryDetailComponent as RenderType_GuestGalleryDetailComponent };
function View_GuestGalleryDetailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "navigation navigation-right clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateRight() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "arrow-right"], ["class", "icon-arrow-right"], ["src", "assets/icon/back-icon-arrow.svg"]], null, null, null, null, null))], null, null); }
function View_GuestGalleryDetailComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "navigation navigation-left clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateLeft() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "arrow-left"], ["class", "icon-arrow-left"], ["src", "assets/icon/back-icon-arrow.svg"]], null, null, null, null, null))], null, null); }
function View_GuestGalleryDetailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "img-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "photo"], ["class", "photo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestGalleryDetailComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestGalleryDetailComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.myPhotos.length > (_co.index + 1)); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.index > 0); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.myPhotos[_co.index].img; _ck(_v, 1, 0, currVal_0); }); }
function View_GuestGalleryDetailComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "num-photo"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "/", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.index + 1); var currVal_1 = _co.myPhotos.length; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_GuestGalleryDetailComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "caption"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "caption-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "caption-desc px-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("property-gallery.caption")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.myPhotos[_co.index].caption; _ck(_v, 5, 0, currVal_1); }); }
export function View_GuestGalleryDetailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "header-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "close-class"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "icon-close"], ["src", "assets/icon/cross.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestGalleryDetailComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestGalleryDetailComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestGalleryDetailComponent_5)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.myPhotos[_co.index]; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.myPhotos; _ck(_v, 9, 0, currVal_2); var currVal_3 = ((_co.myPhotos[_co.index] && _co.myPhotos[_co.index].caption) && (_co.myPhotos[_co.index].caption !== "")); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("property-gallery.property-gallery")); _ck(_v, 2, 0, currVal_0); }); }
export function View_GuestGalleryDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-gallery-detail", [], null, null, null, View_GuestGalleryDetailComponent_0, RenderType_GuestGalleryDetailComponent)), i1.ɵdid(1, 114688, null, 0, i4.GuestGalleryDetailComponent, [i5.ActivatedRoute, i5.Router, i6.GuestServiceIntern, i7.MAT_DIALOG_DATA, i7.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestGalleryDetailComponentNgFactory = i1.ɵccf("app-guest-gallery-detail", i4.GuestGalleryDetailComponent, View_GuestGalleryDetailComponent_Host_0, { photos: "photos" }, {}, []);
export { GuestGalleryDetailComponentNgFactory as GuestGalleryDetailComponentNgFactory };
