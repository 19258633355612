
    <div class="page-container"
        [ngStyle]="{'background-image':  'url(&quot;' + (isDesktopMode()? homeSettings?.pictureDesktopUrl : homeSettings?.pictureMobileUrl) + '&quot;)' }">
        <div class="text-container">

            <img *ngIf="!propertyDetails.homePageWithoutLogo" class="agencyLogo" src={{homeSettings?.agencyLogoUrl}}>

            <div [ngStyle]="{ 'color': homeSettings?.fontColor }" class="first-title">{{homeSettings?.agencyTitle}}</div>
            <div *ngIf="isDesktopMode() && homeSettings?.agencyEmail" class="contact">
                <div class="mail-position">
                    <!-- <img class="mail-width" src="/assets/icon/mail-white.svg"> -->
                 <mat-icon [ngStyle]="{ 'color': homeSettings?.fontColor }" class="mail-width">mail_outline</mat-icon>
                    <div class="contact-display">

                        <a [ngStyle]="{ 'color': homeSettings?.fontColor }" href="mailto:{{homeSettings?.agencyEmail}}">{{homeSettings?.agencyEmail}}</a>
                    </div>
                </div>
                <div class="phone-position" *ngIf="homeSettings?.agencyPhone ">
                    <mat-icon class="phone-width" [ngStyle]="{ 'color': homeSettings?.fontColor }">phone_in_talk</mat-icon>
                    <!-- <img [ngStyle]="{ 'color': homeSettings?.fontColor }" class="phone-width" src="/assets/icon/phone-call.svg"> -->

                    <div  class="contact-display">
                        <a [ngStyle]="{ 'color': homeSettings?.fontColor }" href="tel:{{homeSettings?.agencyPhone}}">{{homeSettings?.agencyPhone}}</a>
                    </div>
                </div>
            </div>
            <div *ngIf="!isDesktopMode() && homeSettings?.agencyPhone" class="contact">
                <div class="phone-position">
                    <a  href="tel:{{homeSettings?.agencyPhone}}">
                        <span [ngStyle]="{ 'color': homeSettings?.fontColor }" class="inner-circle">
                            <mat-icon  class="phone-width" [ngStyle]="{ 'color': homeSettings?.fontColor }">phone_in_talk</mat-icon>
                        </span>
                        <!-- <img class="phone-width" src="/assets/icon/white-phone.svg"> -->
                    </a>
                </div>
                    <div class="mail-position" *ngIf="homeSettings?.agencyEmail">
                    <a href="mailto:{{homeSettings?.agencyEmail}}">
                        <span [ngStyle]="{ 'color': homeSettings?.fontColor }" class="inner-circle">
                            <mat-icon  class="phone-width" [ngStyle]="{ 'color': homeSettings?.fontColor }">mail_outline</mat-icon>

                        </span>
                    </a>

                </div>
                <!-- <div class="mail-position">
                    <a href="mailto:{{propertyDetails?.host?.email}}">
                        <img class="mail-width" src="/assets/icon/white-mail.svg">
                    </a>
                </div> -->

            </div>
            <hr *ngIf="!propertyDetails.homePageWithoutTitle" [ngStyle]="{ 'border': '2px solid' + homeSettings?.fontColor }">
            <div *ngIf="!propertyDetails.homePageWithoutTitle" [ngStyle]="{ 'color': homeSettings?.fontColor }" class="property-name">
                {{propertyDetails?.bookletTitle ? propertyDetails.bookletTitle : propertyDetails.title }}
            </div>
            <div *ngIf="!propertyDetails.homePageWithoutTitle" [ngStyle]="{ 'color': homeSettings?.fontColor }" class="adresse">
                {{propertyDetails?.fullAddress}}
            </div>
       <!--<div style="padding-bottom: 100px;" *ngIf="!propertyDetails.homePageWithoutTitle && !propertyDetails.homePageWithoutLogo">
            <app-button (debounceClick)="navigateToLivret()" [type]="'primary'"
         text="{{'welcome-page.Access-to-booklet' | translate}} "></app-button>
        </div>-->
        </div>
    </div>


    <div style="position: fixed; bottom: 100px; width: 100%">
      <div class="y3-flex-row y3-flex-justify-content-center y3-full-width">
          <app-button (debounceClick)="navigateToLivret()" [type]="'primary'"
                    text="{{'welcome-page.Access-to-booklet' | translate}} "></app-button>
      </div>
    </div>

    <div class="width-100 blue-container">
        <span>{{'©' + year +' '+('welcome-page.copyrights' | translate) }}</span>
    </div>
