/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-property-guides.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./desktop/guest-property-guides-desktop.component.ngfactory";
import * as i3 from "./desktop/guest-property-guides-desktop.component";
import * as i4 from "../guest.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../core/services/utils.service";
import * as i7 from "../../../core/services/guest.service";
import * as i8 from "../../../core/services/language.service";
import * as i9 from "@angular/material/dialog";
import * as i10 from "@angular/common";
import * as i11 from "./guest-property-guides.component";
var styles_GuestPropertyGuidesComponent = [i0.styles];
var RenderType_GuestPropertyGuidesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestPropertyGuidesComponent, data: {} });
export { RenderType_GuestPropertyGuidesComponent as RenderType_GuestPropertyGuidesComponent };
function View_GuestPropertyGuidesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-property-guides-desktop", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GuestPropertyGuidesDesktopComponent_0, i2.RenderType_GuestPropertyGuidesDesktopComponent)), i1.ɵdid(1, 770048, null, 0, i3.GuestPropertyGuidesDesktopComponent, [i4.GuestServiceIntern, i5.Router, i5.ActivatedRoute, i6.UtilsService, i7.GuestService, i8.LanguageManagementService, i9.MatDialog], { property: [0, "property"], booking: [1, "booking"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.property; var currVal_1 = _co.booking; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_GuestPropertyGuidesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mytmpl: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["mytemplate", 1]], null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestPropertyGuidesComponent_1)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isDesktopMode() && _co.property); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_GuestPropertyGuidesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-property-guides", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestPropertyGuidesComponent_0, RenderType_GuestPropertyGuidesComponent)), i1.ɵdid(1, 770048, null, 0, i11.GuestPropertyGuidesComponent, [i4.GuestServiceIntern, i5.Router, i5.ActivatedRoute, i6.UtilsService, i7.GuestService, i9.MatDialog, i8.LanguageManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestPropertyGuidesComponentNgFactory = i1.ɵccf("app-guest-property-guides", i11.GuestPropertyGuidesComponent, View_GuestPropertyGuidesComponent_Host_0, { property: "property", booking: "booking" }, {}, []);
export { GuestPropertyGuidesComponentNgFactory as GuestPropertyGuidesComponentNgFactory };
