import {Component, Input, OnInit} from '@angular/core';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {UtilsService} from '../../../core/services/utils.service';
import {MatDialog} from '@angular/material';
import {PlatformAffiliateServices} from '../../../models/guestview/PlatformAffiliateServices';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';

@Component({
  selector: 'app-y3-plaform-services',
  templateUrl: './y3-plaform-services.component.html',
  styleUrls: ['./y3-plaform-services.component.scss']
})
export class Y3PlaformServicesComponent extends AbstractWindow implements OnInit {

  @Input() platformServices: PlatformAffiliateServices[];
  @Input() property: PropertyForGuest;
  @Input() curIndex: number;

  constructor(
              public utilsService: UtilsService,
              protected dialog: MatDialog) {
    super(utilsService);
  }

  ngOnInit() {
  }

}
