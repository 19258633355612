import * as i0 from "@angular/core";
export class LanguageManagementService {
    constructor() { }
    setLanguageInUrl(lang) {
        this.languageInUrl = lang;
    }
    getLanguageInUrl() {
        return this.languageInUrl;
    }
}
LanguageManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageManagementService_Factory() { return new LanguageManagementService(); }, token: LanguageManagementService, providedIn: "root" });
