import { Component, OnInit } from '@angular/core';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {GuestService} from '../../../core/services/guest.service';
import {LoaderService} from '../../../core/services/LoaderService';
import {ApiService} from '../../../core/http/api.service';
import {Location} from '@angular/common';
import {CategoryItem, Hygiene} from '../../../models/Hygiene';
import {takeUntil} from 'rxjs/operators';
import {InternalPropertyService} from '../../../core/services/internal.property.service';

@Component({
  selector: 'app-m5-hygien',
  templateUrl: './m5-hygien.component.html',
  styleUrls: ['./m5-hygien.component.scss']
})
export class M5HygienComponent extends AbstractWindow implements OnInit {

  property: PropertyForGuest;
  corporate: Corporate;
  booking: BookingForGuest;

  forceMajeursList: Hygiene;
  constructor(private activatedRoute: ActivatedRoute,
              public utilService: UtilsService,
              public translateService: TranslateService,
              private guestService: GuestService,
              private loaderService: LoaderService,
              private apiService: ApiService,
              public internPropertyServices: InternalPropertyService,
              private location: Location) {
    super(utilService);
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      this.loaderService.dismissLoader();
      if (res && res.propertyAndBooking) {
        this.booking = res.propertyAndBooking.booking;
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        //this.utilService.manageCorporateColors(this.corporate);
      }
    });

    this.guestService.getListForceMajeurs(this.translateService.currentLang).subscribe(resp => {
      if (resp) {
        // console.log('hygiene-forceMajeureList', resp);
        this.forceMajeursList = resp;
        this.internPropertyServices.getCurrentProperty().pipe(takeUntil(this.onDestroy)).subscribe(property => {
          // console.log('hygiene-property:', property);
          if (property) {
            this.property = property;
            //this.filterElement(this.forceMajeursList, this.property.forceMajeureItems);
            //this.listForceMajeurTodisplay = this.forceMajeursList.categoryTranslations;
          }
        });
      }
    });
  }

  getCategoryInProperty(itemCode: string): boolean {
    let retBoolean = false;
    if (this.property) {
      if (this.property.forceMajeureItems.indexOf(itemCode) >= 0) {
        retBoolean = true;
      }
    }
    return retBoolean;
  }

  isCategoryVisible(index: number): boolean {
    let categoryItems = null;
    let nbItemVisible = 0;
    if (this.forceMajeursList) {
      categoryItems =  this.forceMajeursList.categoryTranslations[index].categoryItems;
    }
    if (this.property) {
      //console.log(this.property.forceMajeureItems);
      if (categoryItems != null) {
        categoryItems.forEach(cat => {
          if (this.property.forceMajeureItems.indexOf(cat.itemCode) >= 0) {
            nbItemVisible = nbItemVisible + 1;
          }
        });
      }
    }
    return nbItemVisible>0;
  }

  getCategoryIndex(index: number): CategoryItem[] {
    let categoryItems = null;
    if (this.forceMajeursList) {
      categoryItems =  this.forceMajeursList.categoryTranslations[index].categoryItems;
    }
    return categoryItems;
  }
  goBack() {
    this.location.back();
  }


}
