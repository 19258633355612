import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfService} from '../conf.service';
import {LanguageManagementService} from '../language.service';
import {Observable} from 'rxjs';
import {GuestUpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/GuestUpSellOnBoarding';
import {UpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/UpSellOnBoarding';

@Injectable({ providedIn: 'root' })
export class GuestUpSellOnBoardingEndpoint {

  constructor(
    private http: HttpClient,
    private confService: ConfService) {

  }

  validateBasket(guestUpSellOnBoarding: GuestUpSellOnBoarding): Observable<UpSellOnBoarding> {
      return this.http.post<UpSellOnBoarding>(this.confService.getBackendApiEndPoint() + 'api-v2/guest/upsell-onboarding/create-or-update', guestUpSellOnBoarding);
  }

  cancelBasket(guestUpSellOnBoardingId: string): Observable<void> {
      return this.http.delete<void>(this.confService.getBackendApiEndPoint() + 'api-v2/guest/upsell-onboarding/' + guestUpSellOnBoardingId);
  }

}
