import { Injectable } from '@angular/core';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

@Injectable()
export class BugsnagChunkLoadErrorHandler extends BugsnagErrorHandler {

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else {
      super.handleError(error);
    }
  }

  addMetadata(propertyId: string, bookingId: string) {
    this.bugsnagClient.addMetadata("booking", { id: bookingId, property: propertyId });
  }

}
