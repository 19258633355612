/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-usefulnumber.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./desktop-guest-usefulnumber/desktop-guest-usefulnumber.component.ngfactory";
import * as i3 from "./desktop-guest-usefulnumber/desktop-guest-usefulnumber.component";
import * as i4 from "../../../core/services/guest.service";
import * as i5 from "../../../core/services/internal.property.service";
import * as i6 from "@angular/router";
import * as i7 from "../guest.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../core/services/utils.service";
import * as i10 from "@angular/common";
import * as i11 from "./guest-usefulnumber.component";
var styles_GuestUsefulnumberComponent = [i0.styles];
var RenderType_GuestUsefulnumberComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestUsefulnumberComponent, data: {} });
export { RenderType_GuestUsefulnumberComponent as RenderType_GuestUsefulnumberComponent };
function View_GuestUsefulnumberComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-desktop-guest-usefulnumber", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DesktopGuestUsefulnumberComponent_0, i2.RenderType_DesktopGuestUsefulnumberComponent)), i1.ɵdid(1, 114688, null, 0, i3.DesktopGuestUsefulnumberComponent, [i4.GuestService, i5.InternalPropertyService, i6.ActivatedRoute, i6.Router, i7.GuestServiceIntern, i8.TranslateService, i9.UtilsService], { property: [0, "property"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.property; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GuestUsefulnumberComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mytmpl: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["mytemplate", 1]], null, 2, "div", [["style", "background-color: #fafafa"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestUsefulnumberComponent_1)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.property; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_GuestUsefulnumberComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-usefulnumber", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestUsefulnumberComponent_0, RenderType_GuestUsefulnumberComponent)), i1.ɵdid(1, 114688, null, 0, i11.GuestUsefulnumberComponent, [i4.GuestService, i5.InternalPropertyService, i6.ActivatedRoute, i6.Router, i7.GuestServiceIntern, i8.TranslateService, i9.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestUsefulnumberComponentNgFactory = i1.ɵccf("app-guest-usefulnumber", i11.GuestUsefulnumberComponent, View_GuestUsefulnumberComponent_Host_0, { property: "property" }, {}, []);
export { GuestUsefulnumberComponentNgFactory as GuestUsefulnumberComponentNgFactory };
