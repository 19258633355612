/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-recommendations-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./guest-recommendations-map-desktop/guest-recommendations-map-desktop.component.ngfactory";
import * as i3 from "./guest-recommendations-map-desktop/guest-recommendations-map-desktop.component";
import * as i4 from "../../../../core/services/utils.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../../core/services/guest.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../../core/services/language.service";
import * as i9 from "../../../../core/services/collect-data-service.service";
import * as i10 from "../../../../core/http/api.service";
import * as i11 from "./guest-recommendations-map-mobile/guest-recommendations-map-mobile.component.ngfactory";
import * as i12 from "./guest-recommendations-map-mobile/guest-recommendations-map-mobile.component";
import * as i13 from "@angular/common";
import * as i14 from "./guest-recommendations-map.component";
var styles_GuestRecommendationsMapComponent = [i0.styles];
var RenderType_GuestRecommendationsMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestRecommendationsMapComponent, data: {} });
export { RenderType_GuestRecommendationsMapComponent as RenderType_GuestRecommendationsMapComponent };
function View_GuestRecommendationsMapComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-recommendations-map-desktop", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GuestRecommendationsMapDesktopComponent_0, i2.RenderType_GuestRecommendationsMapDesktopComponent)), i1.ɵdid(1, 114688, null, 0, i3.GuestRecommendationsMapDesktopComponent, [i4.UtilsService, i1.NgZone, i1.Renderer2, i5.Router, i6.GuestService, i7.TranslateService, i8.LanguageManagementService, i9.CollectDataServiceService, i10.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_GuestRecommendationsMapComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-recommendations-map-mobile", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_GuestRecommendationsMapMobileComponent_0, i11.RenderType_GuestRecommendationsMapMobileComponent)), i1.ɵdid(1, 114688, null, 0, i12.GuestRecommendationsMapMobileComponent, [i4.UtilsService, i1.NgZone, i1.Renderer2, i5.Router, i6.GuestService, i7.TranslateService, i8.LanguageManagementService, i9.CollectDataServiceService, i10.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_GuestRecommendationsMapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestRecommendationsMapComponent_2)), i1.ɵdid(2, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestRecommendationsMapComponent_3)), i1.ɵdid(4, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDesktopMode(); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isDesktopMode(); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_GuestRecommendationsMapComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { guruMapElement: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestRecommendationsMapComponent_1)), i1.ɵdid(2, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showMap; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GuestRecommendationsMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-recommendations-map", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestRecommendationsMapComponent_0, RenderType_GuestRecommendationsMapComponent)), i1.ɵdid(1, 114688, null, 0, i14.GuestRecommendationsMapComponent, [i4.UtilsService, i1.NgZone, i1.Renderer2, i5.Router, i6.GuestService, i7.TranslateService, i8.LanguageManagementService, i9.CollectDataServiceService, i10.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestRecommendationsMapComponentNgFactory = i1.ɵccf("app-guest-recommendations-map", i14.GuestRecommendationsMapComponent, View_GuestRecommendationsMapComponent_Host_0, {}, {}, []);
export { GuestRecommendationsMapComponentNgFactory as GuestRecommendationsMapComponentNgFactory };
