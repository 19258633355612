/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer-booklet-reviews.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../star-raiting/star-rating.component.ngfactory";
import * as i6 from "../star-raiting/star-rating.component";
import * as i7 from "@angular/forms";
import * as i8 from "../../../shared/components/button/button.component.ngfactory";
import * as i9 from "../../../shared/components/button/button.component";
import * as i10 from "./footer-booklet-reviews.component";
import * as i11 from "../../../core/http/api.service";
import * as i12 from "../../../core/services/SnackbarService";
import * as i13 from "../../../shared/components/shared-snackbar/services/shared-snackbar.service";
var styles_FooterBookletReviewsComponent = [i0.styles];
var RenderType_FooterBookletReviewsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterBookletReviewsComponent, data: {} });
export { RenderType_FooterBookletReviewsComponent as RenderType_FooterBookletReviewsComponent };
export function View_FooterBookletReviewsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "section", [["class", "booklet-bk-color"], ["id", "BOOKLET-REVIEWS"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 28, "div", [["class", "y2021-guest-section "]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "y3-padding-top-24 y3-margin-bottom-16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "material-symbols-outlined y3-steph-title-dot mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["storefront"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "y3-steph-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "y3-steph-title-dot"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "y3-Small y3-margin-top-4 y3-steph-color-text y3-sub-title-colors"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "range-box margin-top-16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-star-rating", [["class", "stars"]], null, [[null, "ratingChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ratingChange" === en)) {
        var pd_0 = ((_co.rating = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_StarRatingComponent_0, i5.RenderType_StarRatingComponent)), i1.ɵdid(16, 638976, null, 0, i6.StarRatingComponent, [], { rating: [0, "rating"] }, { ratingChange: "ratingChange" }), (_l()(), i1.ɵeld(17, 0, null, null, 1, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, ["", ""])), (_l()(), i1.ɵeld(19, 0, null, null, 10, "div", [["class", "y2021-flexrow margin-top-16 div-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 6, "div", [["class", "textarea-for-review"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 5, "textarea", [["rows", "10"], ["style", "width: 100%"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 22).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 22)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 22)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.textReview = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(24, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(26, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵeld(27, 0, null, null, 2, "app-button", [], null, [[null, "debounceClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("debounceClick" === en)) {
        var pd_0 = (_co.saveReview() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ButtonComponent_0, i8.RenderType_ButtonComponent)), i1.ɵdid(28, 114688, null, 0, i9.ButtonComponent, [], { text: [0, "text"], type: [1, "type"] }, { debounceClick: "debounceClick" }), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_4 = _co.rating; _ck(_v, 16, 0, currVal_4); var currVal_13 = _co.textReview; _ck(_v, 24, 0, currVal_13); var currVal_14 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 28, 0, i1.ɵnov(_v, 29).transform("booklet-review.avis-submit")), ""); var currVal_15 = "steph-button-blue"; _ck(_v, 28, 0, currVal_14, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).inline; var currVal_1 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("booklet-review.avis")); _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("booklet-review.avis-subtitle")); _ck(_v, 12, 0, currVal_3); var currVal_5 = _co.rating; _ck(_v, 18, 0, currVal_5); var currVal_6 = i1.ɵnov(_v, 26).ngClassUntouched; var currVal_7 = i1.ɵnov(_v, 26).ngClassTouched; var currVal_8 = i1.ɵnov(_v, 26).ngClassPristine; var currVal_9 = i1.ɵnov(_v, 26).ngClassDirty; var currVal_10 = i1.ɵnov(_v, 26).ngClassValid; var currVal_11 = i1.ɵnov(_v, 26).ngClassInvalid; var currVal_12 = i1.ɵnov(_v, 26).ngClassPending; _ck(_v, 21, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }); }
export function View_FooterBookletReviewsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer-booklet-reviews", [], null, null, null, View_FooterBookletReviewsComponent_0, RenderType_FooterBookletReviewsComponent)), i1.ɵdid(1, 638976, null, 0, i10.FooterBookletReviewsComponent, [i11.ApiService, i4.TranslateService, i12.SnackbarService, i13.SharedSnackbarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterBookletReviewsComponentNgFactory = i1.ɵccf("app-footer-booklet-reviews", i10.FooterBookletReviewsComponent, View_FooterBookletReviewsComponent_Host_0, { bookletReviews: "bookletReviews", bookingId: "bookingId" }, {}, []);
export { FooterBookletReviewsComponentNgFactory as FooterBookletReviewsComponentNgFactory };
