<div *ngIf="guides && guides.length>0" id="propertyGuide" name="propertyGuide"
     class="guest-view-desktop-property-guide guide-back-color separator" #propertyGuide>




  <div class="container-guide">

    <div class=" y3-margin-bottom-16">
      <mat-icon class="material-symbols-outlined y3-steph-title-dot">storefront</mat-icon>
      <div class="y3-steph-title">{{'guest-guid-page.title' | translate}}<span class="y3-steph-title-dot">.</span></div>
      <div class="y3-Small y3-margin-top-4 y3-steph-color-text y3-sub-title-colors">{{'guest-guid-page.title-explanation' | translate}}</div>
    </div>

    <!--<div class="title-wrapper">
      <h3 *ngIf="!property?.host?.btobInfo" class="margin-top-16 y3-title-colors">{{'guest-guid-page.title' | translate}}</h3>
      <h3 *ngIf="property?.host?.btobInfo" class="y3-title-colors">{{'guest-guid-page.btob-title' | translate}}</h3>
        <img src="../../../../assets/icon/propertyGuideLine.svg">
    </div>-->


      <!--<span class="roboto txt-color-scorpion Small margin-top-8 y3-sub-title-colors" *ngIf="!property?.host?.btobInfo">{{'guest-guid-page.title-explanation' | translate}}</span>-->

      <div class="margin-top-24 ">
        <app-tabs-menu  [tabs]="categoryTabs" (selected)="onTabMenuSelected($event)"></app-tabs-menu>
      </div>

      <div class="width-100 flex flex-row flex-wrap margin-top-24" >
        <div *ngFor="let guide of currentGuidesList; let i = index">
          <div *ngIf="needToShowGuide(guide)" class="width-100 guide-card flex flex-row flex-align-center margin-right-16 y3-margin-bottom-16 clickable" matRipple

                (click)="openGuideDetails(guide)">
            <img class="guide-cover" src="{{getGuideCover(guide)}}" alt="cover">

            <span class="guide-title y3-margin-left-8 ">
             {{ guide?.translations[0]?.title}}
            </span>
          </div>
        </div>
      </div>

  </div>

</div>

<div class="details-dialog-container" *ngIf="currentGuide && showGuideDetails">

  <div class="details-container">
    <!--<img src="assets/icon/close-round.svg" alt="icon" class="closee clickable" (click)="closeGuide()">-->

    <app-guide-details [booking]="booking" [property]="property" [guide]="currentGuide"></app-guide-details>
  </div>

</div>
