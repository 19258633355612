import {GuestIdentityDetails} from '../GuestIdentityDetails';


export class OnlineCheckin {
  id: string;
  propertyId: string;
  propertyBookingId: string;
  identityProofUrls: string[];
  checkStatus: string;
  contractDocumentUrl: string;
  contractSignaturePictureUrl: string;
  contractDocumentUrlSigned: string;
  guestCountry: string;
  guestGender: string;
  guestLanguage: string;
  guestBirthDate: string;
  guestAddress: string;
  firstName: string;
  lastName: string;
  email: string;
  personalEmail: string;
  phoneNumber: string;
  secondaryPhoneNumber: string;
  reasonRefused: string;
  onLineCheckInTime: string;
  onLineCheckOutTime: string;
  cgvDocumentUrl: string;
  onLineCheckWithTransport: boolean | any;
  reasonRefusedTranslated: string;
  onlineCheckGuestSendingTime: string;
  onlineCheckGuestApprovalTime: string;

  stayType: string;
  stayTransport: string;
  stayInterests: string[];
  physicalWelcome: boolean;

  planeArrivalFlightNumber: string;
  planeArrivalTime: string;
  planeDepartureFlightNumber: string;
  planeDepartureTime: string;

  adults: number = 0;
  children: number = 0;
  babies: number = 0;
  pets: number = 0;
  parkings: number = 0;

  doubleBeds: number = 0;
  singleBeds: number = 0;
  sofaBeds: number = 0;
  babyBeds: number = 0;

  guestNote: string;

  guestIdentityDetailList: GuestIdentityDetails[];


}



