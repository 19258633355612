export class GenericLinkBookingCreation {
  propertyId: string;
  startDate: string;
  endDate: string;
  guestFirstname: string;
  guestLastname: string;
  guestEmail: string;
  guestPhone: string;
  guestLanguage: string;
  platformName: string;
}
