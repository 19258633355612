<footer id="footer" style="display: block" class="its--wrapper-full footer-block-its-dark">
  <div id="upper_footer">
    <div class="its--container">
      <div class="its--row datos_empresa-wrapper links-wrapper ayuda-wrapper propietarios-wrapper"><div class="its--container"><div class="its--col-sm-4"><div class="widget datos_empresa"><div id="address_widget" class="widget">
        <a href="https://www.cotoon.com/"><img src="https://www.cotoon.com/child/assets/img/logo-light.png"></a>
        <p class="direccion_empresa">
          <!-- 4 Rue Basque, 68000 Colmar France  -->
          <u>Notre agence à Colmar</u>
        </p>

        <div class="widget_inner">
          <p class="" style="padding: 5px;">
            <img src="assets/icon/phone-call.svg" width="19px" style=" margin-bottom: -3px; margin-right: 15px;" > <a href="tel:03.69.618.618">03.69.618.618</a>
          </p>
          <p class="av-tel" style="padding: 5px;">
            <i class="" style="padding-left: 0.35em;"></i>4 Rue Basque, 68000 Colmar France
          </p>
        </div>

    

        <hr>

        <p class="av-email">
          <img src="assets/icon/mail-white.svg" width="19px" style=" margin-bottom: -3px; margin-right: 15px;" >
          <i class="icon-envelope"></i> <a href="mailto:info@cotoon.com">info@cotoon.com</a>
        </p>
      </div>
      </div></div><div class="its--col-sm-4"><div class="widget links"><div id="links_widget" class="widget">
        <p class="title ">Liens rapides</p>
        <div class="widget_inner">
          <ul>
            <li><a href="https://www.cotoon.com/">Accueil</a></li><li><a href="https://www.cotoon.com/location/location-locations-d0/">Location</a></li><li><a href="https://www.cotoon.com/forme/">Location Longue Durée </a></li><li><a href="https://www.cotoon.com/nos-services/">Nos Services</a></li><li><a href="https://www.cotoon.com/louer-votre-bien/">Louer votre bien</a></li><li><a href="https://www.cotoon.com/contact/">Contact</a></li>        </ul>
        </div>
      </div>
      </div></div><div class="its--col-sm-4"><div class="widget ayuda"><div id="links_widget" class="widget">
        <p class="title ">Aider</p>
        <div class="widget_inner">
          <ul>
            <li><a href="https://www.cotoon.com/conditions-generales/">Conditions générales</a></li><li><a href="https://www.cotoon.com/politique-de-cookies/">Politique de Cookies</a></li><li><a href="https://www.cotoon.com/mentions-legales/">Mentions Légales</a></li>        </ul>
        </div>
      </div>
      </div><div class="widget propietarios"><div class="propietarios-footer">
        <hr>
        <a href="https://www.cotoon.com/acces-proprietaire/" class="btn btn-link btn-xs">Accès propriétaire</a>
      </div>
      </div></div></div></div>          </div>
  </div>
  <!--<div id="lower_footer">
    <div class="its--container">
      <div class="its--row">
        <div id="social_widget" class="its--col-sm-5">
          <ul>
            <li><a href="https://www.facebook.com/cotoonalsace" target="_blank" class="icon-facebook"></a></li>
            <li><a href="https://twitter.com/COTOONalsace" target="_blank" class="icon-twitter"></a></li>
          </ul>
        </div>
        <div id="designed_by" class="its--col-sm-2">
          <a href="https://www.avantio.fr/" rel="nofollow" target="_blank"><img src="https://fwk.avantio.com/assets/core-7.0/img/avantio_blanco.svg" alt="Avantio"></a>        </div>
        <div id="tipo_pago" class="its--col-sm-5">
          <ul class="list-inline"><li><img class="bank-transfer" src="https://fwk.avantio.com/assets/core-7.0/img/bank-transfer.svg"></li><li><img class="mastercard" src="https://fwk.avantio.com/assets/core-7.0/img/mastercard.svg"></li><li><img class="visa" src="https://fwk.avantio.com/assets/core-7.0/img/visa.svg"></li><li><img class="paypal" src="https://fwk.avantio.com/assets/core-7.0/img/paypal.svg"></li><li><img src="https://www.cotoon.com/child/assets/img/amex.png" alt="American Express"></li></ul>                  </div>
      </div>
    </div>
  </div>-->
</footer>
