<div *ngIf="!property.noshowPlatformServicesOnBooklet" class="y3-divider y3-margin-top-24 y3-margin-bottom-32 {{(curIndex%2===0)?'y3-white':'y3-white'}} platform-bk-color">
  <div class="y3-container "  >


    <div class="y3-padding-top-48 y3-margin-bottom-16">
      <mat-icon class="material-symbols-outlined y3-steph-title-dot">privacy_tip</mat-icon>
      <div class="y3-steph-title"> {{'guest-platefromservices-page.title' | translate}}<span class="y3-steph-title-dot">.</span></div>
    </div>

    <!--<div class="y3-divider-title y3-XX-large y3-McQueen y3-margin-bottom-24 y3-title-colors">
      {{'guest-platefromservices-page.title' | translate}}
    </div>-->

    <!--
    <div class="y3-margin-bottom-24 y3-Large y3-McQueen y3-flex-responsive y3-flex-justify-content-center ">
      <div style="text-align: center; width: 100%">
        <app-tag sizeText2="'16px'" minWidth="{{isDesktopMode()? '1024px': '100%'}}" text="{{'guest-platefromservices-page.title' | translate}}" [type]="'deproprietaire'"></app-tag>
      </div>
    </div>
    -->


    <div class="y3-flex-responsive">
      <div class="y3-service-card" *ngFor="let p of platformServices; let i = index">
        <div class="y3-service-image-container y3-padding-16">
          <img [id]="'platformLogo' + i" class="y3-service-image" [src]="p.logoUrl" alt="partner-img"/>
        </div>
        <div class="y3-card-description">
          <div class="y3-service-title">
            {{p.name}}
          </div>
          <div class="y3-service-description" *ngIf="p.translations && p.translations.length > 0">
            {{p.translations[0].description}}
          </div>

          <div class="partner-link y3-margin-top-8" *ngIf="p.translations && p.translations.length > 0">
            <a [id]="'platformLink' + i" class="alink-no-decoration " target="_blank" [href]="p.translations[0].webSiteUrl">{{'guest-platefromservices-page.more-btn'  | translate}}
            </a>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
