<app-y3-header [corporate]="corporate" [booking]="booking" [property]="property" [displayChat]="false"></app-y3-header>

<div class="y3-container chat-container {{isDesktopMode()?'y3-padding-top-32':'y3-padding-top-0'}}">

  <div class="y3-flex-row y3-flex-justify-space-between">
    <div>
      <div class="y3-yaago-title-page y3-title-colors" >{{'chat.title' | translate}}</div>
      <div class="y3-yaago-sub-title-page y3-sub-title-colors" >{{'chat.sub-title' | translate}}</div>
    </div>
    <div class="y3-clickable">
      <img src="./assets/icon/close-round.svg" (click)="back()" >
    </div>
  </div>

  <div class="y3-message-container">

    <div *ngFor="let msg of messages; let i = index">
      <div *ngIf="msg.side === 'IN'" style="margin-bottom: 24px;">
        <div style="display: flex; flex-flow: row">
          <div style="min-width: 25%" *ngIf="msg.side === 'IN'">&nbsp;</div>
          <div class="chat-message-out" *ngIf="msg.side === 'IN'">
            <div class="chat-content" [innerHTML]="replaceLinks(msg.content)"></div>
          </div>
        </div>

        <div style="display: flex; flex-flow: row">
          <div style="min-width: 25%" >&nbsp;</div>
          <div class="title-message-in-container chat-content-title " *ngIf="msg.side === 'IN'">
            <div class="div-img" *ngIf="msg.type==='INTERNAL'"><img class="icon-message" src="assets/icon/messaging/chat.png">{{utilsService.formatDateTime(msg.sendingDate)}} - DIRECT</div>
            <div class="div-img" *ngIf="msg.type==='SMS'"><img class="icon-message" src="assets/icon/messaging/sms.png">{{utilsService.formatDateTime(msg.sendingDate)}} - SMS</div>
            <div class="div-img" *ngIf="msg.type==='WHATSAPP'"><img class="icon-message" src="assets/icon/messaging/icon-whatsapp.svg">{{utilsService.formatDateTime(msg.sendingDate)}} - WHATSAPP</div>
          </div>
        </div>
      </div>

      <div *ngIf="msg.side === 'OUT'" style="margin-bottom: 24px;">

        <div style="display: flex; flex-flow: row; align-items: center">
          <div *ngIf="property?.showMeAsHost && property?.coHostInCharge == null" class="card padding-right-24 person-container ">
            <div class="picture-container">
              <img *ngIf="property?.host?.pictureUrl" src="{{property?.host.pictureUrl}}" alt="picture">
              <span *ngIf="!property?.host?.pictureUrl" class="roboto Large picture-placeholder">
                  {{utilsService.getFirstLetter(property?.host)}}
                  </span>
            </div>
          </div>
          <div *ngIf="property?.coHostInCharge != null" class="card padding-right-24 person-container ">
            <div class="picture-container">
              <img *ngIf="property?.coHostInCharge?.pictureUrl" src="{{property?.coHostInCharge.pictureUrl}}" alt="picture">
              <span *ngIf="!property?.coHostInCharge?.pictureUrl" class="roboto Large picture-placeholder">
                  {{utilsService.getFirstLetter(property?.coHostInCharge)}}
                </span>
            </div>
          </div>

          <div class="chat-message-in" *ngIf="msg.side === 'OUT'">
            <div class="chat-content" [innerHTML]="replaceLinks(msg.content)"></div>
          </div>

        </div>

        <div class="title-message-out-container chat-content-title " *ngIf="msg.side === 'OUT'">
          <div class="div-img" *ngIf="msg.type==='INTERNAL'"><img class="icon-message" src="assets/icon/messaging/chat.png">{{utilsService.formatDateTime(msg.sendingDate)}} - DIRECT</div>
          <div class="div-img" *ngIf="msg.type==='SMS'"><img class="icon-message" src="assets/icon/messaging/sms.png">{{utilsService.formatDateTime(msg.sendingDate)}} - SMS</div>
          <div class="div-img" *ngIf="msg.type==='WHATSAPP'"><img class="icon-message" src="assets/icon/messaging/icon-whatsapp.svg">{{utilsService.formatDateTime(msg.sendingDate)}} - WHATSAPP</div>
        </div>
      </div>

    </div>

  </div>




  <div class="y3-flex-responsive y3-flex-align-center">
    <div style="width: 100%">
      <textarea class="textarea-note" [(ngModel)]="textToSend" matInput matTextareaAutosize maxlength="300"  ></textarea>
    </div>
    <div class="y3-flex-row {{!isDesktopMode()?'y3-margin-top-8 y3-flex-justify-space-between y3-full-width': ''}}" >
      <div class="{{isDesktopMode()?'y3-margin-left-16':''}}">
        <app-shared-upload [uploadType]="'single'"
                           [btnLabel]="'guest-inventory.upload'"
                           (docUploaded)="onPictureUploaded($event)"></app-shared-upload>
      </div>
      <div class="y3-margin-left-16">
        <app-button [text]="'chat.send'" [type]="'secondary-small'" (debounceClick)="sendMessage()"></app-button>
      </div>
    </div>
  </div>

</div>

