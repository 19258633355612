/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upsale-category-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./upsale-category-list.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../../core/http/api.service";
import * as i7 from "../../../../core/services/utils.service";
import * as i8 from "../../../../core/services/language.service";
import * as i9 from "../../../../core/services/guest.service";
var styles_UpsaleCategoryListComponent = [i0.styles];
var RenderType_UpsaleCategoryListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UpsaleCategoryListComponent, data: {} });
export { RenderType_UpsaleCategoryListComponent as RenderType_UpsaleCategoryListComponent };
function View_UpsaleCategoryListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "new-upsale-card"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setCurrentCategory(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "new-upsale-photo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "new-upsale-descr"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "header1 truncate-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "reco-title truncate-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_v.context.$implicit.categoryPictureUrl == null) ? "assets/icon/recommendation/empty/activities.jpg" : _v.context.$implicit.categoryPictureUrl), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.categoryTitle; _ck(_v, 5, 0, currVal_1); }); }
function View_UpsaleCategoryListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "new-upsale-card-container clickable"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpsaleCategoryListComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.upsaleCategoryAndProductDtos; _ck(_v, 2, 0, currVal_0); }, null); }
function View_UpsaleCategoryListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "previous-photo"], ["src", "assets/icon/upsale/Back_Arrow.svg.png"]], null, null, null, null, null))], null, null); }
function View_UpsaleCategoryListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "previous-photo-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpsaleCategoryListComponent_6)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.productTitle === "Previous"); _ck(_v, 3, 0, currVal_0); }, null); }
function View_UpsaleCategoryListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "new-upsale-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "new-upsale-photo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "new-upsale-descr"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "header1 truncate-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "reco-title truncate-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "price"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["\u00E0 partir de ", " "])), i1.ɵppd(8, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_v.parent.context.$implicit.productPictureUrl == null) ? "assets/icon/recommendation/empty/activities.jpg" : _v.parent.context.$implicit.productPictureUrl), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.productTitle; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent.parent, 0), _co.getPrice(_v.parent.context.$implicit), _co.getCurrency(_v.parent.context.$implicit))); _ck(_v, 7, 0, currVal_2); }); }
function View_UpsaleCategoryListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "new-upsale-card background-cat"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setCurrentProduct(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpsaleCategoryListComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpsaleCategoryListComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.productTitle === "Previous"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.productTitle !== "Previous"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_UpsaleCategoryListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "new-upsale-card-container clickable"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpsaleCategoryListComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentProductList; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_UpsaleCategoryListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 13, "section", [["class", "section-upsale"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "gris-back container-upsale"], ["id", "platformServices"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "guest-view-desktop-services separator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["style", "width: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "h3", [["class", "big-title y3-title-colors"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "Small sub-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpsaleCategoryListComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpsaleCategoryListComponent_3)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.currentProductList == null); _ck(_v, 12, 0, currVal_2); var currVal_3 = (_co.currentProductList != null); _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("guest-platefromservices-page.title")); _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("guest-platefromservices-page.description")); _ck(_v, 9, 0, currVal_1); }); }
export function View_UpsaleCategoryListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upsale-category-list", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_UpsaleCategoryListComponent_0, RenderType_UpsaleCategoryListComponent)), i1.ɵdid(1, 114688, null, 0, i4.UpsaleCategoryListComponent, [i5.Router, i5.ActivatedRoute, i6.ApiService, i3.TranslateService, i7.UtilsService, i8.LanguageManagementService, i9.GuestService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UpsaleCategoryListComponentNgFactory = i1.ɵccf("app-upsale-category-list", i4.UpsaleCategoryListComponent, View_UpsaleCategoryListComponent_Host_0, { booking: "booking", upsaleCategoryAndProductDtos: "upsaleCategoryAndProductDtos" }, {}, []);
export { UpsaleCategoryListComponentNgFactory as UpsaleCategoryListComponentNgFactory };
