/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-recommendations-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./guest-recommendations-card-desktop/guest-recommendations-card-desktop.component.ngfactory";
import * as i3 from "./guest-recommendations-card-desktop/guest-recommendations-card-desktop.component";
import * as i4 from "../../../../core/services/utils.service";
import * as i5 from "../../../../core/services/guest.service";
import * as i6 from "../../../../core/services/language.service";
import * as i7 from "../../../../core/services/collect-data-service.service";
import * as i8 from "./guest-recommendations-card-mobile/guest-recommendations-card-mobile.component.ngfactory";
import * as i9 from "./guest-recommendations-card-mobile/guest-recommendations-card-mobile.component";
import * as i10 from "@angular/common";
import * as i11 from "./guest-recommendations-card.component";
var styles_GuestRecommendationsCardComponent = [i0.styles];
var RenderType_GuestRecommendationsCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestRecommendationsCardComponent, data: {} });
export { RenderType_GuestRecommendationsCardComponent as RenderType_GuestRecommendationsCardComponent };
function View_GuestRecommendationsCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-recommendations-card-desktop", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GuestRecommendationsCardDesktopComponent_0, i2.RenderType_GuestRecommendationsCardDesktopComponent)), i1.ɵdid(1, 245760, null, 0, i3.GuestRecommendationsCardDesktopComponent, [i4.UtilsService, i5.GuestService, i6.LanguageManagementService, i7.CollectDataServiceService], { bookingId: [0, "bookingId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bookingId; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GuestRecommendationsCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-recommendations-card-mobile", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_GuestRecommendationsCardMobileComponent_0, i8.RenderType_GuestRecommendationsCardMobileComponent)), i1.ɵdid(1, 245760, null, 0, i9.GuestRecommendationsCardMobileComponent, [i4.UtilsService, i5.GuestService, i6.LanguageManagementService, i7.CollectDataServiceService], { bookingId: [0, "bookingId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bookingId; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GuestRecommendationsCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestRecommendationsCardComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestRecommendationsCardComponent_2)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDesktopMode(); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isDesktopMode(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_GuestRecommendationsCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-recommendations-card", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestRecommendationsCardComponent_0, RenderType_GuestRecommendationsCardComponent)), i1.ɵdid(1, 245760, null, 0, i11.GuestRecommendationsCardComponent, [i4.UtilsService, i5.GuestService, i6.LanguageManagementService, i7.CollectDataServiceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestRecommendationsCardComponentNgFactory = i1.ɵccf("app-guest-recommendations-card", i11.GuestRecommendationsCardComponent, View_GuestRecommendationsCardComponent_Host_0, { bookingId: "bookingId" }, {}, []);
export { GuestRecommendationsCardComponentNgFactory as GuestRecommendationsCardComponentNgFactory };
