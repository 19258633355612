import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PaysService {
    constructor(http) {
        this.http = http;
    }
    getPays(lang) {
        return this.http.get('assets/pays.json');
    }
}
PaysService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaysService_Factory() { return new PaysService(i0.ɵɵinject(i1.HttpClient)); }, token: PaysService, providedIn: "root" });
