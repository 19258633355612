
<div class="header">
<div class="header-title">{{'property-gallery.property-gallery' | translate}}</div>
  <div class="close-class" (click)="onClose()">
    <img src="assets/icon/cross.svg" alt="icon-close" />
  </div>
</div>

<div class="img-body" *ngIf="myPhotos[index]">
  <img [src]="myPhotos[index].img" alt="photo" class="photo" />

  <div class="navigation navigation-right clickable" *ngIf="myPhotos.length>(index+1)" (click)="navigateRight()">
    <img class="icon-arrow-right" src="assets/icon/back-icon-arrow.svg" alt="arrow-right"/>
  </div>
  <div class="navigation navigation-left clickable" *ngIf="index>0" (click)="navigateLeft()">
      <img class="icon-arrow-left" src="assets/icon/back-icon-arrow.svg" alt="arrow-left"/>
  </div>
</div>

<div class="num-photo" *ngIf="myPhotos">{{index+1}}/{{myPhotos.length}}</div>

<div class="caption" *ngIf="myPhotos[index] && myPhotos[index].caption && myPhotos[index].caption !== ''">
  <div class="caption-text">{{'property-gallery.caption' | translate}}</div>
  <div class="caption-desc px-1">{{myPhotos[index].caption}}</div>
</div>

