import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guest-host-services',
  templateUrl: './guest-host-services.component.html',
  styleUrls: ['./guest-host-services.component.scss']
})
export class GuestHostServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
