import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./conf.service";
export class CollectDataServiceService {
    constructor(http, confService) {
        this.http = http;
        this.confService = confService;
        this.EndpointDataCollect = this.confService.getBackendApiEndPoint() + 'api-v2/guest/profiling';
        this.RecommendationUrl = this.EndpointDataCollect + "/recommendation";
        this.CategorieUrl = this.EndpointDataCollect + "/category";
        this.TagUrl = this.EndpointDataCollect + "/tag";
    }
    createProfileReview(guestId, recommendationId) {
        this.eventClickRecommendation = {
            guestId: guestId,
            recommendationId: recommendationId,
        };
        this.guestReviews = {
            eventClick: this.eventClickRecommendation,
            nbClickRecoCard: 0,
            nbClickRecoMarker: 0,
            nbClickRecoWebSite: 0,
            nbClickRecoDirection: 0,
            clickOnSliderPictures: false,
            isLike: false
        };
    }
    createProfileCategorie(guestId, category) {
        this.eventClickCategory = {
            guestId: guestId,
            category: category
        };
        this.guestCategory = {
            eventClickCategory: this.eventClickCategory,
            nbClickCategory: 0
        };
    }
    createProfileTag(guestId, tagId) {
        this.eventClickTag = {
            guestId: guestId,
            tagId: tagId
        };
        this.guestTag = {
            eventClickTag: this.eventClickTag,
            nbClickTag: 0
        };
    }
    // If profile click on Tag in Categorie
    setOnEventClickTag(guestId, tagId) {
        this.createProfileTag(guestId, tagId);
        return this.http.post(this.TagUrl, this.guestTag).toPromise().then((data) => {
            console.log(data);
        });
    }
    // If profile click on Categories :
    setOnEventClickCategory(guestId, categorie_id) {
        this.createProfileCategorie(guestId, categorie_id);
        return this.http.post(this.CategorieUrl, this.guestCategory).toPromise().then((data) => {
            console.log(data);
        });
    }
    // IF profile click on Marker in Map
    setOnClickMarker(guestId, recommendation_id) {
        this.createProfileReview(guestId, recommendation_id);
        return this.http.post(this.RecommendationUrl + "/RecommendationMarker", this.guestReviews).toPromise().then((data) => {
            console.log(data);
            this.guestReviews = data;
        });
    }
    setOnClickVisteWebSite(guestId, recommendation_id) {
        this.createProfileReview(guestId, recommendation_id);
        return this.http.post(this.RecommendationUrl + "/RecommendationWebsite", this.guestReviews).toPromise().then((data) => {
            console.log(data);
        });
    }
    setOnClickSyRendre(guestId, recommendation_id) {
        this.createProfileReview(guestId, recommendation_id);
        return this.http.post(this.RecommendationUrl + "/RecommendationDirection", this.guestReviews).toPromise().then((data) => {
            console.log(data);
        });
    }
    setOnClickNextInPhoto(guestId, reccommendation_id) {
        this.createProfileReview(guestId, reccommendation_id);
        return this.http.post(this.RecommendationUrl + "/RecommendationSlider", this.guestReviews).toPromise().then((data) => {
            console.log(data);
        });
    }
    setOnClickItemInRecomendation(guestId, recommendation_id) {
        this.createProfileReview(guestId, recommendation_id);
        return this.http.post(this.RecommendationUrl + "/RecommendationCard", this.guestReviews).toPromise().then((data) => {
            console.log(data);
        });
    }
}
CollectDataServiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CollectDataServiceService_Factory() { return new CollectDataServiceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfService)); }, token: CollectDataServiceService, providedIn: "root" });
