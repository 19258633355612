import { Component, OnInit } from '@angular/core';
import {UpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/UpSellOnBoarding';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/services/utils.service';
import {GuestUpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/GuestUpSellOnBoarding';
import {LoaderService} from '../../../core/services/LoaderService';
import {GuestUpSellOnBoardingEndpoint} from '../../../core/services/V3_UpSellOnboarding/GuestUpSellOnBoardingEndpoint';

@Component({
  selector: 'app-m24-upsells-recap',
  templateUrl: './m24-upsells-recap.component.html',
  styleUrls: ['./m24-upsells-recap.component.scss']
})
export class M24UpsellsRecapComponent implements OnInit {

  realUpSellOnBoarding: UpSellOnBoarding;

  constructor(private activatedRoute: ActivatedRoute,
              private loaderService: LoaderService,
              private guestUpSellOnBoardingEndpoint: GuestUpSellOnBoardingEndpoint,
              public utilService: UtilsService) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      console.log("this is the currentUpSellOnBoarding", res);
      this.realUpSellOnBoarding = res.propertyAndBooking.upSellOnBoarding;
    });
  }

  cancelUpsell() {
    this.loaderService.showFullLoader('');
    this.guestUpSellOnBoardingEndpoint.cancelBasket(this.realUpSellOnBoarding.id).subscribe( res => {
      this.loaderService.dismissLoader();
    });
  }


}
