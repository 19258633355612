import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {GuestService} from '../../../core/services/guest.service';
import {LoaderService} from '../../../core/services/LoaderService';
import {Location} from '@angular/common';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {MessageAudit} from '../../../models/guestview/MessageAudit';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {ApiService} from '../../../core/http/api.service';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {MessageCreationDto} from '../../../models/guestview/MessageCreationDto';
import {YaagoDocument} from '../../../models/Guide';

@Component({
  selector: 'app-m7-chat',
  templateUrl: './m7-chat.component.html',
  styleUrls: ['./m7-chat.component.scss']
})
export class M7ChatComponent extends AbstractWindow implements OnInit {

  messages: MessageAudit[] = [];
  property: PropertyForGuest;
  corporate: Corporate;
  booking: BookingForGuest;

  textToSend: string = '';
  constructor(private activatedRoute: ActivatedRoute,
              public utilService: UtilsService,
              public translateService: TranslateService,
              private guestService: GuestService,
              private loaderService: LoaderService,
              private apiService: ApiService,
              private location: Location) {
    super(utilService);
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      this.loaderService.dismissLoader();
      if (res && res.propertyAndBooking) {
        this.booking = res.propertyAndBooking.booking;
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        //this.utilService.manageCorporateColors(this.corporate);
      }
    });

    this.apiService.getMessages(this.booking.id).subscribe( msgs => {
      console.log("MESSGBES", msgs);
      if (msgs != null && this.messages != null && msgs.length != this.messages.length) {
        this.messages = msgs;
        setTimeout( () => {
          this.scrollToLastMessage();
        }, 1000);
        console.log('--> myMessages', this.messages);
      }
    });
  }

  scrollToLastMessage() {
    let htmlElement = document.getElementById("message-container");
    if (htmlElement != null) {
      htmlElement.scrollTo({ behavior: 'smooth', top: htmlElement.scrollHeight });
    }
  }

  replaceLinks(content: string) {
    if (!content) {
      return content;
    }
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, (url) => {
      return '<div class="chat-link-container"><a href="' + url + '" target="_blank">' + url + '</a></div>';
    });
  }
  goBack() {
    this.location.back();
  }
  onPictureUploaded(doc: YaagoDocument) {
    this.textToSend += ' ' + doc.url;
  }
  sendMessage() {
    if (this.textToSend != null && this.textToSend.trim() != '') {
      this.loaderService.showFullLoader('');
      const message = new MessageCreationDto();
      message.type = 'internal';
      message.content = this.textToSend;
      this.apiService.sendMessage(this.booking.id, message).subscribe( messageBack => {
        this.messages.push(messageBack);
        this.loaderService.dismissLoader();
        this.textToSend = '';
        setTimeout( () => {
          this.scrollToLastMessage();
        }, 500);
        console.log(this.messages);
      });
    }
  }

}
