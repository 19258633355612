<div class="upsell-recap-section">
  <div class="y3-High y3-bold y3-margin-bottom-16 s23-title-color " style="text-align: center">{{'m23-upsell-recap.your-current-command' | translate}}</div>

  <div class="y3-full-width y3-Small">
    <table class="y3-full-width">
      <tr *ngFor="let item of currentUpSellOnBoarding.guides">
        <td><span *ngIf="item.upSellSection.needQty">{{item.upSellSection.relatedQty}} x </span> {{item.translations[0].title}}
          <span class="y3-XX-small" *ngIf="item.upSellSection.upSellToValidate"> (*)</span>
        </td>
        <td style="text-align: right">{{calculateItem(item).toFixed(2) | currency: getCurrency()}}</td>
      </tr>
      <tr class="y3-full-width y3-bold ">
        <td class="y3-padding-top-16" style="width: 75%">Total</td>
        <td class="y3-padding-top-16"  style="text-align: right">{{calculateTotalPrice().toFixed(2) | currency: getCurrency()}}</td>
      </tr>
    </table>

    <div *ngIf="isValidationNeeded()" class="y3-X-small y3-margin-top-8">
      <span class="y3-XX-small">(*)</span> Votre commande contient certain(s) article(s) sousmis à la validation de notre équipe. Vous serez contacté dans les plus brefs délais.
    </div>

  </div>


  <div class="y3-margin-top-16 y3-flex-row y3-flex-align-center y3-flex-justify-content-end">
    <div>
      <app-button [text]="'menu-desktop.remove-basket' | translate" [type]="'ternary'" (debounceClick)="resetBasket()"></app-button>
    </div>
    <div class="y3-margin-left-8">
      <app-button [text]="'menu-desktop.checkout-basket' | translate" [type]="'primary-small'" (debounceClick)="validateUpsell()"></app-button>
    </div>
  </div>

</div>
