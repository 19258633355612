/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./y3-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../shared/components/y3-explained-button/y3-explained-button.component.ngfactory";
import * as i5 from "@angular/router";
import * as i6 from "../../../shared/components/y3-explained-button/y3-explained-button.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/common";
import * as i9 from "./y3-header.component";
import * as i10 from "../../../core/services/utils.service";
import * as i11 from "@angular/material/dialog";
var styles_Y3HeaderComponent = [i0.styles];
var RenderType_Y3HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_Y3HeaderComponent, data: {} });
export { RenderType_Y3HeaderComponent as RenderType_Y3HeaderComponent };
function View_Y3HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["style", "height: 60px; padding-right: 24px;"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.corporate.logoUrl; _ck(_v, 0, 0, currVal_0); }); }
function View_Y3HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "y3-color-green-vogue mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["chat_outline"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_Y3HeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-y3-explained-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_Y3ExplainedButtonComponent_0, i4.RenderType_Y3ExplainedButtonComponent)), i1.ɵdid(1, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 114688, null, 0, i6.Y3ExplainedButtonComponent, [], { title: [0, "title"], description: [1, "description"] }, null), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (("/guest/" + ((_co.booking == null) ? null : _co.booking.id)) + "/chat"), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("menu-desktop.messaging")); var currVal_2 = "Discuter en direct avec votre agence"; _ck(_v, 2, 0, currVal_1, currVal_2); }, null); }
function View_Y3HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Y3HeaderComponent_3)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Y3HeaderComponent_4)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isDesktopMode(); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.isDesktopMode() && _co.booking) && !((_co.property == null) ? null : _co.property.noShowChatContact)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_Y3HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "y3-main-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "y3-flex-row y3-flex-align-center"], ["style", "height: 100%; width: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "y3-flex-row y3-flex-align-center y3-flex-justify-space-between"], ["style", "height: 100%; width: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Y3HeaderComponent_1)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Y3HeaderComponent_2)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.corporate == null) ? null : _co.corporate.logoUrl); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.displayChat; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_Y3HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-y3-header", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_Y3HeaderComponent_0, RenderType_Y3HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i9.Y3HeaderComponent, [i10.UtilsService, i11.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Y3HeaderComponentNgFactory = i1.ɵccf("app-y3-header", i9.Y3HeaderComponent, View_Y3HeaderComponent_Host_0, { corporate: "corporate", booking: "booking", property: "property", displayChat: "displayChat" }, {}, []);
export { Y3HeaderComponentNgFactory as Y3HeaderComponentNgFactory };
