import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { CheckinService } from 'src/app/core/services/checkin.service';
import { DocumentService } from 'src/app/core/services/DocumentService';
import { GuestService } from 'src/app/core/services/guest.service';
import { InternalPropertyService } from 'src/app/core/services/internal.property.service';
import { LanguageManagementService } from 'src/app/core/services/language.service';
import { LoaderService } from 'src/app/core/services/LoaderService';
import { PaysService } from 'src/app/core/services/pays.service';
import { SnackbarService } from 'src/app/core/services/SnackbarService';
import { UtilsService } from 'src/app/core/services/utils.service';
import { BookingForGuest } from 'src/app/models/guestview/BookingForGuest';
import { SharedSnackbarService } from 'src/app/shared/components/shared-snackbar/services/shared-snackbar.service';
import {Corporate} from "../../../models/guestview/Corporate";
import { OnlineCheckDetailsComponent } from '../online-check-details.component';

@Component({
  selector: 'app-online-check-header',
  templateUrl: './online-check-header.component.html',
  styleUrls: ['./online-check-header.component.scss']
})
export class OnlineCheckHeaderComponent
extends OnlineCheckDetailsComponent
implements OnInit, AfterViewInit {

  @Input() corporate: Corporate;
  @Input() booking: BookingForGuest;
  @Input() bookindnewId: string;
  currentActiveMenu;
  currentLanguage;
  languageChooserOpened = false;
  mode = {
    edit: true,
    show: false,
  };
  constructor(

    private utilsService: UtilsService,
    private languageSrv: LanguageManagementService,
    public loaderService: LoaderService,
    public languageService: LanguageManagementService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public guestService: GuestService,
    public translateService: TranslateService,
    public fb: FormBuilder,
    public paysService: PaysService,
    public documentService: DocumentService,
    public utilService: UtilsService,
    public checkinService: CheckinService,
    public snackbarService: SnackbarService,
    public propertyService: InternalPropertyService,
    public sharedSnackbarService: SharedSnackbarService,
    public title: Title
  ) {
    super(
      loaderService,
      languageService,
      route,
      dialog,
      guestService,
      translateService,
      fb,
      utilService,
      checkinService,
      paysService,
      documentService,
      snackbarService,
      propertyService,
      sharedSnackbarService,
      title
    );
   }

  ngOnInit() {
    this.checkinService.mode.subscribe((res) => {
      this.mode = res;

    });

    this.route.queryParams.subscribe(res=>{
      if(res.lang){
        this.languageSrv.setLanguageInUrl(res.lan)
      }
    })
    this.route.queryParams.subscribe(res=>{
      if(res.lang){


        this.currentLanguage = res.lang
      }else{

        this.currentLanguage = this.languageSrv.getLanguageInUrl();
      }
    })
    this.route.paramMap
    .pipe(takeUntil(this.onDestroy))
    .subscribe((ParamMap) => {
    this.bookingId = ParamMap.get('bookingId');
    if (!this.bookingId) {
        return;
      }
    const lang = this.translateService.currentLang;
    this.guestService.getPropertyAndBookingForGuest(this.bookingId, lang).subscribe( res => {
      this.booking = res.booking;
      this.bookindnewId = this.booking.id;
    });
  });
  }


  getLanguages() {
    return UtilsService.languageArray;
  }
  changeLanguage(language: string) {
    let link = window.location.href;
    if (link.endsWith('/')) {
      link = link.substr(0, link.lastIndexOf('/'));
    }
    if (link.includes('?lang=')) {
      link = link.substr(0, link.lastIndexOf('?lang='));
    }
    console.log('LINK', link);
    window.location.href = link + '?lang=' + language;
    window.location.reload();
  }

  getLanguage(languageCode: string) {
    return UtilsService.getLanguageFlag(languageCode);
  }

  getNonTranslatedLanguageName(languageCode: string) {
    return UtilsService.getNonTranslatedLanguageName(languageCode);
  }
  loader() {
    // this.loaderService.showFullLoader('');
  }
ngAfterViewInit(): void {


}

}
