import { Component, OnInit } from '@angular/core';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {GuestService} from '../../../core/services/guest.service';
import {LoaderService} from '../../../core/services/LoaderService';
import {ApiService} from '../../../core/http/api.service';
import {Location} from '@angular/common';
import {Corporate} from '../../../models/guestview/Corporate';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {UsefulNumber} from '../../../models/UsefulNumber';

@Component({
  selector: 'app-m9-numbers',
  templateUrl: './m9-numbers.component.html',
  styleUrls: ['./m9-numbers.component.scss']
})
export class M9NumbersComponent extends AbstractWindow implements OnInit {

  corporate: Corporate;
  property: PropertyForGuest;

  numbers: UsefulNumber[] = [];

  constructor(private activatedRoute: ActivatedRoute,
              public utilService: UtilsService,
              public translateService: TranslateService,
              private guestService: GuestService,
              private loaderService: LoaderService,
              private apiService: ApiService,
              private location: Location) {
    super(utilService);
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      this.loaderService.dismissLoader();
      if (res && res.propertyAndBooking) {
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        //this.utilService.manageCorporateColors(this.corporate);
        this.numbers = this.property.usefulNumbers;
      }
    });
  }

  goBack() {
    this.location.back();
  }

}
