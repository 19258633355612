export class GuestIdentityDetails {
  firstName: string;
  lastName: string;
  birthDate: string;
  cityOfBirth: string;
  nationality: string;
  homeFullAddress: string;
  phone: string;
  email: string;
  identityPictureUrl: string;
}
