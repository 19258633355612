import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {LoaderService} from '../../../core/services/LoaderService';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {OnlineCheckin} from '../../../models/guestview/OnlineCheckin';
import {RecommendationSearchRequestDto} from '../../../models/guestview/RecommendationSearchRequestDto';
import {GuestService} from '../../../core/services/guest.service';
import {Recommendation} from '../../../models/guestview/Recommendation';
import {RecommendationCategory, RecommendationCategoryObject} from '../../../models/guestview/RecommendationCategory';
import * as moment from 'moment/moment';
import {Guide} from '../../../models/Guide';
import {M20UpsellDetailsComponent} from '../m20-upsell-details/m20-upsell-details.component';
import {MatDialog} from '@angular/material';
import {M21RecommendationCardComponent} from '../m21-recommendation-card/m21-recommendation-card.component';

@Component({
  selector: 'app-m2-recommendation-top4',
  templateUrl: './m2-recommendation-top4.component.html',
  styleUrls: ['./m2-recommendation-top4.component.scss']
})
export class M2RecommendationTop4Component implements OnInit {

  property: PropertyForGuest;
  booking: BookingForGuest;
  corporate: Corporate;
  onlineCheck: OnlineCheckin;

  newRecommendations: Recommendation[] = []
  rawCategories: RecommendationCategoryObject[] = new RecommendationCategory().list;
  categories = [];

  recommendationSearch = new RecommendationSearchRequestDto();
  selectedCategory: RecommendationCategoryObject;
  selectedCategoryKey: string;
  dropdownSettings = {};

  constructor(private activatedRoute: ActivatedRoute,
              public utilService: UtilsService,
              public translateService: TranslateService,
              private guestService: GuestService,
              protected dialog: MatDialog,
              private loaderService: LoaderService) { }

  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'key',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.rawCategories.forEach((category) => {
      this.categories.push({key: category.key, name: this.translateService.instant('guest-recommendation.' + category.key) });
    });

    this.categories.sort((a, b) => a.name.localeCompare(b.name));

    this.activatedRoute.data.subscribe((res) => {
      this.loaderService.dismissLoader();
      if (res && res.propertyAndBooking) {
        this.booking = res.propertyAndBooking.booking;
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        if (this.booking) {
          this.onlineCheck = res.propertyAndBooking.booking.onLineCheck;
        }
        //this.utilService.manageCorporateColors(this.corporate);
        console.log("this is the Res from the resolver", res);
        this.findRecommendation();
      }
    });
  }

  deSelectCategory($event) {
    this.recommendationSearch.categories = this.utilService.newRemoveFromIterable(this.recommendationSearch.categories, $event.key);
      if (this.recommendationSearch.categories.length === 1 && this.recommendationSearch.categories[0] === 'all') {
        this.recommendationSearch.categories = [];
      }
    this.findRecommendation();
  }

  selectCategory($event) {
    this.selectedCategory = $event;
    this.selectedCategoryKey = this.selectedCategory.key;
    this.recommendationSearch.categories.push(this.selectedCategory.key);
    this.findRecommendation();
  }
  findRecommendation() {

    if (this.booking && this.booking.startDate && this.booking.endDate) {
      this.recommendationSearch.bookingStart = moment(this.booking.startDate).startOf('day');
      this.recommendationSearch.bookingEnd = moment(this.booking.endDate).endOf('day');
      this.recommendationSearch.bookingId = this.booking.id;
    }

    if (this.property) {
      this.recommendationSearch.propertyId = this.property.id;
    }
    this.recommendationSearch.pageSize = 4;
    this.recommendationSearch.pageNumber = 0;
    this.recommendationSearch.hostAdresses = true;
    this.recommendationSearch.communityAdresses = !this.corporate.onlyMineRecommendations;
    this.recommendationSearch.yaagoAdresses = !this.corporate.onlyMineRecommendations;
    this.recommendationSearch.influencerAdresses = !this.corporate.onlyMineRecommendations;
    console.log("-->> Search Recommendation from TOP 4", this.recommendationSearch);

    this.guestService.getRecommendations(this.recommendationSearch, this.translateService.currentLang, this.corporate.corporateOwnerId, this.corporate.id).subscribe( res => {
      console.log("-->> Get Recommendation from TOP 4", res);
      this.newRecommendations = res;
      //this.newRecommendations = res.slice(0,4);
    }, err=> {
      console.log("Error in getting Recommendation for Top 4");
      this.utilService.handleYaagoErrorCode(err);
    });
  }

  showMap() {
    this.guestService.showMap(true, this.property, this.corporate);
  }
  openCard(recommendation) {
    this.showMap();
    this.guestService.openCard(recommendation, this.property);
  }

  onClickOnRecomendationItemAtPage(recomendation: any) {

  }

  showRecommendationMobile(recommendation: Recommendation) {

    const showUpSellDetails = this.dialog.open(M21RecommendationCardComponent, {
      width: '100vw',
      height: '100vh',
      autoFocus: false,
      data: {
        recommendation: recommendation,
        property: this.property
      }
    });
  }

}
