/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-recommendations-reviews.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./guest-recommendations-reviews-desktop/guest-recommendations-reviews-desktop.component.ngfactory";
import * as i3 from "./guest-recommendations-reviews-desktop/guest-recommendations-reviews-desktop.component";
import * as i4 from "../../../../core/services/guest.service";
import * as i5 from "../../../../core/services/utils.service";
import * as i6 from "./guest-recommendations-reviews-mobile/guest-recommendations-reviews-mobile.component.ngfactory";
import * as i7 from "./guest-recommendations-reviews-mobile/guest-recommendations-reviews-mobile.component";
import * as i8 from "@angular/common";
import * as i9 from "./guest-recommendations-reviews.component";
var styles_GuestRecommendationsReviewsComponent = [i0.styles];
var RenderType_GuestRecommendationsReviewsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestRecommendationsReviewsComponent, data: {} });
export { RenderType_GuestRecommendationsReviewsComponent as RenderType_GuestRecommendationsReviewsComponent };
function View_GuestRecommendationsReviewsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-recommendations-reviews-desktop", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GuestRecommendationsReviewsDesktopComponent_0, i2.RenderType_GuestRecommendationsReviewsDesktopComponent)), i1.ɵdid(1, 114688, null, 0, i3.GuestRecommendationsReviewsDesktopComponent, [i4.GuestService, i5.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_GuestRecommendationsReviewsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-recommendations-reviews-mobile", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_GuestRecommendationsReviewsMobileComponent_0, i6.RenderType_GuestRecommendationsReviewsMobileComponent)), i1.ɵdid(1, 114688, null, 0, i7.GuestRecommendationsReviewsMobileComponent, [i4.GuestService, i5.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_GuestRecommendationsReviewsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestRecommendationsReviewsComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestRecommendationsReviewsComponent_2)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDesktopMode(); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isDesktopMode(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_GuestRecommendationsReviewsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-recommendations-reviews", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestRecommendationsReviewsComponent_0, RenderType_GuestRecommendationsReviewsComponent)), i1.ɵdid(1, 114688, null, 0, i9.GuestRecommendationsReviewsComponent, [i4.GuestService, i5.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestRecommendationsReviewsComponentNgFactory = i1.ɵccf("app-guest-recommendations-reviews", i9.GuestRecommendationsReviewsComponent, View_GuestRecommendationsReviewsComponent_Host_0, {}, {}, []);
export { GuestRecommendationsReviewsComponentNgFactory as GuestRecommendationsReviewsComponentNgFactory };
