import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { OnlineCheckin } from "src/app/models/guestview/OnlineCheckin";
import { ApiService } from "../http/api.service";

@Injectable({ providedIn: 'root' })
export class CheckinService {



  mode = new BehaviorSubject({edit:true,show:false});

    constructor(private apiService:ApiService) {
    }

    saveOnlineCheckin(onlineCheckin:OnlineCheckin) {
      return this.apiService.saveOnlineCheckin(onlineCheckin);
    }

    createContract(bookingId: string) {
      return this.apiService.createContract(bookingId);
    }

}
