/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./image-detail.component";
import * as i3 from "@angular/material/dialog";
var styles_ImageDetailComponent = [i0.styles];
var RenderType_ImageDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageDetailComponent, data: {} });
export { RenderType_ImageDetailComponent as RenderType_ImageDetailComponent };
export function View_ImageDetailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mat-dialog-content"], ["id", "detail-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "closeIcon"], ["src", "assets/icon/icon-exit.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "photo-detail"], ["class", "photodetail"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.photoUrl, ""); _ck(_v, 2, 0, currVal_0); }); }
export function View_ImageDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-detail", [], null, null, null, View_ImageDetailComponent_0, RenderType_ImageDetailComponent)), i1.ɵdid(1, 114688, null, 0, i2.ImageDetailComponent, [i3.MAT_DIALOG_DATA, i3.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageDetailComponentNgFactory = i1.ɵccf("app-image-detail", i2.ImageDetailComponent, View_ImageDetailComponent_Host_0, {}, {}, []);
export { ImageDetailComponentNgFactory as ImageDetailComponentNgFactory };
