<div class="m16-main-container" >

  <div class="y3-margin-bottom-16">
    <div class="y3-High y3-bold y3-title-colors">{{'m16-important-guide-top.title' | translate}}</div>
  </div>

  <div class="y3-partner-section">
    <div class="new-upsale-card-container " >
      <div class="card-small flex flex-column y3-margin-bottom-8" *ngFor="let guide of importantGuides" (click)="showMobileGuideDetails(guide)" >
        <div class="width-100 y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-clickable">
          <div class="y3-flex-row y3-flex-align-center">
            <div style="position: relative">
              <img class="category-cover y3-margin-right-12" src="{{getGuideCover(guide)}}" alt="">
            </div>

            <div style="position: relative" class="y3-flex-row">
              <div class="y3-Small y3-bold y3-title-colors" *ngIf="guide.category === 'wifi'">WIFI :</div>
              <div  class="y3-Small y3-bold y3-title-colors" >{{guide.translations[0].title }}</div>

            </div>
          </div>

          <mat-icon class="m0-button-quick-2">chevron_right</mat-icon>

          <!--<img src="assets/icon/arrow-down.svg"
               class="{{i === mobileCurrentOpenCategory ? 'rotate-90' : 'rotate-270'}}" alt="">-->
        </div>
      </div>
    </div>

  </div>
</div>
