import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "../http/api.service";
export class CheckinService {
    constructor(apiService) {
        this.apiService = apiService;
        this.mode = new BehaviorSubject({ edit: true, show: false });
    }
    saveOnlineCheckin(onlineCheckin) {
        return this.apiService.saveOnlineCheckin(onlineCheckin);
    }
    createContract(bookingId) {
        return this.apiService.createContract(bookingId);
    }
}
CheckinService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CheckinService_Factory() { return new CheckinService(i0.ɵɵinject(i1.ApiService)); }, token: CheckinService, providedIn: "root" });
