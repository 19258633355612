import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class SnackbarService {
    constructor() {
        this.dismissTimeout = 5000;
        this.message = new Subject();
        this.newMessageEvent$ = this.message.asObservable();
    }
    setDismissTimeout(timeout) {
        this.dismissTimeout = timeout;
    }
    push(message) {
        console.log('snackbarpush');
        this.message.next(message);
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if (message && (message.type === 'info' || message.type === 'warning')) {
            this.timeout = setTimeout(() => {
                this.dismiss();
            }, this.dismissTimeout);
        }
    }
    dismiss() {
        this.push(undefined);
    }
}
SnackbarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SnackbarService_Factory() { return new SnackbarService(); }, token: SnackbarService, providedIn: "root" });
