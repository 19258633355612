<div class="online-check-header">
  <div class="online-check-logo">
    <div>
      <img *ngIf="corporate?.logoUrl != null" [src]="corporate?.logoUrl" style="height: 50px; padding-right: 24px;"/>
    </div>
    <div *ngIf="isDesktopMode()" class="online-check-title"> {{'online-check.check-in-online' | translate}} </div>
    <mat-icon *ngIf="!isDesktopMode()" class="colorCatSkill" routerLink="{{'/guest/' +bookindnewId+ '/messaging'}}">chat</mat-icon>
  </div>
<div *ngIf="isDesktopMode()" style="padding-top: 0px;">
  <app-button routerLink="{{'/guest/' +bookindnewId+ '/messaging'}}"  [text]="'menu-desktop.messaging' | translate" [type]="'primary'" (debounceClick)="currentActiveMenu='messaging'"></app-button>
</div>
  <div *ngIf="this.mode.edit" class="language-chooser flex flex-row flex-align-center clickable margin-left-16" (click)="this.languageChooserOpened = !this.languageChooserOpened;"
  appClickOutside (clickOutside)="languageChooserOpened = false">

<img class="flag margin-left-8" src="{{getLanguage(currentLanguage)}}" alt="Flag">

<div class="chooser-container grey-scroll" *ngIf="languageChooserOpened">
 <div class="flex flex-row flex-align-center clickable margin-top-12 margin-bottom-12"
      *ngFor="let lang of getLanguages()"
      (click)="changeLanguage(lang)">
   <img class="flag margin-right-12" src="{{getLanguage(lang)}}" alt="Flag">
   <span
     class="roboto Small txt-color-black txt-nowrap">{{getNonTranslatedLanguageName(lang)}}</span>
 </div>
</div>
</div>


</div>
