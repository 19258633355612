import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {UtilsService} from '../../../core/services/utils.service';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {BookletReviewsLight} from '../../../models/BookletReviewsLight';
import {SnackbarMessage} from '../../../shared/components/shared-snackbar/models/snackbar-message';
import {ApiService} from '../../../core/http/api.service';
import {TranslateService} from '@ngx-translate/core';
import {SharedSnackbarService} from '../../../shared/components/shared-snackbar/services/shared-snackbar.service';
import {ActivatedRoute} from '@angular/router';
import {Corporate} from '../../../models/guestview/Corporate';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {LoaderService} from '../../../core/services/LoaderService';

@Component({
  selector: 'app-m14-booklet-review',
  templateUrl: './m14-booklet-review.component.html',
  styleUrls: ['./m14-booklet-review.component.scss']
})
export class M14BookletReviewComponent extends AbstractWindow implements OnInit {

  textReview: string = "";
  rating: number = 0;
  corporate: Corporate;
  property: PropertyForGuest;
  booking: BookingForGuest;
  idForRouting: string;

  constructor(public utilService: UtilsService,
              private apiService: ApiService,
              private activatedRoute: ActivatedRoute,
              private loaderService: LoaderService,
              private translateService: TranslateService,
              public sharedSnackbarService: SharedSnackbarService,
              private location: Location) {
    super(utilService);
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      this.loaderService.dismissLoader();
      if (res && res.propertyAndBooking) {
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        //this.utilService.manageCorporateColors(this.corporate);
        this.booking = res.propertyAndBooking.booking;

        this.idForRouting = this.property.id;
        if (this.booking) {
          this.idForRouting = this.booking.id;
        }
      }
    });
  }

  goBack() {
    this.location.back();
  }

  saveReview() {
    if (this.textReview.length > 0 && this.rating > 0) {
      const bookletReviewsLight = new BookletReviewsLight();
      bookletReviewsLight.guestReviewComment = this.textReview;
      bookletReviewsLight.guestReviewRate = this.rating;
      this.apiService.leaveBookletReviews(this.booking.id, bookletReviewsLight).subscribe(res => {
        this.sharedSnackbarService.push(new SnackbarMessage(this.translateService.instant('booklet-review.success'), 'info'));
      });
    } else {
      this.sharedSnackbarService.push(new SnackbarMessage(this.translateService.instant('booklet-review.need-info'), 'warning'));
    }
  }

}
