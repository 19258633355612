import {Component, Input, OnInit} from '@angular/core';
import {UpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/UpSellOnBoarding';
import {ActivatedRoute} from '@angular/router';
import {GuestUpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/GuestUpSellOnBoarding';
import {Guide} from '../../../models/Guide';
import {GuestUpSellOnBoardingEndpoint} from '../../../core/services/V3_UpSellOnboarding/GuestUpSellOnBoardingEndpoint';

@Component({
  selector: 'app-m23-upsell-recap',
  templateUrl: './m23-upsell-recap.component.html',
  styleUrls: ['./m23-upsell-recap.component.scss']
})
export class M23UpsellRecapComponent implements OnInit {

  @Input() currentUpSellOnBoarding: GuestUpSellOnBoarding;
  realUpSellOnBoarding: UpSellOnBoarding;

  constructor(private activatedRoute: ActivatedRoute,
              private guestUpSellOnBoardingEndpoint: GuestUpSellOnBoardingEndpoint) { }

  ngOnInit() {
    this.currentUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
    console.log("this is the currentUpSellOnBoarding", this.currentUpSellOnBoarding);

    this.activatedRoute.data.subscribe((res) => {
      this.currentUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
    });
  }

  calculateItem(guide: Guide) {
    if (guide.upSellSection && guide.upSellSection.needQty) {
      return guide.upSellSection.upSellAmount.amount * guide.upSellSection.relatedQty;
    } else {
      return guide.upSellSection.upSellAmount.amount;
    }
  }
  getCurrency() {
    let currency = 'EUR';
    this.currentUpSellOnBoarding.guides.forEach( guide   => {
      if (guide.upSellSection && guide.upSellSection.upSellAmount) {
        currency = guide.upSellSection.upSellAmount.currency.toUpperCase();
      }
    });
    return currency;
  }
  calculateTotalPrice() {
    let total = 0;
    this.currentUpSellOnBoarding.guides.forEach( guide   => {
      if (guide.upSellSection && guide.upSellSection.upSellAmount) {
        if (guide.upSellSection.needQty) {
          total += guide.upSellSection.upSellAmount.amount * guide.upSellSection.relatedQty;
        } else {
          total += guide.upSellSection.upSellAmount.amount;
        }
      }
    });
    return total;
  }

  validateUpsell() {
    let guestUpSellOnboarding: GuestUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
    this.guestUpSellOnBoardingEndpoint.validateBasket(guestUpSellOnboarding).subscribe( res => {
      this.realUpSellOnBoarding = res;
      localStorage.removeItem("guestUpSellOnBoarding");
      this.putPaymentMethod();
    });
  }

  isValidationNeeded() {
    let isNeeded = false;
    this.currentUpSellOnBoarding.guides.forEach( guide => {
      if (guide.upSellSection && guide.upSellSection.upSellToValidate) {
        isNeeded = true;
      }
    });
    return isNeeded;
  }
  resetBasket() {
    localStorage.removeItem("guestUpSellOnBoarding");
  }

  putPaymentMethod() {
    window.open(this.realUpSellOnBoarding.intentConfirmationUrl, '_blank');
  }

}
