import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {UtilsService} from '../../../core/services/utils.service';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {GuestService} from '../../../core/services/guest.service';
import {LoaderService} from '../../../core/services/LoaderService';
import {Corporate} from '../../../models/guestview/Corporate';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {PersonForGuest} from '../../../models/guestview/PersonForGuest';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';

@Component({
  selector: 'app-m4-contact-details',
  templateUrl: './m4-contact-details.component.html',
  styleUrls: ['./m4-contact-details.component.scss']
})
export class M4ContactDetailsComponent extends AbstractWindow implements OnInit {

  corporate: Corporate;
  property: PropertyForGuest;
  booking: BookingForGuest;
  corporateOwner: PersonForGuest;
  constructor(private activatedRoute: ActivatedRoute,
              public utilService: UtilsService,
              public translateService: TranslateService,
              private guestService: GuestService,
              private loaderService: LoaderService,
              private location: Location) {
    super(utilService);
  }

  ngOnInit() {

    this.activatedRoute.data.subscribe((res) => {
      this.loaderService.dismissLoader();
      if (res && res.propertyAndBooking) {
        this.booking = res.propertyAndBooking.booking;
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        //this.utilService.manageCorporateColors(this.corporate);
      }
    });
  }

  getLanguage(code: string) {
    return UtilsService.getLanguageFlag(code);
  }

  goBack() {
    this.location.back();
  }

}
