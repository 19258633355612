import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { finalize, take, takeUntil } from "rxjs/operators";
import { SnackbarMessage } from '../shared-snackbar/models/snackbar-message';
import { DocType, DocumentUploadTypes, YaagoDocument } from '../../../models/YaagoDocument';
export class SharedUploadComponent {
    constructor(snackbar, translate, documentService, compressImage) {
        this.snackbar = snackbar;
        this.translate = translate;
        this.documentService = documentService;
        this.compressImage = compressImage;
        this.btnType = 'inline-blue';
        this.uploadType = 'single';
        this.id = 'upload-btn';
        this.btnLabel = 'documents.upload';
        this.allowedTypes = DocumentUploadTypes.all();
        this.hideButton = false;
        this.isUploading = false;
        this.uploading = new EventEmitter();
        this.docUploaded = new EventEmitter();
        this._onDestroy = new Subject();
        this.selectedFiles = [];
    }
    ngOnInit() {
    }
    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    onFileChoosed(event) {
        console.log(event);
        const arrayFiles = Array.from(event.target.files);
        if (!this.isValidFileTypes(arrayFiles)) {
            this.snackbar.push(new SnackbarMessage(this.translate.instant('documents.filetypes-not-supported'), 'error'));
            return;
        }
        this.selectedFiles = arrayFiles;
        this.documentService.uploadLinksGeneration(arrayFiles)
            .pipe(takeUntil(this._onDestroy), finalize(() => { this.fileUploader.nativeElement.value = ''; }))
            .subscribe(urlsDtoList => {
            this.startUploadProcess(urlsDtoList);
        });
    }
    isValidFileTypes(files) {
        toto: FileList;
        console.log('FILES', files);
        const types = [];
        files.forEach(file => {
            types.push(file.type);
        });
        const matched = types.filter(type => this.allowedTypes.includes(type));
        return matched.length === types.length;
    }
    startUploadProcess(urlsDtoList) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isUploading = true;
            this.uploading.emit(this.isUploading);
            yield Promise.all(urlsDtoList.map((doc, index) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                yield this.upload(doc, this.selectedFiles[index]);
            })));
        });
    }
    upload(doc, file) {
        if (this.getDocType(file.type) === "IMAGE") {
            console.log(file);
            console.log("zzz", file.type);
            console.log(this.getDocType(file.type));
            console.log(DocType.IMAGE);
            let image = file;
            return this.compressImage.compress(image)
                .pipe(take(1)).toPromise().then(compressedImage => {
                console.log(`Image size after compressed: ${compressedImage.size} bytes.`);
                // now you can do upload the compressed image
                file = compressedImage;
                console.log(`file size after compressed: ${file.size} bytes.`);
                return this.documentService.storageDirectUpload(doc.url, file).toPromise().then(res => {
                    file = compressedImage;
                    const yaagoDocument = new YaagoDocument();
                    yaagoDocument.title = file.name;
                    yaagoDocument.type = this.getDocType(file.type);
                    yaagoDocument.url = this.cleanSignedUrl(doc.url);
                    this.docUploaded.emit(yaagoDocument);
                    this.isUploading = false;
                    this.uploading.emit(this.isUploading);
                    this.selectedFiles = [];
                }, err => {
                    this.isUploading = false;
                    this.uploading.emit(this.isUploading);
                });
            });
        }
        else {
            return this.documentService.storageDirectUpload(doc.url, file).toPromise().then(res => {
                console.log('RESULT-DIRECT', res, doc);
                const yaagoDocument = new YaagoDocument();
                yaagoDocument.title = file.name;
                yaagoDocument.type = this.getDocType(file.type);
                yaagoDocument.url = this.cleanSignedUrl(doc.url);
                console.log('EMITTED', yaagoDocument);
                this.docUploaded.emit(yaagoDocument);
                this.isUploading = false;
                this.uploading.emit(this.isUploading);
                this.selectedFiles = [];
            }, err => {
                this.isUploading = false;
                this.uploading.emit(this.isUploading);
            });
        }
    }
    getDocType(type) {
        if (DocumentUploadTypes.videos().includes(type)) {
            return DocType.VIDEO;
        }
        if (DocumentUploadTypes.images().includes(type)) {
            return DocType.IMAGE;
        }
        if (DocumentUploadTypes.pdf().includes(type)) {
            return DocType.PDF;
        }
        if (DocumentUploadTypes.words().includes(type)) {
            return DocType.WORD;
        }
        return DocType.IMAGE;
    }
    cleanSignedUrl(url) {
        if (url.includes('?X-Goog-Algorithm')) {
            return url.substr(0, url.indexOf('?X-Goog-Algorithm'));
        }
        return url;
    }
}
