/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tabs-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../node_modules/ngx-perfect-scrollbar/dist/ngx-perfect-scrollbar.ngfactory";
import * as i5 from "ngx-perfect-scrollbar";
import * as i6 from "./tabs-menu.component";
var styles_TabsMenuComponent = [i0.styles];
var RenderType_TabsMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabsMenuComponent, data: {} });
export { RenderType_TabsMenuComponent as RenderType_TabsMenuComponent };
function View_TabsMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "y3-clickable"], ["style", "margin-bottom: 16px"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "local-menu-tab"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "selected": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "local-menu-tab-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(8, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "local-menu-tab"; var currVal_1 = _ck(_v, 4, 0, (_v.context.$implicit === _co.selectedTab)); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 6, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_v.context.$implicit.title)))); _ck(_v, 6, 0, currVal_2); }); }
export function View_TabsMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TitleCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "perfect-scrollbar", [], [[2, "ps-show-limits", null], [2, "ps-show-active", null]], null, null, i4.View_PerfectScrollbarComponent_0, i4.RenderType_PerfectScrollbarComponent)), i1.ɵdid(3, 507904, null, 0, i5.PerfectScrollbarComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID], { config: [0, "config"] }, null), i1.ɵpod(4, { useBothWheelAxes: 0, suppressScrollX: 1, suppressScrollY: 2 }), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "div", [["class", "y3-flex-row "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsMenuComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, true, false, false); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.tabs; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).autoPropagation; var currVal_1 = i1.ɵnov(_v, 3).scrollIndicators; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_TabsMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tabs-menu", [], null, null, null, View_TabsMenuComponent_0, RenderType_TabsMenuComponent)), i1.ɵdid(1, 638976, null, 0, i6.TabsMenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabsMenuComponentNgFactory = i1.ɵccf("app-tabs-menu", i6.TabsMenuComponent, View_TabsMenuComponent_Host_0, { tabs: "tabs" }, { selected: "selected" }, []);
export { TabsMenuComponentNgFactory as TabsMenuComponentNgFactory };
