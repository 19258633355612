<div class="datepicker-head-container">
    <div class="datepicker-header">
      <div class="date-label">{{periodLabel}}</div>
    </div>
    <div class="d-flex align-items-center datepicker-sub-header mt-1 mb-4 position">
     
        <img (click)="previousClicked('month')" class="datepicker-arrow-left datepicker-arrow clickable" src="assets/icon/arrows/arrow-up.svg" alt="previous month">
      
        <span class="datepicker-header-label clickable" (click)="previousClickedyear('year')" >{{periodLabel}}</span>

        <img (click)="nextClicked('month')" class="datepicker-arrow-right datepicker-arrow clickable" src="assets/icon/arrows/arrow-up.svg" alt="previous month">
    </div>
  </div>
  