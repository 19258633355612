import { Component, HostListener, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { CheckinService } from "src/app/core/services/checkin.service";
import { GuestService } from "src/app/core/services/guest.service";
import { LanguageManagementService } from "src/app/core/services/language.service";
import { UtilsService } from "src/app/core/services/utils.service";
import { BookingForGuest } from "src/app/models/guestview/BookingForGuest";
import { Corporate } from "src/app/models/guestview/Corporate";
import { PropertyForGuest } from "src/app/models/guestview/PropertyForGuest";
import { OnlineCheckDetailsComponent } from "../online-check-details.component";
import { PaysService } from "../../../core/services/pays.service";
import { LoaderService } from "../../../core/services/LoaderService";
import { DocumentService } from "../../../core/services/DocumentService";
import { OnlineCheckin } from "../../../models/guestview/OnlineCheckin";
import { SnackbarService } from "../../../core/services/SnackbarService";
import { InternalPropertyService } from "../../../core/services/internal.property.service";
import { SharedSnackbarService } from "src/app/shared/components/shared-snackbar/services/shared-snackbar.service";
import {Title} from '@angular/platform-browser';

@Component({
  selector: "app-mobile-online-check-waiting",
  templateUrl: "./mobile-online-check-waiting.component.html",
  styleUrls: ["./mobile-online-check-waiting.component.scss"],
})
export class MobileOnlineCheckWaitingComponent
  extends OnlineCheckDetailsComponent
  implements OnInit
{
  @Input() booking: BookingForGuest;
  bookingId: string;
  @Input() property: PropertyForGuest;
  @Input() localOnLineCheck: OnlineCheckin;
  @Input() mainPropertyPhoto: string;

  currentLang: string;
  onDestroy = new Subject();
  @Input() corporate: Corporate;
  constructor(
    public loaderService: LoaderService,
    public languageService: LanguageManagementService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public guestService: GuestService,
    public translateService: TranslateService,
    public fb: FormBuilder,
    public utilService: UtilsService,
    public checkinService: CheckinService,
    public paysService: PaysService,
    public documentService: DocumentService,
    public snackbarService: SnackbarService,
    public propertyService: InternalPropertyService,
    public sharedSnackbarService: SharedSnackbarService,
    public router: Router,
    public title: Title
  ) {
    super(
      loaderService,
      languageService,
      route,
      dialog,
      guestService,
      translateService,
      fb,
      utilService,
      checkinService,
      paysService,
      documentService,
      snackbarService,
      propertyService,
      sharedSnackbarService,
      title
    );
  }
  ngOnInit() {
    console.log("HISTORY", history.state.data);
    if (this.booking) {
      if (
        this.booking.onLineCheck.checkStatus === 'VALIDATED' &&
        !history.state.data
      ) {
        this.router.navigate(['/guest/' + this.booking.id]);
      }

      this.localOnLineCheck = this.booking.onLineCheck;
      this.booking.onLineCheck.checkStatus = this.localOnLineCheck.checkStatus;

      setTimeout(() => {
        this.contratSRC = this.localOnLineCheck.contractDocumentUrlSigned;
      }, 3000);
    } else {
      //fix bugsnag
      if (history && history.state && history.state.data && history.state.data.booking){
      this.booking = history.state.data.booking;
      this.bookingId = this.booking.id;
    }
      if (
        this.booking.onLineCheck.checkStatus === 'VALIDATED' &&
        !history.state.data.keep
      ) {
        this.router.navigate(['/guest/' + this.booking.id]);
      }

      this.localOnLineCheck = this.booking.onLineCheck;
      this.booking.onLineCheck.checkStatus = this.localOnLineCheck.checkStatus;

      setTimeout(() => {
        this.contratSRC = this.localOnLineCheck.contractDocumentUrlSigned;
      }, 3000);
    }
  }
}
