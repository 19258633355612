import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import {AfterViewInit, Component, ElementRef, HostListener, OnInit, TRANSLATIONS, ViewChild, } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CheckinService} from 'src/app/core/services/checkin.service';
import {GuestService} from 'src/app/core/services/guest.service';
import {LanguageManagementService} from 'src/app/core/services/language.service';
import {UtilsService} from 'src/app/core/services/utils.service';
import {BookingForGuest} from 'src/app/models/guestview/BookingForGuest';
import {Corporate} from 'src/app/models/guestview/Corporate';
import {PropertyForGuest} from 'src/app/models/guestview/PropertyForGuest';
import {DocumentUploadTypes, YaagoDocument} from 'src/app/models/YaagoDocument';
import {LoaderService} from '../../core/services/LoaderService';
import {PaysService} from '../../core/services/pays.service';
import SignaturePad from 'signature_pad';
import {DeletePhotoComponent} from '../guest/guest-inventory/delete-photo/delete-photo.component';
import {OnlineCheckin} from '../../models/guestview/OnlineCheckin';
import {DocType} from '../../models/Guide';
import {MatStepper} from '@angular/material/stepper';
import {DocumentService} from '../../core/services/DocumentService';
import {SnackbarService} from '../../core/services/SnackbarService';
import {SnackbarMessage} from '../../shared/components/shared-snackbar/models/snackbar-message';
import {InternalPropertyService} from '../../core/services/internal.property.service';
import { SharedSnackbarService } from 'src/app/shared/components/shared-snackbar/services/shared-snackbar.service';
import * as moment from 'moment';
import { Console } from 'console';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-online-check-details',
  templateUrl: './online-check-details.component.html',
  styleUrls: ['./online-check-details.component.scss'],
})
export class OnlineCheckDetailsComponent implements OnInit, AfterViewInit {

  favIcon: HTMLLinkElement | null = document.querySelector('#favicon');

  localOnLineCheck: OnlineCheckin;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  signaturePad: any;
  canvas: any;
  canvasID: any;
  booking: BookingForGuest;
  bookingId: string | null;
  property: PropertyForGuest;
  display = false;
  onDestroy = new Subject();
  proofOfIdentity: YaagoDocument[] = [];
  documents: YaagoDocument[];
  urlsPhotos = [];
  pdf = DocType.PDF;
  DocType = DocType;
  allowedImagesDocs = DocumentUploadTypes.images().concat(DocumentUploadTypes.pdf());
  allowedPfd = DocumentUploadTypes.pdf();
  documentsTypeImgPdf: string[] = [];
  showSpinner: boolean;
  message: string;
  currentLang: string;
  checkin: any;
  checkout: any;
  screenWidth: number;
  onLineCheckCountry: any;
  country: any;
  countries: any[] = [];
  language = '';
  contratSRC: any;
  mainPropertyPhoto: string | null;
  corporate: Corporate;
  urlLang: string;
  imagePdfUrls: string[] = [];
  MenuBookingId: string;
  mode = {
    edit: true,
    show: false,
  };
  signed = false;
  contrattest = true;
  done = false;
  agree = false;
  custom = 1;
notValid = true;
  bookindnewId: string;
  public config: PerfectScrollbarConfigInterface = {};

  constructor(
    public loaderService: LoaderService,
    public languageService: LanguageManagementService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public guestService: GuestService,
    public translateService: TranslateService,
    public fb: FormBuilder,

    public utilService: UtilsService,
    public checkinService: CheckinService,
    public paysService: PaysService,
    public documentService: DocumentService,
    public snackbarService: SnackbarService,
    public propertyService: InternalPropertyService,
    public sharedSnackbarService: SharedSnackbarService,
    public title: Title
  ) {
    this.getScreenWidth();
  }
  resizeCanvas(test?) {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    this.canvasID = document.getElementById('canvasIDForSignature');
    if (this.canvasID) {
      this.canvasID.width = this.canvasID.offsetWidth * ratio;

      if (this.isDesktopMode()) {
        this.canvasID.height = this.canvasID.offsetHeight * ratio;
      } else {
        this.canvasID.height = this.canvasID.offsetHeight * ratio + 200;
      }
      this.canvasID.getContext('2d').scale(ratio, ratio);
    }

    this.signaturePad.clear();
  }
  isDesktopMode(): boolean {
    return this.utilService.isDesktop(this.screenWidth);
  }
  initialization() {
    this.route.paramMap
      .pipe(takeUntil(this.onDestroy))
      .subscribe((ParamMap) => {
      this.loaderService.showFullLoader();
      this.bookingId = ParamMap.get('bookingId');
      if (!this.bookingId) {
        return;
      }
      const lang = this.translateService.currentLang;
      this.guestService.getPropertyAndBookingForGuest(this.bookingId, lang).subscribe( res => {
        this.property = res.property;
        this.MenuBookingId =  res.booking.id;
        console.log('thisres', res.booking.id);

        console.log('booking', this.booking);
        this.propertyService.setCurrentProperty(this.property);
        if (this.propertyService.getMainPhotoUrl()) {
          this.mainPropertyPhoto = this.propertyService.getMainPhotoUrl().url.replace('aki_policy=medium', '');
        } else {
          this.mainPropertyPhoto = null;
        }

        this.booking = res.booking;
        this.bookindnewId = this.booking.id;
        console.log('this.bookingid', this.booking.id);
        this.utilService.bookingTransferSub.next(this.booking);
        this.localOnLineCheck = this.booking.onLineCheck;


        let bookletLanguage = this.urlLang;
        if (bookletLanguage == null) {
          this.urlLang = navigator.language;
          if (this.booking && this.booking.guests != null && this.booking.guests.length > 0
            && this.booking.guests[0].languages != null && this.booking.guests[0].languages.length > 0) {
            bookletLanguage = this.booking.guests[0].languages[0];
          }
        }

        this.route.queryParams.subscribe(res => {

          if (res.lang) {
            bookletLanguage = res.lang;
          }
          if (bookletLanguage) {
          bookletLanguage = UtilsService.isLanguageSupported(bookletLanguage);
          this.languageService.setLanguageInUrl(bookletLanguage);


          this.translateService.use(bookletLanguage);
        } });

        // add condition if validated show waiting page
        if (this.localOnLineCheck && this.localOnLineCheck.checkStatus === 'VALIDATED') {
          this.checkinService.mode.next({ edit: false, show: true, });
        }

        this.corporate = res.corporate;
        //this.utilService.manageCorporateColors(this.corporate);

        console.log('--------------------SendCorporate');
        this.guestService.sendCorporate(this.corporate);
        if (this.corporate != null && this.corporate.favLogoUrl != null && this.corporate.favLogoUrl !== '' ) {
          this.favIcon.href = this.corporate.favLogoUrl;
          if (this.corporate.bookletBrowserTitle != null) {
            this.title.setTitle(this.corporate.bookletBrowserTitle);
          } else {
            this.title.setTitle(this.corporate.name);
          }
        }


        if (this.booking.onLineCheck && (this.booking.onLineCheck.checkStatus === 'WAITING' || this.booking.onLineCheck.checkStatus === 'NOUVEAU') ) {
          this.mode.show = true;
          this.mode.edit = false;
        }
        if (this.booking.onLineCheck && this.booking.onLineCheck.checkStatus === 'REFUSED') {
          this.mode.show = true;
          this.mode.edit = false;
        }
        this.loaderService.dismissLoader();
      });
    });
  }

  ngOnInit() {

    this.initialization();

    this.checkinService.mode.subscribe((res) => {
      this.mode = res;
    });

    this.paysService
    .getPays(this.languageService.getLanguageInUrl())
    .pipe(takeUntil(this.onDestroy))
    .subscribe((res) => {
      this.countries = res;
      if (this.language) {
        const language = res.find(
          (element) => element.cca2.toUpperCase() == this.language.toUpperCase()
        );
        if (language && language != 'eng') {
        this.language = language.cca3.toLowerCase();
      } else {
        this.language = 'eng';
      }
        if (this.language === 'esp') {
        this.language = 'spa';
      }
        if (this.language == 'eng') {
        this.countries.sort((a, b) =>
          a.name.common >
          b.name.common
            ? 1
            : -1
        );
      } else {
        this.countries.sort((a, b) =>
          a.translations[this.language].common[0] >
          b.translations[this.language].common[0]
            ? 1
            : -1
        );
      }

      } else {
        console.log('Error');
      }
    });

  }


  ngAfterViewInit(): void {
    this.language = this.languageService.getLanguageInUrl();
    if (this.localOnLineCheck) {
    this.country = this.booking.onLineCheck.guestCountry;
    }
    if (this.localOnLineCheck) {
    this.paysService
      .getPays(this.languageService.getLanguageInUrl())
      .pipe(takeUntil(this.onDestroy))
      .subscribe((res) => {
        this.countries = res;
        const country = res.find(
          (element) => element.cca2.toUpperCase() == this.country.toUpperCase()
        );
        if (
            this.booking &&
            this.booking.guests &&
            this.booking.guests.length > 0 &&
            this.booking.guests[0] &&
            this.booking.guests[0].languages &&
            this.booking.guests[0].languages.length > 0
          ) {
            this.language = this.booking.guests[0].languages[0].toUpperCase();
            if (
              this.language === 'EN' ||
              this.language === 'JA' ||
              this.language === 'TR' ||
              this.language === 'RU' ||
              this.language === 'ZH' ||
              this.language === 'ES' ||
              this.language === 'DA' ||
              this.language === 'CS'
            ) {
              const lan = 'EN';
              this.country = country.name.common;
              this.onLineCheckCountry = this.country;
            } else {
              const lan = res.find(
                (element) =>
                  element.cca2.toUpperCase() == this.language.toUpperCase()
              );
              this.country =
                country.translations[lan.cca3.toLowerCase()].common;
              this.onLineCheckCountry = this.country;
            }
          }});
  }
}

  @HostListener('window:resize', ['$event'])
  getScreenWidth(event?) {
    this.screenWidth = window.innerWidth;
  }
  loaded() {
     this.canvasID = document.getElementById('canvasIDForSignature');
     this.signaturePad = new SignaturePad(this.canvasID);
     window.addEventListener('resize', this.resizeCanvas);
     this.resizeCanvas();
  }
  showDialog(index, type: string) {
    console.log('URLS PHOTOS', this.urlsPhotos);
    const dialogRef = this.dialog.open(DeletePhotoComponent, {
      data: {
        index,
        photos: this.proofOfIdentity,
        urlsPhotos: this.urlsPhotos,
        documentType: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('RESULT', result);
      if (result) {
        if (result != true) {
          console.log('Index', index);
          this.documentsTypeImgPdf.slice(index, 1);
        } else {
          console.log(result);
          this.urlsPhotos = result.urlsPhotos;
        }
      }
    });
  }
  onPictureOutUploaded(doc: YaagoDocument) {
    if (!this.proofOfIdentity) {
      this.proofOfIdentity = [];
    }
    console.log('DOCC', doc);
    this.proofOfIdentity.push(doc);
  }
  signContrat() {
  }
  savePad() {
    if (this.signaturePad.isEmpty()) {
       this.sharedSnackbarService.push( new  SnackbarMessage(this.translateService.instant('online-check.empty-signature'), 'error'));
       return;
    } else {
      this.sharedSnackbarService.dismiss();
    }

    this.loaderService.showFullLoader('loader.contract-signed');
    this.canvasID = document.getElementById('canvasIDForSignature');
    this.canvasID.toBlob( blob => {
      console.log(blob);
      const file = new File([blob], 'signature.JPG', { type: 'image/JPG' });
      const arrayFiles: File[] = Array.from([file]);
      this.documentService.uploadLinksGeneration(arrayFiles).subscribe( res => {
        const url = res[0].url;
        console.log('URL:', url);
        this.documentService.storageDirectUpload(res[0].url, arrayFiles[0]).subscribe( res => {
          console.log(res);

          this.localOnLineCheck.contractSignaturePictureUrl = this.cleanSignedUrl(url);
          this.GetHostData();
          this.checkinService.saveOnlineCheckin(this.localOnLineCheck).subscribe(res => {
            this.localOnLineCheck = res;
            this.booking.onLineCheck = res;
            console.log('---> Retour du CheckInOnLine:', res);
            this.loaderService.dismissLoader();

          });

        });
      });

    });
    if (this.booking.onLineCheck.contractDocumentUrlSigned != '') {
      this.signed = true;
      console.log(this.signed); }
    if (this.signed == true) {
    setTimeout( () => {
      this.validate();

      }, 8000);
    }
  }

  savePadCGV() {

    if (this.signaturePad.isEmpty()) {
      this.done = false;
      this.sharedSnackbarService.push( new  SnackbarMessage(this.translateService.instant('online-check.empty-signature'), 'error'));
      return;
    } else {
      this.done = true;
      console.log('true1', this.done);

      this.sharedSnackbarService.dismiss();
    }

    this.loaderService.showFullLoader('loader.contract-signed');
    this.canvasID = document.getElementById('canvasIDForSignature');
    this.canvasID.toBlob( blob => {
      console.log(blob);
      const file = new File([blob], 'signature.JPG', { type: 'image/JPG' });
      const arrayFiles: File[] = Array.from([file]);
      this.documentService.uploadLinksGeneration(arrayFiles).subscribe( res => {
        const url = res[0].url;
        console.log('URL:', url);
        this.documentService.storageDirectUpload(res[0].url, arrayFiles[0]).subscribe( res => {
          console.log(res);

          this.localOnLineCheck.contractSignaturePictureUrl = this.cleanSignedUrl(url);
          this.GetHostData();
          this.checkinService.saveOnlineCheckin(this.localOnLineCheck).subscribe(res => {
            this.localOnLineCheck = res;

            this.booking.onLineCheck = res;
            console.log('---> Retourrr du CheckInOnLine:', res);
            this.loaderService.dismissLoader();
            this.property.cgvDocumentUrl = this.localOnLineCheck.cgvDocumentUrl;
            console.log('CGVvv', this.property.cgvDocumentUrl);
          });

        });
      });
    });
    if (this.booking.onLineCheck.contractDocumentUrlSigned != '') {
      this.signed = true;
      console.log(this.signed); }
    if (this.signed == true) {
    setTimeout( () => {
      this.loaderService.dismissLoader();
      console.log('yess');
      }, 8000);
    }
  }

  withoutContrat() {

    this.loaderService.showFullLoader('');
    this.GetHostData();

    this.checkinService.saveOnlineCheckin(this.localOnLineCheck).subscribe((res) => {
      this.localOnLineCheck = res;

      this.booking.onLineCheck = res;
      this.checkinService.mode.next({ edit: false, show: true, });
      this.loaderService.dismissLoader();
    });
  }
  withoutContratCGV() {

    this.loaderService.showFullLoader('');
    this.GetHostData();
    this.checkinService.saveOnlineCheckin(this.localOnLineCheck).subscribe((res) => {
      this.localOnLineCheck = res;

      this.booking.onLineCheck = res;
      console.log('response with CGV', this.booking.onLineCheck);
      this.loaderService.dismissLoader();
    });
  }

  getFormatDate(date: string) {
    return UtilsService.formatDateWithLocale(
      date,
      this.translateService.currentLang
    );
  }

  createContract() {
    this.loaderService.showFullLoader('loader.contract');
    this.checkinService.createContract(this.booking.id)
      .pipe(takeUntil(this.onDestroy))
      .subscribe( check => {
      this.loaderService.dismissLoader();
      this.booking.onLineCheck = check;
      this.localOnLineCheck = check;
      console.log('whatscheck', check);
      console.log('create', this.localOnLineCheck);
    });
  }


  goForward(stepper: MatStepper) {
    console.log(this.localOnLineCheck);
    if (this.firstFormGroup.valid) {
      stepper.next();
    }

  }


  goForwardValid(stepper: MatStepper) {

    if (this.property.defaultCheckInTime && !this.property.canEarlyCheck) {
      if (moment(this.firstFormGroup.get('onLineCheckInTime').value, 'hh:mm').isBefore(moment(this.property.defaultCheckInTime, 'hh:mm'))) {
        this.sharedSnackbarService.push(new SnackbarMessage(this.translateService.instant('online-check.impossible-checkin-time') + ' '
          + this.property.defaultCheckInTime, 'error'));
        return;
      } else {
        this.notValid = false;
      }
    }
    if (this.property.defaultCheckOutTime && !this.property.canEarlyCheck) {
      if (moment(this.firstFormGroup.get('onLineCheckOutTime').value, 'hh:mm').isAfter(moment(this.property.defaultCheckOutTime, 'hh:mm'))) {
        this.notValid = true;
        this.sharedSnackbarService.push(new SnackbarMessage(this.translateService.instant('online-check.impossible-checkout-time') + ' '
          + this.property.defaultCheckOutTime, 'error'));
        return;
      } else {
        this.notValid = false;
      }
    }

    if (this.notValid = true) {
  stepper.next();
}


  }




runboth(stepper) {
    this.savePadCGV();
    if (this.done === true) {
    this.goForward(stepper);
  }
    }

  goPrevious(stepper: MatStepper) {
    console.log(this.localOnLineCheck);
    stepper.previous();
  }

validate() {
  this.checkinService.mode.next({ edit: false, show: true, });
}

  initForm() {
    this.firstFormGroup = this.fb.group({
      prenom: ['', [Validators.required]],
      onLineCheckInTime: ['', [Validators.required]],
      onLineCheckOutTime: ['', [Validators.required]],
      nom: ['', [Validators.required]],
      email: ['', [Validators.required]],
      pays: ['', [Validators.required]],
      genre: ['', [Validators.required]],
      dateNaissance: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      onLineCheckWithTransport: ['' ],
    });

  }
  valid() {







  }
  countryChange() {
    this.firstFormGroup.controls.phone.setValue('');
  }

  getDocType(type: string) {
    if (DocumentUploadTypes.videos().includes(type)) {
      return DocType.VIDEO;
    }
    if (DocumentUploadTypes.images().includes(type)) {
      return DocType.IMAGE;
    }
    if (DocumentUploadTypes.pdf().includes(type)) {
      return DocType.PDF;
    }
    if (DocumentUploadTypes.words().includes(type)) {
      return DocType.WORD;
    }
    return null;
  }

  cleanSignedUrl(url: string) {
    if (url.includes('?X-Goog-Algorithm')) {
      return url.substr(0, url.indexOf('?X-Goog-Algorithm'));
    }
    return url;
  }
  editInfo() {

    this.localOnLineCheck = this.booking.onLineCheck;

    console.log('onedit', this.localOnLineCheck);
    this.checkinService.mode.next({ edit: true, show: false });
  }
  openInNewTab() {
    if (this.booking.onLineCheck.contractDocumentUrlSigned) {
    const contrat = this.booking.onLineCheck.contractDocumentUrlSigned;
    UtilsService.openLinkInNewTab(contrat);
    }
     }
     openInNewTabCgv() {

      if (this.booking.onLineCheck.cgvDocumentUrl) {
      const contrat = this.booking.onLineCheck.cgvDocumentUrl;
      UtilsService.openLinkInNewTab(contrat);
      }
       }
     customzoom() {
      this.custom = this.custom + 1;

    }
    customminus() {
      this.custom = this.custom - 1;
    }

    GetHostData() {
      this.proofOfIdentity.forEach((item ) => {
        this.imagePdfUrls.push(item.url);
        console.log('imagePdfUrls', this.imagePdfUrls);
      });
      if (this.localOnLineCheck && this.firstFormGroup) {
        this.localOnLineCheck.phoneNumber = this.firstFormGroup.get('phone').value.internationalNumber;
        this.localOnLineCheck.guestCountry = this.firstFormGroup.get('pays').value;
        this.localOnLineCheck.email = this.firstFormGroup.get('email').value;
        this.localOnLineCheck.firstName = this.firstFormGroup.get('prenom').value;
        this.localOnLineCheck.lastName = this.firstFormGroup.get('nom').value;
        this.localOnLineCheck.identityProofUrls = this.imagePdfUrls;
        this.localOnLineCheck.guestGender = this.firstFormGroup.get('genre').value;
        this.localOnLineCheck.guestBirthDate = moment(this.firstFormGroup.get('dateNaissance').value).format('YYYY-MM-DD');
        this.localOnLineCheck.onLineCheckInTime = this.firstFormGroup.get('onLineCheckTime') ? this.firstFormGroup.get('onLineCheckInTime').value : null;
        this.localOnLineCheck.onLineCheckOutTime = this.firstFormGroup.get('onLineCheckOutTime').value;
        if (this.firstFormGroup.get('onLineCheckWithTransport') != null ) {
          this.localOnLineCheck.onLineCheckWithTransport =  this.firstFormGroup.get('onLineCheckWithTransport').value ? this.firstFormGroup.get('onLineCheckWithTransport').value : false;
        }
      }
    }


}
