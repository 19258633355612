
<div class="new-recommendation-section">





  <div class="y3-High y3-bold y3-margin-bottom-16 y3-title-colors">{{'guest-view-recommendation.your-recommendations' | translate}}</div>
  <div class="y3-flex-column y3-X-small g11-input-margin g11-second-input" >



    <div class="y3-margin-bottom-8 y3-Small y3-sub-title-colors" style=" font-style: italic;">{{'guest-view-recommendation.select-category' | translate}}</div>
    <div>
      <ng-multiselect-dropdown class="" placeholder="{{'guest-view-recommendation.select-category-placeholder' | translate}}"
                               [settings]="dropdownSettings"
                               [data]="categories"
                               [(ngModel)]="selectedCategoryKey"
                               (onSelect)="selectCategory($event)"
                               (onDeSelect)="deSelectCategory($event)"
      >
      </ng-multiselect-dropdown>
    </div>
  </div>



  <div class="SECTION__HORIZONTAL_VIEW_ONLY" class="y3-margin-top-16">
    <perfect-scrollbar class="y3-flex-row" [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}">
      <div class="y3-flex-row">
        <div class="m2-horizontal-card" *ngFor="let recommendation of newRecommendations" (click)="showRecommendationMobile(recommendation);" >
          <img class="m2-horizontal-img" src="{{recommendation.mainPhoto == null ? 'assets/icon/recommendation/empty/' + recommendation.category.key : recommendation.mainPhoto}}">
          <div class="y3-flex-row y3-flex-justify-space-between y3-flex-align-center y3-Small ">
            <div class="y3-Small y3-padding-8 y3-bold y3-text-ellipsis-nowrap">{{recommendation.title }}</div>
            <div class="category-container y3-padding-8" style="margin-left: 8px">
              <img class="img-star" src="assets/icon/icon-rate-star.svg">
              <div class="y3-bold">{{recommendation.ranking.rating}}</div>
            </div>
          </div>

          <div class="y3-flex-row y3-flex-justify-space-between y3-flex-align-center y3-X-small y3-padding-8 y3-padding-top-0 ">
            <div class="category-container">
              <img *ngIf="recommendation.comment?.myFavorite" class="img-small-category" src="{{'./assets/icon/recommendation/map-cluster-favorites.svg'}}">
              <img *ngIf="!recommendation.comment?.myFavorite" class="img-small-category" src="{{'./assets/icon/recommendation/map-cluster-' + recommendation.category.key + '.svg'}}">
              <div class="category truncate-text">{{('recommendation.' + recommendation.category.key) | translate}}</div>
            </div>
            <div class="distance" >{{recommendation.distance}} km</div>
          </div>

        </div>
      </div>
    </perfect-scrollbar>
  </div>

  <!--<section #SectionRecommendationCard>
    <div>
      <div class="new-recommendation-card-container" >
        <div class="new-recommendation-card"
             (click)="openCard(recommendation) ; onClickOnRecomendationItemAtPage(recommendation)"
             *ngFor="let recommendation of newRecommendations">
          <img class="new-reco-photo" src="{{recommendation.mainPhoto == null ? 'assets/icon/recommendation/empty/' + recommendation.category.key : recommendation.mainPhoto}}"
               onerror="">

          <div class="new-reco-descr">
            <div class="header1 truncate-text">
              <div class="reco-title truncate-text">{{recommendation.title }}</div>
              <div class="category-container" style="margin-left: 8px">
                <img class="img-star" src="assets/icon/icon-rate-star.svg">
                <div class="distance">{{recommendation.ranking.rating}}</div>
              </div>
            </div>

            <div class="header1 truncate-text" style="margin-top: 8px">
              <div class="category-container">
                <img *ngIf="recommendation.comment?.myFavorite" class="img-small-category" src="{{'./assets/icon/recommendation/map-cluster-favorites.svg'}}">
                <img *ngIf="!recommendation.comment?.myFavorite" class="img-small-category" src="{{'./assets/icon/recommendation/map-cluster-' + recommendation.category.key + '.svg'}}">
                <div class="category truncate-text">{{('recommendation.' + recommendation.category.key) | translate}}</div>
              </div>
              <div class="distance" style="width: 70px">({{'guest-view-recommendation.at' | translate}} {{recommendation.distance}} km)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>-->

  <div>
    <div class="secondary-button" (click)="showMap()" style="background-color: transparent">{{'guest-view-recommendation.see-on-map' | translate}}</div>
  </div>
</div>

<app-guest-recommendations-map></app-guest-recommendations-map>
