import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {GuestService} from '../../../core/services/guest.service';
import {GenericLinkEndpoint} from '../../../core/services/V3_GenericLinkEndpoint/GenericLinkEndpoint';
import {TranslateService} from '@ngx-translate/core';
import {SharedSnackbarService} from '../../../shared/components/shared-snackbar/services/shared-snackbar.service';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {Corporate} from '../../../models/guestview/Corporate';

@Component({
  selector: 'app-property-generic-confirmation',
  templateUrl: './property-generic-confirmation.component.html',
  styleUrls: ['./property-generic-confirmation.component.scss']
})
export class PropertyGenericConfirmationComponent implements OnInit, OnDestroy {

  onDestroy = new Subject();
  lang: string = 'fr';
  corporate: Corporate;
  constructor(private guestService: GuestService,
              private genericLinkEndpoint: GenericLinkEndpoint,
              private translateService: TranslateService,
              private snackbarService: SharedSnackbarService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.lang = this.translateService.currentLang;
    this.route.params.pipe(takeUntil(this.onDestroy)).subscribe(params => {
      if (params.propertyId != null) {
        console.log("params", params);
        this.guestService.getPropertyAndBookingForGuest(params.propertyId, this.lang).subscribe( res => {
          this.corporate = res.corporate;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

}
