import { Component, OnInit } from '@angular/core';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {UtilsService} from '../../../core/services/utils.service';
import {ActivatedRoute} from '@angular/router';
import {LanguageManagementService} from '../../../core/services/language.service';
import {GuestService} from '../../../core/services/guest.service';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from '../../../core/services/SnackbarService';
import {LoaderService} from '../../../core/services/LoaderService';
import {MatDialog} from '@angular/material';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {takeUntil} from 'rxjs/operators';
import {MessageCreationDto} from '../../../models/guestview/MessageCreationDto';
import {ApiService} from '../../../core/http/api.service';
import {MessageAudit} from '../../../models/guestview/MessageAudit';
import {YaagoDocument} from '../../../models/Guide';
import {Location} from '@angular/common';

@Component({
  selector: 'app-y3-chat-messaging',
  templateUrl: './y3-chat-messaging.component.html',
  styleUrls: ['./y3-chat-messaging.component.scss']
})
export class Y3ChatMessagingComponent extends AbstractWindow implements OnInit {

  booking: BookingForGuest;
  corporate: Corporate;
  property: PropertyForGuest;
  textToSend: string = '';
  bookingId: string = '';
  messages: MessageAudit[] = [];

  constructor(
              public utilsService: UtilsService,
              private route: ActivatedRoute,
              private languageService: LanguageManagementService,
              private guestService: GuestService,
              private apiService: ApiService,
              private location: Location,
              private translateService: TranslateService,
              private snackbarService: SnackbarService,
              private loaderService: LoaderService,
              protected dialog: MatDialog) {
    super(utilsService);
  }

  ngOnInit() {
    this.route.paramMap.subscribe( ParamMap => {
      this.bookingId = ParamMap.get('bookingId');
      console.log("bookingId", this.bookingId);
      this.getMessages();
      this.guestService.getPropertyAndBookingForGuest(this.bookingId, this.languageService.getLanguageInUrl()).pipe(takeUntil(this.onDestroy)).subscribe(res => {
        this.booking = res.booking;
        this.corporate = res.corporate;
        this.property = res.property;

        //this.utilsService.manageCorporateColors(this.corporate);
      });
    });
  }

  getMessages() {
    if (!this.bookingId) {
      return;
    }
    this.apiService.getMessages(this.bookingId).subscribe( msgs => {
      console.log("MESSGBES", msgs);
      if (msgs != null && this.messages != null && msgs.length != this.messages.length) {
        this.messages = msgs;
        setTimeout( () => {
          this.scrollToLastMessage();
        }, 1000);
        console.log('--> myMessages', this.messages);
      }
    });
  }

  replaceLinks(content: string) {
    if (!content) {
      return content;
    }
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, (url) => {
      return '<div class="chat-link-container"><a href="' + url + '" target="_blank">' + url + '</a></div>';
    });
  }

  scrollToLastMessage() {
    let htmlElement = document.getElementById("message-container");
    if (htmlElement != null) {
      htmlElement.scrollTo({ behavior: 'smooth', top: htmlElement.scrollHeight });
    }
  }

  onPictureUploaded(doc: YaagoDocument) {
    this.textToSend += ' ' + doc.url;
  }
  back() {
    this.location.back();
  }

  sendMessage() {
    if (this.textToSend != null && this.textToSend.trim() != '') {
      this.loaderService.showFullLoader('');
      const message = new MessageCreationDto();
      message.type = 'internal';
      message.content = this.textToSend;
      this.apiService.sendMessage(this.bookingId, message).subscribe( messageBack => {
        this.messages.push(messageBack);
        this.loaderService.dismissLoader();
        this.textToSend = '';
        setTimeout( () => {
          this.scrollToLastMessage();
        }, 500);
        console.log(this.messages);
      });
    }
  }

}
