import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
export class GuestServiceIntern {
    constructor() {
        this.currentGuideId = new BehaviorSubject(null);
        this.bookingForGuest = new BehaviorSubject(null);
        this.propertyForGuest = new BehaviorSubject(null);
        this.guidesList = new BehaviorSubject(null);
        this.guidesDetail = new BehaviorSubject(null);
        this.guidesOneDetail = new BehaviorSubject(null);
        this.activeGuideIndex = new BehaviorSubject(null);
        this.listGuidePhotos = new BehaviorSubject(null);
        this.currentGuideId$ = this.currentGuideId.asObservable();
        this.bookingForGuest$ = this.bookingForGuest.asObservable();
        this.propertyForGuest$ = this.propertyForGuest.asObservable();
        this.guidesList$ = this.guidesList.asObservable();
        this.guidesDetail$ = this.guidesDetail.asObservable();
        this.guidesOneDetail$ = this.guidesOneDetail.asObservable();
        this.activeGuideIndex$ = this.activeGuideIndex.asObservable();
        this.listGuidePhotos$ = this.listGuidePhotos.asObservable();
    }
    setCurrentGuideId(value) {
        this.currentGuideId.next(value);
    }
    getCurrentGuideId() {
        return this.currentGuideId$;
    }
    setBookingForGuest(value) {
        this.bookingForGuest.next(value);
    }
    getBookingForGuest() {
        return this.bookingForGuest$;
    }
    setPropertyForGuest(value) {
        this.propertyForGuest.next(value);
    }
    getPropertyForGuest() {
        return this.propertyForGuest$;
    }
    setGuidesList(value) {
        this.guidesList.next(value);
    }
    getGuidesList() {
        return this.guidesList$;
    }
    setGuideDetail(value) {
        this.guidesDetail.next(value);
    }
    getGuideDetail() {
        return this.guidesDetail$;
    }
    setGuideOneDetail(value) {
        this.guidesOneDetail.next(value);
    }
    getGuideOneDetail() {
        return this.guidesOneDetail$;
    }
    setGuidePhotos(value) {
        this.listGuidePhotos.next(value);
    }
    getGuidePhotos() {
        return this.listGuidePhotos;
    }
    setActiveGuideIndex(value) {
        this.activeGuideIndex.next(value);
    }
    getActiveGuideIndex() {
        return this.activeGuideIndex$;
    }
}
GuestServiceIntern.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GuestServiceIntern_Factory() { return new GuestServiceIntern(); }, token: GuestServiceIntern, providedIn: "root" });
