<div class="m9-main-container">
  <div class="m9-close-button">
    <img src="assets/icon/close-round.svg" alt="close" (click)="goBack()" />
  </div>

  <div class="y3-flex-row y3-flex-justify-content-center y3-margin-bottom-32 ">
    <img *ngIf="corporate.logoUrl" class="m9-logo" [src]="corporate.logoUrl" alt="image" />
  </div>

  <div class="y3-margin-top-24 y3-margin-bottom-24">
    <div class="y3-High y3-bold y3-title-colors">{{'guest-view-booking-details.useful-number' | translate}}</div>
  </div>

  <div class="y3-padding-bottom-16">
    <div *ngFor="let number of numbers; let iNumber = index" >
      <div class="m9-card-usefulNumber y3-flex-column y3-flex-align-center y3-flex-justify-content-center">
        <div class=" y3-Small y3-color-scorpion">{{number.category}}</div>
        <div class="y3-margin-top-4 y3-bold y3-Small ">{{number.title}}</div>

        <div class="y3-margin-top-4 y3-X-small ">{{number.fullAddress}}</div>
        <div class="y3-margin-top-8 y3-Medium y3-color-scorpion">{{number.phoneNumber}}</div>
      </div>
    </div>
  </div>

</div>

