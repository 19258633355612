import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { CheckinService } from "src/app/core/services/checkin.service";
import { GuestService } from "src/app/core/services/guest.service";
import { LanguageManagementService } from "src/app/core/services/language.service";
import { UtilsService } from "src/app/core/services/utils.service";
import { BookingForGuest } from "src/app/models/guestview/BookingForGuest";
import { Corporate } from "src/app/models/guestview/Corporate";
import { PropertyForGuest } from "src/app/models/guestview/PropertyForGuest";
import { OnlineCheckDetailsComponent } from "../online-check-details.component";
import {LoaderService} from "../../../core/services/LoaderService";
import {PaysService} from "../../../core/services/pays.service";
import {DocumentService} from "../../../core/services/DocumentService";
import {SnackbarService} from "../../../core/services/SnackbarService";
import {OnlineCheckin} from "../../../models/guestview/OnlineCheckin";
import { DOCUMENT } from "@angular/common";
import {InternalPropertyService} from "../../../core/services/internal.property.service";
import { SharedSnackbarService } from "src/app/shared/components/shared-snackbar/services/shared-snackbar.service";
import { Console } from "console";
import {Title} from '@angular/platform-browser';

@Component({
  selector: "app-online-check-waiting",
  templateUrl: "./online-check-waiting.component.html",
  styleUrls: ["./online-check-waiting.component.scss"],
})
export class OnlineCheckWaitingComponent
  extends OnlineCheckDetailsComponent
  implements OnInit
{
  @Input() booking: BookingForGuest;
  @Input() localOnLineCheck: OnlineCheckin;
  bookingId: string;
  @Input() property: PropertyForGuest;
  urlContrat: string;
  currentLang: string;
  display = false;
  country: any;
  countryName: any;
  contratSRC : any
  onLineCheckCountry: any;
  iframeTimeoutId : any
  onDestroy = new Subject();
  str3 : any
  date : Date
  mode = { edit: false, show: true };
  reasonFr : string
  reasonEn : string
  @Input() corporate: Corporate;
  constructor(
    public loaderService: LoaderService,
    public languageService: LanguageManagementService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public guestService: GuestService,
    public translateService: TranslateService,
    public fb: FormBuilder,
    public utilService: UtilsService,
    public checkinService: CheckinService,
    public paysService: PaysService,
    public documentService: DocumentService,
    public snackbarService: SnackbarService,
    public propertyService: InternalPropertyService,
    public sharedSnackbarService: SharedSnackbarService,
    public title: Title,
    @Inject(DOCUMENT) private document: any,
    public router:Router
  )
   {
    super(
      loaderService,
      languageService,
      route,
      dialog,
      guestService,
      translateService,
      fb,
      utilService,
      checkinService,
      paysService,
      documentService,
      snackbarService,
      propertyService,
      sharedSnackbarService,
      title
    );

  }

  ngOnInit() {
    console.log("HISTORY",history.state.data)
    if(this.booking){
      if(this.booking.onLineCheck.checkStatus == "VALIDATED" && !history.state.data){
        this.router.navigate(['/guest/' + this.booking.id]);
      }

      this.localOnLineCheck = this.booking.onLineCheck;
      console.log("editresp", this.localOnLineCheck)
      this.booking.onLineCheck.checkStatus = this.localOnLineCheck.checkStatus

      setTimeout(() => {
        this.contratSRC = this.localOnLineCheck.contractDocumentUrlSigned;
      }, 3000);
    }else{
       //fix bugsnag
       if (history.state.data.booking){
      this.booking = history.state.data.booking;
      this.bookingId = this.booking.id;
      console.log("BOOOOK",history.state.data.booking)
       }
      if(this.booking.onLineCheck.checkStatus == "VALIDATED" && !history.state.data.keep){
        this.router.navigate(['/guest/' + this.booking.id]);
      }

      this.localOnLineCheck = this.booking.onLineCheck;
      this.booking.onLineCheck.checkStatus = this.localOnLineCheck.checkStatus

      setTimeout(() => {
        this.contratSRC = this.localOnLineCheck.contractDocumentUrlSigned;
      }, 3000);

    }
  }



}
