<div class="menu-desktop" id="menu" [class.dark]="theme === 'dark'">
  <div class="containermenu">
    <div class="row-navbar navbar">
      <a *ngIf="(withOnlinecheck === false || withOnlinecheck === true && CheckinValidated === true )" href="#"  [routerLink]="['/guest', bookingId] ">
        <div class="logo">
          <img *ngIf="property?.customLayout && property?.host?.btobInfo?.logoUrl" style="height: 60px; padding-right: 24px;" src="{{property?.host?.btobInfo?.logoUrl}}"/>

          <img *ngIf="corporate?.logoUrl" style="height: 60px; padding-right: 24px;" [src]="corporate.logoUrl" (click)="loader()">

        </div>
      </a>
      <a *ngIf="withOnlinecheck === true && CheckinValidated == false " >
        <div class="logo">
          <img *ngIf="property?.customLayout && property?.host?.btobInfo?.logoUrl" style="height: 60px; padding-right: 24px;" src="{{property?.host?.btobInfo?.logoUrl}}"/>

          <img *ngIf="corporate?.logoUrl" style="height: 60px; padding-right: 24px;" [src]="corporate.logoUrl" (click)="loader()">

        </div>
      </a>
      <li class="navigation">
        <ul *ngIf="withOnlinecheck === false" class="menu_list">
          <li *ngIf="onlineCheck?.checkStatus ===  'VALIDATED' || onlineCheck?.checkStatus === 'WAITING' || onlineCheck?.checkStatus === 'REFUSED'" appToogleBtnSwitch ngClass="{{currentActiveMenu === 'onlinecheck' ? 'open' : ''}}">
            <button (click)="goOnlinecheck()"
              >{{'menu-desktop.checkinOnline' | translate}}</button>
          </li>
          <li  appToogleBtnSwitch ngClass="{{currentActiveMenu === 'details' && currentMode =='guest' ? 'open' : ''}}">
            <button routerLink="{{'/guest/' + bookingId +'/details'}}"  [queryParams]="{lang:currentLanguage}"  (click)="currentActiveMenu='details'"
              >{{'menu-desktop.bookingDetails' | translate}}</button>
          </li>
          <li *ngIf="property?.guides?.length > 0 " ngClass="{{currentActiveMenu === 'guides' ? 'open' : ''}}">
            <button routerLink="{{'/guest/' + bookingId +'/guides'}}" [queryParams]="{lang:currentLanguage}"  (click)="currentActiveMenu='guides'"
               >{{'menu-desktop.propertyGuide' | translate}}</button>
          </li>
          <li *ngIf="!property?.noshowRecommendationOnBooklet && property?.poi" ngClass="{{currentActiveMenu === 'recommendations' ? 'open' : ''}}">
            <button routerLink="{{'/guest/' + bookingId +'/recommendations' }}" [queryParams]="{lang:currentLanguage}"  (click)="currentActiveMenu='recommendations'"
              >{{'menu-desktop.recommendations' | translate}}</button>
          </li>
          <li
            *ngIf="property?.usefulNumbers?.length > 0 "
            ngClass="{{currentActiveMenu === 'usefulnumber' ? 'open' : ''}}">
            <button routerLink="{{'/guest/' + bookingId +'/usefulnumber' }}"[queryParams]="{lang:currentLanguage}" (click)="currentActiveMenu='usefulnumber'"
              >{{'menu-desktop.usefulNumbers' | translate}} </button>
          </li>
          <li *ngIf="!property?.host?.btobInfo && !property?.noshowPlatformServicesOnBooklet " ngClass="{{currentActiveMenu === 'services' ? 'open' : ''}}">
            <button routerLink="{{'/guest/' + bookingId +'/services'}}"  [queryParams]="{lang:currentLanguage}" (click)="currentActiveMenu='services'"
              >{{'menu-desktop.platformServices' | translate}}</button>
          </li>
          <li *ngIf="property?.forceMajeureItems?.length > 0 && !property?.noshowHygieneOnBooklet " ngClass="{{currentActiveMenu === 'hygienes' ? 'open' : ''}}">
            <button routerLink="{{'/guest/' + bookingId +'/hygienes' }}" [queryParams]="{lang:currentLanguage}" (click)="currentActiveMenu='hygienes'"
              >{{'menu-desktop.hygiene' | translate}}</button>
          </li>

          <!-- <li *ngIf="booking != null" ngClass="{{currentActiveMenu === 'messaging' ? 'open' : ''}}">
            <button routerLink="{{'/guest/' + bookingId +'/messaging'}}"  (click)="currentActiveMenu='messaging'" style="position: relative "
            >{{'menu-desktop.messaging' | translate}}<div *ngIf="hasUnreadMessages" class="badge"></div></button>
          </li> -->

          <li *ngIf="booking != null && hasUnreadMessages && !property?.noShowChatContact" class="margin-left-16 roboto Large nav-alert-placeholder clickable" routerLink="{{'/guest/' + bookingId +'/messaging'}}">
            <img alt="avatar" src="assets/icon/mail-inbox.svg"
                 style="width: 25px;  margin-bottom: 3px;">
            <div *ngIf="hasUnreadMessages" class="badge">&nbsp;</div>
          </li>

        </ul>

        <ul *ngIf="withOnlinecheck === true" class="menu_list">
          <li *ngIf="property?.checkInOnline == true" appToogleBtnSwitch ngClass="{{currentActiveMenu === 'details' ? 'open' : ''}}">
            <button (click)="goOnlinecheck()"
              >{{'menu-desktop.checkinOnline' | translate}}</button>
          </li>
          <li *ngIf="CheckinValidated == true" appToogleBtnSwitch ngClass="{{currentActiveMenu === 'details' && currentMode =='guest' ? 'open' : ''}}">
            <button routerLink="{{'/guest/' + bookingId +'/details'}}"  [queryParams]="{lang:currentLanguage}"  (click)="currentActiveMenu='details'"
              >{{'menu-desktop.bookingDetails' | translate}}</button>
          </li>
          <li *ngIf="property?.guides?.length > 0 && CheckinValidated == true" ngClass="{{currentActiveMenu === 'guides' ? 'open' : ''}}">
            <button routerLink="{{'/guest/' + bookingId +'/guides'}}" [queryParams]="{lang:currentLanguage}"  (click)="currentActiveMenu='guides'"
              >{{'menu-desktop.propertyGuide' | translate}}</button>
          </li>
          <li *ngIf="!property?.noshowRecommendationOnBooklet  && CheckinValidated == true " ngClass="{{currentActiveMenu === 'recommendations' ? 'open' : ''}}">
            <button routerLink="{{'/guest/' + bookingId +'/recommendations' }}" [queryParams]="{lang:currentLanguage}"  (click)="currentActiveMenu='recommendations'"
              >{{'menu-desktop.recommendations' | translate}}</button>
          </li>
          <li
            *ngIf="property?.usefulNumbers?.length > 0  && CheckinValidated == true"
            ngClass="{{currentActiveMenu === 'usefulnumber' ? 'open' : ''}}">
            <button routerLink="{{'/guest/' + bookingId +'/usefulnumber' }}"[queryParams]="{lang:currentLanguage}" (click)="currentActiveMenu='usefulnumber'"
              >{{'menu-desktop.usefulNumbers' | translate}} </button>
          </li>
          <li *ngIf="!property?.host?.btobInfo && !property?.noshowPlatformServicesOnBooklet  && CheckinValidated == true " ngClass="{{currentActiveMenu === 'services' ? 'open' : ''}}">
            <button routerLink="{{'/guest/' + bookingId +'/services'}}"  [queryParams]="{lang:currentLanguage}" (click)="currentActiveMenu='services'"
              >{{'menu-desktop.platformServices' | translate}}</button>
          </li>
          <li *ngIf="property?.forceMajeureItems?.length > 0 && !property?.noshowHygieneOnBooklet  && CheckinValidated == true" ngClass="{{currentActiveMenu === 'hygienes' ? 'open' : ''}}">
            <button routerLink="{{'/guest/' + bookingId +'/hygienes' }}" [queryParams]="{lang:currentLanguage}" (click)="currentActiveMenu='hygienes'"
              >{{'menu-desktop.hygiene' | translate}}</button>
          </li>



          <li *ngIf="booking != null && hasUnreadMessages && !property?.noShowChatContact" class="margin-left-16 roboto Large nav-alert-placeholder clickable" routerLink="{{'/guest/' + bookingId +'/messaging'}}">
            <img alt="avatar" src="assets/icon/mail-inbox.svg"
                 style="width: 25px;  margin-bottom: 3px;">
            <div *ngIf="hasUnreadMessages" class="badge">&nbsp;</div>
          </li>

        </ul>

        <!--<app-button routerLink="{{'/guest/' + bookingId +'/messaging'}}" *ngIf=" booking && !property?.noShowChatContact" [text]="'menu-desktop.messaging' | translate" [type]="'primary'" (debounceClick)="currentActiveMenu='messaging'"></app-button>-->
 <!-- <app-button routerLink="{{'/guest/' + bookingId +'/messaging'}}" *ngIf="booking != null && !property.noShowChatContact" [text]="'menu-desktop.messaging' | translate" [type]="'primary'" (debounceClick)="currentActiveMenu='messaging'"></app-button> -->
        <div class="language-chooser flex flex-row flex-align-center clickable margin-right-16" (click)="this.languageChooserOpened = !this.languageChooserOpened;"
             appClickOutside (clickOutside)="languageChooserOpened = false">

          <img class="flag margin-left-8" src="{{getLanguage(currentLanguage)}}" alt="Flag">

          <div class="chooser-container grey-scroll" *ngIf="languageChooserOpened">
            <div class="flex flex-row flex-align-center clickable margin-top-12 margin-bottom-12"
                 *ngFor="let lang of getLanguages()"
                 (click)="changeLanguage(lang)">
              <img class="flag margin-right-12" src="{{getLanguage(lang)}}" alt="Flag">
              <span
                class="roboto Small txt-color-black txt-nowrap">{{getNonTranslatedLanguageName(lang)}}</span>
            </div>
          </div>
        </div>



        <div >
          <app-y3-explained-button *ngIf="booking && !property?.noShowChatContact" routerLink="{{'/guest/' + bookingId +'/chat'}}" [title]="'menu-desktop.messaging' | translate" [description]="'menu-desktop.messaging-description' | translate"></app-y3-explained-button>
        </div>

      </div>

    </div>
</div>
<!--<div class="upsell-container" *ngIf="checkIfUpSell()">
  <div class="y3-flex-row y3-flex-justify-space-between y3-flex-align-center y3-padding-8 y3-Small" style="height: 100%">
    <div></div>
    <div class="y3-flex-row y3-flex-align-center">
      <div class="y3-margin-right-16" style="color: white">{{'menu-desktop.basket-content' | translate}} {{getNumbers()}}</div>
      <div class="y3-margin-right-16">
        <app-button [text]="'menu-desktop.checkout-basket' | translate" [type]="'primary-x-small'" (debounceClick)="validateUpsell()"></app-button>
      </div>
      <div>
        <app-button [text]="'menu-desktop.remove-basket' | translate" [type]="'secondary-x-small-white'" (debounceClick)="resetBasket()"></app-button>
      </div>
    </div>
  </div>
</div>-->
<!--<div class="upsell-waiting-container" *ngIf="currentUpSellOnBoarding != null && currentUpSellOnBoarding.paymentIntentStatus == null">
  <div class="y3-flex-row y3-flex-justify-space-between y3-flex-align-center y3-padding-8 y3-Small" style="height: 100%">
    <div></div>
    <div class="y3-flex-row y3-flex-align-center">
      <mat-icon>warning</mat-icon>
      <div class="y3-margin-right-16 y3-margin-left-8">{{'menu-desktop.add-paiment-method-descr' | translate}}</div>

      <div class="y3-margin-right-8">
        <app-button [text]="'menu-desktop.add-paiment-method' | translate" [type]="'primary-x-small'" (debounceClick)="putPaymentMethod()"></app-button>
      </div>

      <div>
        <app-button [text]="'menu-desktop.cancel-basket' | translate" [type]="'secondary-x-small-white'" (debounceClick)="cancelUpsell()"></app-button>
      </div>
    </div>
  </div>
</div>-->
