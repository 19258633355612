import * as i0 from "@angular/core";
import * as i1 from "@ngx-config/core";
export class ConfService {
    constructor(config) {
        this.config = config;
    }
    getBackendApiEndPoint() {
        try {
            /*const  url: string = this.config.getSettings('system.apiEndPoint');
            if (url == null || url.trim() === '') {
            } else {
              return url;
            }*/
            if (window.location.origin.indexOf("staging") > -1) {
                return window.location.origin.replace("guest.staging", "staging") + "/";
            }
            else if (window.location.origin.indexOf("demo") > -1) {
                return window.location.origin.replace("guest.demo", "demo") + "/";
            }
            else {
                return window.location.origin.replace("guest.", "app.") + "/";
            }
        }
        catch (exception) {
            console.log(exception);
            if (window.location.origin.indexOf("staging") > -1) {
                return window.location.origin.replace("guest.staging", "staging") + "/";
            }
            else if (window.location.origin.indexOf("demo") > -1) {
                return window.location.origin.replace("guest.demo", "demo") + "/";
            }
            else {
                return window.location.origin.replace("guest.", "app.") + "/";
            }
        }
    }
}
ConfService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfService_Factory() { return new ConfService(i0.ɵɵinject(i1.ConfigService)); }, token: ConfService, providedIn: "root" });
