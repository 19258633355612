/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-photo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./delete-photo.component";
var styles_DeletePhotoComponent = [i0.styles];
var RenderType_DeletePhotoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeletePhotoComponent, data: {} });
export { RenderType_DeletePhotoComponent as RenderType_DeletePhotoComponent };
export function View_DeletePhotoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "container-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h4", [["class", "request-confirm text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", "", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 12, "mat-dialog-actions", [["class", "row d-flex align-items-center justify-content-center text-center buttons-container mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i3.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [["class", "cancel  col-sm-12"], ["mat-stroked-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dialogRef.close(i1.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(10, 606208, null, 0, i3.MatDialogClose, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(11, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["class", "confirm col-sm-12 text-center"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeImg() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(16, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_6 = true; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.data.documentType ? "" : i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("guest-inventory.ask-for-delete-identity"))); var currVal_1 = (_co.data.documentType ? "" : i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 4).transform("guest-inventory.ask-for-delete"))); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 9).disabled || null); var currVal_3 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); var currVal_4 = (i1.ɵnov(_v, 10).ariaLabel || null); var currVal_5 = i1.ɵnov(_v, 10).type; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("guest-inventory.cancel")); _ck(_v, 11, 0, currVal_7); var currVal_8 = (i1.ɵnov(_v, 15).disabled || null); var currVal_9 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_8, currVal_9); var currVal_10 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("guest-inventory.delete")); _ck(_v, 16, 0, currVal_10); }); }
export function View_DeletePhotoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delete-photo", [], null, null, null, View_DeletePhotoComponent_0, RenderType_DeletePhotoComponent)), i1.ɵdid(1, 114688, null, 0, i8.DeletePhotoComponent, [i3.MAT_DIALOG_DATA, i3.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeletePhotoComponentNgFactory = i1.ɵccf("app-delete-photo", i8.DeletePhotoComponent, View_DeletePhotoComponent_Host_0, {}, {}, []);
export { DeletePhotoComponentNgFactory as DeletePhotoComponentNgFactory };
