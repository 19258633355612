/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-property-guides-desktop.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/core";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../shared/components/tabs-menu/tabs-menu.component.ngfactory";
import * as i10 from "../../../../shared/components/tabs-menu/tabs-menu.component";
import * as i11 from "../../guest-guides/guest-guide-details/guest-guide-details.component.ngfactory";
import * as i12 from "../../guest-guides/guest-guide-details/guest-guide-details.component";
import * as i13 from "@angular/router";
import * as i14 from "../../../../core/services/utils.service";
import * as i15 from "../../../../core/services/V3_GuestGuideEndpoint/GuestGuideEndpoint";
import * as i16 from "@angular/material/dialog";
import * as i17 from "../../../../core/http/api.service";
import * as i18 from "./guest-property-guides-desktop.component";
import * as i19 from "../../guest.service";
import * as i20 from "../../../../core/services/guest.service";
import * as i21 from "../../../../core/services/language.service";
var styles_GuestPropertyGuidesDesktopComponent = [i0.styles];
var RenderType_GuestPropertyGuidesDesktopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestPropertyGuidesDesktopComponent, data: {} });
export { RenderType_GuestPropertyGuidesDesktopComponent as RenderType_GuestPropertyGuidesDesktopComponent };
function View_GuestPropertyGuidesDesktopComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "width-100 guide-card flex flex-row flex-align-center margin-right-16 y3-margin-bottom-16 clickable mat-ripple"], ["matRipple", ""]], [[2, "mat-ripple-unbounded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openGuideDetails(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i2.MatRipple, [i1.ElementRef, i1.NgZone, i3.Platform, [2, i2.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "cover"], ["class", "guide-cover"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "guide-title y3-margin-left-8 "]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).unbounded; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.getGuideCover(_v.parent.context.$implicit), ""); _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_v.parent.context.$implicit == null) ? null : ((_v.parent.context.$implicit.translations[0] == null) ? null : _v.parent.context.$implicit.translations[0].title)); _ck(_v, 4, 0, currVal_2); }); }
function View_GuestPropertyGuidesDesktopComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestPropertyGuidesDesktopComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.needToShowGuide(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); }, null); }
function View_GuestPropertyGuidesDesktopComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["propertyGuide", 1]], null, 19, "div", [["class", "guest-view-desktop-property-guide guide-back-color separator"], ["id", "propertyGuide"], ["name", "propertyGuide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "container-guide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", " y3-margin-bottom-16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "material-symbols-outlined y3-steph-title-dot mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["storefront"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "y3-steph-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "y3-steph-title-dot"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "y3-Small y3-margin-top-4 y3-steph-color-text y3-sub-title-colors"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "margin-top-24 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-tabs-menu", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.onTabMenuSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_TabsMenuComponent_0, i9.RenderType_TabsMenuComponent)), i1.ɵdid(16, 638976, null, 0, i10.TabsMenuComponent, [], { tabs: [0, "tabs"] }, { selected: "selected" }), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "width-100 flex flex-row flex-wrap margin-top-24"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestPropertyGuidesDesktopComponent_2)), i1.ɵdid(19, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_4 = _co.categoryTabs; _ck(_v, 16, 0, currVal_4); var currVal_5 = _co.currentGuidesList; _ck(_v, 19, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).inline; var currVal_1 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("guest-guid-page.title")); _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("guest-guid-page.title-explanation")); _ck(_v, 12, 0, currVal_3); }); }
function View_GuestPropertyGuidesDesktopComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "details-dialog-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "details-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-guide-details", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_GuestGuideDetailsComponent_0, i11.RenderType_GuestGuideDetailsComponent)), i1.ɵdid(3, 114688, null, 0, i12.GuestGuideDetailsComponent, [i13.ActivatedRoute, i8.TranslateService, i14.UtilsService, i15.GuestGuideEndpoint, i5.Location, i16.MAT_DIALOG_DATA, i17.ApiService, i16.MatDialogRef], { guide: [0, "guide"], property: [1, "property"], booking: [2, "booking"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentGuide; var currVal_1 = _co.property; var currVal_2 = _co.booking; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_GuestPropertyGuidesDesktopComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mytmpl: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestPropertyGuidesDesktopComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestPropertyGuidesDesktopComponent_4)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.guides && (_co.guides.length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.currentGuide && _co.showGuideDetails); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_GuestPropertyGuidesDesktopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-property-guides-desktop", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestPropertyGuidesDesktopComponent_0, RenderType_GuestPropertyGuidesDesktopComponent)), i1.ɵdid(1, 770048, null, 0, i18.GuestPropertyGuidesDesktopComponent, [i19.GuestServiceIntern, i13.Router, i13.ActivatedRoute, i14.UtilsService, i20.GuestService, i21.LanguageManagementService, i16.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestPropertyGuidesDesktopComponentNgFactory = i1.ɵccf("app-guest-property-guides-desktop", i18.GuestPropertyGuidesDesktopComponent, View_GuestPropertyGuidesDesktopComponent_Host_0, { property: "property", booking: "booking" }, {}, []);
export { GuestPropertyGuidesDesktopComponentNgFactory as GuestPropertyGuidesDesktopComponentNgFactory };
