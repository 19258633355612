<div class="m4-main-container">
  <div class="m4-close-button">
    <img src="assets/icon/close-round.svg" alt="close" (click)="goBack()" />
  </div>

  <div *ngIf="property.showMeAsHost" class="y3-flex-column y3-flex-justify-content-center y3-flex-align-center y3-full-width">

      <div class="y3-flex-row y3-flex-justify-content-center y3-margin-bottom-32 ">
        <img *ngIf="corporate.logoUrl" class="m4-logo" [src]="corporate.logoUrl" alt="image" />
      </div>

      <div *ngIf="property.coHostInCharge?.companyName" class="y3-XLarge y3-margin-bottom-8 y3-margin-top-16 y3-bold">{{property.coHostInCharge?.companyName }} </div>
      <div class="y3-XLarge y3-margin-bottom-8 y3-margin-top-16 y3-bold">{{property.coHostInCharge.firstName}} {{property.coHostInCharge.lastName}}</div>
      <div class="y3-Small y3-color-scorpion">{{property.coHostInCharge.fullAddress}}</div>
      <div class="y3-Small y3-color-scorpion">{{property.coHostInCharge.email}}</div>
      <div class="y3-Small y3-color-scorpion">{{property.coHostInCharge.phoneNumber}}</div>

      <div *ngIf="property.coHostInCharge.openHours" class="y3-X-small y3-color-scorpion y3-margin-top-16">{{property.coHostInCharge.openHours}}</div>

    <div *ngIf="corporate.showMeAsHost && property?.coHostInCharge" style="text-align: center">
      <div *ngIf="property.coHostInCharge?.companyName" class="y3-XLarge y3-margin-bottom-8 y3-margin-top-16 y3-bold">{{property.coHostInCharge?.companyName }} </div>
      <div class="y3-XLarge y3-margin-top-16 y3-bold">{{property?.coHostInCharge?.firstName}} {{property?.coHostInCharge?.lastName}}</div>

      <div class="language-container y3-margin-bottom-8">
        <ng-template [ngIf]="property?.coHostInCharge?.languages ">
          <img *ngFor="let languageCode of property?.coHostInCharge?.languages" class="margin-right-8 margin-top-8" src="{{ getLanguage(languageCode) }}" alt="flags">
        </ng-template>
      </div>

      <div class="y3-Small y3-color-scorpion">{{property?.coHostInCharge?.email}}</div>
      <div class="y3-Small y3-color-scorpion">{{property?.coHostInCharge?.phoneNumber}}
        <span *ngIf="property?.coHostInCharge?.phoneUrgencyOnly">&nbsp; {{'guest-view-booking-details.urgency-only' | translate}}</span>
      </div>

      <div class="y3-margin-top-24">

        <a class="y3-margin-top-4 y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="property?.coHostInCharge?.whatsAppLink"
           href="{{property?.coHostInCharge?.whatsAppLink}}" target="_blank">
          <div class="y3-flex-row y3-flex-align-center">
            <img  class="icon-social" src="assets/icon/whatsapp_icon.png">
            <div>{{property?.coHostInCharge?.whatsAppLink}}</div>
          </div>
        </a>

        <a class="y3-margin-top-4 y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="property?.coHostInCharge?.instagramUrl"
           href="{{property?.coHostInCharge?.instagramUrl}}" target="_blank">
          <div class="y3-flex-row y3-flex-align-center">
            <img  class="icon-social" src="assets/icon/Instagram_icon.png">
            <div>{{property?.coHostInCharge?.instagramUrl}}</div>
          </div>
        </a>


        <a class="y3-margin-top-4 y3-flex-align-center y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="property?.coHostInCharge?.linkedInUrl"
           href="{{property?.coHostInCharge?.linkedInUrl}}" target="_blank">
          <div class="y3-flex-row y3-flex-align-center">
            <img  class="icon-social" src="assets/icon/linkedin.png">
            <div style="text-decoration: underline; font-weight: bold; color: #03224C">{{property?.coHostInCharge?.linkedInUrl}}</div>
          </div>
        </a>


        <a class="y3-margin-top-4 y3-flex-align-center y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="property?.coHostInCharge?.facebookUrl"
           href="{{property?.coHostInCharge?.facebookUrl}}" target="_blank">
          <div class="y3-flex-row y3-flex-align-center">
            <img class="icon-social" src="assets/icon/facebook_icon.png">
            <div>{{property?.coHostInCharge?.facebookUrl}}</div>
          </div>
        </a>

        <a class="y3-margin-top-4 y3-roboto y3-X-small txt-color-dimgray" *ngIf="property?.coHostInCharge?.webSiteUrl"
           href="{{property?.coHostInCharge?.webSiteUrl}}" target="_blank">
          <div class="y3-flex-row y3-flex-align-center">
            <div style="text-decoration: underline; font-weight: bold; color: #03224C">{{property?.coHostInCharge?.webSiteUrl}}</div>
          </div>
        </a>

      </div>
    </div>


</div>
