import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../../../core/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {GuestService} from '../../../core/services/guest.service';
import {LoaderService} from '../../../core/services/LoaderService';
import {ApiService} from '../../../core/http/api.service';
import {Location} from '@angular/common';
import {Corporate} from '../../../models/guestview/Corporate';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {CheckPassword} from '../../../models/guestview/CheckPassword';
import {SecurityService} from '../../../core/services/SecurityService';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';

@Component({
  selector: 'app-m12-password-screen',
  templateUrl: './m12-password-screen.component.html',
  styleUrls: ['./m12-password-screen.component.scss']
})
export class M12PasswordScreenComponent extends AbstractWindow implements OnInit {

  corporate: Corporate;
  property: PropertyForGuest;
  booking: BookingForGuest;
  idForRouting: string;

  password: string = '';
  errorMsg: string = '';
  constructor(private activatedRoute: ActivatedRoute,
              public utilService: UtilsService,
              public translateService: TranslateService,
              private guestService: GuestService,
              private loaderService: LoaderService,
              private apiService: ApiService,
              private router: Router,
              private securityService: SecurityService,
              private location: Location) {
    super(utilService);
  }


  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      this.loaderService.dismissLoader();
      if (res && res.propertyAndBooking) {
        this.corporate = res.propertyAndBooking.corporate;
        this.property = res.propertyAndBooking.property;
        //this.utilService.manageCorporateColors(this.corporate);
        this.booking = res.propertyAndBooking.booking;

        this.idForRouting = this.property.id;
        if (this.booking) {
          this.idForRouting = this.booking.id;
        }
      }
    });
  }

  setPassword() {
    this.loaderService.showFullLoader('');
    this.errorMsg = '';
    const checkPassword: CheckPassword = new CheckPassword();
    checkPassword.code = this.password;
    checkPassword.corporateId = this.corporate.id;
    checkPassword.propertyId = this.property.id;
    this.apiService.getCheckPassword(checkPassword).subscribe( res => {

      this.securityService.setSecurityPassword(this.idForRouting);
      if (res != null) {
        return this.router.navigate(['/guest/' + res.id]).then( () => {
          this.loaderService.dismissLoader();
        });
      } else {
        return this.router.navigate(['/guest/' + this.property.id]).then( () => {
          this.loaderService.dismissLoader();
        });
      }
    }, err => {
      this.loaderService.dismissLoader();
      this.errorMsg = this.translateService.instant('m12-password-screen.wrong-password');
    });
  }

}
