/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./external-video-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./external-video-box.component";
import * as i4 from "../../../core/services/utils.service";
var styles_ExternalVideoBoxComponent = [i0.styles];
var RenderType_ExternalVideoBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExternalVideoBoxComponent, data: {} });
export { RenderType_ExternalVideoBoxComponent as RenderType_ExternalVideoBoxComponent };
function View_ExternalVideoBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["frameborder", "0"], ["height", "100%;"], ["id", "video1"], ["mozallowfullscreen", ""], ["webkitallowfullscreen", ""], ["width", "100%"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.securedUrl; _ck(_v, 0, 0, currVal_0); }); }
export function View_ExternalVideoBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "thumbnail-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExternalVideoBoxComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.securedUrl; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ExternalVideoBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-external-video-box", [], null, null, null, View_ExternalVideoBoxComponent_0, RenderType_ExternalVideoBoxComponent)), i1.ɵdid(1, 638976, null, 0, i3.ExternalVideoBoxComponent, [i4.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExternalVideoBoxComponentNgFactory = i1.ɵccf("app-external-video-box", i3.ExternalVideoBoxComponent, View_ExternalVideoBoxComponent_Host_0, { videoUrl: "videoUrl" }, {}, []);
export { ExternalVideoBoxComponentNgFactory as ExternalVideoBoxComponentNgFactory };
