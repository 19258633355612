import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter, MatCalendar, MatDateFormats, MatDatepicker, MAT_DATE_FORMATS, yearsPerPage } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss']
})
export class CustomHeaderComponent<D>  implements OnDestroy,OnInit {
  private _destroyed = new Subject<void>();
  

  date = new FormControl(moment());
  constructor(
    
    private _datePicker: MatDatepicker<D>,
    private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    private translate: TranslateService,
    
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) { 
   
    calendar.stateChanges
    .pipe(takeUntil(this._destroyed))
    .subscribe(() => cdr.markForCheck());
  this.dateAdapter.setLocale(this.translate.currentLang);
  this.translate.onLangChange.pipe(takeUntil(this._destroyed)).subscribe(event => {
    this.dateAdapter.setLocale(event.lang);
  });
  }
  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  ngOnInit() {
  }
  get periodYearLabel() {
    const date =  new Date();
    const momentDate = moment(date);
    const momentYear = momentDate.year();
    return momentYear;
  }

  get periodDateLabel() {
    const date =  new Date();
    const momentDate = moment(date);
    return this.capitalizeFirstLetter(momentDate.format('dddd DD MMMM'));
  }

  get periodLabel() {
    return this.capitalizeFirstLetter(this.dateAdapter
      .format(this.calendar.activeDate, this.dateFormats.display.monthYearA11yLabel));
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  previousClicked(mode: 'month' ) {
    this.calendar.activeDate = mode === 'month' ?
      this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1) :
      this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
   
  }
 


  nextClicked(mode: 'month') {
    this.calendar.activeDate = mode === 'month' ?
      this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1) :
      this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
   
  }
  previousClickedyear( mode: 'year' ) {
    
    this.calendar.currentView = 'multi-year';
    this.calendar.activeDate = mode === 'year' ?
    this.dateAdapter.addCalendarYears(this.calendar.activeDate, -24) :
    this.dateAdapter.addCalendarYears(this.calendar.activeDate, -24);
  }
  previousClickeddd(mode: 'year' ) {
    this.calendar.activeDate = mode === 'year' ?
      this.dateAdapter.addCalendarYears(this.calendar.activeDate, -100) :
      this.dateAdapter.addCalendarYears(this.calendar.activeDate, -100);
   
  }

}
