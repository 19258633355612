<div >
  <div *ngIf="inventory != null">
    <div class="y3-Small y3-margin-top-16 y3-margin-bottom-8">{{'guest-inventory.add-comment-description' | translate}}</div>
    <textarea [rows]="10" style="width: 100%" class="y3-input-text-small" [(ngModel)]="inventory.comment"></textarea>

    <div class="y3-Medium y3-margin-top-16">{{'guest-inventory.share-pictures' | translate}}</div>

    <div class="y3-flex-column y3-margin-top-16 y3-X-small" >
      <div class="y3-X-small " *ngFor="let document of inventory.pictures; let iPicture = index">
        <div class="y3-margin-bottom-8 ">
          <a href="{{document}}" target="_blank">{{utilService.getDocumentName(document)}}</a>
          <img [src]="'assets/icon/inventory/delete.svg'" (click)="deletePicture(iPicture)" class="y3-cursor-pointer y3-margin-left-8">
        </div>
      </div>
    </div>

    <div class="g11-upload">
      <app-shared-upload (docUploaded)="onPicturesUpload($event)" [allowedTypes]="utilService.allowedImagesDocs"
                         [btnLabel]="'guest-inventory.upload'" [uploadType]="'multiple'"></app-shared-upload>
    </div>


    <div class="y3-Medium y3-margin-top-16">{{'guest-inventory.share-videos' | translate}}</div>

    <div class="y3-flex-column y3-margin-top-16 y3-X-small" >
      <div class="y3-X-small " *ngFor="let document of inventory.videos; let iVideo = index">
        <div class="y3-margin-bottom-8 ">
          <a href="{{document}}" target="_blank">{{utilService.getDocumentName(document)}}</a>
          <img [src]="'assets/icon/inventory/delete.svg'" (click)="deleteVideo(iVideo)" class="y3-cursor-pointer y3-margin-left-8">
        </div>
      </div>
    </div>

    <div class="g11-upload ">
      <app-shared-upload (docUploaded)="onVideosUpload($event)" [allowedTypes]="utilService.allowVideosDocs"
                         [btnLabel]="'guest-inventory.upload'" [uploadType]="'multiple'"></app-shared-upload>
    </div>

    <div class=" y3-margin-top-16">
      <app-button [type]="'primary'" [text]="'guest-inventory.validate' | translate" (debounceClick)="validate()"></app-button>
    </div>
  </div>

</div>
