import * as i0 from "@angular/core";
export class SecurityService {
    removeSecurtityPassword() {
        localStorage.removeItem("CheckPassword");
    }
    setSecurityPassword(bookingId) {
        if (bookingId != null) {
            localStorage.setItem("CheckPassword", bookingId);
        }
    }
    getSecurityPassword() {
        return localStorage.getItem("CheckPassword");
    }
}
SecurityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SecurityService_Factory() { return new SecurityService(); }, token: SecurityService, providedIn: "root" });
