/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-property-gallery.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/guest-gallery-detail/guest-gallery-detail.component.ngfactory";
import * as i3 from "../../../shared/components/guest-gallery-detail/guest-gallery-detail.component";
import * as i4 from "@angular/router";
import * as i5 from "../guest.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "./guest-property-gallery.component";
import * as i8 from "../../../core/services/internal.property.service";
var styles_GuestPropertyGalleryComponent = [i0.styles];
var RenderType_GuestPropertyGalleryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestPropertyGalleryComponent, data: {} });
export { RenderType_GuestPropertyGalleryComponent as RenderType_GuestPropertyGalleryComponent };
export function View_GuestPropertyGalleryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-gallery-detail", [], null, null, null, i2.View_GuestGalleryDetailComponent_0, i2.RenderType_GuestGalleryDetailComponent)), i1.ɵdid(1, 114688, null, 0, i3.GuestGalleryDetailComponent, [i4.ActivatedRoute, i4.Router, i5.GuestServiceIntern, i6.MAT_DIALOG_DATA, i6.MatDialogRef], { photos: [0, "photos"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.photos; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GuestPropertyGalleryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-property-gallery", [], null, null, null, View_GuestPropertyGalleryComponent_0, RenderType_GuestPropertyGalleryComponent)), i1.ɵdid(1, 114688, null, 0, i7.GuestPropertyGalleryComponent, [i8.InternalPropertyService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestPropertyGalleryComponentNgFactory = i1.ɵccf("app-guest-property-gallery", i7.GuestPropertyGalleryComponent, View_GuestPropertyGalleryComponent_Host_0, {}, {}, []);
export { GuestPropertyGalleryComponentNgFactory as GuestPropertyGalleryComponentNgFactory };
