import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GuestResolverService} from "../guest/guest-resolver.service";

import {OnlineCheckDetailsComponent} from './online-check-details.component';
import {OnlineCheckLightComponent} from './online-check-light/online-check-light.component';

const routes: Routes = [
  {
    path: '',
    // resolve: {propertyAndBooking: GuestResolverService},
    children: [

      {
        path: ':bookingId',
        component: OnlineCheckDetailsComponent
      },
      {
        path: 'light/:bookingId',
        component: OnlineCheckLightComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnlinecheckRoutingModule {

}
