/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./haloconcierge.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./haloconcierge.component";
var styles_HaloconciergeComponent = [i0.styles];
var RenderType_HaloconciergeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HaloconciergeComponent, data: {} });
export { RenderType_HaloconciergeComponent as RenderType_HaloconciergeComponent };
export function View_HaloconciergeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "footer clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "images_footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["src", "../../../../../assets/images/footers/halo-conciergerie/halo-crabe.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "Logo Halo Conciergerie"], ["src", "../../../../../assets/images/footers/halo-conciergerie/footer_logo_halo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", ""], ["src", "../../../../../assets/images/footers/halo-conciergerie/halo-poisson.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 7, "p", [["class", "colophon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 2020 - 2022 \u2013 Halo Conciergerie en Vend\u00E9e "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [["href", "https://www.haloconcierge.fr/contact.html"], ["rel", "nofollow"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contact"])), (_l()(), i1.ɵted(-1, null, [" \u2013 "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "a", [["href", "https://www.haloconcierge.fr/mentions-legales-credits.html"], ["rel", "nofollow"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Mentions l\u00E9gales"]))], null, null); }
export function View_HaloconciergeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-haloconcierge", [], null, null, null, View_HaloconciergeComponent_0, RenderType_HaloconciergeComponent)), i1.ɵdid(1, 114688, null, 0, i2.HaloconciergeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HaloconciergeComponentNgFactory = i1.ɵccf("app-haloconcierge", i2.HaloconciergeComponent, View_HaloconciergeComponent_Host_0, {}, {}, []);
export { HaloconciergeComponentNgFactory as HaloconciergeComponentNgFactory };
