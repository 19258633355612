/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./shared/components/loader/loader.component.ngfactory";
import * as i4 from "./shared/components/loader/loader.component";
import * as i5 from "./core/services/LoaderService";
import * as i6 from "./core/services/utils.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./shared/components/shared-snackbar/shared-snackbar.component.ngfactory";
import * as i9 from "./shared/components/shared-snackbar/shared-snackbar.component";
import * as i10 from "./shared/components/shared-snackbar/services/shared-snackbar.service";
import * as i11 from "./app.component";
import * as i12 from "./core/services/icon.service";
import * as i13 from "./core/services/SecurityService";
import * as i14 from "./core/services/language.service";
import * as i15 from "./core/services/guest.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "scrollable-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, [[null, "activate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activate" === en)) {
        var pd_0 = (_co.onActivate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, { activateEvents: "activate" }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-loader", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_LoaderComponent_0, i3.RenderType_LoaderComponent)), i1.ɵdid(5, 245760, null, 0, i4.LoaderComponent, [i5.LoaderService, i6.UtilsService, i7.TranslateService], { corporate: [0, "corporate"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "gusl-shared-snackbar", [], null, null, null, i8.View_SharedSnackbarComponent_0, i8.RenderType_SharedSnackbarComponent)), i1.ɵdid(7, 114688, null, 0, i9.SharedSnackbarComponent, [i10.SharedSnackbarService], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.corporate; _ck(_v, 5, 0, currVal_0); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i11.AppComponent, [i7.TranslateService, i12.IconService, i2.ActivatedRoute, i2.Router, i13.SecurityService, i14.LanguageManagementService, i15.GuestService, i6.UtilsService, i5.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i11.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
