<div class="m14-main-container">
  <div class="m14-close-button">
    <img src="assets/icon/close-round.svg" alt="close" (click)="goBack()" />
  </div>


  <div class="y3-margin-top-24 y3-margin-bottom-24">
    <div class="y3-High y3-bold y3-title-colors">{{'m14-page.title' | translate}}</div>
  </div>

  <div class="y3-margin-top-16 y3-flex-row y3-flex-align-center">
    <app-star-rating class="stars" [(rating)]="rating"></app-star-rating>
    <div class="y3-margin-left-8 y3-Large y3-bold">{{rating}}</div>
  </div>

  <div class="y2021-flexrow margin-top-16 div-button">
    <div class="y3-margin-bottom-8">
      <textarea class="y3-input-text-small " style="width: 100%" rows="10" [(ngModel)]="textReview"></textarea>
    </div>

    <app-button [type]="'primary'" text="{{'booklet-review.avis-submit' | translate}}" (debounceClick)="saveReview()"></app-button>

  </div>

</div>

